import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isSmallScreen } from 'app/utils/windowUtils';
import IntlCrawlableLink from 'app/components/IntlCrawlableLink';
import { isElectron } from 'app/utils/envUtil';
import { useSelector } from 'react-redux';
import { selectLocale } from 'app/redux/language/selectors';
import { FRONT_END_PATH } from 'app/redux/global/constants';

function ButtonRegister() {
  const text = isSmallScreen() ? (
    <i className="ui icon child user-icon" />
  ) : (
    <FormattedMessage id="button.register.text" />
  );

  const classes = 'ui inverted button';
  const styles = { marginRight: '11px' };
  const page = 'register';

  if (isElectron()) {
    const locale = useSelector(selectLocale);
    return (
      <a href={`${FRONT_END_PATH}/${locale}/${page}`} className={classes} style={styles}>
        {text}
      </a>
    );
  }

  return (
    <IntlCrawlableLink url={`/${page}`} className={classes} style={styles}>
      {text}
    </IntlCrawlableLink>
  );
}

export default ButtonRegister;
