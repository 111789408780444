import {
  generatePicoxiaDicomHeader,
  updateDicomDataAcquisitionParams,
  updateDicomDataID,
  updateDicomDataPatient,
  updateDicomDataViewDescription,
} from 'app/utils/dicom/DicomData';

export default function makeDicomFromAcquisitionDetails({
  studyId,
  imageId,
  imageIndex,
  acquisitionTime,
  dicomData,
  intl,
  patient = undefined,
  detectorInfo = undefined,
  acquisitionConstants = undefined,
  anatomicRegion = undefined,
  forceUniqueID = false,
}) {
  // We should always add header first else the other fields will be incorrect
  let generatedDicomData = { ...generatePicoxiaDicomHeader(), ...dicomData };
  generatedDicomData = updateDicomDataPatient(generatedDicomData, patient, intl);
  generatedDicomData = updateDicomDataID(
    generatedDicomData,
    studyId,
    imageId,
    imageIndex,
    forceUniqueID
  );
  generatedDicomData = updateDicomDataAcquisitionParams(generatedDicomData, {
    acquisitionTime,
    isProcessedImage: true,
    ...detectorInfo,
  });
  generatedDicomData = updateDicomDataViewDescription(
    generatedDicomData,
    intl,
    anatomicRegion,
    acquisitionConstants
  );

  return generatedDicomData;
}
