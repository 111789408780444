import './style.scss';

import React, { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Creatable from 'react-select/creatable';
import _ from 'lodash';
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select';

const listToOptions = (value: string) => ({
  label: value,
  value,
});

type AETitleSelectorProps = {
  aeTitles: string[];
  setAETitles: (aeTitles: string[]) => void;
};

type AETitleOption = {
  label: string;
  value: string;
};

const AETitleSelector = ({ aeTitles, setAETitles }: AETitleSelectorProps) => {
  const selectedOperatorsOptions = useMemo(() => aeTitles?.map(listToOptions) ?? [], [aeTitles]);
  const [inputValue, setInputValue] = React.useState('');
  const onCreateAETitle = useCallback(
    (aeTitle: string) => {
      setAETitles([...aeTitles, aeTitle]);
    },
    [setAETitles, aeTitles]
  );
  const onBlurAETitle = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (!event.target.value) return;
      setAETitles([...aeTitles, event.target.value]);
    },
    [setAETitles, aeTitles]
  );
  const onChange = (value: ValueType<AETitleOption, true>, action: ActionMeta<AETitleOption>) => {
    if (action.action === 'pop-value') {
      setAETitles(_.without(aeTitles, action?.removedValue.value));
    }
    if (action.action === 'remove-value') {
      setAETitles(_.without(aeTitles, action?.removedValue.value));
    }
    if (action.action === 'clear') {
      setAETitles([]);
    }
  };

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        if (!event.target.value) return;
        setAETitles([...aeTitles, event.target.value]);
        setInputValue('');
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [setAETitles, aeTitles]
  );

  return (
    <Creatable
      isMulti
      inputValue={inputValue}
      onInputChange={setInputValue}
      onChange={onChange}
      options={[]}
      value={selectedOperatorsOptions}
      menuIsOpen={false}
      styles={{
        container: (provided) => ({ ...provided, width: '100%' }),
        control: (provided) => ({ ...provided, borderRadius: '0 4px 4px 0' }),
      }}
      onBlur={onBlurAETitle as any}
      onCreateOption={onCreateAETitle}
      components={{ DropdownIndicator: null, IndicatorSeparator: null }}
      isValidNewOption={(inputValue) => {
        // AET Titles should be 16 characters or less and contain only uppercase letters, numbers, and underscores
        return /^[A-Z0-9_]{1,16}$/.test(inputValue);
      }}
      onKeyDown={onKeyDown as any}
      placeholder={<FormattedMessage id="pacs.configuration.worklist.aetitle.placeholder" />}
    />
  );
};

export default memo(AETitleSelector);
