import { createContext } from 'react';

export interface SharedUserConfig {
  noXRayDeletion: boolean;
}

export interface SharedUserConfigurationAPI {
  // This function should update the user configuration on the server
  // It should handle the retry mechanism
  update: (config: SharedUserConfig) => Promise<void>;

  get: () => Promise<SharedUserConfig>;
}

export const SharedUserConfigurationAPIContext =
  createContext<SharedUserConfigurationAPI>(undefined);
