import React from 'react';
import Helmet from 'react-helmet';
import { makeHelmetHreflangLinks } from 'app/utils/languageUtil';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectLocale } from 'app/redux/language/selectors';
import * as PropTypes from 'prop-types';

/**
 *
 * @param {Object} props
 * @param {string} props.titleIntlID
 * @param {string} props.descriptionIntlID
 * @param {Object[]} [props.additionnalMeta]
 * @returns
 */
function AppHelmet({ titleIntlID, descriptionIntlID, additionnalMeta }) {
  const intl = useIntl();

  const additionalMetaContent = [];
  if (additionnalMeta) {
    additionnalMeta.forEach((metaData) => {
      additionalMetaContent.push({
        name: metaData.name,
        content: metaData.content,
      });
    });
  }
  const fullMetaContent = [
    {
      name: 'description',
      content: intl.formatMessage({ id: descriptionIntlID }),
    },
    ...additionalMetaContent,
  ];

  const locale = useSelector(selectLocale);
  return (
    <Helmet
      htmlAttributes={{ lang: locale }}
      link={makeHelmetHreflangLinks(locale)}
      title={intl.formatMessage({ id: titleIntlID })}
      meta={fullMetaContent}
    />
  );
}

AppHelmet.propTypes = {
  additionnalMeta: PropTypes.array,
};

export default AppHelmet;
