import 'semantic-ui-css/semantic.min.css';

import './style.scss';

import { connect } from 'react-redux';
import React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';

import { FormattedMessage, injectIntl } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import ApiCalls from 'app/utils/apiCalls';
import LoginButton from 'app/components/LoginButton';
import Pattern from 'app/components/Pattern';
import ButtonRegister from 'app/components/Button/Register';
import { postProcessPredictions } from 'app/utils/predictions/postProcessPredictions';
import { VALID_PREDICTION_TYPES } from 'app/utils/predictions/constants';
import { selectLoggedIn } from 'app/redux/global/selectors';

export const THRESHOLD_FOR_DOUBTFUL = 0.45;

class PatternStructuredList extends React.Component {
  constructor(props) {
    super(props);

    this.THRESHOLD_FOR_DOUBTFUL = THRESHOLD_FOR_DOUBTFUL;

    this.state = {
      // predictions: props.image,
      // feedback: {},
      showAlpha: null,
      thresholdForDisplay: this.THRESHOLD_FOR_DOUBTFUL,
      openHelpMessagePattern: null,
      showAllDifferentialDiagnosisForGroup: {},
    };
  }

  renderPredictions = () => {
    const { image } = this.props;
    if (!image || Object.keys(image.predictions).length === 0) {
      return null;
    }
    const isBadImage = !VALID_PREDICTION_TYPES.includes(image.predictions.type);
    if (isBadImage) return null;

    return (
      <div>
        {this.renderPositivePatterns()}
        <h4 className="ui dividing header patternGroup">
          <FormattedMessage id="patternStructuredList.leastLikely" />
        </h4>
        {this.renderNegativePatterns()}
        <button
          type="button"
          onClick={this.showAll}
          style={{ fontWeight: 'bold', color: '#459ad3' }}
          className={this.state.thresholdForDisplay === 0 ? 'hidden' : ''}
        >
          <FormattedMessage id="patternStructuredList.showAll" />
          <i className="caret down icon" />
        </button>
        <button
          type="button"
          onClick={this.hideUnlikelyResults}
          style={{ fontWeight: 'bold', color: '#459ad3' }}
          className={this.state.thresholdForDisplay !== 0 ? 'hidden' : ''}
        >
          <FormattedMessage id="patternStructuredList.hideUnlikelyResults" />
          <i className="caret up icon" />
        </button>
      </div>
    );
  };

  showAll = () => {
    this.setState({ thresholdForDisplay: 0 });
  };

  hideUnlikelyResults = () => {
    this.setState({ thresholdForDisplay: this.THRESHOLD_FOR_DOUBTFUL });
  };

  renderNegativePatterns = () => {
    const { image } = this.props;
    const { thresholdForDisplay } = this.state;
    const { feedback } = image;
    const pathologiesSorted = this.sortPredictions(image.predictions);
    return pathologiesSorted.map((pattern) => {
      const confidence = image.predictions[pattern];
      if (confidence < this.THRESHOLD_FOR_DOUBTFUL && !feedback[pattern]) {
        return this.renderSinglePrediction(pattern, confidence);
      }
      if (feedback[pattern] === false && thresholdForDisplay === 0) {
        return this.renderSinglePrediction(pattern, confidence);
      }
      return null;
    });
  };

  renderPositivePatterns = () =>
    // eslint-disable-next-line react/destructuring-assignment
    Object.keys(this.props.groups).map((groupName) => this.renderGroup(groupName));

  renderGroup = (groupName) => (
    <div key={groupName}>
      <h4 className="ui dividing header patternGroup">
        <FormattedMessage id={groupName} /> {this.renderOkay(groupName)}
      </h4>
      {this.renderGroupSpecificFeaturesBefore(groupName)}
      {this.renderGroupPathologies(groupName)}
      {this.renderDifferentialDiagnosis(groupName)}
    </div>
  );

  renderOkay = (groupName) => {
    const { image, groups } = this.props;
    const positivePatternsInGroup = groups[groupName].patterns.filter(
      (patternName) => image.predictions[patternName] >= this.THRESHOLD_FOR_DOUBTFUL
    );
    if (positivePatternsInGroup.length === 0) {
      return <FormattedMessage id="patternStructuredList.noPatternInThisGroup" />;
    }
    return null;
  };

  renderDifferentialDiagnosis = (groupName) => {
    const thresholdForPresence = 0.45;
    const { groups, image, patterns } = this.props;
    const { differentialDiagnosis } = groups[groupName];
    if (groups[groupName].differentialDiagnosis === undefined) {
      return null;
    }

    const { predictions, feedback } = image;
    const { thresholdForDisplay, showAllDifferentialDiagnosisForGroup } = this.state;
    const showAllDifferentialDiagnosis = showAllDifferentialDiagnosisForGroup[groupName];

    const switchShowDifferentialDiagnosis = () =>
      this.setState((state) => ({
        showAllDifferentialDiagnosisForGroup: {
          ...state.showAllDifferentialDiagnosisForGroup,
          [groupName]: !state.showAllDifferentialDiagnosisForGroup[groupName],
        },
      }));

    const sortedDifferentialDiagnosisPredictions = _(differentialDiagnosis)
      .filter((patternName) => predictions[patternName] !== undefined)
      .orderBy((patternName) => predictions[patternName], 'desc')
      .value();

    const positiveDD = sortedDifferentialDiagnosisPredictions.filter(
      (patternName) => predictions[patternName] >= thresholdForPresence || feedback[patternName]
    );
    const numberOfPositiveDD = positiveDD.length;

    const symptomPatterns = _.keys(
      _.pickBy(patterns, (pattern) => pattern.differentialDiagnosis === differentialDiagnosis)
    );
    const isAnySymptomPatternPresent = _.some(
      symptomPatterns,
      (patternName) => predictions[patternName] > thresholdForPresence || feedback[patternName]
    );

    if (numberOfPositiveDD === 0 && !showAllDifferentialDiagnosis) {
      if (isAnySymptomPatternPresent) {
        return (
          <button
            onClick={switchShowDifferentialDiagnosis}
            style={{ color: 'white' }}
            type="button"
          >
            <FormattedMessage id="patternStructuredList.differentialDiagnosisEmpty" />
            <Icon
              name={`angle ${showAllDifferentialDiagnosis ? 'down' : 'right'}`}
              style={{ marginRight: '3px' }}
            />
          </button>
        );
      }
      return null;
    }

    const DDToDisplay = showAllDifferentialDiagnosis
      ? sortedDifferentialDiagnosisPredictions
      : positiveDD;

    const renderedDD = DDToDisplay.map((patternName) => {
      const patternConfidence = predictions[patternName];
      const threshold = showAllDifferentialDiagnosis ? 0 : thresholdForDisplay;
      return this.renderSinglePrediction(patternName, patternConfidence, threshold, true);
    });

    return (
      <div className="patternStructuredList">
        <div className="patternStructuredList__title">
          <Icon name="map signs" style={{ fontSize: '1.1em' }} />
          <FormattedMessage id="patternStructuredList.differentialDiagnosis" />
          <Icon
            name={`angle ${showAllDifferentialDiagnosis ? 'down' : 'right'}`}
            onClick={switchShowDifferentialDiagnosis}
            style={{ cursor: 'pointer', marginRight: '3px' }}
          />
        </div>
        <div style={{ marginLeft: '38px', marginTop: '5px', marginBottom: '12px' }}>
          {renderedDD}
        </div>
      </div>
    );
  };

  renderGroupSpecificFeaturesBefore = (groupName) => {
    const { groupSpecificChildrenBefore } = this.props;
    return groupSpecificChildrenBefore?.[groupName];
  };

  renderGroupPathologies = (groupName) => {
    const { image, groups } = this.props;
    const { feedback } = image;
    const predictions = postProcessPredictions(image.predictions);
    const pathologiesInGroup = this.getPathologiesInGroupSorted(groupName, predictions, groups);
    return pathologiesInGroup.map((pattern) => {
      const confidence = predictions[pattern];
      if (confidence >= this.THRESHOLD_FOR_DOUBTFUL && feedback[pattern] !== false) {
        return this.renderSinglePrediction(pattern, confidence);
      }
      if (feedback[pattern]) {
        return this.renderSinglePrediction(pattern, 1);
      }
      return null;
    });
  };

  getPathologiesInGroupSorted = (groupName, predictions, groups) => {
    const pathologiesSorted = this.sortPredictions(predictions);
    return pathologiesSorted.filter((pattern) => groups[groupName].patterns.indexOf(pattern) > -1);
  };

  sortPredictions = (dict) => {
    let items = Object.keys(dict).map((key) => [key, dict[key]]);

    items.sort((first, second) => second[1] - first[1]);

    items = items.map((item) => item[0]);

    return items;
  };

  giveFeedback = (patternName, patternValue) => {
    const { onFeedbackUpdate } = this.props;

    onFeedbackUpdate(patternName, patternValue);
  };

  renderSinglePrediction = (
    patternName,
    patternConfidence,
    thresholdForDisplay,
    renderEvenIfNotInPatterns = false
  ) => {
    const { image, patterns } = this.props;
    if (thresholdForDisplay === undefined) {
      // eslint-disable-next-line no-param-reassign
      ({ thresholdForDisplay } = this.state);
    }
    if (!renderEvenIfNotInPatterns && patterns[patternName] === undefined) return null;
    const showHelpIcon = patterns[patternName]?.Causes !== undefined;
    const showAlphaButton = patterns[patternName]?.isAlpha === true;
    if (patternConfidence < thresholdForDisplay) {
      return null;
    }
    return (
      <div className="ui divided selection list" key={patternName}>
        <div>
          <Pattern
            showFeedbackButtons
            feedback={image.feedback[patternName]}
            giveFeedback={this.giveFeedback}
            confidence={patternConfidence}
            patternName={patterns[patternName]?.nameAlias ?? patternName}
            thresholdForDisplay={thresholdForDisplay}
          />
          {showAlphaButton && this.showAlphaButton(patternName)}
          {showHelpIcon && this.showHelpIcon(patternName)}
        </div>
        {this.renderAlphaMessage(patternName)}
        {this.renderHelpMessage(patternName)}
      </div>
    );
  };

  showHelpIcon = (patternName) => (
    <button
      type="button"
      className="dark-button circular"
      onClick={() => this.toggleHelpMessage(patternName)}
    >
      ?
    </button>
  );

  toggleHelpMessage = (patternName) => {
    if (this.state.openHelpMessagePattern === patternName) {
      this.setState({ openHelpMessagePattern: null });
    } else {
      this.setState({ openHelpMessagePattern: patternName });
    }
  };

  renderHelpMessage = (patternName) => {
    if (this.state.openHelpMessagePattern !== patternName) {
      return null;
    }
    return (
      <div className="patternStructuredList">
        <div className="patternStructuredList__title">
          <FormattedMessage id="patternStructuredList.possibleCauses" />
        </div>
        <ul className="causes">{this.renderPatternCauses(patternName)}</ul>
      </div>
    );
  };

  renderPatternCauses = (patternName) =>
    // eslint-disable-next-line react/destructuring-assignment
    this.props.patterns[patternName]?.Causes.map((cause, index) => (
      <li key={`cause_${patternName}_${index}`}>
        <FormattedMessage id={`patterns.causes.${patternName}.${cause}`} />
      </li>
    ));

  showAlphaButton = (patternName) => (
    <button
      type="button"
      className="beta-button"
      onClick={() => this.toggleAlphaMessage(patternName)}
    >
      β
    </button>
  );

  toggleAlphaMessage = (patternName) => {
    if (this.state.showAlpha === patternName) {
      this.setState({ showAlpha: null });
    } else {
      this.setState({ showAlpha: patternName });
    }
  };

  renderAlphaMessage = (patternName) => {
    if (this.state.showAlpha === patternName) {
      return (
        <div className="patternStructuredList">
          <div className="patternStructuredList__title">
            <FormattedMessage id="patternStructuredList.alpha" />
          </div>
          <div style={{ margin: '12px', marginLeft: '20px', lineHeight: '1.8em', color: 'grey' }}>
            <FormattedMessage id="patternStructuredList.alphaPatternMessage" />
          </div>
        </div>
      );
    }
    return null;
  };

  renderSubscribe = (numberOfFreeRequests) => {
    if (!this.props.isLoggedIn) {
      return (
        <div style={{ marginTop: '2vh', textAlign: 'center' }}>
          <div style={{ marginTop: '2vh' }}>
            <h3>
              <FormattedMessage
                id="patternStructuredList.registerToGetMoreCredits"
                values={{ numberOfFreeRequests }}
              />
            </h3>
          </div>
          <div style={{ marginTop: '2vh', textAlign: 'center' }}>
            <ButtonRegister />
            <LoginButton />
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        {this.renderPredictions()}
        {this.renderSubscribe(5)}
      </div>
    );
  }
}

PatternStructuredList.propTypes = {
  groups: PropTypes.shape().isRequired,
  patterns: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  onFeedbackUpdate: PropTypes.func,
  image: PropTypes.shape({
    predictions: PropTypes.shape(),
    feedback: PropTypes.shape(),
  }).isRequired,
  groupSpecificChildrenBefore: PropTypes.shape(),
};

PatternStructuredList.defaultProps = {
  onFeedbackUpdate: () => {},
  groupSpecificChildrenBefore: null,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: selectLoggedIn(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PatternStructuredList));
