import AppHelmet from 'app/components/AppHelmet';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Icon } from 'semantic-ui-react';

import './style.scss';

const tutorials = [
  {
    titleId: 'tutorials.registerAndUseOnline',
    videoTranslationKey: 'tutorials.registerAndUseOnline.videoId',
  },
  {
    titleId: 'tutorials.installPicoxIAApp',
    videoTranslationKey: 'tutorials.installPicoxIAApp.videoId',
  },
  {
    titleId: 'tutorials.sendXRays',
    videoTranslationKey: 'tutorials.sendXRays.videoId',
  },
];

const TutorialsPage = () => {
  const intl = useIntl();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div>
      <AppHelmet titleIntlID="tutorials.pageTitle" descriptionIntlID="tutorials.pageDescription" />
      <div className="ui container tutorials-page">
        <h1 className="tutorials-page__title">
          <FormattedMessage id="tutorials.title" />
        </h1>
        <div className="ui custom divider tutorials-page__divider" />
        <p className="tutorials-page__description">
          <FormattedMessage id="tutorials.text.description" />
        </p>
        <Accordion styled className="tutorials-page__accordion">
          {tutorials.map((tutorial, index) => (
            <article key={tutorial.titleId} className="tutorial">
              <Accordion.Title
                as="h2"
                className="tutorial__title"
                active={activeIndex === index}
                index={index}
                onClick={() => {
                  setActiveIndex(index);
                }}
              >
                <Icon name="dropdown" />
                <FormattedMessage id={tutorial.titleId} />
              </Accordion.Title>
              <Accordion.Content active={activeIndex === index} className="tutorial__content">
                <iframe
                  className="tutorial__iframe"
                  src={`https://www.youtube.com/embed/${intl.formatMessage({
                    id: tutorial.videoTranslationKey,
                  })}?rel=0&amp;loop=1`}
                  title={intl.formatMessage({ id: tutorial.titleId })}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </Accordion.Content>
            </article>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default TutorialsPage;
