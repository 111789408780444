import { Button, Segment, Table, Header, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as pt from 'prop-types';
import React from 'react';

import { injectIntl, FormattedMessage, useIntl } from 'react-intl';

import DisplayDate from '../DisplayDate';
import ApiCalls from '../../utils/apiCalls';
import { CREDIT_PACKS } from '../../containers/BillingPage/packs';
import { getCouponMessage } from '../../containers/BillingPage/utils';
import { selectLoggedIn } from 'app/redux/global/selectors';

function DisplaySubscriptionTranslationOrKey({ prefix, name }) {
  const intl = useIntl();

  const expectedTranslationKey = `${prefix}.${name}`;
  const isPresentInTranslations = !!intl.messages[expectedTranslationKey];
  if (!isPresentInTranslations) return name;

  return <FormattedMessage id={`${prefix}.${name}`} />;
}

class PurchaseHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseHistory: [],
    };
  }

  componentDidMount = () => {
    this.getPurchaseHistory();
  };

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  getPurchaseHistory = () => {
    const { isLoggedIn } = this.props;
    if (isLoggedIn) {
      ApiCalls.getPurchaseHistory().then((response) => {
        this.setState({ purchaseHistory: response.data.purchaseHistory });
      });
    }
  };

  unsubscribe = (mongoIdToDelete) => {
    ApiCalls.unsubscribe(mongoIdToDelete).then(() => {
      this.getPurchaseHistory();
    });
  };

  renderSubscriptionHistory = () => {
    const { purchaseHistory } = this.state;
    if (purchaseHistory.length === 0 || !purchaseHistory[0].subscription) {
      return null;
    }
    return purchaseHistory[0].subscription.map((purchasedItem, index) => (
      <Table.Row key={`purchasedItem${index}`}>
        <Table.Cell>
          <Header as="h4">
            <Header.Content>
              <DisplaySubscriptionTranslationOrKey
                prefix="billing.subscriptionPlansNames"
                name={purchasedItem.backend_name}
              />
              <Header.Subheader>
                <DisplayDate date={purchasedItem.buyDate} format="dateNotDayInWeek" long />
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Table.Cell>
        <Table.Cell>{this.renderPrice(purchasedItem)}</Table.Cell>
        <Table.Cell>
          <Header as="h4">
            <Header.Content>
              <DisplayDate date={purchasedItem.currentPeriodEnd} format="dateNotDayInWeek" long />
              {this.renderAutomaticRenew(purchasedItem)}
            </Header.Content>
          </Header>
        </Table.Cell>
        <Table.Cell>{this.getDisplayUsedCredits(purchasedItem)}</Table.Cell>
        <Table.Cell>{getCouponMessage(purchasedItem.coupon, this.formatMessage)}</Table.Cell>
        <Table.Cell>{this.renderUnsubscribeButton(purchasedItem)}</Table.Cell>
      </Table.Row>
    ));
  };

  renderAutomaticRenew = (purchasedItem) => {
    if (purchasedItem.isCancellable && !purchasedItem.cancelled) {
      return (
        <Header.Subheader>
          <FormattedMessage id="billing.subscription.automaticallyRenewed" />
        </Header.Subheader>
      );
    }
    return null;
  };

  renderUnsubscribeButton = (purchasedItem) => {
    if (!purchasedItem.isCancellable) {
      return null;
    }

    if (purchasedItem.cancelled) {
      return (
        <Label color="red">
          <FormattedMessage id="billing.subscription.cancelled" />
        </Label>
      );
    }

    if (Date.parse(purchasedItem.currentPeriodEnd) <= Date.now()) {
      return null;
    }

    return (
      <Button color="red" basic compact onClick={() => this.unsubscribe(purchasedItem._id)}>
        <FormattedMessage id="billing.unsubscribe" />
      </Button>
    );
  };

  renderPrice = (purchasedItem) => {
    const CURRENCY_TO_SYMBOL = { gbp: '£', eur: '€', usd: '$', cad: '$ CA' };
    const currency = CURRENCY_TO_SYMBOL[purchasedItem.currency] || '€';
    if (purchasedItem.price === 0) {
      return ' - ';
    }
    return `${purchasedItem.price / 100} ${currency}`;
  };

  renderCreditsHistory = () => {
    const { purchaseHistory } = this.state;
    if (purchaseHistory.length === 0 || !purchaseHistory[0].credits) {
      return null;
    }
    return purchaseHistory[0].credits.map((purchasedItem, index) => (
      // <Header.Subheader>Human Resources</Header.Subheader>
      <Table.Row key={`purchasedItem${index}`}>
        <Table.Cell>
          <Header as="h4">
            <Header.Content>
              <DisplaySubscriptionTranslationOrKey
                prefix="billing.packsNames"
                name={purchasedItem.backend_name}
              />
              <Header.Subheader>
                <DisplayDate date={purchasedItem.buyDate} format="dateNotDayInWeek" long />
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Table.Cell>
        <Table.Cell>{this.renderPrice(purchasedItem)}</Table.Cell>
        <Table.Cell>{this.getDisplayUsedCredits(purchasedItem)}</Table.Cell>
        <Table.Cell>{getCouponMessage(purchasedItem.coupon, this.formatMessage)}</Table.Cell>
      </Table.Row>
    ));
  };

  getDisplayUsedCredits = (purchasedItem) => {
    if (CREDIT_PACKS.hasOwnProperty(purchasedItem.backend_name)) {
      return `${purchasedItem.used}/${purchasedItem.numberOfImages}`;
    }
    return purchasedItem.used;
  };

  renderSubscriptionsTable = () => {
    const { purchaseHistory } = this.state;
    if (purchaseHistory.length === 0 || !purchaseHistory[0].subscription) {
      return null;
    }
    return (
      <Segment style={{ marginBottom: '40px' }}>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage id="billing.subscription.name" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="billing.price" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="billing.subscription.expires" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="billing.subscription.used" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="billing.coupon" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="billing.action" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderSubscriptionHistory()}</Table.Body>
        </Table>
      </Segment>
    );
  };

  renderCreditsTable = () => {
    const { purchaseHistory } = this.state;
    if (purchaseHistory.length === 0 || !purchaseHistory[0].credits.length) {
      return null;
    }
    return (
      <Segment style={{ marginBottom: '40px' }}>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage id="billing.pack" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="billing.price" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="billing.credits" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="billing.coupon" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderCreditsHistory()}</Table.Body>
        </Table>
      </Segment>
    );
  };

  render() {
    return (
      <>
        {this.renderSubscriptionsTable()}
        {this.renderCreditsTable()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: selectLoggedIn(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

PurchaseHistory.propTypes = {
  isLoggedIn: pt.bool.isRequired,
  intl: pt.shape().isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PurchaseHistory));
