export const SET_DETECTOR_KIND = 'SET_DETECTOR_KIND' as const;
export const SET_DETECTOR_IP_CONFIGURATION = 'SET_DETECTOR_IP_CONFIGURATION' as const;

export type DetectorKind = 'GER' | 'THALES';

export const setDetectorKind = (detectorKind: DetectorKind) => ({
  type: SET_DETECTOR_KIND,
  payload: { detectorKind },
});

export type DetectorIPConfiguration = { detectorIP: string; hostIP: string };

export const setDetectorIPConfiguration = (ipConfiguration: DetectorIPConfiguration) => ({
  type: SET_DETECTOR_IP_CONFIGURATION,
  payload: ipConfiguration,
});

export type FlatPanelConfigurationAction =
  | ReturnType<typeof setDetectorKind>
  | ReturnType<typeof setDetectorIPConfiguration>;
