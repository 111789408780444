import reportTemplateHtml from 'static/template-generic-report.html';
import { ageToString } from 'app/components/AgeFromDate';
import { splitReport } from 'app/utils/reports/reportsGenerator';
import { StudyType } from 'app/pms/exporter/PmsExporter';
import { IntlShape } from 'react-intl';
import _ from 'lodash';
import { Patient } from 'app/interfaces/Patient';
import { DEFAULT_SPECIE } from 'app/constants/species';

export type reportInfoType = {
  _id: string;
  mail: string;
  last_name: string;
  first_name: string;
  clinicInfo: string;
  has_logo: boolean;
  clinic_logo?: {
    base64: string;
    height: number;
    width: number;
  };
};

const BR = '<br/>';

function nl2br(str: string) {
  let newStr = _.trim(str, '\n');
  return newStr.replaceAll('\n', BR);
}

export function getReportHtml(
  study: StudyType,
  patient: Patient,
  images: string[],
  reportInfo: reportInfoType,
  intl: IntlShape
): string {
  const { name = '', owner_name = '', sex = '', birth_date } = patient ?? {};
  const { comment } = study;
  const animalAgeString = ageToString(birth_date, intl);
  const specieString = patient.specie ?? DEFAULT_SPECIE;
  const { header, compteRendu, conclusion, recommandations } = splitReport(comment, intl);
  const clinicMail = reportInfo.mail;
  const clinicInfosArray = reportInfo.clinicInfo.split('\n');
  const clinicName = clinicInfosArray[0];
  const clinicLogo =
    reportInfo.has_logo !== true
      ? ''
      : `<img src="${reportInfo.clinic_logo.base64}" alt="${clinicName}" />`;
  const clinicInfo = clinicInfosArray.join(BR);

  let reportHtml = reportTemplateHtml.replaceAll(
    '{docx.title}',
    intl.formatMessage({ id: 'docx.title' })
  );
  reportHtml = reportHtml.replaceAll('{docx.clinic}', intl.formatMessage({ id: 'docx.clinic' }));
  reportHtml = reportHtml.replaceAll('{docx.email}', intl.formatMessage({ id: 'docx.email' }));
  reportHtml = reportHtml.replaceAll('{docx.patient}', intl.formatMessage({ id: 'docx.patient' }));
  reportHtml = reportHtml.replaceAll('{docx.owner}', intl.formatMessage({ id: 'docx.owner' }));
  reportHtml = reportHtml.replaceAll(
    '{docx.animal_name}',
    intl.formatMessage({ id: 'docx.animal_name' })
  );
  reportHtml = reportHtml.replaceAll('{docx.species}', intl.formatMessage({ id: 'docx.species' }));
  reportHtml = reportHtml.replaceAll('{docx.age}', intl.formatMessage({ id: 'docx.age' }));
  reportHtml = reportHtml.replaceAll('{docx.sex}', intl.formatMessage({ id: 'docx.sex' }));

  reportHtml = reportHtml.replaceAll('{clinic-logo}', clinicLogo);
  reportHtml = reportHtml.replaceAll('{clinicName}', clinicName);
  reportHtml = reportHtml.replaceAll('{ownerName}', owner_name);
  reportHtml = reportHtml.replaceAll('{animalName}', name);
  reportHtml = reportHtml.replaceAll('{clinicInfo}', clinicInfo);
  reportHtml = reportHtml.replaceAll('{clinicMail}', clinicMail);
  reportHtml = reportHtml.replaceAll('{specie}', specieString);
  reportHtml = reportHtml.replaceAll('{animalAge}', animalAgeString);
  reportHtml = reportHtml.replaceAll(
    '{animalSex}',
    sex ? intl.formatMessage({ id: `patient_info.sex.${sex}` }) : ''
  );
  reportHtml = reportHtml.replaceAll('{header}', nl2br(header));

  let compteRenduSection = '';
  if (nl2br(compteRendu) !== '') {
    compteRenduSection =
      '<h2>' + intl.formatMessage({ id: 'docx.radiographic_findings' }) + '</h2>';
    compteRenduSection += '<p>' + nl2br(compteRendu) + '</p>';
  }
  reportHtml = reportHtml.replaceAll('{compteRendu}', compteRenduSection);

  let conclusionSection = '';
  if (nl2br(conclusion) !== '') {
    conclusionSection = '<h2>' + intl.formatMessage({ id: 'docx.conclusion' }) + '</h2>';
    conclusionSection += '<p>' + nl2br(conclusion) + '</p>';
  }
  reportHtml = reportHtml.replaceAll('{conclusion}', conclusionSection);

  let recommandationsSection = '';
  if (nl2br(recommandations) !== '') {
    recommandationsSection = '<h2>' + intl.formatMessage({ id: 'docx.recommendations' }) + '</h2>';
    recommandationsSection += '<p>' + nl2br(recommandations) + '</p>';
  }
  reportHtml = reportHtml.replaceAll('{recommandations}', recommandationsSection);

  let xRaySection = '';
  if (images.length) {
    xRaySection = '<h2>' + intl.formatMessage({ id: 'docx.x-ray_images' }) + '</h2>';

    const imagesSection = images.map((img) => `<img src="data:${img}" />`).join('');
    xRaySection += `<div class="rx">${imagesSection}</div>`;
  }
  reportHtml = reportHtml.replaceAll('{x-ray-images}', xRaySection);

  return reportHtml;
}
