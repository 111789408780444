import React, { useCallback, useContext } from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import XRayGeneratorManagerContext from 'app/providers/XRayGeneratorManagerProvider/context';
import TechnicianPasswordValidationContext from 'app/providers/TechnicianPasswordValidationProvider/context';
import { toggleNoXRayDeletion } from 'app/redux/viewerConfiguration/actions';
import { selectNoXRayDeletion } from 'app/redux/viewerConfiguration/selector';

function NoXRayDeletionConfiguration() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const requestValidation = useContext(TechnicianPasswordValidationContext);
  const isNoXRayDeletion: boolean = useSelector(selectNoXRayDeletion) ?? false;

  const onChangeNoXRayDeletion: CheckboxProps['onChange'] = useCallback(
    (event, { checked }) =>
      requestValidation()
        .then(() => dispatch(toggleNoXRayDeletion(checked)))
        .catch(() => {}),
    [dispatch, requestValidation]
  );

  return (
    <div>
      <Checkbox
        toggle
        checked={isNoXRayDeletion}
        label={
          isNoXRayDeletion
            ? intl.formatMessage({ id: 'account.configuration.no_xray_deletion.enable' })
            : intl.formatMessage({ id: 'account.configuration.no_xray_deletion.disable' })
        }
        onChange={onChangeNoXRayDeletion}
      />
    </div>
  );
}

export default React.memo(NoXRayDeletionConfiguration);
