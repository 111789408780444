import './styles.scss';

import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, FormField, Icon, Message, MessageHeader } from 'semantic-ui-react';
import { browserHistory } from 'react-router';
import DropdownCountriesList from 'app/components/Dropdown/CountriesList';
import AppHelmet from 'app/components/AppHelmet';
import GoogleReCaptcha from 'app/components/Form/GoogleReCaptcha';
import countries from 'app/constants/countries';
import { CLEAR_ERROR, REQUEST_ERROR } from 'app/redux/global/actions';
import validator from 'validator';
import { selectLoginError } from 'app/redux/global/selectors';
import auth from 'app/containers/auth';
import { getInitLanguage } from 'app/utils/languageUtil';
import FormattedServerError from 'app/components/FormattedServerError';
import ModalTermsOfUse from 'app/containers/RegisterPage/Modal/TermsOfUse';

function RegisterPage() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const registerError = useSelector(selectLoginError);

  const [isLoading, setIsLoading] = useState(false);
  const [sentSuccessfully, setSentSuccessfully] = useState(false);

  const [workplaceName, setWorkplaceName] = useState('');
  const [address, setAddress] = useState('');
  const [postCode, setPostCode] = useState('');
  const [city, setCity] = useState('');
  const [userCountry, setUserCountry] = useState('');
  const [userCountryCode, setUserCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [mail, setMail] = useState('');
  const [sponsor, setSponsor] = useState('');
  const [password, setPassword] = useState('');
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [isVeterinarian, setIsVeterinarian] = useState(false);

  const [addedSponsor, setAddedSponsor] = useState(null);
  const [addedSponsorType, setAddedSponsorType] = useState(null);

  const captchaRef = useRef();

  const [missingWorkplaceNameError, setMissingWorkplaceNameError] = useState(false);
  const [missingAddressError, setMissingAddressError] = useState(false);
  const [missingPostCodeError, setMissingPostCodeError] = useState(false);
  const [missingCityError, setMissingCityError] = useState(false);
  const [missingCountryCodeError, setMissingCountryCodeError] = useState(false);
  const [missingMailError, setMissingMailError] = useState(false);
  const [invalidMailError, setInvalidMailError] = useState(false);
  const [missingPasswordError, setMissingPasswordError] = useState(false);
  const [missingTermsOfUseError, setMissingTermsOfUseError] = useState(false);
  const [missingIsVeterinarianError, setMissingIsVeterinarianError] = useState(false);

  const setFieldError = (fieldName, value) => {
    if ('workplaceName' === fieldName) {
      setMissingWorkplaceNameError(value);
    }
    if ('address' === fieldName) {
      setMissingAddressError(value);
    }

    if ('postCode' === fieldName) {
      setMissingPostCodeError(value);
    }
    if ('city' === fieldName) {
      setMissingCityError(value);
    }

    if ('countryCode' === fieldName) {
      setMissingCountryCodeError(value);
    }

    if ('password' === fieldName) {
      setMissingPasswordError(value);
    }

    if ('termsOfUse' === fieldName) {
      setMissingTermsOfUseError(value);
    }

    if ('isVeterinarian' === fieldName) {
      setMissingIsVeterinarianError(value);
    }
  };

  const checkFieldIsFilled = (fieldName) => {
    const fields = {
      workplaceName,
      address,
      postCode,
      city,
      userCountryCode,
      mail,
      password,
      termsOfUse,
      isVeterinarian,
    };

    const fieldValue = fields[fieldName];
    if (fieldValue === '') {
      setFieldError(fieldName, true);
      return false;
    }
    setFieldError(fieldName, false);
    return true;
  };

  const checkEmailIsValid = () => {
    if (mail === '') {
      setMissingMailError(true);
      setInvalidMailError(false);
      return false;
    }

    if (!validator.isEmail(mail)) {
      setMissingMailError(false);
      setInvalidMailError(true);
      return false;
    }
    setMissingMailError(false);
    setInvalidMailError(false);

    return true;
  };

  const handleChangeCountryCode = (event, { value }) => {
    const country = countries.find(({ countryCode }) => countryCode === value);
    setUserCountry(country.name);
    setUserCountryCode(country.countryCode);
    checkFieldIsFilled('userCountryCode');
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const isAllCorrect =
      checkFieldIsFilled('workplaceName') &&
      checkFieldIsFilled('address') &&
      checkFieldIsFilled('postCode') &&
      checkFieldIsFilled('city') &&
      checkFieldIsFilled('phoneNumber') &&
      checkFieldIsFilled('password') &&
      checkFieldIsFilled('termsOfUse') &&
      checkFieldIsFilled('isVeterinarian') &&
      checkEmailIsValid();

    if (!isAllCorrect) return;

    setIsLoading(true);

    const data = {
      address: {
        workplaceName,
        address,
        postCode,
        city,
        countryCode: userCountryCode,
        country: userCountry,
        phoneNumber,
      },
      mail,
      sponsor: sponsor === undefined || sponsor === '' ? null : sponsor,
      language: getInitLanguage(),
      password,
      captcha: captchaRef.current.getValue(),
    };

    auth
      .register(data)
      .then((response) => {
        dispatch({ type: CLEAR_ERROR });
        setSentSuccessfully(true);
        setIsLoading(false);
        setAddedSponsor(response.data.added_sponsor);
        setAddedSponsorType(response.data.sponsor_type);
        captchaRef.current?.reset();
        setTimeout(function () {
          browserHistory.push('/');
        }, 5500);
      })
      .catch((error) => {
        setIsLoading(false);
        captchaRef.current?.reset();
        dispatch({ type: REQUEST_ERROR, error });
      });
  };

  const isFormFilled = () => {
    if (workplaceName === '') {
      return false;
    }
    if (address === '') {
      return false;
    }
    if (postCode === '') {
      return false;
    }
    if (userCountryCode === '') {
      return false;
    }
    if (mail === '') {
      return false;
    }
    if (password === '') {
      return false;
    }
    if (termsOfUse === false) {
      return false;
    }
    if (isVeterinarian === false) {
      return false;
    }

    return true;
  };

  const workplaceNameErrorText = missingWorkplaceNameError
    ? intl.formatMessage({ id: 'register.form.error.missing.workplace-name' })
    : null;
  const addressErrorText = missingAddressError
    ? intl.formatMessage({ id: 'register.form.error.missing.address' })
    : null;
  const postCodeErrorText = missingPostCodeError
    ? intl.formatMessage({ id: 'register.form.error.missing.post-code' })
    : null;
  const cityErrorText = missingCityError
    ? intl.formatMessage({ id: 'register.form.error.missing.city' })
    : null;
  const countryCodeErrorText = missingCountryCodeError
    ? intl.formatMessage({ id: 'register.form.error.missing.country-code' })
    : null;
  let mailErrorText;
  if (invalidMailError) {
    mailErrorText = intl.formatMessage({ id: 'register.form.error.invalid.mail' });
  } else if (missingMailError) {
    mailErrorText = intl.formatMessage({ id: 'register.form.error.missing.mail' });
  }
  const passwordErrorText = missingPasswordError
    ? intl.formatMessage({ id: 'register.form.error.missing.password' })
    : null;
  const termsOfUseErrorText = missingTermsOfUseError
    ? intl.formatMessage({ id: 'register.form.error.missing.terms-of-use' })
    : null;
  const isVeterinarianErrorText = missingIsVeterinarianError
    ? intl.formatMessage({ id: 'register.form.error.missing.is-veterinarian' })
    : null;

  useEffect(() => {
    if (missingWorkplaceNameError) checkFieldIsFilled('workplaceName');
  }, [workplaceName, missingWorkplaceNameError]);
  useEffect(() => {
    if (missingAddressError) checkFieldIsFilled('address');
  }, [address, missingAddressError]);
  useEffect(() => {
    if (missingPostCodeError) checkFieldIsFilled('postCode');
  }, [postCode, missingPostCodeError]);
  useEffect(() => {
    if (missingCityError) checkFieldIsFilled('city');
  }, [city, missingCityError]);
  useEffect(() => {
    if (missingPasswordError) checkFieldIsFilled('password');
  }, [password, missingPasswordError]);
  useEffect(() => {
    if (missingTermsOfUseError) checkFieldIsFilled('termsOfUse');
  }, [termsOfUse, missingTermsOfUseError]);
  useEffect(() => {
    if (missingIsVeterinarianError) checkFieldIsFilled('isVeterinarian');
  }, [isVeterinarian, missingIsVeterinarianError]);
  useEffect(() => {
    if (missingMailError || invalidMailError) checkEmailIsValid();
  }, [mail, missingMailError, invalidMailError]);

  return (
    <div className="register-page">
      <AppHelmet titleIntlID="register.pageTitle" descriptionIntlID="register.pageDescription" />
      <div className="container">
        <h1>
          <FormattedMessage id="register.title" />
        </h1>
        {!sentSuccessfully && (
          <>
            <p className="mandatory-mention">
              <FormattedMessage
                id="form.mandatory-mention"
                values={{
                  span: (chunks) => <span className="asterisk">{chunks}</span>,
                }}
              />
            </p>
            <Form onSubmit={onSubmit} loading={isLoading}>
              <Form.Input
                required
                fluid
                id="workplaceName"
                label={intl.formatMessage({ id: 'invoiceAddressModal.workplaceName' })}
                placeholder={intl.formatMessage({ id: 'invoiceAddressModal.workplaceName' })}
                value={workplaceName}
                error={workplaceNameErrorText}
                onChange={(event, { value }) => {
                  setWorkplaceName(value);
                }}
                onBlur={() => checkFieldIsFilled('workplaceName')}
              />
              <Form.Input
                required
                fluid
                id="address"
                label={intl.formatMessage({ id: 'invoiceAddressModal.address' })}
                placeholder={intl.formatMessage({ id: 'invoiceAddressModal.address' })}
                value={address}
                error={addressErrorText}
                onChange={(event, { value }) => {
                  setAddress(value);
                }}
                onBlur={() => checkFieldIsFilled('address')}
              />
              <Form.Group widths="equal">
                <Form.Input
                  required
                  fluid
                  id="postCode"
                  label={intl.formatMessage({ id: 'invoiceAddressModal.postCode' })}
                  placeholder={intl.formatMessage({ id: 'invoiceAddressModal.postCode' })}
                  value={postCode}
                  error={postCodeErrorText}
                  onChange={(event, { value }) => {
                    setPostCode(value);
                  }}
                  onBlur={() => checkFieldIsFilled('postCode')}
                />
                <Form.Input
                  required
                  fluid
                  id="city"
                  label={intl.formatMessage({ id: 'invoiceAddressModal.city' })}
                  placeholder={intl.formatMessage({ id: 'invoiceAddressModal.city' })}
                  value={city}
                  error={cityErrorText}
                  onChange={(event, { value }) => {
                    setCity(value);
                  }}
                  onBlur={() => checkFieldIsFilled('city')}
                />
              </Form.Group>
              <FormField required error={countryCodeErrorText}>
                <label>
                  <FormattedMessage id="invoiceAddressModal.country" />
                </label>
                <DropdownCountriesList
                  fluid
                  value={userCountryCode}
                  error={countryCodeErrorText}
                  onChange={handleChangeCountryCode}
                  onBlur={() => checkFieldIsFilled('userCountryCode')}
                />
              </FormField>
              <Form.Input
                fluid
                id="phoneNumber"
                label={intl.formatMessage({ id: 'forms.phoneNumber' })}
                placeholder={intl.formatMessage({ id: 'forms.phoneNumber' })}
                value={phoneNumber}
                onChange={(event, { value }) => setPhoneNumber(value)}
              />
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  required
                  type="email"
                  id="mail"
                  label={intl.formatMessage({ id: 'forms.email' })}
                  placeholder={intl.formatMessage({ id: 'forms.email' })}
                  value={mail}
                  error={mailErrorText}
                  onChange={(event, { value }) => {
                    setMail(value);
                  }}
                  onBlur={() => checkEmailIsValid()}
                />
                <Form.Input
                  fluid
                  required
                  id="password"
                  type="password"
                  label={intl.formatMessage({ id: 'forms.password' })}
                  placeholder={intl.formatMessage({ id: 'forms.password' })}
                  value={password}
                  error={passwordErrorText}
                  onChange={(event, { value }) => {
                    setPassword(value);
                  }}
                  onBlur={() => checkFieldIsFilled('password')}
                />
              </Form.Group>
              <Form.Input
                fluid
                id="sponsor"
                label={intl.formatMessage({ id: 'register.fields.sponsor' })}
                placeholder={intl.formatMessage({ id: 'register.fields.sponsor' })}
                onChange={(event, { value }) => setSponsor(value)}
              />

              <Form.Field>
                <Form.Checkbox
                  required
                  id="termsOfUse"
                  label={
                    <label>
                      <FormattedMessage id="register.fields.iAcceptTermsOfUse" />
                      &nbsp;
                      <ModalTermsOfUse onAcceptClick={() => setTermsOfUse(true)} />
                    </label>
                  }
                  checked={termsOfUse}
                  error={termsOfUseErrorText}
                  onChange={(event, { checked }) => {
                    setTermsOfUse(checked);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Form.Checkbox
                  required
                  id="isVeterinarian"
                  label={
                    <label>
                      <FormattedMessage id="register.fields.iAmVeterinarian" />
                    </label>
                  }
                  checked={isVeterinarian}
                  error={isVeterinarianErrorText}
                  onChange={(event, { checked }) => {
                    setIsVeterinarian(checked);
                  }}
                />
              </Form.Field>

              <GoogleReCaptcha useRef={captchaRef} />

              {registerError && (
                <Message negative>
                  <MessageHeader>
                    <FormattedMessage id="sendRequestForm.error" />
                  </MessageHeader>
                  <FormattedServerError error={registerError} />
                </Message>
              )}

              <Form.Field className="form-actions">
                <Button disabled={!isFormFilled()} type="submit" color="green">
                  <Icon name="user doctor" />
                  <FormattedMessage id="register.form.button.submit.subscribe" />
                </Button>
              </Form.Field>
            </Form>
          </>
        )}

        {sentSuccessfully && (
          <MessageSuccess addedSponsor={addedSponsor} addedSponsorType={addedSponsorType} />
        )}
      </div>
    </div>
  );
}

function MessageSuccess({ addedSponsor, addedSponsorType }) {
  let sponsorAdvantageId;
  if (addedSponsor) {
    if (addedSponsorType === 'team') {
      sponsorAdvantageId = 'register.sponsorAdvantages.team';
    } else if (addedSponsorType === 'user') {
      sponsorAdvantageId = 'register.sponsorAdvantages.user';
    } else {
      sponsorAdvantageId = 'register.sponsorAdvantages.' + addedSponsor;
    }
  }

  return (
    <Message success>
      <MessageHeader>
        <FormattedMessage id="register.success.title" />
      </MessageHeader>
      <FormattedMessage id="register.success.mail-sent" />
      {addedSponsor && <FormattedMessage id={sponsorAdvantageId} />}
    </Message>
  );
}

export default RegisterPage;
