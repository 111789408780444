export const USER_SET_LANGUAGE = 'USER_SET_LANGUAGE' as const;
export const CHANGE_LOCALE = 'CHANGE_LOCALE' as const;

export function userSetLanguage(languageLocale: string) {
  return {
    type: USER_SET_LANGUAGE,
    locale: languageLocale,
  };
}

export function changeLocale(languageLocale: string) {
  return {
    type: CHANGE_LOCALE,
    locale: languageLocale,
  };
}

export type LanguageActions = ReturnType<typeof userSetLanguage> | ReturnType<typeof changeLocale>;
