export const SET_LAST_RELEASE_URL = 'SET_LAST_RELEASE_URL' as const;
export const SET_PATCH_NOTES = 'SET_LAST_PATCH_NOTES' as const;

export const setLastReleaseUrl = (releaseUrl: string) => ({
  type: SET_LAST_RELEASE_URL,
  payload: { releaseUrl },
});

type PactNotes = Record<string, string>;

export const setPatchNotes = (patchNotes: PactNotes) => ({
  type: SET_PATCH_NOTES,
  payload: { patchNotes },
});

export type VersionUpdateAction =
  | ReturnType<typeof setLastReleaseUrl>
  | ReturnType<typeof setPatchNotes>;
