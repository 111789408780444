/* eslint-disable import/no-unresolved */
import { setHasSubscribed } from 'app/redux/global/actions';
import observeStore from 'app/utils/redux/observeStore';
import ApiCalls from 'app/utils/apiCalls';
import { updateManagerPermissions, updatePermissions } from 'app/redux/permissions/actions';
import updateTeleradiology from 'app/redux/teleradiology/actions';
import APICauses from 'app/errors/APICauses';
import { selectLoggedIn } from './global/selectors';

const MAX_RETRY_TIMEOUT = 60 * 10 * 1000;

// Subscriptions management

const requestUntilSuccess = (requestor, errorTimeout = 5000, maxTimeout = MAX_RETRY_TIMEOUT) => {
  requestor().catch(() => {
    setTimeout(() => {
      requestUntilSuccess(requestor, Math.min(2 * errorTimeout, maxTimeout), maxTimeout);
    }, errorTimeout);
  });
};

export const checkPermissions = (store, errorTimeout = 5000, maxTimeout = MAX_RETRY_TIMEOUT) => {
  requestUntilSuccess(
    () =>
      ApiCalls.getPermissions().then(({ data }) => {
        store.dispatch(updatePermissions(data.permissions));
      }),
    errorTimeout,
    maxTimeout
  );
};

export const checkManagerPermissions = (
  store,
  errorTimeout = 5000,
  maxTimeout = MAX_RETRY_TIMEOUT
) => {
  requestUntilSuccess(
    () =>
      ApiCalls.getManagerPermissions()
        .then(({ data }) => {
          store.dispatch(updateManagerPermissions(data.permissions));
        })
        .catch((error) => {
          const { response } = error;
          if (response?.status === 401) {
            store.dispatch(updateManagerPermissions({}));
          } else {
            throw error;
          }
        }),
    errorTimeout,
    maxTimeout
  );
};

export const checkHasSubscribed = (store, errorTimeout = 5000, maxTimeout = MAX_RETRY_TIMEOUT) => {
  requestUntilSuccess(
    () =>
      ApiCalls.hasOngoingSubscription().then((response) => {
        const hasSubscribedData = response.data.has_valid_subscription;
        store.dispatch(setHasSubscribed(hasSubscribedData));
      }),
    errorTimeout,
    maxTimeout
  );
};

export const checkTeleradiology = (store, errorTimeout = 5000, maxTimeout = MAX_RETRY_TIMEOUT) => {
  requestUntilSuccess(
    () =>
      ApiCalls.getTeleradiologyAffiliate()
        .then(({ data }) => {
          store.dispatch(
            updateTeleradiology({
              type: data.type,
              affiliateId: data.teleradiology_affiliate_id,
            })
          );
        })
        .catch((error) => {
          // Si cause is TELERAD_USER_NOT_FOUND, exit
          if (error.response.data.cause === APICauses.TELERADIOLOGY_USER_NOT_FOUND) {
            return;
          }
          console.error('server-api-error', error);
          throw error;
        }),
    errorTimeout,
    maxTimeout
  );
};

export const setSubscriptions = (store, loggedIn) => {
  if (loggedIn === true) {
    checkPermissions(store);
    checkHasSubscribed(store);
    checkManagerPermissions(store);
    checkTeleradiology(store);
  } else {
    store.dispatch(setHasSubscribed(false));
    store.dispatch(updatePermissions(undefined));
    store.dispatch(updateManagerPermissions(undefined));
    store.dispatch(updateTeleradiology({}));
  }
};

const attachSubscriptionManagerObserver = (store) => {
  // eslint-disable-next-line no-shadow
  const onLoggedInChange = ({ store, state: loggedIn }) => setSubscriptions(store, loggedIn);

  observeStore(store, selectLoggedIn, onLoggedInChange);
};

export { attachSubscriptionManagerObserver };
