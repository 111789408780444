class CancellablePromise<T> extends Promise<T> {
  cancel: () => void;

  constructor(
    executor: (
      resolve: (value?: T | PromiseLike<T>) => void,
      reject: (reason?: any) => void
    ) => void
  ) {
    super(executor);
  }
}

export class CancelError extends Error {}

/**
 * Create a promise that will be resolved after the given timeout.
 * @param timeout - The timeout in milliseconds.
 * @param signal - An optional AbortSignal to cancel the timeout.
 * @returns A promise that will be resolved after the given timeout.
 * @throws {CancelError} - If the promise is canceled.
 */
const TimeoutPromise = (
  timeout: number,
  { signal }: { signal?: AbortSignal } = {}
): CancellablePromise<void> => {
  let timeoutHandle: NodeJS.Timeout;

  let cancelOnAbort: () => void;
  const promise = new CancellablePromise<void>((resolve, reject) => {
    timeoutHandle = setTimeout(resolve, timeout);

    cancelOnAbort = () => {
      clearTimeout(timeoutHandle);
      reject(new CancelError('Promise was canceled'));
    };

    signal?.addEventListener('abort', cancelOnAbort);
  });
  promise.finally(() => signal?.removeEventListener('abort', cancelOnAbort));
  // We should throw here to avoid dangling Promise listener.
  promise.cancel = () => clearTimeout(timeoutHandle);
  return promise;
};

export default TimeoutPromise;
