import React from 'react';
import { Image, Card, Popup, Button, Header, Grid, Divider } from 'semantic-ui-react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import HelpMessage from '../HelpMessage';
import IntlCrawlableLink from '../IntlCrawlableLink';
import { selectLoggedIn } from 'app/redux/global/selectors';

class WhatImagesToSend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestRegister: false,
    };
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  renderCard = (image, good, face, description) => {
    const backgroundColor = good ? 'rgb(100,200,150)' : 'rgb(210, 85, 85)';
    const maxWidth = face ? '10%' : '17%';
    const cardContent = (
      <Card
        style={{
          backgroundColor,
          display: 'inline-table',
          maxWidth,
          width: maxWidth,
        }}
      >
        <Card.Content style={{ padding: '4px', textAlign: 'center' }}>
          <Image src={image} fluid style={{ maxHeight: '150px' }} />
        </Card.Content>
      </Card>
    );
    return <Popup content={this.formatMessage({ id: description })} trigger={cardContent} />;
  };

  onDemoClick = () => {
    if (this.props.isLoggedIn) {
      this.props.loadDemo();
    } else {
      this.setState({ suggestRegister: true });
    }
  };

  renderDemoButton = () => {
    /**
     * L'essai gratuit de façon automatique a été désativé
     * Du coup, si on est loggé, on peut essayer
     * Sinon, on s'inscrit
     */
    // if (this.state.suggestRegister) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.props.isLoggedIn) {
      return (
        <IntlCrawlableLink
          url="/register"
          className="ui large blue button"
          style={{ lineHeight: '1.6em', marginBottom: '5px' }}
        >
          <FormattedMessage id="dropzone.helpMessages.whatImagesToSend.demoButtonRegister" />
        </IntlCrawlableLink>
      );
    }
    return (
      <Button
        onClick={(event) => {
          event.preventDefault();
          this.onDemoClick();
        }}
        size="large"
        color="green"
        style={{ lineHeight: '1.6em', marginBottom: '5px' }}
      >
        <FormattedMessage id="dropzone.helpMessages.whatImagesToSend.demoButton" />
      </Button>
    );
  };

  renderExampleImages = () => (
    <div>
      <Card.Group>
        <div style={{ margin: 'auto' }}>{this.renderDemoButton()}</div>
      </Card.Group>
    </div>
  );
  //        {this.renderCard(require('../../images/examples/good_face.jpg'), true, true, 'whatImagesToSend.examples.good_face')}
  //        {this.renderCard(require('../../images/examples/good_profil.jpg'), true, false, 'whatImagesToSend.examples.good_profil')}
  //        {this.renderCard(require('../../images/examples/too_much_abdomen_face.jpg'), false, true, 'whatImagesToSend.examples.too_much_abdomen_face')}
  //        {this.renderCard(require('../../images/examples/whole_animal.jpg'), false, false, 'whatImagesToSend.examples.whole_animal')}
  //        {this.renderCard(require('../../images/examples/too_much_abdomen.jpg'), false, false, 'whatImagesToSend.examples.too_much_abdomen')}

  renderContent = () => (
    <div>
      <Grid columns={2} relaxed="very">
        <Grid.Column>
          <Header>
            {this.formatMessage({ id: 'dropzone.helpMessages.whatImagesToSend.header' })}
          </Header>
          <p>
            <FormattedMessage id="dropzone.helpMessages.whatImagesToSend.paragraph" />
          </p>
        </Grid.Column>
        <Grid.Column style={{ textAlign: 'center', margin: 'auto' }}>
          {this.renderDemoButton()}
        </Grid.Column>
      </Grid>

      <Divider vertical>
        <FormattedMessage id="link.or" />
      </Divider>
    </div>
  );

  render() {
    //    return this.renderContent()
    return (
      <HelpMessage
        style={{ margin: '10px 10px 5px 10px', zIndex: '0' }}
        content={this.renderContent()}
        // iconClass="image outline icon"
        closable
        additionalContent={this.renderExampleImages()}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: selectLoggedIn(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WhatImagesToSend));
