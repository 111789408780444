/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */
/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
export const GLOBAL_SUBSCRIPTIONS_CONFIGURATION_LOCAL_STORAGE_KEY = 'global.subscriptions' as const;
export const DEFAULT_LOCALE = 'en' as const;
export const CHANGE_USERNAME = 'CHANGE_USERNAME' as const;
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD' as const;
export const SET_AUTH = 'SET_AUTH' as const;
export const SENDING_REQUEST = 'SENDING_REQUEST' as const;
export const LOGIN_REQUEST = 'LOGIN_REQUEST' as const;
export const REGISTER_REQUEST = 'REGISTER_REQUEST' as const;
export const LOGOUT = 'LOGOUT' as const;
export const REQUEST_ERROR = 'REQUEST_ERROR' as const;
export const CLEAR_ERROR = 'CLEAR_ERROR' as const;
export const SET_HAS_SUBSCRIBED = 'SET_HAS_SUBSCRIBED' as const;
export const SET_SUBSCRIPTION_MAIL_SENT = 'SET_SUBSCRIPTION_MAIL_SENT' as const;
export const SET_AI_PANEL_DISPLAY_MODE = 'SET_AI_PANEL_DISPLAY_MODE' as const;

export function changeUsername(username: string) {
  return {
    type: CHANGE_USERNAME,
    username,
  };
}

export function changePassword(password: string) {
  return {
    type: CHANGE_PASSWORD,
    password,
  };
}

export function setAuthState(newAuthState: boolean) {
  return {
    type: SET_AUTH,
    newAuthState,
  };
}

/**
 * Sets the `currentlySending` state, which displays a loading indicator during requests
 * @param  {boolean} sending True means we're sending a request, false means we're not
 */
export function sendingRequest(sending: boolean) {
  return {
    type: SENDING_REQUEST,
    sending,
  };
}

/**
 * Tells the app we want to log in a user
 * @param  {object} data          The data we're sending for log in
 * @param  {string} data.username The username of the user to log in
 * @param  {string} data.password The password of the user to log in
 */
export function loginRequest(data: { username: string; password: string }) {
  return {
    type: LOGIN_REQUEST,
    data,
  };
}

/**
 * Tells the app we want to log out a user
 */
export function logout(data: any) {
  return {
    type: LOGOUT,
    data,
  };
}

/**
 * Tells the app we want to register a user
 * @param  {object} data          The data we're sending for registration
 * @param  {string} data.username The username of the user to register
 * @param  {string} data.password The password of the user to register
 */
export function registerRequest(data: { username: string; password: string }) {
  return {
    type: REGISTER_REQUEST,
    data,
  };
}

/*
 * Sets the `error` state to the error received
 * @param  {object} error The error we got when trying to make the request
 */
export function requestError(error: any) {
  return {
    type: REQUEST_ERROR,
    error,
  };
}

/**
 * Sets the `error` state as empty
 */
export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

/**
 * Sets the subscribed boolean as true or false
 */
export function setHasSubscribed(hasSubscribed: boolean) {
  return {
    type: SET_HAS_SUBSCRIBED,
    hasSubscribed,
  };
}

/**
 * Sets the AI Panel display mode
 */
export function setAIPanelDisplayMode(mode: 'hovered' | 'fixed') {
  return {
    type: SET_AI_PANEL_DISPLAY_MODE,
    mode,
  };
}

export type GlobalAction =
  | ReturnType<typeof changeUsername>
  | ReturnType<typeof changePassword>
  | ReturnType<typeof setAuthState>
  | ReturnType<typeof sendingRequest>
  | ReturnType<typeof loginRequest>
  | ReturnType<typeof logout>
  | ReturnType<typeof registerRequest>
  | ReturnType<typeof requestError>
  | ReturnType<typeof clearError>
  | ReturnType<typeof setHasSubscribed>
  | ReturnType<typeof setAIPanelDisplayMode>
  | { type: typeof SET_SUBSCRIPTION_MAIL_SENT; value: boolean };
