// /* eslint no-alert: 0 */
// import external from './../../externalModules.js';
// import BaseAnnotationTool from '../base/BaseAnnotationTool.js';

import {
  BaseAnnotationTool,
  EVENTS,
  IBaseAnnotationTool,
  addToolState,
  draw,
  drawHandles,
  drawLine,
  drawLinkedTextBox,
  getHandleNearImagePoint,
  getNewContext,
  getToolState,
  lineSegDistance,
  removeToolState,
  setShadow,
  store,
  toolColors,
  toolStyle,
  triggerEvent,
} from 'app/CornerstoneTools';
import { rulerCursor } from 'app/CornerstoneTools/Cursors';
import moveNewHandle from 'app/CornerstoneTools/moveNewHandle';
import { getImage, updateImage } from 'cornerstone-core';
import { ToolMeasurement, ToolPoint, Point } from './types';

export type RealSizeMeasurementCalibrationMeasurement = ToolMeasurement & {
  handles: {
    start: ToolPoint;
    end: ToolPoint;
    textBox?: {
      active: boolean;
      hasMoved: boolean;
      movesIndependently: boolean;
      drawnIndependently: boolean;
      allowedOutsideImage: boolean;
      hasBoundingBox: boolean;
    };
  };
  length?: number;
};

export type RealSizeMeasurementCalibrationToolProps = IBaseAnnotationTool & {
  configuration: {
    getLengthCallback: (doneGettingLengthCb: (length: number) => void, eventData: any) => void;
    updateLengthCallback: (
      doneUpdatingLengthCb: (updatedLength: number) => void,
      data: RealSizeMeasurementCalibrationMeasurement,
      eventData: any
    ) => void;
  };
};

function textBoxAnchorPoints(handles: RealSizeMeasurementCalibrationMeasurement['handles']) {
  const midpoint = {
    x: (handles.start.x + handles.end.x) / 2,
    y: (handles.start.y + handles.end.y) / 2,
  };

  return [handles.start, midpoint, handles.end];
}

export function computeRealSizePixelSpacingFromActualSpacing(
  {
    columnPixelSpacing = 1,
    rowPixelSpacing = 1,
  }: { columnPixelSpacing: number; rowPixelSpacing: number },
  measurement: RealSizeMeasurementCalibrationMeasurement
) {
  const pixelSpacingRatio = rowPixelSpacing / columnPixelSpacing;

  if (!measurement.length || measurement.length <= 0) return;
  const { length } = measurement;

  const lengthWidth = Math.abs(measurement.handles.end.x - measurement.handles.start.x);
  const lengthHeight =
    Math.abs(measurement.handles.end.y - measurement.handles.start.y) / pixelSpacingRatio;
  const pixelLength = Math.sqrt(lengthWidth ** 2 + lengthHeight ** 2);

  const realPixelSpacing = length / pixelLength;
  return {
    rowPixelSpacing: realPixelSpacing * pixelSpacingRatio,
    columnPixelSpacing: realPixelSpacing,
  };
}

export function computeRealSizePixelSpacingFromMeasurement(
  element: HTMLElement,
  measurement: RealSizeMeasurementCalibrationMeasurement
) {
  try {
    return computeRealSizePixelSpacingFromActualSpacing(getImage(element), measurement);
  } catch {}
}

export default class RealSizeMeasurementCalibrationTool extends BaseAnnotationTool {
  protected preventNewMeasurement: boolean = false;
  protected touchPressCallback: any;
  protected mouseClickCallback: any;

  constructor(props: RealSizeMeasurementCalibrationToolProps) {
    const defaultProps = {
      name: 'RealSizeMeasurementCalibration',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        arrowFirst: true,
        renderDashed: false,
        allowEmptyLabel: false,
      },
      svgCursor: rulerCursor,
    };

    super(props ?? {}, defaultProps);

    this.touchPressCallback = (evt: any) => this._changeText(evt, 'touch');
    this.mouseClickCallback = (evt: any) => this._changeText(evt, 'mouse');
  }

  get configuration() {
    return super
      .configuration as unknown as RealSizeMeasurementCalibrationToolProps['configuration'];
  }

  createNewMeasurement(evt: any): RealSizeMeasurementCalibrationMeasurement {
    const { element } = evt.detail;
    const toolData = getToolState(element, this.name);
    if (toolData?.data?.length > 0) {
      return undefined;
    }
    // Create the measurement data for this tool with the end handle activated
    return {
      visible: true,
      active: true,
      color: undefined as string,
      handles: {
        start: {
          x: evt.detail.currentPoints.image.x,
          y: evt.detail.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: evt.detail.currentPoints.image.x,
          y: evt.detail.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: true,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  pointNearTool(
    element: HTMLElement,
    data: RealSizeMeasurementCalibrationMeasurement,
    coords: Point
  ) {
    if (data.visible === false) {
      return false;
    }

    return lineSegDistance(element, data.handles.start, data.handles.end, coords) < 25;
  }

  pointNearText(
    element: HTMLElement,
    data: RealSizeMeasurementCalibrationMeasurement,
    coords: Point,
    interactionType: 'mouse' | 'touch'
  ) {
    if (data.visible === false) {
      return false;
    }

    const nearDistance =
      interactionType === 'mouse' ? store.state.clickProximity : store.state.touchProximity;

    const nearHandleInRange = getHandleNearImagePoint(
      element,
      [data.handles.textBox],
      coords,
      nearDistance
    );
    return nearHandleInRange !== undefined;
  }

  updateCachedStats() {
    // Implementing to satisfy BaseAnnotationTool
  }

  renderToolData(evt: any) {
    const eventData = evt.detail;
    const { element } = evt.detail;
    const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving } = this.configuration;

    // If we have no toolData for this element, return immediately as there is nothing to do
    const toolData = getToolState(element, this.name);

    if (!toolData) {
      return;
    }

    // We have tool data for this element - iterate over each one and draw it
    const canvas = evt.detail.canvasContext.canvas;
    const context = getNewContext(canvas);

    const lineWidth = toolStyle.getToolWidth();

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }

      draw(context, (context: CanvasRenderingContext2D) => {
        // Configurable shadow
        setShadow(context, this.configuration);

        const color = toolColors.getColorIfActive(data);

        const lineOptions = { color };

        // Draw the measurement line
        drawLine(context, element, data.handles.start, data.handles.end, lineOptions);

        // Draw the handles
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        if (this.configuration.drawHandles) {
          drawHandles(context, eventData, data.handles, handleOptions);
        }

        if (!data.handles.textBox.hasMoved) {
          const coords: Point = {
            x: Math.max(data.handles.start.x, data.handles.end.x),
            y: undefined,
          };

          // Depending on which handle has the largest x-value,
          // Set the y-value for the text box
          if (coords.x === data.handles.start.x) {
            coords.y = data.handles.start.y;
          } else {
            coords.y = data.handles.end.y;
          }

          data.handles.textBox.x = coords.x;
          data.handles.textBox.y = coords.y;
        }

        // Move the textbox slightly to the right and upwards
        // So that it sits beside the length tool handle
        const xOffset = 10;

        // Update textbox stats
        if (data.length) {
          const text = `${+data.length.toFixed(1)} mm`;

          drawLinkedTextBox(
            context,
            element,
            data.handles.textBox,
            text,
            data.handles,
            textBoxAnchorPoints,
            color,
            lineWidth,
            xOffset,
            true
          );
        }
      });
    }
  }

  addNewMeasurement(evt: any, interactionType: string) {
    const element = evt.detail.element;

    const toolData = getToolState(element, this.name);
    if (toolData?.data?.length > 0) {
      return;
    }
    const measurementData = this.createNewMeasurement(evt);

    // Associate this data with this imageId so we can render it and manipulate it
    addToolState(element, this.name, measurementData);
    updateImage(element);

    moveNewHandle(
      evt.detail,
      this.name,
      measurementData,
      measurementData.handles.end,
      this.options,
      interactionType,
      (success: boolean) => {
        if (success) {
          if (measurementData.length === undefined) {
            this.configuration.getLengthCallback((length: number) => {
              if (length === undefined) {
                removeToolState(element, this.name, measurementData);
                return;
              }
              measurementData.length =
                typeof length === 'string' ? Number.parseFloat(length) : length;
              measurementData.active = false;

              const modifiedEventData = {
                toolName: this.name,
                toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
                element,
                measurementData,
              };

              updateImage(element);
              triggerEvent(element, EVENTS.MEASUREMENT_COMPLETED, modifiedEventData);
            }, evt.detail);
          }
        } else {
          removeToolState(element, this.name, measurementData);
        }

        updateImage(element);
      }
    );
  }

  private _changeText(evt: any, interactionType: 'mouse' | 'touch' = 'mouse') {
    const eventData = evt.detail;
    const { element, currentPoints } = eventData;

    const config = this.configuration;
    const coords = currentPoints.canvas;
    const toolData = getToolState(element, this.name);

    // Now check to see if there is a handle we can move
    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];
      if (this.pointNearText(element, data, coords, interactionType)) {
        data.active = true;
        updateImage(element);

        // Allow relabelling via a callback
        config.updateLengthCallback(
          (updatedLength: number) => {
            if (updatedLength === undefined) {
              removeToolState(element, this.name, data);
              return;
            }
            data.length =
              typeof updatedLength === 'string' ? Number.parseFloat(updatedLength) : updatedLength;

            data.active = false;
            updateImage(element);
            triggerEvent(element, EVENTS.MEASUREMENT_MODIFIED, data);
          },
          data,
          eventData
        );

        evt.stopImmediatePropagation();
        evt.preventDefault();
        evt.stopPropagation();

        return;
      }
    }
  }
}
