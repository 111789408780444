/* eslint-disable react/prop-types */
import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import 'app/styles/style.scss';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Confirm } from 'semantic-ui-react';
import { isSmallScreen } from '../../utils/windowUtils';
import { logout } from '../../redux/global/actions';
import { selectLoggedIn } from 'app/redux/global/selectors';

class LogoutButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  open = () => this.setState({ open: true });

  close = () => this.setState({ open: false });

  render() {
    const { isLoggedIn, intl, dispatch, onClick } = this.props;
    if (isLoggedIn) {
      let logoutButton;
      if (isSmallScreen()) {
        /* NavbarBurger */
        logoutButton = (
          <button
            type="button"
            className="navbar-burger__link"
            onClick={() => {
              this.open();
              onClick?.();
            }}
          >
            <FormattedMessage id="header.logout.title" />
            <i className="sign-out icon navbar-burger__dropdown-icon" />
          </button>
        );
      } else {
        /* Account Dropdown */
        logoutButton = (
          <button
            type="button"
            className="item account-dropdown__link"
            onClick={() => {
              this.open();
              onClick?.();
            }}
          >
            <i className="sign-out icon account-dropdown__icon" />
            <FormattedMessage id="header.logout.title" />
          </button>
        );
      }

      return (
        <>
          {logoutButton}
          <Confirm
            /* ForceDebug du on click sur le confirm */
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            /* /ForceDebug */
            open={this.state.open}
            content={intl.formatMessage({ id: 'logout.confirm.content' })}
            cancelButton={intl.formatMessage({ id: 'general.cancel' })}
            confirmButton={intl.formatMessage({ id: 'logoutButton.buttonText' })}
            onCancel={this.close}
            onConfirm={() => dispatch(logout({ redirectRoute: '/login' }))}
          />
        </>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: selectLoggedIn(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LogoutButton));
