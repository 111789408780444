/* eslint-disable class-methods-use-this */
import electron from 'app/native/node/electron';
import fs from 'app/native/node/fs';
import path from 'app/native/node/path';
import ApiCalls from 'app/utils/apiCalls';
import logger from 'app/utils/debug/logger';

function cropRectToRefreshRect(cropRect) {
  return (
    cropRect && [cropRect[0], cropRect[1], cropRect[0] + cropRect[2], cropRect[1] + cropRect[3]]
  );
}

async function saveBadImage(imageId, base64Image) {
  if (!fs() || !electron() || !path()) return;
  try {
    const badImageSavePath = path().join(
      electron().remote.app.getPath('userData'),
      'bad_images',
      `${imageId}.base64`
    );
    logger.info(`Saving bad image to ${badImageSavePath}`);
    await fs().promises.mkdir(path().join(path().dirname(badImageSavePath)), {
      recursive: true,
    });
    await fs().promises.writeFile(badImageSavePath, base64Image, { encoding: 'utf8' });
  } catch (e) {
    logger.error('Error saving bad image');
    logger.error(e);
  }
}

export default class OnlineInferenceExecutor {
  /** @type {InferenceExecutor.infer} */
  infer = (imageToInfer) =>
    ApiCalls.infer(imageToInfer)
      .then(({ data }) => {
        if (data?.predictions?.error === 'BAD_IMAGE') {
          saveBadImage(imageToInfer.imageId, imageToInfer.base64Image);
        }
        return data.predictions;
      })
      .catch((error) => {
        if (error?.response?.data?.cause === 'BAD_IMAGE') {
          saveBadImage(imageToInfer.imageId, imageToInfer.base64Image);
        }
        if ([401, 403].includes(error.response?.status)) {
          return { no_credit: true };
        }
        throw error;
      });

  /** @type {InferenceExecutor.refreshInference} */
  refreshInference = ({ imageId, image, cropRect, imageSize }) =>
    ApiCalls.refreshInference(imageId, image, imageSize, cropRectToRefreshRect(cropRect)).then(
      ({ data }) => data.predictions
    );
}
