import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import 'app/styles/style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import './styles.scss';
import { getInnerURL } from 'app/utils/languageUtil';
import { selectRoute } from 'app/redux/route/reducer';
import { selectLocale } from 'app/redux/language/selectors';
import { userSetLanguage } from '../../redux/language/actions';

const languageOptions = [
  { key: 'Français', text: 'Français', locale: 'fr', flag: 'fr' },
  { key: 'English', text: 'English', locale: 'en', flag: 'uk' },
  { key: 'Deutsch', text: 'Deutsch', locale: 'de', flag: 'de' },
  { key: 'Serbian', text: 'Srpski', locale: 'sr', flag: 'rs' },
  { key: 'Greek', text: 'Ελληνικά', locale: 'el', flag: 'gr' },
  { key: 'Dutch ', text: 'Nederlands', locale: 'nl', flag: 'nl' },
  { key: 'Russian ', text: 'русский', locale: 'ru', flag: 'ru' },
  { key: 'Ukrainian ', text: 'Українська', locale: 'uk', flag: 'ua' },
  { key: 'Italian ', text: 'Italiano', locale: 'it', flag: 'it' },
  { key: 'Finish ', text: 'Viedä loppuun', locale: 'fi', flag: 'fi' },
  { key: 'Portuguese ', text: 'Português', locale: 'pt', flag: 'pt' },
  { key: 'Slovak', text: 'Slovenský', locale: 'sk', flag: 'sk' },
  { key: 'Spanish', text: 'Español', locale: 'es', flag: 'es' },
  { key: 'Czech', text: 'Čeština', locale: 'cz', flag: 'cz' },
];

const LanguageMenu = () => {
  const dispatch = useDispatch();
  const currentPathname = useSelector(selectRoute).locationBeforeTransitions.pathname;
  const innerURL = getInnerURL(currentPathname);

  const handleLanguageLinkClick = (event) => {
    event.preventDefault();
    dispatch(userSetLanguage(event.target.dataset.locale));
  };

  return languageOptions.map((languageOption) => (
    <li className="language-dropdown__item" key={languageOption.text}>
      <a
        href={`/${languageOption.locale}/${innerURL}`}
        className="language-dropdown__link"
        onClick={handleLanguageLinkClick}
        data-locale={languageOption.locale}
      >
        <i className={`${languageOption.flag} flag`} />
        {languageOption.text}
      </a>
    </li>
  ));
};

const CurrentLanguageLabel = () => {
  const currentLocale = useSelector(selectLocale);
  const currentLanguageOption = languageOptions.find(
    (languageOption) => languageOption.locale === currentLocale
  );

  return (
    <>
      <i className={`${currentLanguageOption.flag} flag`} />
      {currentLanguageOption.text}
    </>
  );
};

const LanguageDropdown = ({ location }) => {
  let dropdownClassName = 'link item'; // Default navbarFullSize location style
  if (location === 'accountPage') {
    dropdownClassName = 'accountPage';
  }
  if (location === 'navbarBurger') {
    dropdownClassName = 'navbarBurger';
  }

  return (
    <Dropdown trigger={<CurrentLanguageLabel />} className={dropdownClassName}>
      <Dropdown.Menu as="ul" className="language-dropdown">
        <LanguageMenu />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageDropdown;
