import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPMSExportConfiguration, selectPMSType } from 'app/redux/pms/selector';
import PmsExporter, { PMSExportFn } from 'app/pms/exporter/PmsExporter';
import { useIntl } from 'react-intl';
import AssistoVetExporter from 'app/pms/exporter/AssistoVetExporter';
import getFs from 'app/native/node/fs';
import IDisplayableImageEncoderContext from 'app/providers/IDisplayableImageEncoder/context';
import _ from 'lodash';
import GmVetExporter from 'app/pms/exporter/GmVetExporter';
import VetoPartnerExporter from 'app/pms/exporter/VetoPartnerExporter';

export const PMSExportContext = React.createContext<PMSExportFn>(undefined);

type PMSType = 'assisto_vet' | 'gm_vet' | 'veto_partner';

const PMSExportProviderImpl = ({ children }: PropsWithChildren<{}>) => {
  const fs = getFs();
  const intl = useIntl();
  const imageEncoder = useContext(IDisplayableImageEncoderContext);
  const [exporters] = useState(
    () =>
      ({
        assisto_vet: new AssistoVetExporter(imageEncoder, fs.promises),
        gm_vet: new GmVetExporter(imageEncoder, fs.promises),
        veto_partner: new VetoPartnerExporter(imageEncoder, fs.promises),
      } as const)
  );

  const pmsType: PMSType = useSelector(selectPMSType);
  const pmsExportConfiguration = useSelector(selectPMSExportConfiguration);
  const isPMSEnabled = !!(pmsType && pmsExportConfiguration);

  const exportToPMSFn = useMemo(() => {
    if (!isPMSEnabled) return undefined;

    const executeExport: PMSExportFn = async (studyInfo, patientInfo, images) =>
      exporters[pmsType]?.exportDataToPMS(pmsExportConfiguration, studyInfo, patientInfo, images);
    return executeExport;
  }, [pmsExportConfiguration, isPMSEnabled, exporters]);

  useEffect(() => {
    _.forEach(exporters, (exporter) => exporter?.setIntl(intl));
  }, [intl]);
  useEffect(() => {
    _.forEach(exporters, (exporter) => exporter?.setImageEncoder(imageEncoder));
  }, [imageEncoder]);

  return <PMSExportContext.Provider value={exportToPMSFn}>{children}</PMSExportContext.Provider>;
};

function PMSExportProvider({ children }: PropsWithChildren<{}>) {
  const fs = getFs();
  if (!fs) return children;

  return <PMSExportProviderImpl>{children}</PMSExportProviderImpl>;
}

export default PMSExportProvider;
