import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import { browserHistory } from 'react-router';
import { parseQueryString } from '../../utils/windowUtils';
import auth from '../auth';
import { SET_AUTH, REQUEST_ERROR, CLEAR_ERROR } from 'app/redux/global/actions';
import { FormattedMessage } from 'react-intl';
import FormattedServerError, { errorType } from '../../components/FormattedServerError';
import { selectLoginError } from 'app/redux/global/selectors';

const Error = styled.h4`
  color: #cc0000;
`;

export class ConfirmMailPage extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {
      mailConfirmed: false,
    };
  }

  componentDidMount = () => {
    const token = parseQueryString()['token'];
    auth.setLocalToken(token);
    this.confirmMail();
  };

  confirmMail = () => {
    auth.confirmMail().then((response) => {
      if (response.error) {
        this.props.dispatch({ type: REQUEST_ERROR, error: response.error });
      } else if (response.data.status === 'fail') {
        this.props.dispatch({ type: REQUEST_ERROR, error: response.data });
      } else {
        this.props.dispatch({ type: SET_AUTH, newAuthState: true });
        this.props.dispatch({ type: CLEAR_ERROR });
        this.setState({ mailConfirmed: true });
        setTimeout(function () {
          browserHistory.push('/');
        }, 2500);
      }
    });
  };

  renderSuccess = () => {
    return (
      <div style={{ marginTop: '10%', textAlign: 'center' }}>
        <h1>
          <FormattedMessage id="confirmMail.title" />
        </h1>
        <span style={{ fontSize: '2em' }}>
          <FormattedMessage id="confirmMail.success" />
        </span>
      </div>
    );
  };

  render() {
    if (this.state.mailConfirmed) {
      return this.renderSuccess();
    } else if (this.props.loginError) {
      return (
        <Error>
          <FormattedServerError error={this.props.loginError} />
        </Error>
      );
    } else {
      return (
        <div style={{ marginTop: '10%', textAlign: 'center' }}>
          <h2>
            <FormattedMessage id="confirmMail.sendingRequest" />
          </h2>
        </div>
      );
    }
  }
}

ConfirmMailPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loginError: PropTypes.shape(errorType),
};

ConfirmMailPage.defaultProps = {
  loginError: null,
};

const mapStateToProps = createStructuredSelector({
  loginError: selectLoginError,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmMailPage);
