import * as _ from 'lodash';

import { isNumberConvertible } from 'app/utils/mathUtils';
import { getFormat } from '../dateUtil';
import { delimiter, newline } from './constants';
import getThoraxReport from './thoraxReportGenerator';
import getPelvisReport from './pelvisReportGenerator';
import BaseReportGenerator from './BaseReportGenerator';
import {
  ABDOMEN_PREDICTION_TYPES,
  FRONTAL_VIEW_TYPES,
  PELVIS_PREDICTION_TYPES,
  SIDE_VIEW_TYPES,
  THORAX_PREDICTION_TYPES,
} from '../predictions/constants';
import getAbdomenReport from './abdomenReportGenerator';
import isWorkListImage from '../isWorkListImage';
import { ImageForReport, StudyForReport } from 'app/utils/reports/types';
import { IntlShape } from 'react-intl';

const updateImagePredictionsWithFeedbacks = ({
  feedback,
  predictions,
  ...rest
}: ImageForReport) => {
  const feedbackAsNumbers = _.mapValues(_.pickBy(feedback, isNumberConvertible), (v) => +v);
  const predictionsWithFeedbackOverride = {
    ...predictions,
    ...feedbackAsNumbers,
  };
  return {
    predictions: predictionsWithFeedbackOverride,
    feedback,
    ...rest,
  };
};

const updateAllImagesPredictionsWithFeedbacks = (images: ImageForReport[]) =>
  images.map(updateImagePredictionsWithFeedbacks);

class FullReportGenerator extends BaseReportGenerator {
  getCompteRenduDelimiter = () =>
    `${delimiter} ${this.formatMessage('report.compteRendu')} ${delimiter}`;

  getConclusionDelimiter = () =>
    `${delimiter} ${this.formatMessage('report.conclusion')} ${delimiter}`;

  getRecommandationsDelimiter = () =>
    `${delimiter} ${this.formatMessage('report.recommandations')} ${delimiter}`;

  getNumberOfFaces = (imagesInCase: ImageForReport[]) =>
    imagesInCase.filter((image) => FRONTAL_VIEW_TYPES.includes(image?.predictions?.type as any))
      .length;

  getNumberOfProfiles = (imagesInCase: ImageForReport[]) =>
    imagesInCase.filter((image) => SIDE_VIEW_TYPES.includes(image?.predictions?.type as any))
      .length;

  getThoraxImages = (imagesInCase: ImageForReport[]) =>
    _.filter(imagesInCase, (image) =>
      THORAX_PREDICTION_TYPES.includes(image?.predictions?.type as any)
    );

  getAbdomenImages = (imagesInCase: ImageForReport[]) =>
    _.filter(imagesInCase, (image) =>
      ABDOMEN_PREDICTION_TYPES.includes(image?.predictions?.type as any)
    );

  getPelvisImages = (imagesInCase: ImageForReport[]) =>
    _.filter(imagesInCase, (image) =>
      PELVIS_PREDICTION_TYPES.includes(image?.predictions?.type as any)
    );

  getReportDate = (date: Date) =>
    `${this.formatMessage('report.beforeDate')}${this.formatMessage(
      'date.le'
    )}${this.intl.formatDate(date || Date.now(), getFormat('longDateTime'))}.`;

  getRegionImagesSummary = (regionImages: ImageForReport[], regionTranslationKey: string) => {
    let summary = '';
    if (regionImages.length > 0) {
      const numberOfThorax = regionImages.length;
      const numberOfFaces = this.getNumberOfFaces(regionImages);
      const numberOfProfiles = this.getNumberOfProfiles(regionImages);
      summary += `${numberOfThorax} ${this.formatMessageIfPlural(
        regionTranslationKey,
        numberOfThorax > 1
      )}`;
      const xRaysProjections = [];
      if (numberOfProfiles) {
        xRaysProjections.push(
          this.formatMessageIfPlural('report.imagesInCaseSummary.profile', numberOfProfiles > 1)
        );
      }
      if (numberOfFaces) {
        xRaysProjections.push(
          this.formatMessageIfPlural('report.imagesInCaseSummary.face', numberOfFaces > 1)
        );
      }
      summary += ` (${xRaysProjections.join(` ${this.formatMessage('report.and')} `)})`;
    }
    return summary;
  };

  getImagesInCaseSummary = (imagesInCase: ImageForReport[]) => {
    const thoraxImages = this.getThoraxImages(imagesInCase);
    const abdomenImages = this.getAbdomenImages(imagesInCase);
    const pelvisImages = this.getPelvisImages(imagesInCase);
    const numberOfRadios = thoraxImages.length + abdomenImages.length + pelvisImages.length;

    let report = '';

    const regionsSummaries = [];
    if (thoraxImages.length > 0) {
      regionsSummaries.push(
        this.getRegionImagesSummary(thoraxImages, 'report.imagesInCaseSummary.thorax.x_ray')
      );
    }
    if (abdomenImages.length > 0) {
      regionsSummaries.push(
        this.getRegionImagesSummary(abdomenImages, 'report.imagesInCaseSummary.abdomen.x_ray')
      );
    }
    if (pelvisImages.length > 0) {
      regionsSummaries.push(
        this.getRegionImagesSummary(pelvisImages, 'report.imagesInCaseSummary.pelvis.x_ray')
      );
    }

    if (numberOfRadios > 0) {
      report += this.enumerate(regionsSummaries);
      report += ` ${this.formatMessageIfPlural(
        'report.imagesInCaseSummary.haveBeenRealized',
        numberOfRadios > 1
      )}.`;
    }
    return report;
  };

  getReportIntro = (
    animalName: string,
    ownerName: string,
    imagesInCase: ImageForReport[],
    creationDate: Date
  ) => {
    let report = '';
    report += this.getReportDate(creationDate);
    report += newline;
    report += this.getImagesInCaseSummary(imagesInCase);
    report += newline;
    report += newline;
    return report;
  };

  getReportConclusion = () =>
    this.getConclusionDelimiter() +
    newline +
    newline +
    this.getRecommandationsDelimiter() +
    newline;

  removeDoubleSpaces = (report: string) => report.replace(/ {2}/g, ' ');

  replaceWithApostrophes = (string: string) => {
    const regex = new RegExp('de\\s([aeiouy])', 'g');
    return string.replace(regex, "d'$1");
  };

  generateReport = (
    animalName: string,
    ownerName: string,
    imagesInCase: ImageForReport[],
    creationDate: Date
  ) => {
    const thoraxImages = this.getThoraxImages(imagesInCase);
    const abdomenImages = this.getAbdomenImages(imagesInCase);
    const pelvisImages = this.getPelvisImages(imagesInCase);

    if (thoraxImages.length + abdomenImages.length + pelvisImages.length === 0) {
      return '';
    }
    let report = this.getReportIntro(animalName, ownerName, imagesInCase, creationDate);

    report += this.getCompteRenduDelimiter() + newline + newline;
    report += thoraxImages.length > 0 ? getThoraxReport(thoraxImages, this.intl) : '';
    report += abdomenImages.length > 0 ? getAbdomenReport(abdomenImages, this.intl) : '';
    report += pelvisImages.length > 0 ? getPelvisReport(pelvisImages, this.intl) : '';
    report += this.getReportConclusion();

    report = this.replaceWithApostrophes(report);
    return this.removeDoubleSpaces(report);
  };

  splitReport = (report: string) => {
    const [header = '', headerRest = ''] = report.split(this.getCompteRenduDelimiter());
    if (headerRest === '') {
      // In case the report was altered we consider that all text present is part of the
      // 'compteRendu' section. This can happen if the user modified the content and deleted a
      // Delimiter section.
      return {
        header: '',
        compteRendu: header,
        conclusion: '',
        recommandations: '',
      };
    }
    const [compteRendu = '', crRest = ''] = headerRest.split(this.getConclusionDelimiter());
    const [conclusion = '', recommandations = ''] = crRest.split(
      this.getRecommandationsDelimiter()
    );
    return { header, compteRendu, conclusion, recommandations };
  };
}

const splitReport = (report: string, intl: IntlShape) => {
  const fullReportGenerator = new FullReportGenerator(intl);

  return fullReportGenerator.splitReport(report);
};

const getReport = (
  animalName: string,
  ownerName: string,
  imagesInCase: ImageForReport[],
  intl: IntlShape,
  creationDate: Date
) => {
  const fullReportGenerator = new FullReportGenerator(intl);
  const nonWorkListImages = _.reject(imagesInCase, isWorkListImage);
  const imagesForReport = updateAllImagesPredictionsWithFeedbacks(nonWorkListImages);

  return fullReportGenerator.generateReport(animalName, ownerName, imagesForReport, creationDate);
};

const getReportFromStudy = (study: StudyForReport, intl: IntlShape) =>
  getReport(study.animalName, study.ownerName, study.images, intl, study.creationDate);

export { getReport, getReportFromStudy, splitReport };
