import { StoreWithLanguage } from 'app/redux/language/reducer';

/**
 * Direct selector to the languageToggle state domain
 */
export const selectLanguage = (state: StoreWithLanguage) => state.language;

/**
 * Select the language locale
 */

export const selectLocale = (state: StoreWithLanguage) => state.language.locale;
