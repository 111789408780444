import React from 'react';
import { useSelector } from 'react-redux';
import { selectLocale } from '../../redux/language/selectors';
import './styles.scss';
import AppHelmet from 'app/components/AppHelmet';
import { Container, Header, List } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import IntlCrawlableLink from 'app/components/IntlCrawlableLink';

/**
 * Render French Text
 * @returns {JSX.Element}
 */
function RenderFrenchText() {
  return (
    <>
      <section id="1-preamble">
        <h2>1.&nbsp;Préambule</h2>
        <p>
          Dans le cadre de son activité, la société <b>PicoxIA</b>, dont le siège est situé au&nbsp;
          <b>37 Rue du Bois Chaland Bat 5E, 91090 Lisses, France</b>, est amenée à collecter et
          traiter des informations dont certaines sont qualifiées de "données personnelles", auprès
          des visiteurs et des Utilisateurs du Site&nbsp;
          <b>
            <a href="https://picoxia.com/fr/">picoxia.com</a>
          </b>
          . Cette collecte a notamment lieu lors de la navigation et l'inscription sur le Site, ou
          par le biais de questionnaires et formulaires. PicoxIA attache une grande importance au
          respect de la vie privée, et n'utilise les données que de manière responsable et
          confidentielle, dans une finalité précise. La présente Politique de confidentialité
          (ci-après, la «&nbsp;Politique&nbsp;») encadre la collecte et le traitement de ces
          informations par PicoxIA conformément aux dispositions de la réglementation française en
          vigueur.
        </p>
        <p>
          Cette Politique peut être modifiée à tout moment par PicoxIA, afin notamment de se
          conformer à toutes évolutions réglementaires, jurisprudentielles, éditoriales ou
          techniques. Cependant, les Données Personnelles de l'Utilisateur seront toujours traitées
          conformément à la Politique en vigueur au moment de leur collecte. L'Utilisateur doit se
          référer avant toute navigation à la dernière version de la Politique publiée sur le site,
          dans l'onglet
          <b> Politique de Confidentialité</b>. Tout Utilisateur du Site, consent préalablement à la
          collecte, au traitement et à l'hébergement desdites Données à caractère personnel et des
          données liées aux animaux faisant l'objet des consultations vétérinaires.
        </p>
        <p>
          La Politique complète les Mentions Légales et les Conditions Générales de Vente des
          Services que les Utilisateurs peuvent consulter, dans les onglets intitulés&nbsp;
          <b>
            <IntlCrawlableLink url="/legal-notices">Mentions Légales</IntlCrawlableLink>
          </b>
          &nbsp;et&nbsp;
          <b>
            <IntlCrawlableLink url="/terms-of-sales">CGV</IntlCrawlableLink>
          </b>
          .
        </p>
      </section>

      <section id="2-definitions">
        <h2>2.&nbsp;Définitions</h2>
        <List divided relaxed>
          <List.Item>
            <List.Content>
              <List.Header>Clinique&nbsp;:</List.Header>
              <List.Description>
                désigne la clinique vétérinaire ayant souscrit aux solutions PicoxIA.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Cookie&nbsp;:</List.Header>
              <List.Description>
                désigne un fichier d'information enregistré sur le terminal de l'utilisateur dans le
                cadre de sa navigation sur des sites internet ou de l'utilisation de services en
                ligne. Ce fichier est susceptible de contenir des informations telles que l'adresse
                IP de l'utilisateur, les dates et heures d'accès aux services, ses identifiants.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Données à caractère personnel&nbsp;:</List.Header>
              <List.Description>
                toute information concernant une personne physique recueillie par le biais du Site
                et/ou dans le cadre de la fourniture des Services.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Identifiants&nbsp;:</List.Header>
              <List.Description>
                désignent le login (adresse email) et le mot de passe personnel associé au Compte de
                la Clinique.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Solutions&nbsp;:</List.Header>
              <List.Description>
                désigne les fonctions opérationnelles, soit tous logiciels ou applications
                développés par PicoxIA et fournis en mode service au Professionel via son Site.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Site&nbsp;:</List.Header>
              <List.Description>
                désigne le site internet accessible à l'adresse&nbsp;
                <b>
                  <a href="https://picoxia.com/fr/">picoxia.com</a>
                </b>{' '}
                et permettant l'accès aux Solutions PicoxIA.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Utilisateur&nbsp;:</List.Header>
              <List.Description>
                désigne toute personne qui visite et/ou utilise le Site Internet et les Solutions
                PicoxIA, ce compris les vétérinaires indépendant, Vétérinaires et les Cliniques.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Vétérinaire&nbsp;:</List.Header>
              <List.Description>
                désigne chaque Vétérinaire membre de la Clinique ayant souscrit l'abonnement.
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      </section>

      <section id="3-personal-data">
        <h2>3.&nbsp;Données à caractère personnel collectées dans le cadre des Services</h2>
        <p>
          L'Utilisateur est informé que PicoxIA traite les Données à caractère personnel des
          Utilisateurs suivantes&nbsp;: Nom, Prénom, Email, Identifiants, code postal, numéro de
          téléphone, adresse postale, adresse IP&nbsp;; ainsi que des informations transmises sur
          leurs patients (Nom, espèce, âge, identifiant du Propriétaire et images radiographiques),
          ayant pour principales finalités&nbsp;:
        </p>
        <ul>
          <li>la création d'un compte client chez PicoxIA&nbsp;;</li>
          <li>de permettre le prélèvement automatique du prix de l'abonnement&nbsp;;</li>
          <li>l'identification et l'archivage des radiographies transmises et analysées&nbsp;;</li>
          <li>La réalisation d'études marketing&nbsp;;</li>
          <li>
            la transmission d'informations à caractère commercial et la réalisation d'actions de
            prospection.
          </li>
        </ul>
        <p>
          Des traitements supplémentaires peuvent être mis en place dès lors qu'ils sont requis par
          la loi ou ordonnés dans le cadre de procédure judiciaire ou administrative.
        </p>
        <p>
          Les Données de l'Utilisateur sont traitées pendant une durée qui n'excède pas la durée
          nécessaire aux finalités pour lesquelles elles sont collectées.
        </p>
        <p>
          Le Site PicoxIA est hébergé par la société Google Ireland Limited, dont le siège est situé
          à l'adresse Gordon House, Barrow Street, Dublin 4, Irlande. PicoxIA garantit ainsi que
          l'hébergement des données communiquées est exclusivement assuré au sein de l'Union
          Européenne.
        </p>
      </section>

      <section id="4-authorized-persons">
        <h2>4.&nbsp;Personnes habilitées et destinataires des Données</h2>
        <p>
          Les Données collectées et traitées de manière loyale et licite sont strictement destinées
          à PicoxIA pour les finalités décrites dans&nbsp;
          <IntlCrawlableLink url="/privacy-policy#3-personal-data">l'article 3</IntlCrawlableLink>.
          Elles ne peuvent faire l'objet d'opérations de traitement par un sous-traitant ou
          partenaire contractuel de PicoxIA que sur ses instructions. PicoxIA veille à ce titre à ce
          que ses éventuels sous-traitants et partenaires présentent les garanties nécessaires à la
          protection des Données de l'Utilisateur.
        </p>
        <p>
          PicoxIA est le seul et unique responsable de traitement des Données. PicoxIA ne
          divulguera, vendra ou louera en aucun cas de Données à caractère personnelle à un tiers.
        </p>
        <p>
          Les Données collectées par PicoxIA pourraient également faire l'objet d'un transfert à des
          tiers dans le cadre d'un changement de contrôle de la société PicoxIA, d'une acquisition,
          d'une procédure collective ou d'une vente des actifs de la société, ce qu'accepte
          l'Utilisateur.
        </p>
      </section>

      <section id="5-right-of-access">
        <h2>
          5.&nbsp;Droit d'accès, de rectification, de modification, d'opposition et de suppression
        </h2>
        <p>
          Les Utilisateurs disposent à tout moment d'un droit d'accès, d'interrogation, de
          modification, de rectification et de suppression de leurs Données qui leur permet, le cas
          échéant, de faire rectifier, compléter, mettre à jour, verrouiller ou effacer les Données
          personnelles les concernant qui sont inexactes, incomplètes, équivoques, périmées ou dont
          la collecte, l'utilisation, la communication ou la conservation est interdite.
        </p>
        <p>
          Les Utilisateurs disposent également d'un droit d'opposition au traitement de leurs
          Données pour des motifs légitimes, sous réserves des droits et obligations légales de
          PicoxIA, ainsi que d'un droit d'opposition à ce que leurs Données soient utilisées à des
          fins de prospection notamment commerciale.
        </p>
        <p>
          L'Utilisateur dispose enfin du droit de définir des directives relatives au sort de ses
          Données personnelles après sa mort.
        </p>
        <p>
          Ces droits, et tout autres droits individuels établies par la loi n° 2016-1321 du 7
          octobre 2016 ou par le Règlement général sur la protection des données (UE) n°2016/679,
          peuvent s'exercer directement ou via l'intermédiaire de la Clinique, par courrier
          électronique accompagné d'une copie d'un titre d'identité signé à l'adresse
          e-mail&nbsp;:&nbsp;
          <b>
            <a href="mailto:contact@picoxia.com" target="_blank">
              contact@picoxia.com
            </a>
          </b>
          .
        </p>
        <p>
          Le responsable du traitement des Données est tenu de répondre à l'Utilisateur dans un
          délai de 30 (trente) jours maximum.
        </p>
      </section>

      <section id="6-data-controller">
        <h2>6.&nbsp;Responsable de traitement</h2>
        <p>
          Dans le cadre de l'utilisation du Site et de la fourniture des Services, le responsable de
          la collecte, du traitement et de la conservation des Données à caractère personnelles est
          la société PicoxIA. Celle-ci assure plus généralement la fonction d'éditrice du Site.
        </p>
      </section>

      <section id="7-security-and-confidentiality">
        <h2>7.&nbsp;Sécurité et confidentialité</h2>
        <p>
          PicoxIA prend toutes les mesures raisonnables visant à ce que les Données à caractère
          personnel soient traitées de manière à garantir une sécurité et une confidentialité
          appropriées, y compris pour prévenir l'accès non autorisé à ces données et à l'équipement
          utilisé pour leur traitement ainsi que l'utilisation non autorisée de ces données et de
          cet équipement. Dans ce but, le Site dispose notamment d'un certificat SSL.
        </p>
        <p>
          PicoxIA s'engage à préserver la confidentialité des informations fournies en ligne par la
          Clinique pour la bonne gestion des abonnements et la fourniture des Services.
        </p>
        <p>
          Dans le cas où l'intégrité, la confidentialité ou la sécurité des Données à caractère
          personnel de l'Utilisateur est compromise, PicoxIA s'engage à en informer l'Utilisateur
          par tous moyen.
        </p>
      </section>

      <section id="8-cookies">
        <h2>8.&nbsp;Cookies et Local Storage</h2>
        <p>
          PicoxIA dépose dans le navigateur de l'utilisateur des informations permettant
          d'enregistrer sa session, d'afficher un rendu correspondant à ses abonnements et de
          mémoriser ses préférences d'affichage. PicoxIA utilise également des cookies nécessaires
          au fonctionnement normal du site, notamment pour l'achat en ligne et la visualisation de
          vidéos. Leurs caractéristiques sont conformes au RGPD.
        </p>
      </section>

      <section id="9-contact">
        <h2>9.&nbsp;Nous Contacter</h2>
        <p>
          Toutes questions, réclamations ou commentaires à l'égard de la présente Politique pourront
          être adressées par courrier électronique à l'adresse suivante&nbsp;:&nbsp;
          <b>
            <a href="mailto:contact@picoxia.com" target="_blank">
              contact@picoxia.com
            </a>
          </b>
          .
        </p>
        <p>La présente Politique est applicable au 18 Juin 2020.</p>
      </section>
    </>
  );
}

/**
 * Render English Text
 * @returns {JSX.Element}
 */
function RenderEnglishText() {
  return (
    <>
      <h2 id="1-preamble">1.&nbsp;Preamble</h2>
      <p>
        As part of its activity, the company <b>PicoxIA</b>, whose head office is located at
        <b>37 Rue du Bois Chaland Bat 5E, 91090 Lisses, France</b>, is required to collect and
        process information, some of which are qualified as "personal data", with visitors and Users
        of the Website&nbsp;
        <b>
          <a href="https://picoxia.com/fr/">picoxia.com</a>
        </b>
        . This collection takes place in particular when browsing and registering on the Site, or
        through questionnaires and forms. PicoxIA values privacy, and only uses data in a esponsible
        and confidential manner, for a specific purpose. This Privacy Policy (hereinafter, the
        "Policy") oversees the collection and processing of this information by PicoxIA in
        accordance with the provisions of French regulations in force.
      </p>
      <p>
        This Policy may be modified at any time by PicoxIA, in particular in order to comply with
        all regulatory, jurisprudential, editorial or technical developments. However, the Personal
        Data of the User will always be treated in accordance with the Policy in force at the time
        of their collection. The User must refer before any navigation to the latest version of the
        Policy published on the Website, in the
        <b> Privacy Policy </b> tab. Any User of the Webite, consents prior to the collection,
        processing and hosting of said Personal Data and data relating to the animals subject to the
        veterinarian consultations.
      </p>
      <p>
        The Policy supplements the Legal Notices and the General Conditions of Sale of the Services
        that the Users can consult, in the tabs entitled&nbsp;
        <b>
          <IntlCrawlableLink url="/legal-notices">Legal Notices</IntlCrawlableLink>
        </b>
        &nbsp;et&nbsp;
        <b>
          <IntlCrawlableLink url="/terms-of-sales">Terms of Sales</IntlCrawlableLink>
        </b>
        .
      </p>

      <section id="2-definitions">
        <h2>2.&nbsp;Definitions</h2>
        <List divided relaxed>
          <List.Item>
            <List.Content>
              <List.Header>Clinic:</List.Header>
              <List.Description>
                refers to the veterinary clinic that has subscribed to PicoxIA solutions.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Cookie:</List.Header>
              <List.Description>
                designates an information file saved on the user's terminal as part of its browsing
                websites or using online services. This file may contain information such as the
                user's IP address, the dates and times of access to the services or his identifiers.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Personal data:</List.Header>
              <List.Description>
                any information concerning a natural person, collected through the website and/or
                during the provision of the Services.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Identifiers:</List.Header>
              <List.Description>
                designate the login (email address) and the personal password associated with the
                Clinic Account.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Solutions:</List.Header>
              <List.Description>
                refers to the operational functions, i.e. all software or applications developed by
                PicoxIA and provided in service mode to Professionals via its website.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Website:</List.Header>
              <List.Description>
                refers to the operational functions, i.e. all software or applications developed by
                PicoxIA and provided in service mode to Professionals via its Site.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Users:</List.Header>
              <List.Description>
                refers to any person who visits and/or uses the Website and the PicoxIA Solutions,
                including Independent Vets and Clinics.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Veterinarian:</List.Header>
              <List.Description>
                refers to each Veterinarian who is a member of the Clinic who has taken out the
                subscription.
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      </section>

      <section id="3-personal-data">
        <h2>3.&nbsp;Personal data collected as part of the Services</h2>
        <p>
          The User is informed that PicoxIA processes the Personal Data of the following Users: Last
          name, First name, Email, Identifiers, postal code, telephone number, postal address, IP
          address; as well as information transmitted on their patients (Name, species, age, Owner's
          identifier and radiographic images), the main purposes of which are:
        </p>
        <ul>
          <li>The creation of a customer account with PicoxIA;</li>
          <li>Allow the automatic debit of the subscription price;</li>
          <li>Identification and archiving of radiographs transmitted and analyzed;</li>
          <li>Carrying out marketing studies;</li>
          <li>
            The transmission of information of a commercial nature and the carrying out of
            prospecting actions.
          </li>
        </ul>
        <p>
          Additional treatments may be implemented when they are required by law or ordered in the
          framework of judicial or administrative proceedings.
        </p>
        <p>
          User Data is processed for a period that does not exceed the period necessary for the
          purposes for which they are collected.
        </p>
        <p>
          PicoxIA Website is hosted by Google Ireland Limited, headquartered at Gordon House, Barrow
          Street, Dublin 4, Ireland. PicoxIA thus guarantees that the data communicated is
          exclusively insured within the European Union.
        </p>
      </section>

      <section id="4-authorized-persons">
        <h2>4.&nbsp;Authorized persons and recipients of the Data</h2>
        <p>
          The Data collected and processed in a fair and lawful manner are strictly intended for
          PicoxIA for purposes described in&nbsp;
          <IntlCrawlableLink url="/privacy-policy#3-personal-data">l'article 3</IntlCrawlableLink>.
          They may be the subject of processing operations by a subcontractor or contractual partner
          of PicoxIA only on its instructions. PicoxIA therefore ensures that any subcontractors and
          partners present the necessary guarantees for the protection of User Data.
        </p>
        <p>
          PicoxIA is the one and only Data Controller. PicoxIA will not disclose, sell or rent in no
          case of Personal Data to a third party.
        </p>
        <p>
          The Data collected by PicoxIA could also be transferred to third parties in case of a
          change of control of the company, an acquisition, a collective procedure or a sale of the
          company's assets, which the User accepts.
        </p>
      </section>

      <section id="5-right-of-access">
        <h2>5.&nbsp;Right of access, rectification, modification, opposition and deletion</h2>
        <p>
          Users have at any time a right of access, interrogation, modification, rectification and
          deletion of their Data which allows them, if necessary, to have them rectified, completed,
          updated, blocked or deleted Personal in case they are inaccurate, incomplete, equivocal,
          out of date or whose collection, use, disclosure or storage is prohibited.
        </p>
        <p>
          Users also have the right to oppose the processing of their Data for legitimate reasons,
          subject to PicoxIA's legal rights and obligations, as well as a right of opposition to the
          use of their Data for prospecting and other commercial purposes.
        </p>
        <p>
          Finally, the User has the right to define directives relating to the fate of his Personal
          Data after his death.
        </p>
        <p>
          These rights, and any other individual rights established by law n° 2016-1321 of October
          7, 2016 or by the General Data Protection Regulation (EU) No. 2016/679, can be exercised
          directly or via the registered Clinic, by e-mail accompanied by a copy of a signed
          identity document to the email address:&nbsp;
          <b>
            <a href="mailto:contact@picoxia.com" target="_blank">
              contact@picoxia.com
            </a>
          </b>
          .
        </p>
        <p>
          The Data Controller is required to respond to the User within 30 (thirty) maximum days.
        </p>
      </section>

      <section id="6-data-controller">
        <h2>6.&nbsp;Data controller</h2>
        <p>
          In the context of the use of the Website and the provision of the Services, the person
          responsible for the collection, processing and storage of Personal Data is the company
          PicoxIA. The company ensures more generally the function of editor of the Site.
        </p>

        <h2 id="7-security-and-confidentiality">7.&nbsp;Security and confidentiality</h2>
        <p>
          PicoxIA takes all reasonable measures to ensure that Personal Data are processed in a way
          to ensure appropriate security and confidentiality, including prevention of unauthorized
          use of [or access to] this data and to the equipment used for its processing. For this
          purpose, the website has in particular a SSL certificate.
        </p>
        <p>
          PicoxIA undertakes to preserve the confidentiality of the information provided online by
          the Clinic for the proper management of subscriptions and the provision of Services.
        </p>
        <p>
          In the event that the integrity, confidentiality or security of Personal Data of the User
          is compromised, PicoxIA undertakes to inform the User by all means.
        </p>
      </section>

      <section id="8-cookie">
        <h2>8.&nbsp;Cookies and Local Storage</h2>
        <p>
          PicoxIA records data on the User's browser to save his session, get him a display
          corresponding to his subscriptions and save his preferences. PicoxIA also uses cookies for
          the normal operation of the Website, in particular for the online purchases and the
          watching of videos. Their characteristics are compliant with the General Data Protection
          Regulation (France).
        </p>
      </section>

      <section id="9-contact">
        <h2>9.&nbsp;Contact us</h2>
        <p>
          Any questions, complaints or comments regarding this Policy may be addressed by email to
          the following address:&nbsp;
          <b>
            <a href="mailto:contact@picoxia.com" target="_blank">
              contact@picoxia.com
            </a>
          </b>
          .
        </p>
        <p>This Policy is applicable as of June 18, 2020.</p>
      </section>
    </>
  );
}

/**
 * Render Privacy Policy
 * @returns {JSX.Element}
 */
function RenderPrivacyPolicy() {
  const locale = useSelector(selectLocale);

  if (locale === 'fr') {
    return <RenderFrenchText />;
  }

  return <RenderEnglishText />;
}

/**
 * PrivacyPolicyPage du site
 * @returns {JSX.Element}
 */
function PrivacyPolicyPage() {
  return (
    <div className="privacy-policy-page">
      <AppHelmet
        titleIntlID="privacy-policy.page-title"
        descriptionIntlID="privacy-policy.page-description"
      />
      <Container textAlign="justified">
        <Header as="h1">
          <FormattedMessage id="privacy-policy.page-title" />
        </Header>
        <RenderPrivacyPolicy />
      </Container>
    </div>
  );
}

export default PrivacyPolicyPage;
