import _, { isNil } from 'lodash';
import { DYSPLASIA_STADE_THRESHOLDS } from 'app/constants/dysplasia';
import { predictionsMerger } from 'app/utils//predictions/utils';

import BaseReportGenerator from './BaseReportGenerator';
import { newline, tab, bulletPointLevel1, bulletPointLevel2 } from './constants';
import { IntlShape } from 'react-intl';
import { ImageForReport, PatternsThresholds, StageGroup } from 'app/utils/reports/types';
import { getFirstAnnotationValue } from './getFirstAnnotationValue';
import { HipPredictions, HipSide, PelvisPredictions } from 'app/interfaces/Predictions';
import { DistractionIndexAnnotation, NorbergOlssonAnnotation } from 'app/interfaces/Image';

const DYSPLASIA_ORDERED_PATTERNS = [
  'defaut_de_coaptation',
  'incongruence',
  'insuffisance_de_couverture_acetabulaire',
  'arthrose',
] as const;
Object.freeze(DYSPLASIA_ORDERED_PATTERNS);

const DYSPLASIA_PATTERNS_WITH_THRESHOLDS = {
  defaut_de_coaptation: [
    { severity: 'none', threshold: 0.4 },
    { severity: 'minor', threshold: 0.5 },
    { severity: 'moderate', threshold: 0.75 },
    { severity: 'significant', threshold: 1 },
  ],
  incongruence: [
    { severity: 'none', threshold: 0.4 },
    { severity: 'minor', threshold: 0.5 },
    { severity: 'moderate', threshold: 0.75 },
    { severity: 'significant', threshold: 1 },
  ],
  insuffisance_de_couverture_acetabulaire: [
    { severity: 'good', threshold: 0.4 },
    { severity: null, threshold: 0.5 },
    { severity: 'poor', threshold: 1 },
  ],
  arthrose: [
    { severity: 'none', threshold: 0.4 },
    { severity: 'minor', threshold: 0.5 },
    { severity: 'moderate', threshold: 0.75 },
    { severity: 'significant', threshold: 1 },
  ],
};
Object.freeze(DYSPLASIA_PATTERNS_WITH_THRESHOLDS);

const STADE_GROUPS = [
  { needed: ['A'], accepted: [], key: 'A' },
  { needed: ['A-B', 'B'], accepted: ['A'], key: 'B' },
  { needed: ['B-C', 'C'], accepted: ['C-D'], key: 'C' },
  { needed: ['C-D', 'D'], accepted: ['D-E'], key: 'D' },
  { needed: ['D-E', 'E'], accepted: [], key: 'E' },
];
Object.freeze(STADE_GROUPS);

const getObjectUnderThreshold = <T extends { threshold: number }>(
  thresholds: T[],
  value: number
) => {
  const thresholdObject = _.find(thresholds, ({ threshold }) => value < threshold);
  return thresholdObject || thresholds[thresholds.length - 1];
};

const makeJoinedValuesWithHeader = (header: string, values: string[]) =>
  values.length > 0 ? header + values.join('; ') : undefined;

const formatNorbergOlssonValue = (value: string) => `${value}°`;

type NorbergOlssonComputedValuesKeys = 'leftAngle' | 'rightAngle';
type DistractionIndexComputedValuesKeys = 'leftIndex' | 'rightIndex';

const getToolValues = (
  toolName: 'NorbergOlsson' | 'DistractionIndex',
  images: ImageForReport[],
  valueKey: NorbergOlssonComputedValuesKeys | DistractionIndexComputedValuesKeys
): number[] =>
  _(images)
    // @ts-ignore Not work getting into all the type details
    .map((image) => getFirstAnnotationValue(image?.annotations?.[toolName])?.[valueKey])
    .filter()
    .value();

const getNOAngles = (images: ImageForReport[], angleName: NorbergOlssonComputedValuesKeys) =>
  getToolValues('NorbergOlsson', images, angleName);

const getDistractionIndexes = (
  images: ImageForReport[],
  angleName: DistractionIndexComputedValuesKeys
) => getToolValues('DistractionIndex', images, angleName);

class PelvisReportGenerator extends BaseReportGenerator {
  private dysplasiaPatternsThresholds: PatternsThresholds;
  private dysplasiaOrderedPatterns: typeof DYSPLASIA_ORDERED_PATTERNS;
  private dysplasiaStadeThresholds: typeof DYSPLASIA_STADE_THRESHOLDS;
  private stadeGroups: StageGroup[];
  constructor(intl: IntlShape) {
    super(intl);
    this.dysplasiaPatternsThresholds = DYSPLASIA_PATTERNS_WITH_THRESHOLDS;
    this.dysplasiaOrderedPatterns = DYSPLASIA_ORDERED_PATTERNS;
    this.dysplasiaStadeThresholds = DYSPLASIA_STADE_THRESHOLDS;

    this.stadeGroups = STADE_GROUPS;
  }

  /**
   *
   * @param imagesInCase
   * @returns string
   */
  generateReport = (imagesInCase: ImageForReport[]) => {
    if (
      !imagesInCase.some(
        (img) =>
          _.get(img, 'predictions.norberg_olsson', null) ||
          _.get(img, 'annotations.NorbergOlsson', null) ||
          _.get(img, 'annotations.DistractionIndex', null)
      )
    ) {
      return '';
    }
    let report = '';

    // The pelvis xray is a ventro-dorsal (the animal is on its back). This means that the
    // left part of the image match the right side of the animal and the right part of the
    // image match the left side of the animal.
    const leftHipAggregatedPredictions = this.getAggregatedHipPredictions(imagesInCase, 'right');
    const rightHipAggregatedPredictions = this.getAggregatedHipPredictions(imagesInCase, 'left');

    const [leftStade, rightStade] = this.getStadesGroup(
      leftHipAggregatedPredictions.stade,
      rightHipAggregatedPredictions.stade
    );

    const isSameStade = leftStade === rightStade;
    if (isSameStade) {
      const mergedPelvisPredictions = _.mergeWith(
        {},
        leftHipAggregatedPredictions,
        rightHipAggregatedPredictions,
        predictionsMerger
      );
      mergedPelvisPredictions.stade = leftStade;
      const hipsReport = this.getDysplasiaPatternsReport(mergedPelvisPredictions, true);
      report += hipsReport + (hipsReport ? newline + newline : '');
    }

    leftHipAggregatedPredictions.stade = leftStade;
    rightHipAggregatedPredictions.stade = rightStade;

    const leftImageReport = this.getLeftHipReport(
      imagesInCase,
      leftHipAggregatedPredictions,
      isSameStade
    );

    if (leftImageReport) {
      report += `${this.formatMessage('report.section.Left_hip')} :${newline}`;
      report += leftImageReport + newline;
    }

    const rightImageReport = this.getRightHipReport(
      imagesInCase,
      rightHipAggregatedPredictions,
      isSameStade
    );

    if (rightImageReport) {
      report += `${this.formatMessage('report.section.Right_hip')} :${newline}`;
      report += rightImageReport + newline;
    }

    return report
      ? `${this.formatMessage('pelvis').toUpperCase()} :${newline}${newline}${report}${newline}`
      : '';
  };

  formatDecimalNumber = (value: number) =>
    this.formatNumber(+value.toFixed(2), { style: 'decimal' });

  /**
   *
   * @param imagesInCase
   * @param NorbergAngleKey
   * @param DistractionIndexKey
   * @param hipPatterns
   * @param isSameStade
   * @returns string
   */
  getHipReport = (
    imagesInCase: ImageForReport[],
    NorbergAngleKey: 'rightAngle' | 'leftAngle',
    DistractionIndexKey: 'leftIndex' | 'rightIndex',
    hipPatterns: HipPredictions,
    isSameStade: boolean
  ) => {
    const norbergOlssonReport = makeJoinedValuesWithHeader(
      `${this.formatMessage('report.measure.norberg_olsson')} = `,
      getNOAngles(imagesInCase, NorbergAngleKey)
        .map(this.formatDecimalNumber)
        .map(formatNorbergOlssonValue)
    );

    const distractionIndexReport = makeJoinedValuesWithHeader(
      `${this.formatMessage('report.measure.distraction_index')} = `,
      getDistractionIndexes(imagesInCase, DistractionIndexKey).map(this.formatDecimalNumber)
    );

    let dysplasiaPatternsReport;
    if (!isSameStade && hipPatterns !== null) {
      dysplasiaPatternsReport = this.getDysplasiaPatternsReport(hipPatterns, false);
    }

    const report = _([norbergOlssonReport, distractionIndexReport, dysplasiaPatternsReport])
      .filter()
      .join(newline);

    return report + (report && newline);
  };

  /**
   *
   * @param imagesInCase
   * @param hipPatterns
   * @param isSameStade
   * @returns string
   */
  getLeftHipReport = (
    imagesInCase: ImageForReport[],
    hipPatterns: HipPredictions,
    isSameStade: boolean
  ) => this.getHipReport(imagesInCase, 'rightAngle', 'rightIndex', hipPatterns, isSameStade);

  /**
   *
   * @param imagesInCase
   * @param hipPatterns
   * @param isSameStade
   * @returns string
   */
  getRightHipReport = (
    imagesInCase: ImageForReport[],
    hipPatterns: HipPredictions,
    isSameStade: boolean
  ) => this.getHipReport(imagesInCase, 'leftAngle', 'leftIndex', hipPatterns, isSameStade);

  /**
   *
   * @param hipPatterns
   * @param isBothHips boolean
   * @returns string
   */
  getDysplasiaPatternsReport = (hipPatterns: HipPredictions, isBothHips = false) => {
    if (!hipPatterns) return '';

    let report = '';
    const hipPatternsSeverity = this.convertHipPredictionsToSeverity(hipPatterns);
    if (!_.isNil(hipPatterns.stade)) {
      report += _.capitalize(
        this.formatMessageIfPlural(
          `report.patterns.dysplasia.stade.${hipPatternsSeverity.stade}.description`,
          isBothHips
        )
      );
      report += ' : ';
    }

    const orderedPatternsList = this.dysplasiaOrderedPatterns
      .filter((pattern) => !isNil(hipPatternsSeverity.patterns[pattern]))
      .map((pattern) => `dysplasia.${pattern}_${hipPatternsSeverity.patterns[pattern]}`);

    report += _.capitalize(this.enumeratePatterns(orderedPatternsList));
    if (report) report += '.';
    return report;
  };

  /**
   *
   * @param imagesInCase
   * @param hipKey "left" or "right"
   * @returns HipPredictions
   */
  getAggregatedHipPredictions = (imagesInCase: ImageForReport[], hipKey: HipSide) => {
    const hipsPredictions = imagesInCase
      .filter((img) => (img.predictions as PelvisPredictions).norberg_olsson !== undefined)
      .map((img) => (img.predictions as PelvisPredictions).norberg_olsson[hipKey]);

    const relevantHipsPredictions = hipsPredictions.map((hipPredictions) =>
      _.pick(hipPredictions, [
        'stade',
        ...this.dysplasiaOrderedPatterns.map((pattern) => `patterns.${pattern}`),
      ])
    );

    const aggregatedHipsPredictions: HipPredictions = _.mergeWith(
      {},
      ...relevantHipsPredictions,
      predictionsMerger
    );

    return aggregatedHipsPredictions;
  };

  convertHipPredictionsToSeverity = ({ stade, patterns }: HipPredictions) => ({
    stade,
    patterns: _.mapValues(
      patterns,
      (value, pattern) =>
        getObjectUnderThreshold(this.dysplasiaPatternsThresholds[pattern], value).severity
    ),
  });

  /**
   * Transform the diverse stades 'A', 'A-B', 'B', 'B-C', ... to a shorter list of key (A, B,
   * C, D, E) used to display stade description.
   * This is not a direct mapping because intermediate stades ('B-C', 'C-D', ...) can be mapped
   * to their higher or lower counterpart depending on the others stades provided.
   * @param  {...number} stades Stades to associate to their stade group
   * @returns {string[]} List of corresponding stade group for each stade provided.
   */
  getStadesGroup = (...stades: number[]) => {
    const stadesName = stades.map((stade) =>
      _.isNil(stade)
        ? stade
        : getObjectUnderThreshold(this.dysplasiaStadeThresholds, stade).stade.name
    );
    let stadesKey = new Array(stades.length).fill(undefined);

    _.forEach(this.stadeGroups, (stadeGroup) => {
      const isAnyStadeInStadeGroup = _.intersection(stadeGroup.needed, stadesName).length > 0;
      if (isAnyStadeInStadeGroup) {
        const compatibleStades = stadeGroup.needed.concat(stadeGroup.accepted);

        if (stadesName.every((stadeName) => compatibleStades.includes(stadeName))) {
          stadesKey = new Array(stades.length).fill(stadeGroup.key);
          return false;
        }
      }

      // If our stade do not belong to the same stade group,
      // we still fill `stadesKey` with the correct stade names.
      stadesName.forEach((stadeName, stadesNameIndex) => {
        if (stadeGroup.needed.includes(stadeName)) {
          stadesKey[stadesNameIndex] = stadeGroup.key;
        }
      });

      if (stadesKey.every(Boolean)) {
        return false;
      }
    });

    return stadesKey;
  };

  /**
   *
   * @param hipPatterns
   * @param isBothHips boolean
   * @param level integer
   * @returns string
   */
  getDysplasiaPatternsList = (hipPatterns: HipPredictions, isBothHips = false, level = 1) => {
    if (!hipPatterns) return '';
    const tabulation = tab.repeat(level);
    const bulletPoint = level === 1 ? bulletPointLevel1 : bulletPointLevel2;

    let list = '';
    const hipPatternsSeverity = this.convertHipPredictionsToSeverity(hipPatterns);
    if (!_.isNil(hipPatterns.stade)) {
      list +=
        tabulation +
        (level > 1 ? bulletPointLevel1 : '') +
        _.capitalize(
          this.formatMessageIfPlural(
            `report.patterns.dysplasia.stade.${hipPatterns.stade}.description`,
            isBothHips
          )
        ) +
        ' :' +
        newline;
    }

    const orderedPatternsList = this.dysplasiaOrderedPatterns
      .filter((pattern) => !isNil(hipPatternsSeverity.patterns[pattern]))
      .map((pattern) => `dysplasia.${pattern}_${hipPatternsSeverity.patterns[pattern]}`);

    orderedPatternsList.forEach((pattern) => {
      list +=
        tabulation +
        tab +
        bulletPoint +
        _.capitalize(this.formatMessage(`report.patterns.${pattern}`)) +
        newline;
    });
    return list;
  };

  getHipList = (
    imagesInCase: ImageForReport[],
    NorbergAngleKey: 'rightAngle' | 'leftAngle',
    DistractionIndexKey: 'leftIndex' | 'rightIndex',
    hipPatterns: HipPredictions,
    isSameStade: boolean
  ) => {
    const norbergOlssonPattern = makeJoinedValuesWithHeader(
      tab + tab + bulletPointLevel1 + this.formatMessage('report.measure.norberg_olsson') + ' = ',
      getNOAngles(imagesInCase, NorbergAngleKey)
        .map(this.formatDecimalNumber)
        .map(formatNorbergOlssonValue)
    );

    const distractionIndexPattern = makeJoinedValuesWithHeader(
      tab +
        tab +
        bulletPointLevel1 +
        this.formatMessage('report.measure.distraction_index') +
        ' = ',
      getDistractionIndexes(imagesInCase, DistractionIndexKey).map(this.formatDecimalNumber)
    );

    let dysplasiaPatterns;
    if (!isSameStade && hipPatterns !== null) {
      dysplasiaPatterns = this.getDysplasiaPatternsList(hipPatterns, false, 2);
    }

    const hipList = _([norbergOlssonPattern, distractionIndexPattern, dysplasiaPatterns])
      .filter()
      .join(newline);

    return hipList + (hipList && newline);
  };

  /**
   * Generates Report as a List
   * @param imagesInCase
   * @returns string
   */
  generateList = (imagesInCase: ImageForReport[]) => {
    if (
      !imagesInCase.some(
        (img) =>
          _.get(img, 'predictions.norberg_olsson', null) ||
          _.get(img, 'annotations.NorbergOlsson', null) ||
          _.get(img, 'annotations.DistractionIndex', null)
      )
    ) {
      return '';
    }

    const leftHipAggregatedPredictions = this.getAggregatedHipPredictions(imagesInCase, 'right');
    const rightHipAggregatedPredictions = this.getAggregatedHipPredictions(imagesInCase, 'left');

    const [leftStade, rightStade] = this.getStadesGroup(
      leftHipAggregatedPredictions.stade,
      rightHipAggregatedPredictions.stade
    );

    let list = '';

    const isSameStade = leftStade === rightStade;
    if (isSameStade) {
      const mergedPelvisPredictions = _.mergeWith(
        {},
        leftHipAggregatedPredictions,
        rightHipAggregatedPredictions,
        predictionsMerger
      );
      mergedPelvisPredictions.stade = leftStade;

      list += this.getDysplasiaPatternsList(mergedPelvisPredictions, isSameStade) + newline;
    }

    leftHipAggregatedPredictions.stade = leftStade;
    rightHipAggregatedPredictions.stade = rightStade;

    /* Hanche Gauche */
    const leftImageList = this.getHipList(
      imagesInCase,
      'rightAngle',
      'rightIndex',
      leftHipAggregatedPredictions,
      isSameStade
    );

    if (leftImageList) {
      list += tab + this.formatMessage('report.section.Left_hip') + ' :' + newline;
      list += leftImageList + newline;
    }

    /* Hanche Droite */
    const rightImageList = this.getHipList(
      imagesInCase,
      'leftAngle',
      'leftIndex',
      rightHipAggregatedPredictions,
      isSameStade
    );

    if (rightImageList) {
      list += tab + this.formatMessage('report.section.Right_hip') + ' :' + newline;
      list += rightImageList + newline;
    }

    return list
      ? this.formatMessage('pelvis').toUpperCase() + ' :' + newline + newline + list + newline
      : '';
  };
}

const getPelvisReport = (imagesInCase: ImageForReport[], intl: IntlShape) => {
  const pelvisReportGenerator = new PelvisReportGenerator(intl);

  if (['fr', 'en'].includes(intl.locale) === false) {
    return pelvisReportGenerator.generateList(imagesInCase);
  }

  return pelvisReportGenerator.generateReport(imagesInCase);
};

export default getPelvisReport;
