import { setAiOnlyEnabled } from 'app/redux/aiOnlyConfiguration/actions';
import { selectAiOnlyEnabledConfiguration } from 'app/redux/aiOnlyConfiguration/reducer';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Radio } from 'semantic-ui-react';
import useAiOnlyPasswordProtection from 'app/utils/hooks/passwordProtection/useAiOnlyPasswordProtection';

/**
 * Block de setting de l'opion AI Only
 * Permet cacher certains ouitils, informations pour l'interprétation IA de la version Deskstop
 * Conséquence d'utilisation dans les fichiers suivants :
 *  - {@link app\components\Dropzone\ButtonStudySave.jsx} Cache la mention de PACS
 *  - {@link app\components\Header\index.jsx}  Cache le menu Historique
 *  - {@link app\components\ToolsBar\ProcessingListButton.jsx}  Cache le Button de
 *    Post-Processing pour les DICOMs
 *
 * @returns {JSX.Element}
 */
function AiOnlyConfiguration() {
  const aiOnlyEnabled = useSelector(selectAiOnlyEnabledConfiguration);
  const [requestValidation, modalComponent] = useAiOnlyPasswordProtection();

  const dispatch = useDispatch();

  const toggleConfiguration = useCallback(() => {
    requestValidation()
      .then(() => dispatch(setAiOnlyEnabled(!aiOnlyEnabled)))
      .catch(() => {});
  }, [dispatch, aiOnlyEnabled, requestValidation]);

  if (process.env.PLATFORM !== 'electron') {
    return null;
  }

  return (
    <Grid.Row>
      <Grid.Column>
        <FormattedMessage id="account.configuration.ai_only.title" />
      </Grid.Column>
      <Grid.Column>
        <Radio toggle checked={aiOnlyEnabled} onChange={toggleConfiguration} />
        {modalComponent}
      </Grid.Column>
    </Grid.Row>
  );
}

export default AiOnlyConfiguration;
