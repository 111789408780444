import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import 'app/styles/style.scss';
import './style.scss';
import { useSelector } from 'react-redux';
import { selectLocale } from 'app/redux/language/selectors';
import { FormattedMessage, useIntl } from 'react-intl';
import imgTropheesEsante2019 from './img/trophees-e-sante-2019.jpg';
import imgLogoFacebook from './img/facebook.png';
import imgLogoLinkedIn from './img/linkedin.png';
import imgLogoTwitterX from './img/twitter-x.png';
import imgLogoYoutube from './img/youtube.png';
import imgLogoGroupeMsiFas from './img/groupe-msi-fas.png';
import imgLogoGroupMsiFas from './img/group-msi-fas.png';
import imgLogoGer from './img/ger.png';
import IntlCrawlableLink from 'app/components/IntlCrawlableLink';

/**
 * Footer du site
 */
function Footer() {
  const intl = useIntl();
  const locale = useSelector(selectLocale);
  const imgLogoMsiFas = locale === 'fr' ? imgLogoGroupeMsiFas : imgLogoGroupMsiFas;

  return (
    <footer className="ui inverted vertical footer segment">
      <div className="ui container">
        <div className="footer__content">
          <div className="ui stackable mobile reversed three column grid">
            <div className="three wide column">
              <img
                className="ui rounded fluid image"
                src={imgTropheesEsante2019}
                alt={intl.formatMessage({ id: 'footer.img.trophees-e-sante-2019.alt' })}
              />
            </div>
            <div className="ten wide column">
              <address className="footer__contact">
                <a href="mailto:contact@picoxia.com" className="footer-contact-mail">
                  contact@picoxia.com
                </a>
                <br />
                <a href="tel:+33160861717" className="footer-contact-tel">
                  +33 1 60 86 17 17
                </a>
              </address>

              <ul className="footer__social-networks">
                <li className="footer__social-networks-item">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="ui centered mini image"
                    href="https://www.linkedin.com/company/picoxia/about/"
                    title="LinkedIn"
                  >
                    <img src={imgLogoLinkedIn} alt="LinkedIn" />
                  </a>
                </li>
                <li className="footer__social-networks-item">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="ui centered mini image"
                    href="https://www.facebook.com/Picoxia-361761101111791/"
                    title="Facebook"
                  >
                    <img src={imgLogoFacebook} alt="Facebook" />
                  </a>
                </li>
                <li className="footer__social-networks-item">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="ui centered mini image"
                    href="https://x.com/picox_ia"
                    title="Twitter"
                  >
                    <img src={imgLogoTwitterX} alt="X" />
                  </a>
                </li>
                <li className="footer__social-networks-item">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="ui centered mini image"
                    href="https://www.youtube.com/@Picoxia"
                    title="YouTube"
                  >
                    <img src={imgLogoYoutube} alt="X" />
                  </a>
                </li>
              </ul>

              <ul className="footer__menu">
                <li className="footer__menu-item">
                  <IntlCrawlableLink url="/cheat_sheet" className="white">
                    <FormattedMessage id="cheatSheet.pageTitle" />
                  </IntlCrawlableLink>
                  &nbsp;
                </li>
                <li className="footer__menu-item">
                  <IntlCrawlableLink url="/legal-notices" className="white">
                    <FormattedMessage id="legal-notices.title" />
                  </IntlCrawlableLink>
                  &nbsp;
                </li>
                <li className="footer__menu-item">
                  <IntlCrawlableLink url="/privacy-policy" className="white">
                    <FormattedMessage id="privacy-policy.page-title" />
                  </IntlCrawlableLink>
                </li>
              </ul>
            </div>
            <div className="three wide column">
              <div className="ui two column grid">
                <div className="ten wide column">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="ui image"
                    href="https://msi-fas.com/"
                    title={intl.formatMessage({ id: 'footer.img.logo-msi-fas.alt' })}
                  >
                    <img src={imgLogoMsiFas} alt="MSI-FAS" />
                  </a>
                </div>
                <div className="six wide column">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="ui image"
                    href="https://www.ger-int.com/"
                    title="GER"
                  >
                    <img src={imgLogoGer} alt="GER" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
