/* eslint-disable react/prop-types */
import { selectAiOnlyEnabledConfiguration } from 'app/redux/aiOnlyConfiguration/reducer';
import { selectViewerConfiguration } from 'app/redux/reducers';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';

/**
 * Button Save study with Popup
 * @param {object} props
 * @param {boolean} [props.saved]
 * @param {()=>void} props.validateStudy
 * @returns {JSX.Element}
 */
function ButtonStudySave({ saved, validateStudy }) {
  const viewerConfiguration = useSelector(selectViewerConfiguration);
  /** @type {boolean} */
  const aiOnlyEnabled = useSelector(selectAiOnlyEnabledConfiguration);
  /** @type {boolean} */
  const { syncToPACSOnValidate } = viewerConfiguration ?? {};
  const allTooltipContentIds = ['save_all_img', 'sync_img_with_pacs', 'remove_non_acquired_views'];
  const authorizedTooltipContentIds = allTooltipContentIds.filter(
    (tooltipContentId) =>
      !(
        (aiOnlyEnabled === true || !syncToPACSOnValidate) &&
        tooltipContentId === 'sync_img_with_pacs'
      )
  );
  const tooltipContentList = authorizedTooltipContentIds.map((id) => <LiRow key={id} id={id} />);

  return (
    <Popup
      trigger={
        <button
          data-testid="validate-study"
          className={`picoxia ${saved ? 'saved' : ''}`}
          type="button"
          onClick={validateStudy}
        >
          <Icon name="check" size="big" />
        </button>
      }
    >
      <Popup.Header>
        <FormattedMessage id="dropzone.save.tooltip.header" />
      </Popup.Header>
      <Popup.Content>
        <ul>{tooltipContentList}</ul>
      </Popup.Content>
    </Popup>
  );
}

/**
 * LiRow Loop
 * @param {string} id
 * @returns {JSX.Element}
 */
function LiRow({ id }) {
  const fullId = `dropzone.save.tooltip.content.li.${id}`;
  return (
    <li>
      <FormattedMessage id={fullId} />
    </li>
  );
}

export default ButtonStudySave;
