import {
  SharedUserConfig,
  SharedUserConfigurationAPI,
} from 'app/interfaces/SharedUserConfiguration';
import ApiCalls from 'app/utils/apiCalls';
import { withRetry } from 'app/utils/async/withRetry';

export default class OnlineSharedUserConfigurationAPI implements SharedUserConfigurationAPI {
  private currentGetController: AbortController | null = null;

  async get(): Promise<SharedUserConfig> {
    this.currentGetController?.abort();
    this.currentGetController = new AbortController();

    try {
      return await withRetry(
        () => ApiCalls.getUserConfig(),
        this.currentGetController,
        'GetSharedUserConfigurationAPI'
      );
    } finally {
      this.currentGetController = null;
    }
  }

  private currentUpdateController: AbortController | null = null;

  async update(config: SharedUserConfig): Promise<void> {
    this.currentUpdateController?.abort();
    this.currentUpdateController = new AbortController();

    await withRetry(
      () => ApiCalls.postUserConfig(config),
      this.currentUpdateController,
      'UpdateSharedUserConfigurationAPI'
    );
  }
}
