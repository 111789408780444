const delimiter = '-----';

const placeHolderBefore = '<<';
const placeHolderAfter = '>>';

const newline = '\n';
const tab = '\t';
const bulletPointLevel1 = '• ';
const bulletPointLevel2 = '- ';

const auxiliaryPredictions = ['face', 'chat', 'vhs', 'type', 'rotation', 'pas_thorax'];

const DIFFERENTIAL_DIAGNOSIS = [
  'bronchopneumonie',
  'atelectasie',
  'oedeme_pulmonaire',
  'infection',
  'contusion',
];

const OPACITIES = [
  'opacite_bronchique',
  'opacite_alveolo_interstitielle_diffuse',
  'opacite_interstitielle_diffuse',
  'opacite_non_specifique',
  'opacite_alveolaire_focale',
];

const MERGED_OPACITIES = [
  'opacite_alveolo_interstitielle_diffuse',
  'opacite_interstitielle_diffuse',
  'opacite_non_specifique',
];

const MARQUE = 4;
const PRESENT = 3;
const PROBABLE = 2;
const SUSPICION = 1;
const ABSENT = 0;

const MARKED_AFFIXES: [string, string] = [
  'report.beforeMarkedPatterns',
  'report.afterMarkedPatterns',
];
const PRESENT_AFFIXES: [string, string] = [
  'report.beforePresentPatterns',
  'report.afterPresentPatterns',
];
const LIKELY_AFFIXES: [string, string] = [
  'report.beforeLikelyPatterns',
  'report.afterLikelyPatterns',
];
const UNLIKELY_AFFIXES: [string, string] = [
  'report.beforeUnlikelyPatterns',
  'report.afterUnlikelyPatterns',
];

const PATTERNS_AFFIXES_BY_KEYS = {
  markedPatterns: MARKED_AFFIXES,
  presentPatterns: PRESENT_AFFIXES,
  likelyPatterns: LIKELY_AFFIXES,
  unlikelyPatterns: UNLIKELY_AFFIXES,
};

export {
  delimiter,
  placeHolderBefore,
  placeHolderAfter,
  newline,
  tab,
  bulletPointLevel1,
  bulletPointLevel2,
  auxiliaryPredictions,
  MARQUE,
  PRESENT,
  PROBABLE,
  SUSPICION,
  ABSENT,
  DIFFERENTIAL_DIAGNOSIS,
  OPACITIES,
  MERGED_OPACITIES,
  MARKED_AFFIXES,
  PRESENT_AFFIXES,
  LIKELY_AFFIXES,
  UNLIKELY_AFFIXES,
  PATTERNS_AFFIXES_BY_KEYS,
};
