import React from 'react';
import { useSelector } from 'react-redux';
import { selectLocale } from '../../redux/language/selectors';
import './styles.scss';
import IntlCrawlableLink from 'app/components/IntlCrawlableLink';
import { List } from 'semantic-ui-react';

/**
 * French Terms Of Sales
 */
function RenderFrenchText() {
  return (
    <>
      <section id="1-preambule">
        <h2>1.&nbsp;Préambule</h2>
        <p>
          Picoxia est un fournisseur de Software as a Service, c'est-à-dire de solutions louées en
          ligne (dit fournisseur SaaS) sur le site internet accessible à l'adresse&nbsp;
          <b>
            <a href="https://www.picoxia.com/fr/">www.picoxia.com</a>
          </b>
          &nbsp; (ci-après «&nbsp;le&nbsp;Site&nbsp;»).
          <br />
          Le site propose des solutions d'aide à l'analyse radiographique des animaux de compagnie,
          dédiées aux professionnels de santé animale.
          <br />
          Le contrat de licence des solutions accessible sur le site (le «&nbsp;Contrat&nbsp;») est
          établi par et conclu entre Picoxia, SAS dont le siège social est situé au 37 rue du Bois
          Chaland, 91090 Lisses, France, immatriculée au RCS d'Evry sous le n° 850 833 047,
          représentée par son Président M. Robin IMBACH et le cabinet et/ou la clinique et/ou le
          Vétérinaire (tels que définis à l'
          <IntlCrawlableLink url="/terms-of-sales#2-definitions">article 2</IntlCrawlableLink>)
          (ci-après dénommés indifféremment le «&nbsp;Professionnel&nbsp;») qui consent à ces
          modalités.
        </p>
        <p>
          Ce Contrat régit l'accès aux Solutions et leur utilisation par le Professionnel. Il
          détermine les droits et obligations des parties dans le cadre de l'utilisation du SaaS par
          le Professionnel.
          <br />
          Toute utilisation des Solutions et Services Picoxia suppose la consultation, la
          compréhension et l'acceptation en ligne du présent contrat par le Professionnel. Le
          présent accord couvre tous les utilisateurs des solutions Picoxia quelle que soit
          l'interface utilisée et la personne à l'origine de l'installation (utilisateur ou tiers).
        </p>
        <p>Le Professionnel déclare et reconnait&nbsp;:</p>
        <ul>
          <li>être établi en Europe, Amérique du Sud, Australie, Canada et USA&nbsp;;</li>
          <li>disposer de la compétence pour utiliser le présent site&nbsp;;</li>
          <li>
            avoir la capacité juridique lui permettant de s'engager au titre des présentes
            CGV&nbsp;;
          </li>
          <li>
            être informé que son engagement ne nécessite pas de signature manuscrite ou électronique
            &nbsp;;
          </li>
          <li>
            qu'il agit bien en tant que professionnel dans le cadre de son activité, c'est-à-dire
            que l'abonnement aux services est exclusivement destiné à une utilisation
            professionnelle de sa part&nbsp;;
          </li>
          <li>
            avoir obtenu toutes les informations nécessaires quant à l'utilisation du compte
            personnel et aux caractéristiques aussi bien tarifaires que qualitatives des services
            proposés via le Site.
          </li>
        </ul>
        <p>
          Ce Contrat entre en vigueur à la date à laquelle le Professionnel clique pour accepter le
          Contrat (la «&nbsp;Date d'entrée en vigueur&nbsp;»). Si vous acceptez au nom d'un
          professionnel tiers, vous déclarez et garantissez que&nbsp;:
        </p>
        <ol>
          <li>vous êtes légalement autorisé à lier le professionnel au présent Contrat,</li>
          <li>vous avez lu et compris cet Accord,</li>
          <li>et vous consentez, au nom du professionnel, aux modalités du présent Contrat.</li>
        </ol>
        <p>
          Le Site est conforme à la législation française, et ne donne en aucun cas, de garantie de
          conformité à la législation locale qui serait applicable, dès lors que le Professionnel
          accèderait au Site à partir d'autres pays.
        </p>
      </section>

      <section id="2-definitions">
        <h2>2.&nbsp;Définitions</h2>
        <p>
          Les termes débutant par une majuscule au sein du Contrat, qu'ils soient utilisés au
          singulier ou au pluriel, auront la signification qui leur est donnée ci-après.
        </p>
        <List divided relaxed>
          <List.Item>
            <List.Content>
              <List.Header>Données&nbsp;:</List.Header>
              <List.Description>
                désignent les informations et de manière générale, les données de la base de données
                Professionnel pouvant être consultées uniquement par les Utilisateurs.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Identifiants&nbsp;:</List.Header>
              <List.Description>
                désignent tant l'identifiant propre de l'utilisateur («&nbsp;login&nbsp;») que le
                mot de passe de connexion («&nbsp;pass-word&nbsp;») renseignés lors de l'inscription
                au service.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Solutions / Services&nbsp;:</List.Header>
              <List.Description>
                désigne les fonctions opérationnelles, soit tous logiciels ou applications
                développés par Picoxia et fourni en mode service au Professionel via son Site,
                notamment&nbsp;:
                <ul>
                  <li>Un outil d'analyse radio faisant appel à de l'intelligence artificielle</li>
                  <li>
                    Un hébergement des données patients envoyées par l'Utilisateur (image radio et
                    résultats de l'analyse correspondant)
                  </li>
                </ul>
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Parties&nbsp;:</List.Header>
              <List.Description>
                désigne conjointement le Professionnel et la société Picoxia, ayant tous les deux le
                statut de professionnels.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Politique&nbsp;:</List.Header>
              <List.Description>
                désigne la Politique relative à la protection des données à caractère personnel
                accessible à l'adresse suivante&nbsp;: Politique de Confidentialité).
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Utilisateur&nbsp;:</List.Header>
              <List.Description>
                désigne toute personne qui utilise le Site Internet et les Solutions / Services
                Picoxia.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Clinique&nbsp;:</List.Header>
              <List.Description>
                désigne la structure (clinique ou cabinet) vétérinaire au sein duquel exerce, le cas
                échéant, le Vétérinaire ayant souscrit aux Services/ Solutions Picoxia.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Vétérinaire&nbsp;:</List.Header>
              <List.Description>
                désigne chaque Vétérinaire exerçant à titre individuel membre d'une Clinique ou d'un
                cabinet ayant souscrit l'abonnement.
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      </section>

      <section id="3-object">
        <h2>3.&nbsp;Objet</h2>
        <section id="3-1-description-of-the-solutions-services" className="sub-section">
          <h3>3.1.&nbsp;Description des Solutions / Services proposés par Picoxia</h3>
          <p>
            Le présent contrat régit les étapes nécessaires à la souscription et définissent les
            conditions et modalités d'abonnement et d'utilisation des solutions Picoxia par le
            Professionnel.
          </p>
          <p>
            Sous réserve des modalités du présent Contrat, pendant la Durée dudit contrat, Picoxia
            consent au Professionnel, qui accepte&nbsp;:
          </p>
          <ul>
            <li>
              un droit d'accès par le biais du réseau Internet aux serveurs du Prestataire dans les
              conditions définies ci-après&nbsp;;
            </li>
            <li>un droit d'utilisation finale, non exclusif, des Solutions&nbsp;;</li>
            <li>
              un ensemble de Services ci-après définis, notamment d'hébergement des données
              cliniques, de maintenance et de sécurité des Solutions.
            </li>
          </ul>
          <p>
            Il est formellement convenu entre les Parties que toute tolérance ou renonciation d'une
            des Parties, dans l'application de tout ou partie des engagements prévus au Contrat,
            quelles que puissent en être la fréquence et la durée, ne saurait valoir modification du
            Contrat, ni être susceptible de créer un droit quelconque.
          </p>
          <p>
            Dans l'hypothèse où le contrat d'abonnement est souscrit par une Clinique Vétérinaire
            dans lequel exercent plusieurs Vétérinaires qui disposent d'un accès aux Services, la
            Clinique Vétérinaire garantit avoir recueilli le consentement, sans réserve des
            Vétérinaires aux présentes CGV, et de la Politique relative à la protection des données
            personnelles.
          </p>
        </section>

        <section id="3-2-license" className="sub-section">
          <h3>3.2.&nbsp;License </h3>
          <p>
            Sous réserve des dispositions du présent Accord, Picoxia accorde par la présente au
            Professionel un nombre limité, étendu (depuis le souscripteur) au personnel de la
            Clinique dans laquelle il exerce, de licence non sous-licenciable, non transférable, non
            exclusive, à utiliser en ligne ou bien à télécharger, installer et utiliser sur les
            postes de la Clinique. Les Solutions et applications accessibles en ligne ou
            installables doivent être utilisées conformément aux instructions fournies par Picoxia.
          </p>
          <p>
            En particulier, la licence relative aux Solutions n'est concédée que dans le seul et
            unique but de permettre au Professionnel l'utilisation des Services en mode SaaS via une
            connexion à un réseau Internet, à l'exclusion de toute autre finalité. Le Professionnel
            s'abstiendra, et ne permettra à aucun tiers sous son autorité&nbsp;:
          </p>
          <ol>
            <li>
              de procéder à la copie, à la modification, à la création d'une oeuvre dérivée, à
              l'ingénierie inverse, à la décompilation, à la traduction, au démontage ou à toute
              autre tentative d'extraction de tout ou partie du code source des Services&nbsp;;
            </li>
            <li>
              de concéder en sous-licence, revendre ou distribuer tout ou partie des Services
              séparément de toute Application intégrée&nbsp;;
            </li>
            <li>
              d'accéder aux Services d'une manière destinée à éviter le paiement de Frais ou à
              dépasser les quotas ou limites d'utilisation autorisée.
            </li>
          </ol>
        </section>

        <section id="3-3-facilities" className="sub-section">
          <h3>3.3.&nbsp;Installations</h3>
          <p>
            Toutes les installations servant au stockage et au traitement des Données des
            Utilisateurs adopteront des normes de sécurité raisonnables en vue de&nbsp;:
          </p>
          <ol>
            <li>garantir la sécurité et la confidentialité des Données des Utilisateurs,</li>
            <li>
              fournir une protection contre les risques ou les menaces à la sécurité ou l'intégrité
              des Données des Utilisateurs,
            </li>
            <li>
              et fournir une protection contre l'accès ou l'utilisation non autorisés des Données
              des Utilisateurs.
            </li>
          </ol>
        </section>

        <section id="3-4-access-to-solutions" className="sub-section">
          <h3>3.4.&nbsp;Accès aux solutions </h3>
          <p>
            Le Professionnel doit disposer d'un Compte pour accéder aux services/ solutions de
            Picoxia. Le Professionnel est entièrement responsable des renseignements qu'il fournit
            lors de la création du Compte, de la garde des codes d'accès pour le Compte et de toute
            utilisation qui est faite de son Compte. Les Identifiants sont destinés à réserver
            l'accès des Solutions objets du Contrat au professionnel, à protéger l'intégrité et la
            disponibilité des Solutions, ainsi que l'intégrité, la disponibilité et la
            confidentialité des Données des Utilisateurs.
          </p>
          <p>
            Les Identifiants sont personnels et confidentiels. Le Professionnel s'assurera qu'aucune
            autre personne, à l'exception des Vétérinaires exerçant sa Clinique et ayant consenti
            aux présentes CGV, n'a accès aux Solutions Picoxia. De manière générale, le
            Professionnel assume la responsabilité de la sécurité des postes individuels d'accès aux
            Solutions. Dans l'hypothèse où il aurait connaissance de ce qu'une autre personne y
            accède, le Professionnel en informera Picoxia sans délai. En cas de perte ou de vol d'un
            des identifiants, le Professionnel utilisera la procédure mise en place sur le site lui
            permettant de créer de nouveaux identifiants.
            <br />
            Picoxia n'a aucune obligation de fournir au Professionnel plusieurs comptes.
          </p>
        </section>

        <section id="3-5-new-applications-and-new-services" className="sub-section">
          <h3>3.5.&nbsp;Nouvelles applications et nouveaux services</h3>
          <p>
            Picoxia peut introduire de temps à autre des nouvelles applications, outils,
            caractéristiques ou fonctionnalités aux solutions existantes.
          </p>
        </section>

        <section id="3-6-modifications" className="sub-section">
          <h3>3.6.&nbsp;Modifications</h3>
          <section id="3-6-a-modification-to-services" className="sub-sub-section">
            <h4>a.&nbsp;Modification Aux Services, soutien et mises à jour</h4>
            <p>
              Picoxia se réserve le droit d'ajouter ou de supprimer des fonctionnalités ou des
              fonctions, ou de procéder à des corrections de programmation, des améliorations, des
              mises à jour. Le professionnel convient que Picoxia n'est pas tenu de fournir aux
              Utilisateurs des mises à jour, ou bien de fournir des versions améliorées. Si Picoxia
              apporte une modification déterminante aux Services/solutions, Picoxia en informera le
              Professionnel. Les Utilisateurs conviennent également qu'ils peuvent avoir à adhérer à
              une nouvelle version du présent accord s'ils souhaitent télécharger, installer ou
              utiliser une mise à jour.
            </p>
          </section>
          <section id="3-6-b-modifications-to-the-contract" className="sub-sub-section">
            <h4>b.&nbsp;Modifications au Contrat</h4>
            <p>
              Picoxia peut à tout moment apporter des modifications au présent Contrat, notamment
              concernant les prix (et tous les documents liés). Sauf indication contraire de
              Picoxia, les modifications déterminantes apportées au Contrat entreront en vigueur 30
              jours après leur publication, excepté si les modifications concernent l'ajout de
              fonctionnalités, auquel cas elles entreront en vigueur immédiatement. Picoxia fournira
              un alors un préavis soit par courriel adressé au Professionnel. Si le Professionnel ne
              consent pas au Contrat révisé, il est prié de cesser d'utiliser les Services. Picoxia
              publiera toute modification au présent Contrat à l'URL des CGV.
              <br />
              La dernière version du Contrat s'applique d'emblée aux abonnements souscrits
              postérieurement à sa mise en ligne.
            </p>
          </section>
        </section>
      </section>

      <section id="4-terms-of-service-subscription">
        <h2>4.&nbsp;Modalités de souscription des services</h2>
        <section id="4-1-subscription-conditions" className="sub-section">
          <h3>4.1.&nbsp;Conditions de souscription</h3>
          <p>
            La souscription aux services Picoxia nécessite la création préalable d'un Compte. La
            création d'un Compte, nécessite de renseigner les informations suivantes&nbsp;:
          </p>
          <ul>
            <li>Nom et adresse de la clinique</li>
            <li>Email de la clinique</li>
            <li>Nom et prénom de la personne qui fait la demande d'abonnement</li>
          </ul>

          <p>
            Le Professionnel s'engage à fournir des informations sincères, exactes, à jour et
            complètes sur l'identité de la Clinique et du Vétérinaires souscripteur des Services,
            ainsi que toute information demandée dans le formulaire d'inscription. L'enregistrement
            de l'inscription vaut pouvoir irrévocable donné par le Professionnel à Picoxia
            d'exploiter les informations transmises dans le but de pouvoir réaliser les Services mis
            à disposition sur le Site.
          </p>
          <p>
            Le cas échéant, le signataire s'engage à avoir le pouvoir de contracter au nom et pour
            le compte de la Clinique.
          </p>
        </section>
        <section id="4-2-free-trials" className="sub-section">
          <h3>4.2.&nbsp;Essais gratuits</h3>
          <p>
            La création d'un Compte donne accès au Professionnel à un nombre fixe de requêtes
            gratuites, spécifié lors de l'inscription, afin de tester le Service. Picoxia se réserve
            le droit de déterminer l'admissibilité du Professionnel à un essai gratuit et de la
            limiter, pour empêcher tout abus. Picoxia se réserve le droit de révoquer l'essai
            gratuit et de suspendre le Compte du Professionnel en cas de non admissibilité.
          </p>
          <p>
            Le nombre restant d'essais gratuits est consultable sur le Site à la page
            «&nbsp;Achats&nbsp;».
          </p>
        </section>
        <section id="4-3-offers" className="sub-section">
          <h3>4.3.&nbsp;Offres</h3>
          <p>
            Picoxia propose un type d'offre commerciale&nbsp;: la souscription à un abonnement
            mensuel (90 € par mois HT pour un(e) cabinet/clinique Vétérinaire). La souscription à un
            abonnement se fait directement sur la page «&nbsp;Achats&nbsp;» du Site.
          </p>
          <p>
            Lors de la souscription en ligne, le Professionnel valide son acceptation pleine et sans
            réserve des CGV, et de la Politique relative à la protection des données dont il
            reconnait avoir pris connaissance. A l'issue de la souscription, le Professionnel
            dispose d'une période de 15 jours d'essai gratuit&nbsp;; à l'issue de cette période il
            sera ensuite procédé au premier prélèvement au prorata du mois en cours. Par la suite,
            le Professionnel recevra un courrier électronique à l'adresse qu'il aura spécifiée,
            confirmant le montant qui lui sera prélevé.
          </p>
          <p>
            L'abonnement est souscrit au nom du Vétérinaire indépendant ou au nom de la Clinique,
            pour un nombre déterminé de Vétérinaire exerçant en son sein.
          </p>
          <p>
            En s'abonnant aux Services Picoxia, le Professionnel déclare utiliser les services en
            accord avec le droit applicable et les présentes CGV. La Clinique se porte garante du
            respect de ceux-ci par les Vétérinaires Utilisateurs.
          </p>
          <p>
            Pour consulter les frais d'abonnement, le professionnel peut se rendre sur la page «
            Achats&nbsp;» de son Compte.
          </p>
          <p>
            Le Professionnel ainsi que les vétérinaires sous son autorité s'engagent à utiliser
            Picoxia, de façon raisonnable, en accord avec l'offre à laquelle il a souscrit. Le
            nombre de requêtes envoyées mensuellement sur le Site doit être cohérent avec le nombre
            de vétérinaires Utilisateur de la clinique.
            <br />
            En cas d'usage abusif, Picoxia se réserve le droit d'entrer en contact avec le
            Professionnel et, dans le cas où un accord entre les deux parties ne pourrait être
            établi, de résilier l'abonnement du Professionnel.
          </p>
        </section>
      </section>

      <section id="5-financial-conditions">
        <h2>5.&nbsp;Conditions financières</h2>
        <section id="5-1-prices" className="sub-section">
          <h3>5.1.&nbsp;Tarifs </h3>
          <p>
            Les tarifs d'accès aux Solutions Picoxia sont présentés dans le&nbsp;
            <IntlCrawlableLink url="/terms-of-sales#4-3-offers">point 4.3</IntlCrawlableLink> des
            présentes CGV. Les prix en vigueur sont ceux affichés sur le Site au jour de la
            souscription des Services Picoxia par le Professionnel. Picoxia se réserve le droit de
            modifier ses prix à tout moment.
            <br />
            Dans l'hypothèse d'une modification de tarif, cette dernière sera annoncée au moins (1)
            un mois avant la prise d'effet du nouveau tarif et ouvrira le droit à résiliation du
            présent contrat.
          </p>
        </section>

        <section id="5-2-method-of-payment" className="sub-section">
          <h3>5.2.&nbsp;Modalité de paiement </h3>
          <p>
            Le paiement des abonnements s'effectue par prélèvement mensuel automatique sur compte
            bancaire. Le prélèvement se fait chaque mois, à la date anniversaire de la souscription,
            pour les Services du mois à venir. Le prélèvement automatique est sous-traité à la
            société Stripe.
            <br />
            Le professionnel est redevable de tout montant non prélevé. Si le règlement d'un
            paiement échoue en raison de l'expiration de la carte, de solde insuffisant ou pour tout
            autre motif, et que le compte n'a pas été résilié, Picoxia peut suspendre l'accès à ses
            Service jusqu'au règlement de la somme due.
          </p>
        </section>
      </section>

      <section id="6-user-behaviour">
        <h2>6.&nbsp;Conduite des utilisateurs</h2>
        <p>Les Utilisateurs n'utiliseront pas les Solutions Picoxia afin&nbsp;:</p>
        <ol>
          <li>
            de porter atteinte aux droits de propriété intellectuelle, de propriété, ou de publicité
            d'un tiers&nbsp;;
          </li>
          <li>de violer toute loi applicable, toute ordonnance ou tout règlement&nbsp;;</li>
          <li>
            de diffuser des virus informatiques ou tout autre code informatique, fichiers ou
            programmes qui peuvent interrompre, détruire ou limiter la fonctionnalité de tout
            logiciel ou matériel informatique ou équipement de télécommunications.
          </li>
        </ol>
        <p>
          L'Utilisateur est seul responsable du contenu transmis sur Picoxia. L'utilisateur sera
          ainsi tenu pour seul responsable de tout dommage causé à toute partie en résultant.
          <br />
          Les Utilisateurs reconnaissent et acceptent que leurs informations puissent être retiré de
          la base de données et des serveurs Picoxia conformément à l'
          <IntlCrawlableLink url="/terms-of-sales#9-2-account-suspension-and-termination-for-breach">
            article 9.2
          </IntlCrawlableLink>
          &nbsp; de ce Contrat, sans le consentement du titulaire du compte.
        </p>
      </section>

      <section id="7-personal-data">
        <h2>7.&nbsp;Données personnelles </h2>
        <section id="7-1-personal-data-of-the-users" className="sub-section">
          <h3>7.1.&nbsp;Données personnelles de l'Utilisateur</h3>
          <p>
            Les Utilisateurs comprennent et acceptent que Picoxia collecte et traite des
            informations pouvant constituer des Données à caractère personnel, recueillies notamment
            lors de l'inscription et des visites sur le Site. En acceptant le présent accord,
            l'Utilisateur consent à ce transfert de données.
            <br />
            Aucune information personnelle n'est collectée à l'insu de l'Utilisateur. Aucune
            information personnelle n'est diffusée, vendue, ou cédée à l'insu de l'Utilisateur.
          </p>
          <p>
            Le professionnel accepte de recevoir des courriels de Picoxia concernant son Compte, les
            mises à jour des Solutions et les nouvelles Solutions et services. Le professionnel peut
            à tout moment se désabonner de la newsletter en contactant Picoxia par courriel, à
            l'adresse&nbsp;
            <b>
              <a href="mailto:contact@picoxia.com" target="_blank">
                contact@picoxia.com
              </a>
            </b>
            . Le désabonnement entrainera l'arrêt d'envoi de message promotionnel mais le
            Professionnel pourra toujours recevoir des messages concernant les changements de
            fonction ou de service, ainsi que des notes d'informations en cas de problème/ panne, et
            continueront de recevoir des notifications conçues dans le cadre de la plate-forme.
            <br />
            L'arrêt d'utilisation et la désinscription du Site stoppe la collecte de données auprès
            des Utilisateurs, mais toutes les données recueillies, intentionnellement ou non,
            pendant ou après la désinscription seront soumises aux termes et conditions actuelles de
            ce document.
          </p>
          <p>
            Les présentes CGV sont complétées par la&nbsp;
            <b>Politique relative à la protection des données personnelles</b> qui encadre les
            modalités desdits traitements et collecte, conformément à la loi n° 78-17 du 6 janvier
            1978 relative à l'informatique, aux fichiers et aux libertés dans sa version consolidée
            et au Règlement général sur la protection des données n°2016/679.
          </p>
        </section>

        <section id="7-2-data-transmitted-by-the-user" className="sub-section">
          <h3>7.2.&nbsp;Données transmises par l'Utilisateur </h3>
          <p>
            Si les Données transmises par l'Utilisateur dans le cadre de l'utilisation des Solutions
            Picoxia comportent des données à caractère personnel, l'Utilisateur garantit qu'il a
            procédé à l'ensemble des obligations qui lui incombent au terme de la loi du 6 janvier
            1978 dite «&nbsp;Informatique &amp; Libertés&nbsp;», et qu'il a informé les personnes
            physiques concernées de l'usage qui est fait desdites données personnelles. A ce titre,
            le Professionnel garantit Picoxia contre tout recours, plainte ou réclamation émanant
            d'une personne physique dont les données personnelles seraient reproduites et hébergées
            par Picoxia.
          </p>
          <p>
            À toutes fins utiles, il est précisé que, en ce qui concerne les Données à caractère
            personnel transférées par le Professionnel à Picoxia, le Professionnel demeure le
            responsable du traitement et Picoxia le sous-traitant au sens au Règlement général sur
            la protection des données n°2016/679.
            <br />
            Les données stockées par Picoxia peuvent être utilisés pour développer, créer et
            commercialiser des produits et services dédié à la santé des animaux de compagnie, à la
            recherche ou de manière plus générale à industrie animale (sans toutefois s'y limiter).
          </p>
        </section>

        <section id="7-3-data-security" className="sub-section">
          <h3>7.3.&nbsp;Sécurité des données</h3>
          <p>
            Chacune des Parties s'engagent à mettre en oeuvre les moyens techniques appropriés pour
            assurer la sécurité des Données. Picoxia mettra en place les mesures techniques et
            organisationnelles de nature à empêcher tout accès ou utilisations fraudeuses des
            Données et à prévenir toutes pertes, altérations et destructions des Données
          </p>
        </section>
      </section>

      <section id="8-intellectual-property">
        <h2>8.&nbsp;Propriété intellectuelle</h2>
        <section id="8-1-proprietary-rights" className="sub-section">
          <h3>8.1.&nbsp;Droits de Propriété</h3>
          <p>
            Picoxia est et demeure titulaire des droits de propriété relatifs à tout élément des
            Services et des Solutions mis à disposition de l'Utilisateur, ainsi plus généralement
            que de l'infrastructure informatique (logicielle et matérielle) mise en oeuvre ou
            développée dans le cadre du Contrat. En particulier, le contenu du Site, la structure
            générale et l'ensemble des éléments/documents y figurant (informations, données,
            photographies, textes, sons, images, vidéos, dessins, graphismes, signes distinctifs,
            logos, modèles, marques, noms de domaine, logiciels et programmes, bases de données...)
            sont la propriété exclusive Picoxia. Au titre de l'article L.&nbsp;122-4 du code de la
            propriété intellectuelle, toute utilisation, au sens large, est interdite. La
            reproduction non autorisée, est interdite (loi 98-536 du 1er juillet 1998).
          </p>
          <p>
            Le Contrat ne confère au Professionnel aucun droit de propriété sur les Solutions. Le
            Professionnel s'interdit de reproduire tout élément des Logiciels, ou toute
            documentation les concernant, par quelque moyen que ce soit, sous quelque forme que ce
            soit et sur quelque support que ce soit.
            <br />
            Toute représentation ou reproduction, réédition, redistribution, adaptation, traduction
            et/ou transformation intégrale ou partielle du contenu du Site et des services Picoxia,
            par quelque procédé que ce soit est interdite et constituerait notamment mais non
            exclusivement une contrefaçon sanctionnée par les dispositions du Code de la propriété
            intellectuelle susceptible d'engager la responsabilité civile et pénale du
            contrefacteur.
          </p>
          <p>
            Le Professionnel est et demeure propriétaire de l'ensemble des Données qu'il utilise via
            les Solutions dans le cadre du Contrat, sous réserve à la seule discrétion du droit de
            Picoxia à utiliser, copier, modifier, partager et analyser ce contenu uniquement dans le
            but de fournir les Services Picoxia.
          </p>
        </section>

        <section id="8-2-use-of-professional-data" className="sub-section">
          <h3>8.2.&nbsp;Utilisation des Données Professionnelles</h3>
          <p>
            Toute information médicale et/ou d'imagerie vétérinaire transmise sur le Site peut faire
            l'objet d'une utilisation par Picoxia dans le cadre de l'amélioration de ses services.
          </p>
        </section>

        <section id="8-3-professional-comments" className="sub-section">
          <h3>8.3.&nbsp;Commentaires du Professionnel</h3>
          <p>
            Si l'Utilisateur émet des commentaires à Picoxia concernant les Services, Picoxia peut
            utiliser ces informations sans obligation envers l'Utilisateur, et l'Utilisateur cède
            irrévocablement à Picoxia tous les droits, titres et intérêts relatifs à ces
            Commentaires.
          </p>
        </section>

        <section id="8-4-comparative-evaluations" className="sub-section">
          <h3>8.4.&nbsp;Évaluations comparatives</h3>
          <p>
            Le Professionnel ne peut divulguer publiquement de manière directe ou par le biais de
            tiers les résultats de toute évaluation comparative ou de compatibilité, étalonnages ou
            évaluation (chacun dénommé par la suite sous le terme un «&nbsp;Test&nbsp;») des
            Services, à moins que la divulgation soit faite de toute l'information qui permettrait
            que Picoxia ou un tiers puissent reproduire le Test.
          </p>
        </section>
      </section>

      <section id="9-duration-and-termination">
        <h2>9.&nbsp;Durée et résiliation</h2>
        <section id="9-1-duration-of-the-contract" className="sub-section">
          <h3>9.1.&nbsp;Durée du Contrat</h3>
          <p>
            La «&nbsp;Durée&nbsp;» du présent Contrat commencera à la Date d'entrée en vigueur et se
            poursuivra jusqu'à résiliation du Contrat.
          </p>
        </section>

        <section id="9-2-account-suspension-and-termination-for-breach" className="sub-section">
          <h3>9.2.&nbsp;Suspension de Compte et Résiliation pour violation</h3>
          <p>L'une ou l'autre partie peut résilier le présent Contrat pour violation si&nbsp;:</p>
          <ol>
            <li>
              l'autre partie est en violation substantielle du Contrat et ne remédie pas à cette
              violation dans les trente (30) jours suivant la réception d'un avis écrit,
            </li>
            <li>
              l'autre partie cesse ses activités commerciales ou fait l'objet d'une procédure
              d'insolvabilité et que la procédure n'est pas rejetée dans les quatre-vingt-dix (90)
              jours,
            </li>
            <li>
              ou l'autre partie commet une violation substantielle du présent Contrat plus de deux
              fois, nonobstant la résolution de telles violations.
            </li>
          </ol>
          <p>
            Picoxia peut automatiquement Suspendre tout ou partie de l'utilisation des Services par
            le l'Utilisateur si&nbsp;:
          </p>
          <ol>
            <li>
              Picoxia juge que l'utilisation des Services par le Professionnel ou l'un des
              Utilisateurs de la Clinique pourrait perturber les Services ou l'utilisation des
              Services par d'autres Utilisateurs&nbsp;;
            </li>
            <li>l'on soupçonne l'accès non autorisé d'un tiers aux Services&nbsp;;</li>
            <li>Picoxia constate un usage abusif des solutions Picoxia</li>
            <li>
              si Picoxia juge que cela est nécessaire pour se conformer aux lois applicables&nbsp;;
            </li>
            <li>
              ou le Professionnel est en violation de l'
              <IntlCrawlableLink url="/terms-of-sales#3-2-license">article 3.2</IntlCrawlableLink>
              &nbsp;(Restrictions). Picoxia lèvera ladite suspension quand les circonstances ayant
              donné lieu à la Suspension ont été résolus.
            </li>
          </ol>
          <p>
            À la demande du Professionnel, sauf si la loi applicable l'interdit, Picoxia avisera le
            Professionnel sur la base de la Suspension dès qu'il est raisonnablement possible de le
            faire.
          </p>
        </section>

        <section id="9-3-termination-for-convenience" className="sub-section">
          <h3>9.3.&nbsp;Résiliation pour raisons de commodité</h3>
          <p>
            Le Professionnel peut cesser d'utiliser les Services à tout moment. Le professionnel
            peut ainsi résilier le présent Contrat pour raisons de commodité à tout moment moyennant
            un préavis écrit et, au moment de la résiliation, doit cesser d'utiliser les Services
            applicables étant précisé que tout mois commencé est dû. Afin de résilier son
            abonnement, le Professionnel peut se rendre à la page «&nbsp;Achats&nbsp;».
          </p>
          <p>
            Afin de clôturer son Compte Utilisateur, le Professionnel adressera un courriel à
            l'adresse&nbsp;
            <b>
              <a href="mailto:contact@picoxia.com" target="_blank">
                contact@picoxia.com
              </a>
            </b>
            . Picoxia informera le Professionnel ou la Clinique par courriel de la bonne réception
            dudit courriel de résiliation.
          </p>
          <p>
            Picoxia peut résilier le présent Contrat pour raisons de commodité à tout moment sans
            engager sa responsabilité envers le Professionnel.
          </p>
        </section>

        <section id="9-4-effect-of-termination" className="sub-section">
          <h3>9.4.&nbsp;Effet de la résiliation</h3>
          <p>Advenant la résiliation du Contrat&nbsp;:</p>
          <ol>
            <li>
              les droits de licence accordés par Picoxia au Professionnel cesseront immédiatement,
            </li>
            <li>
              tous les Frais dus par le Professionnel à Picoxia sont immédiatement exigibles à la
              réception de la facture électronique finale,
            </li>
            <li>
              Le professionnel supprimera le ou les Logiciels, et/ou toute Application Picoxia.
            </li>
          </ol>
          <p>
            A l'issue de la résiliation par le Professionnel, Picoxia se réserve le droit de
            conserver les données, y compris mais sans s'y limiter aux données liées au compte ou à
            des fins de facturation.
          </p>
        </section>

        <section id="9-5-lack-of-responsibility" className="sub-section">
          <h3>9.5.&nbsp;Absence de responsabilité</h3>
          <p>
            Picoxia ne pourra être tenu responsable de tout dommage causé par la résiliation du
            présent Accord.
          </p>
        </section>
      </section>

      <section id="10-obligations-of-the-professional">
        <h2>10.&nbsp;Obligations du Professionnel</h2>
        <p>
          L'utilisation des Services ne dilue, ne modifie ou n'atténue ni directement, ni
          indirectement la responsabilité et les obligations de la Clinique ou le Vétérinaire
          exerçant à titre individuel inscrit aux Services (et de ses Vétérinaires) vis-à-vis des
          Propriétaires d'animaux. Ces derniers exercent leur pratique professionnelle en toute
          indépendance, dans le respect de leurs obligations déontologiques, légales et
          réglementaires personnelles et sous leur responsabilité exclusive.
        </p>
      </section>

      <section id="11-liability-and-guarantees">
        <h2>11.&nbsp;Responsabilité et garanties</h2>
        <p>
          Le Professionnel est seul responsable de l'utilisation qu'il fait du Site et des
          Services/Solutions auxquels il accède depuis le Site.
          <br />
          Picoxia ne pourra en aucun cas être tenue responsable dans le cadre d'une procédure
          introduite à l'encontre de la Clinique ou des Vétérinaires membres qui se seraient rendus
          coupables d'une utilisation non-conforme du Site et/ou des Services Picoxia.
          <br />
          Le Professionnel reconnait et accepte à cet égard qu'il fera son affaire personnelle de
          toute réclamation ou procédure formée contre Picoxia, du fait de l'utilisation
          non-conforme par elle ou ses Vétérinaires des Services et/ou du Site.
        </p>
        <p>
          Les Parties ne sont en aucun cas responsables des dommages indirects subis par l'autre
          Partie résultant de l'exécution du présent Contrat, tels que les gains manqués et tout
          autre préjudice qualifié d'indirect par la jurisprudence.
        </p>
        <p>
          La responsabilité de Picoxia ne saurait être directement ou indirectement retenue, à
          quelque titre et pour quelque cause que ce soit pour les dommages notamment liés&nbsp;:
        </p>
        <ul>
          <li>à la pratique professionnelle du ou des Vétérinaires&nbsp;;</li>
          <li>
            à l'inexécution par l'Utilisateur de ses obligations, tel que le non-respect des
            conditions d'utilisation du Site&nbsp;;
          </li>
          <li>un défaut de paramétrage des Services proposés&nbsp;;</li>
          <li>
            à tout incident ou interruption de la connexion Internet du Professionnel ou à tout
            incident inhérent au matériel du Professionnel&nbsp;;
          </li>
          <li>à une difficulté d'accès au Site dues à des opérations de maintenance&nbsp;;</li>
          <li>aux cas d'accès illicite au compte du Professionnel&nbsp;;</li>
          <li>à la mauvaise utilisation du Site par le Professionnel.</li>
        </ul>
        <p>
          Les solutions/services Picoxia sont fournis «&nbsp;en l'état&nbsp;», sans garantie
          d'aucune sorte, et Picoxia rejette toute garantie explicite ou implicite, y compris les
          garanties de qualité/ performance, d'adaptation à un usage particulier, de fiabilité et de
          non contrefaçon. Picoxia ne garantit pas que les logiciels accessibles par son site sont
          sans défauts.
          <br />
          Le site Picoxia n'a pas de vocation d'expertise&nbsp;; Les analyses produites sont le fait
          d'une machine et non d'un être humain. Ces analyses ne peuvent faire office de document
          légal et sont dépourvues de valeur juridique. L'Utilisateur est responsable de
          l'utilisation qu'il fait de ces analyses, ainsi que du diagnostic qu'il délivre.
        </p>
      </section>

      <section id="12-discontinuation-of-services">
        <h2>12.&nbsp;Discontinuation des Services</h2>
        <section id="12-1-final-discontinuation" className="sub-section">
          <h3>12.1.&nbsp;Discontinuation définitive</h3>
          <p>
            Sous réserve des dispositions de la&nbsp;
            <IntlCrawlableLink url="/terms-of-sales#9-4-effect-of-termination">
              section 9.4
            </IntlCrawlableLink>
            , Picoxia peut interrompre tout ou partie des Services, ou toute fonctionnalité
            afférente pour quelque raison que ce soit, à tout moment et sans engager sa
            responsabilité envers le Professionnel.
            <br />
            S'il y a lieu, Picoxia annoncera son intention de discontinuer ou d'effectuer des
            modifications non rétrocompatibles avec les Services à l'adresse&nbsp;
            <a href="https://www.picoxia.com/fr/">www.picoxia.com</a>.
          </p>
        </section>
        <section id="12-2-temporary-discontinuation-for-maintenance" className="sub-section">
          <h3>12.2.&nbsp;Discontinuation temporaire pour maintenance</h3>
          <p>
            Les Services/ solutions Picoxia peuvent être occasionnellement suspendus en raison
            d'interventions de maintenance nécessaires au bon fonctionnement des serveurs [ou de la
            plate-forme]. En cas d'interruption des Services pour maintenance, Picoxia informera le
            Professionnel au mieux de l'interruption, afin qu'il puisse prendre ses dispositions
            suffisamment à l'avance pour éviter toute perturbation de son activité. Picoxia ne
            pourra être tenu responsable de l'impact éventuel de cette indisponibilité sur les
            activités du Professionnel.
            <br />
            Toute anomalie de fonctionnement doit être signalée par courriel à Picoxia sans délai.
            Picoxia procèdera alors au diagnostic de l'anomalie et mettra en oeuvre sa correction.
          </p>
          <p>Picoxia n'est pas responsable de la maintenance dans les cas suivants&nbsp;:</p>
          <ul>
            <li>
              Refus de l'Utilisateur de collaborer dans la résolution des anomalies et notamment de
              répondre aux questions et demandes de renseignement&nbsp;;
            </li>
            <li>
              Utilisation des Services applicatifs de manière non conforme à leur destination ou à
              leur documentation&nbsp;;
            </li>
            <li>
              Modification non autorisée des Solutions par le Professionnel ou par un tiers&nbsp;;
            </li>
            <li>
              Implantation de tous progiciels, logiciels ou système d'exploitation non compatibles
              avec les Services applicatifs&nbsp;;
            </li>
            <li>Défaillance des réseaux de communication électronique&nbsp;;</li>
            <li>Acte volontaire de dégradation, malveillance, sabotage&nbsp;;</li>
            <li>Détérioration due à un cas de force majeure.</li>
          </ul>
        </section>
      </section>

      <section id="13-applicable-law">
        <h2>13.&nbsp;Loi applicable</h2>
        <p>Les lois de l'État Français régiront le présent Accord.</p>
      </section>

      <section id="14-miscellaneous">
        <h2>14.&nbsp;Divers</h2>
        <section id="14-1-third-party-costs" className="sub-section">
          <h3>14.1.&nbsp;Frais tiers</h3>
          <p>
            Les Utilisateurs sont responsables de tous les frais associés à l'utilisation
            d'appareils mobiles et ordinateurs personnels, tels que le temps d'antenne et les coûts
            d'accès à Internet.
          </p>
        </section>

        <section id="14-2-language" className="sub-section">
          <h3>14.&nbsp;Langue</h3>
          <p>
            La version originale française du présent accord peut avoir été traduite dans d'autres
            langues. En cas d'incohérence ou de divergence entre la version française et toute autre
            version linguistique du présent Accord, la version française prévaudra.
          </p>
        </section>

        <section id="14-3-convention-of-evidence" className="sub-section">
          <h3>14.3.&nbsp;Convention de preuves</h3>
          <p>
            Les Utilisateurs conviennent d'utiliser la communication électronique comme moyen de
            conclure des accords et de passer des commandes, ainsi que comme moyen de délivrance des
            avis, des politiques et des registres des transactions engagées avec Picoxia.
            <br />
            Les parties reconnaissent expressément qu'auront valeur de preuve équivalente à celle
            d'un écrit original et bénéficieront à ce titre d'une présomption de validité&nbsp;: les
            lettres simples, télécopie, messages électroniques échangés entre les parties dans le
            cadre de l'exécution du Contrat.
          </p>
          <p>
            De même, en cas de litige, Picoxia pourra valablement administrer la preuve des actions
            du Professionnel, ou encore d'un accès frauduleux d'un tiers, au moyen des logins de
            connexion et de transmission relevés par ses administrateurs réseaux qui seuls feront
            foi entre les Parties. L'acceptation en ligne des présentes CGV par voie électronique a
            entre les Parties la même valeur probante que l'accord sur support papier.
          </p>
          <p>
            L'archivage des documents contractuels, et factures de Picoxia est effectué sur un
            support fiable et durable pouvant être produit à titre de preuve, en particulier de
            preuve des demandes d'abonnement et des paiements intervenus entre les Parties.
          </p>
        </section>

        <section id="14-4-change-of-control" className="sub-section">
          <h3>14.4.&nbsp;Changement de contrôle</h3>
          <p>
            Tout changement dans l'actionnariat de l'une ou l'autre des Parties entrainant un
            changement de contrôle de la Société au sens de l'article L.&nbsp;233-3 du code de
            commerce sera signalé sans délai à l'autre Partie qui disposera alors du droit de
            résilier unilatéralement le contrat.
            <br />
            Chacune des Parties demeurera garant, à l'égard de l'autre Partie, du respect par le
            cessionnaire de l'ensemble des droits et obligations résultant des présentes, pour la
            durée restant à courir de l'abonnement.
          </p>
        </section>

        <section id="14-5-partial-invalidity" className="sub-section">
          <h3>14.5.&nbsp;Nullité partielle</h3>
          <p>
            Dans l'hypothèse où une disposition des présentes serait nulle, illégale, inopposable ou
            inapplicable d'une manière quelconque, la validité, la légalité ou l'application des
            autres dispositions des présentes conditions générales n'en seraient aucunement
            affectées ou altérées, les autres stipulations des présentes demeurant en vigueur et
            conservant leur plein et entier effet.
          </p>
        </section>
      </section>
    </>
  );
}

/**
 * English Terms Of Sales
 */
function RenderEnglishText() {
  return (
    <>
      <section id="1-preamble">
        <h2>1.&nbsp;Preamble</h2>
        <p>
          Picoxia is a provider of Software as a Service, that is to say solutions rented online
          (said SaaS provider) on the website accessible at the address&nbsp;
          <a href="https://www.picoxia.com/en/">www.picoxia.com</a> (hereinafter "the Website").
          <br />
          The site offers solutions for the radiographic analysis of companion animals, dedicated to
          animal health professionals.
          <br />
          The license contract for the solutions accessible on the Website (the "Contract") is
          established by and concluded between Picoxia, SAS whose head office is located at&nbsp;
          <i>37 rue du Bois Chaland, 91090 Lisses, France</i>, registered to the RCS of Evry under
          the n° 850&nbsp;833&nbsp;047, represented by its President Mr. Robin IMBACH and the
          veterinary practice/clinic and/or the Veterinarian (as defined in&nbsp;
          <IntlCrawlableLink url="/terms-of-sales#2-definitions">article 2</IntlCrawlableLink>))
          (hereinafter referred to as the "Professional") who consents to these terms.
        </p>
        <p>
          This Contract governs access to the Solutions and their use by the Professional. It
          determines the rights and obligations of the parties for the use of the website and the
          SaaS by the Professional.
          <br />
          Any use of Picoxia Solutions and Services implies consultation, understanding and online
          acceptance of this contract by the Professional. This agreement covers all users of
          Picoxia solutions regardless of the interface used and the person responsible for the
          installation (user or third party).
        </p>
        <p>The Professional declares and recognizes:</p>
        <ul>
          <li>To be established in Europe, South America, Australia, Canada and the USA;</li>
          <li>To have the skills to use this website (or app);</li>
          <li>To have legal capacity to engage under these Terms and Conditions;</li>
          <li>
            To be informed that their engagement does not require a handwritten or electronic
            signature;
          </li>
          <li>
            That he acts as a professional within the framework of his activity, that is to say that
            the subscription to the services is exclusively intended for a professional use on his
            part.
          </li>
          <li>
            To have all the necessary information regarding the use of the personal account, the
            price and quality characteristics of the services offered via the website.
          </li>
        </ul>
        <p>
          This Agreement comes into force on the date the Professional clicks to accept the
          Agreement (the "Effective Date"). If you agree on behalf of a third party professional,
          you represent and warrant that:
        </p>
        <ol>
          <li>you are legally authorized to bind the professional to this Agreement,</li>
          <li>you have read and understood this Agreement,</li>
          <li>
            and you consent, on behalf of the professional, under the terms of this Agreement.
          </li>
        </ol>
        <p>
          The Site complies with French law, and in no case gives any guarantee of compliance with
          the local legislation which would be applicable, as soon as the Professional would access
          the Site from other countries.
        </p>
      </section>

      <section id="2-definitions">
        <h2>2. Definitions</h2>
        <p>
          Terms beginning with a capital letter within the Contract, whether used in the singular or
          in the plural, will have the meaning given to them below.
        </p>
        <List divided relaxed>
          <List.Item>
            <List.Content>
              <List.Header>Data:</List.Header>
              <List.Description>
                refers to information and in general data in the Professional database that can only
                be viewed by Users.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Identifiers:</List.Header>
              <List.Description>
                designate both the user's identifier ("login") and the connection password
                ("pass-word") entered when registering for the service.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Solutions / Services:</List.Header>
              <List.Description>
                refers to the operational functions, i.e. all software or applications developed by
                Picoxia and provided in service mode to Professionals via its Site, including:
                <ul>
                  <li>A solution for radiographs analysis using artificial intelligence</li>
                  <li>Hosting of patient data sent by the User</li>
                </ul>
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Parties:</List.Header>
              <List.Description>
                jointly designates the Professional and the company Picoxia, both having the status
                of professionals.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Policy:</List.Header>
              <List.Description>
                refers to the Policy on the protection of personal data accessible at the following
                address: Privacy Policy.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Users:</List.Header>
              <List.Description>
                refers to any person who uses the Website and the Picoxia Solutions / Services.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Clinic:</List.Header>
              <List.Description>
                refers to the veterinary structure (clinic or practice) within which, if applicable,
                the Veterinarian who has subscribed to the Picoxia Services / Solutions practices.
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>Veterinarian:</List.Header>
              <List.Description>
                refers to each Veterinarian practicing as an individual member of a Clinic or of a
                firm having taken out the subscription.
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      </section>

      <section id="3-object">
        <h2>3.&nbsp;Object</h2>
        <section id="3-1-description-of-the-solutions-services" className="sub-section">
          <h3>3.1.&nbsp;Description of the Solutions / Services offered by Picoxia</h3>
          <p>
            This contract governs the steps necessary for subscription and defines the terms and
            conditions of subscription and use of Picoxia solutions by the Professional.
          </p>
          <p>
            Subject to the terms of this Contract, during the Term of said contract, Picoxia
            consents to the Professional, who accepts:
          </p>
          <ul>
            <li>a right of final, non-exclusive use of the Solutions;</li>
            <li>
              a set of Services defined below, in particular hosting of clinical data, maintenance
              and security of the Solutions.
            </li>
          </ul>
          <p>
            It is formally agreed between the Parties that any tolerance or waiver of one of the
            Parties, in the application of all or part of the commitments provided in the Contract,
            whatever the frequency and duration, may not constitute modification of the Contract,
            nor be capable of creating any right.
          </p>
          <p>
            In the event that the subscription contract is taken out by a Veterinary Clinic in which
            several Veterinarians practice who have access to the Services, the Veterinary Clinic
            guarantees to have obtained the consent, without reservation of the Veterinarians to
            these GTC, and of the Personal data protection policy.
          </p>
        </section>

        <section id="3-2-license" className="sub-section">
          <h3>3.2.&nbsp;License</h3>
          <p>
            Subject to the provisions of this Agreement, Picoxia hereby grants the Professional a
            limited number, extended (from the subscriber) to the staff of the Clinic in which he
            works, of a non-sublicensable, non-transferable, non-exclusive license to use. online or
            to download, install and use on the Clinic's workstations. Solutions and applications
            accessible online or installable must be used in accordance with the instructions
            provided by Picoxia.
          </p>
          <p>
            In particular, the license relating to the Solutions is only granted for the sole
            purpose of enabling the Professional to use the Services in SaaS mode via a connection
            to an Internet network, to the exclusion of any other purpose. The Professional will
            refrain, and will not allow any third party under his authority:
          </p>
          <ol>
            <li>
              to proceed with the copying, modification, creation of a derivative work, reverse
              engineering, decompilation, translation, disassembly or any other attempt to extract
              all or part of the source code of the Services;
            </li>
            <li>
              to sublicense, resell or distribute all or part of the Services separately from any
              Integrated Application;
            </li>
            <li>
              to access the Services in a manner intended to avoid the payment of Fees or to exceed
              the quotas or limits of authorized use.
            </li>
          </ol>
        </section>

        <section id="3-3-facilities" className="sub-section">
          <h3>3.3.&nbsp;Facilities</h3>
          <p>
            All facilities used for the storage and processing of User Data will adopt reasonable
            security standards with a view to:
          </p>
          <ol>
            <li>guarantee the security and confidentiality of User Data,</li>
            <li>
              provide protection against risks or threats to the security or integrity of User Data,
            </li>
            <li>and provide protection against unauthorized access or use of User Data.</li>
          </ol>
        </section>

        <section id="3-4-access-to-solutions" className="sub-section">
          <h3>3.4.&nbsp;Access to solutions</h3>
          <p>
            The Professional must have an Account to access Picoxia's services / solutions. The
            Professional is entirely responsible for the information he provides when creating the
            Account, for keeping the access codes for the Account and for any use made of his
            Account. The Identifiers are intended to reserve access to the Solutions that are the
            subject of the Contract to the professional, to protect the integrity and availability
            of the Solutions, as well as the integrity, availability and confidentiality of User
            Data.
          </p>
          <p>
            The Identifiers are personal and confidential. The Professional will ensure that no
            other person, with the exception of Veterinarians exercising their Clinic and having
            consented to these T & Cs, has access to Picoxia Solutions. In general, the Professional
            assumes responsibility for the security of the individual workstations providing access
            to the Solutions. In the event that he is aware of what another person is accessing, the
            Professional will inform Picoxia without delay. In the event of loss or theft of one of
            the identifiers, the Professional will use the procedure set up on the site allowing him
            to create new identifiers.
            <br />
            Picoxia is under no obligation to provide the Professional with several accounts.
          </p>
        </section>

        <section id="3-5-new-applications-and-new-services" className="sub-section">
          <h3>3.5.&nbsp;New applications and new services</h3>
          <p>
            Picoxia may from time to time introduce new applications, tools, features or
            functionality to existing solutions.
          </p>
        </section>

        <section id="3-6-modifications" className="sub-section">
          <h3>3.6.&nbsp;Modifications</h3>
          <section id="3-6-a-modification-to-services" className="sub-sub-section">
            <h4>a.&nbsp;Modification to Services, Support And Updates</h4>
            <p>
              Picoxia reserves the right to add or remove features or functions, or to make
              programming corrections, improvements, updates. The Professional agrees that Picoxia
              is not obliged to provide Users with updates, or to provide improved versions. If
              Picoxia makes a decisive change to the Services / solutions, Picoxia will inform the
              Professional. Users also agree that they may have to sign up to a new version of this
              Agreement if they wish to download, install or use an update.
            </p>
          </section>

          <section id="3-6-b-modifications-to-the-contract" className="sub-sub-section">
            <h4>b.&nbsp;Modifications to the Contract</h4>
            <p>
              Picoxia may at any time make changes to this Contract, in particular to prices (and
              all related documents). Unless otherwise specified by Picoxia, decisive modifications
              made to the Contract will come into force 30 days after their publication, except if
              the modifications concern the addition of functionalities, in which case they will
              come into force immediately. Picoxia will then provide a notice either by email
              addressed to the Professional. If the Professional does not agree to the revised
              Contract, he is requested to stop using the Services. Picoxia will publish any
              modification to this Agreement at the URL of the T & Cs.
              <br />
              The latest version of the Contract applies from the outset to subscriptions taken out
              after it is put online.
            </p>
          </section>
        </section>
      </section>

      <section id="4-terms-of-service-subscription">
        <h2>4.&nbsp;Terms of service subscription</h2>
        <section id="4-1-subscription-conditions" className="sub-section">
          <h3>4.1.&nbsp;Subscription conditions</h3>
          <p>
            Subscription to Picoxia services requires the prior creation of an Account. The creation
            of an Account requires filling in the following information:
          </p>
          <ul>
            <li>Name and address of the clinic</li>
            <li>Clinic email</li>
            <li>Name and first name of the person requesting the subscription</li>
          </ul>
          <p>
            The Professional undertakes to provide sincere, accurate, up-to-date and complete
            information on the identity of the Clinic and the Veterinarian subscribing to the
            Services, as well as any information requested in the registration form. The
            registration constitutes irrevocable power given by the Professional to Picoxia to use
            the information transmitted in order to be able to carry out the Services made available
            on the Site.
          </p>
          <p>
            Where applicable, the signatory undertakes to have the power to contract in the name and
            on behalf of the Clinic.
          </p>
        </section>

        <section id="4-2-free-trials" className="sub-section">
          <h3>4.2.&nbsp;Free Trials</h3>
          <p>
            The creation of an Account gives the Professional access to a fixed number of free
            requests, specified during registration, in order to test the Service. Picoxia reserves
            the right to determine the Professional's eligibility for a free trial and to limit it,
            to prevent any abuse. Picoxia reserves the right to revoke the free trial and to suspend
            the Professional's Account in the event of non-eligibility.
          </p>
          <p>
            The remaining number of free trials can be viewed on the Site on the "Purchases" page.
          </p>
        </section>

        <section id="4-3-offers" className="sub-section">
          <h3>4.3.&nbsp;Offers</h3>
          <p>
            Picoxia provides one kind of commercial offer: the subscription to a monthly
            subscription (90 € per month excluding VAT for a veterinary practice / clinic). The
            subscription is done directly on the "Purchases" page of the Website.
          </p>
          <p>
            When subscribing online, the Professional confirms his full and unreserved acceptance of
            the GTCS and the Data Protection Policy of which he acknowledges having read. At the end
            of the subscription, the Professional has a 15-day free trial period; at the end of this
            period, the first direct debit will then be carried out in proportion to the current
            month. Thereafter, the Professional will receive an e-mail confirming the amount that
            will be debited from him.
          </p>
          <p>
            The subscription is taken out in the name of the Independent Veterinarian or in the name
            of the Clinic, for a determined number of Veterinarians working within it.
          </p>
          <p>
            By subscribing to Picoxia Services, the Professional declares to use the services in
            accordance with applicable law and these GTC. The Clinic guarantees that these are
            respected by the Veterinary Users.
          </p>
          <p>
            To consult the subscription fees, the professional can go to the "Purchases" page of his
            Account.
          </p>
          <p>
            The Professional as well as the veterinarians under his authority undertake to use
            Picoxia in a reasonable manner, in accordance with the offer to which he has subscribed.
            The number of requests sent monthly must be consistent with the number of veterinarians
            Users of the Clinic.
            <br />
            In the event of improper use, Picoxia reserves the right to contact the Professional
            and, if an agreement between the two parties cannot be established, to terminate the
            Professional's subscription.
          </p>
        </section>
      </section>

      <section id="5-financial-conditions">
        <h2>5.&nbsp;Financial conditions</h2>
        <section id="5-1-prices" className="sub-section">
          <h3>5.1.&nbsp;Prices</h3>
          <p>
            The prices for access to Picoxia Solutions are presented in&nbsp;
            <IntlCrawlableLink url="/terms-of-sales#4-3-offers">point 4.3</IntlCrawlableLink> of
            these Terms Of Sales. The prices in force are those displayed on the Website on the day
            of subscription to the Picoxia Services by the Professional. Picoxia reserves the right
            to modify its prices at any time.
            <br />
            In the event of a price change, this will be announced at least (1) one month before the
            new price takes effect and will give rise to the right to terminate this contract.
          </p>
        </section>
        <br />
        <section id="5-2-method-of-payment" className="sub-section">
          <h3>5.2.&nbsp;Method of payment</h3>
          <p>
            Payment for subscriptions is made by automatic monthly debit from a bank account. The
            debit is made each month, on the anniversary date of the subscription, for the Services
            for the coming month. The direct debit is subcontracted to the company Stripe.
            <br />
            The professional is liable for any amount not withdrawn. If the settlement of a payment
            fails due to the expiration of the card, insufficient balance or for any other reason,
            and the account has not been terminated, Picoxia may suspend access to its Services
            until payment of the amount due.
          </p>
        </section>
      </section>

      <section id="6-user-behaviour">
        <h2>6.&nbsp;User behaviour</h2>
        <p>The Users will not use Picoxia Solutions in order to:</p>
        <ol>
          <li>
            infringe the intellectual property, property, or publicity rights of a third party;
          </li>
          <li>violate any applicable law, ordinance or regulation;</li>
          <li>
            disseminate computer viruses or any other computer code, files or programs which may
            interrupt, destroy or limit the functionality of any computer software or hardware or
            telecommunications equipment. The User is solely responsible for the content transmitted
            on Picoxia. The user will thus be held solely responsible for any damage caused to any
            party resulting therefrom.
          </li>
        </ol>
        <p>
          Users acknowledge and accept that their information may be removed from the Picoxia
          database and servers in accordance with&nbsp;
          <IntlCrawlableLink url="/terms-of-sales#9-2-account-suspension-and-termination-for-breach">
            article 9.2
          </IntlCrawlableLink>
          &nbsp; of this Agreement, without the consent of the account holder.
        </p>
      </section>

      <section id="7-personal-data">
        <h2>7.&nbsp;Personal data</h2>
        <section id="7-1-personal-data-of-the-users" className="sub-section">
          <h3>7.1.&nbsp;Personal data of the Users</h3>
          <p>
            Users understand and accept that Picoxia collects and processes information that may
            constitute Personal Data, collected in particular during registration and visits to the
            Site. By accepting this agreement, the User consents to this data transfer
            <br />
            No personal information is collected without the knowledge of the user. No personal
            information is disseminated, sold, or transferred without the knowledge of the User.
          </p>
          <p>
            The professional agrees to receive emails from Picoxia concerning his Account, updates
            to the Solutions and new Solutions and services. The professional can unsubscribe from
            the newsletter at any time by contacting Picoxia by email, at&nbsp;
            <a href="mailto:contact@picoxia.com" target="_blank">
              contact@picoxia.com
            </a>
            . Unsubscribing will stop sending promotional messages, but the Professional will still
            be able to receive messages regarding changes in function or service, as well as
            information notes in the event of a problem / failure, and will continue to receive
            notifications designed as part of the platform.
            <br />
            Unsubscribing from the Website stops collecting data from Users, but all data collected,
            intentionally or not, during or after unsubscribing will be subject to the current terms
            and conditions of this document.
          </p>
          <p>
            These GTCS are supplemented by the&nbsp;
            <b>Policy relating to the protection of personal data</b> which governs the methods of
            said processing and collection, in accordance with Law No. 78-17 of January 6, 1978
            relating to computers, files and freedoms in its consolidated version and the General
            Data Protection Regulation No. 2016/679.
          </p>
        </section>

        <section id="7-2-data-transmitted-by-the-user" className="sub-section">
          <h3>7.2.&nbsp;Data transmitted by the User</h3>
          <p>
            If the Data transmitted by the User in the context of the use of Picoxia Solutions
            include personal data, the User guarantees that he has fulfilled all the obligations
            incumbent on him under the law of 6 January 1978 known as "
            <i>Informatique & Libertés</i>", and that it has informed the natural persons concerned
            of the use which is made of said personal data. As such, the Professional guarantees
            Picoxia against any recourse, complaint or claim from a natural person whose personal
            data is reproduced and hosted by Picoxia.
          </p>
          <p>
            For all practical purposes, it is specified that, with regard to the Personal Data
            transferred by the Professional to Picoxia, the Professional remains the data controller
            and Picoxia the subcontractor within the meaning of the General Data Protection
            Regulation n° 2016/679.
            <br />
            The data stored by Picoxia may be used to develop, create and market products and
            services dedicated to the health of companion animals, to research or more generally to
            the animal industry (but not limited to it).
          </p>
        </section>

        <section id="7-3-data-security" className="sub-section">
          <h3>7.3.&nbsp;Data security</h3>
          <p>
            Each of the Parties undertakes to implement the appropriate technical means to ensure
            the security of the Data. Picoxia will put in place the technical and organizational
            measures to prevent any access or fraudulent use of the Data and to prevent any loss,
            alteration and destruction of the Data.
          </p>
        </section>

        <section id="8-intellectual-property">
          <h2>8.&nbsp;Intellectual property</h2>
          <section id="8-1-proprietary-rights" className="sub-section">
            <h3>8.1.&nbsp;Proprietary Rights</h3>
            <p>
              Picoxia is and remains the owner of the property rights relating to any element of the
              Services and Solutions made available to the User, as well as more generally of the IT
              infrastructure (software and hardware) implemented or developed under the Contract.In
              particular, the content of the Site, the general structure and all the elements /
              documents appearing there (information, data, photographs, texts, sounds, images,
              videos, drawings, graphics, distinctive signs, logos, models, brands, names domain,
              software and programs, databases ...) are the exclusive property of Picoxia. Under
              Article L 122-4 of the Intellectual Property Code, any use, in the broad sense, is
              prohibited. Unauthorized reproduction is prohibited (law 98-536 of July 1, 1998).
            </p>
            <p>
              The Contract does not confer to the Professional any right of ownership over the
              Solutions. The Professional shall refrain from reproducing any element of the
              Software, or any documentation concerning them, by any means whatsoever, in any form
              whatsoever and on any medium whatsoever.
              <br />
              Any representation or reproduction, republication, redistribution, adaptation,
              translation and / or complete or partial transformation of the content of the Site and
              the Picoxia services, by any means whatsoever is prohibited and would constitute in
              particular but not exclusively an infringement punishable by the provisions of the
              Code. intellectual property likely to engage the civil and criminal liability of the
              infringer.
            </p>
            <p>
              The Professional is and remains the owner of all the Data that he uses via the
              Solutions within the framework of the Contract, subject to the sole discretion of
              Picoxia's right to use, copy, modify, share and analyze this content only in the
              purpose of providing the Picoxia Services.
            </p>
          </section>

          <section id="8-2-use-of-professional-data" className="sub-section">
            <h3>8.2.&nbsp;Use of Professional Data</h3>
            <p>
              Any medical and / or veterinary imaging information transmitted on the Site may be
              used by Picoxia in the context of improving its services.
            </p>
          </section>

          <section id="8-3-professional-comments" className="sub-section">
            <h3>8.3.&nbsp;Professional comments</h3>
            <p>
              If the User makes comments to Picoxia concerning the Services, Picoxia may use this
              information without obligation towards the User, and the User irrevocably assigns to
              Picoxia all rights, titles and interests relating to these Comments.
            </p>
          </section>

          <section id="8-4-comparative-evaluations" className="sub-section">
            <h3>8.4.&nbsp;Comparative evaluations</h3>
            <p>
              The Professional may not publicly disclose, directly or through third parties, the
              results of any comparative or compatibility evaluation, calibrations or evaluation
              (each hereinafter referred to as a "Test") of the Services, unless the disclosure be
              made of all the information that would allow Picoxia or a third party to reproduce the
              Test.
            </p>
          </section>
        </section>

        <section id="9-duration-and-termination"></section>
        <h2>9.&nbsp;Duration and termination</h2>
        <section id="9-1-duration-of-the-contract" className="sub-section">
          <h3>9.1.&nbsp;Duration of the Contract</h3>
          <p>
            The "Term" of this Agreement will begin on the Effective Date and continue until
            termination of the Agreement.
          </p>
        </section>

        <section id="9-2-account-suspension-and-termination-for-breach" className="sub-section">
          <h3>9.2.&nbsp;Account Suspension and Termination for Breach</h3>
          <p>Either party may terminate this Agreement for breach if:</p>
          <ol>
            <li>
              the other party is in material breach of the Agreement and does not remedy such breach
              within thirty (30) days of receipt of a written notice,
            </li>
            <li>
              the other party ceases to trade or is the subject of an insolvency proceeding and the
              proceeding is not dismissed within ninety (90) days,
            </li>
            <li>
              or the other party commits a material breach of this Agreement more than twice,
              notwithstanding the resolution of such breaches.
            </li>
          </ol>
          <p>
            Picoxia may automatically Suspend all or part of the use of the Services by the User if:
          </p>
          <ol>
            <li>
              Picoxia judges that the use of the Services by the Professional or one of the Clinic
              Users could disrupt the Services or the use of the Services by other Users
            </li>
            <li>there is a suspicion of unauthorized access by a third party to the Services;</li>
            <li>Picoxia notices an abusive use of Picoxia solutions;</li>
            <li>if Picoxia deems it necessary to comply with applicable laws;</li>
            <li>
              or the Professional is in violation of&nbsp;
              <IntlCrawlableLink url="/terms-of-sales#3-2-license">article 3.2</IntlCrawlableLink>
              &nbsp;(Restrictions).
            </li>
          </ol>
          <p>
            Picoxia will lift such suspension when the circumstances giving rise to the Suspension
            have been resolved.
            <br />
            At the Professional's request, unless the applicable law prohibits it, Picoxia will
            notify the Professional on the basis of the Suspension as soon as it is reasonably
            possible to do so.
          </p>
        </section>

        <section id="9-3-termination-for-convenience" className="sub-section">
          <h3>9.3.&nbsp;Termination for Convenience</h3>
          <p>
            The Professional can stop using the Services at any time. The Professional may thus
            terminate this Agreement for convenience at any time by giving written notice and, at
            the time of termination, must cease using the applicable Services, it being specified
            that any month started is due. In order to cancel their subscription, the Professional
            can go to the "Purchases" page.
          </p>
          <p>
            In order to close his User Account, the Professional will send an email to
            <a href="mailto:contact@picoxia.com" target="_blank">
              contact@picoxia.com
            </a>
            . Picoxia will inform the Professional or the Clinic by email of the successful receipt
            of said termination email.
          </p>
          <p>
            Picoxia may terminate this Contract for reasons of convenience at any time without
            incurring any liability towards the Professional.
          </p>
        </section>

        <section id="9-4-effect-of-termination" className="sub-section">
          <h3>9.4.&nbsp;Effect of termination</h3>
          <p>In the event of termination of the Contract:</p>
          <ol>
            <li>
              the license rights granted by Picoxia to the Professional will cease immediately,
            </li>
            <li>
              all Fees owed by the Professional to Picoxia are immediately payable upon receipt of
              the final electronic invoice,
            </li>
            <li>
              The Professional will remove the Software (s), and / or any Picoxia Application.
              Following termination by the Professional, Picoxia reserves the right to retain data,
              including but not limited to data related to the account or for billing purposes.
            </li>
          </ol>
        </section>

        <section id="9-5-lack-of-responsibility" className="sub-section">
          <h3>9.5.&nbsp;Lack of responsibility</h3>
          <p>
            Picoxia cannot be held responsible for any damage caused by the termination of this
            Agreement.
          </p>
        </section>
      </section>

      <section id="10-obligations-of-the-professional">
        <h2>10.&nbsp;Obligations of the Professional</h2>
        <p>
          The use of the Services does not dilute, modify or attenuate either directly or indirectly
          the responsibility and obligations of the Clinic or the Veterinarian practicing as an
          individual registered for the Services (and of its Veterinarians) towards pets owners. The
          latter exercise in complete independence, in compliance with their personal ethical, legal
          and regulatory obligations and under their exclusive responsibility.
        </p>
      </section>

      <section id="11-liability-and-guarantees">
        <h2>11.&nbsp;Liability and guarantees</h2>
        <p>
          The Professional is solely responsible for the use he makes of the Site and the Services /
          Solutions which he accesses from the Site. Picoxia can in no way be held responsible in
          the context of a procedure brought against the Clinic or its veterinarians members who are
          guilty of improper use of the Website and / or the Picoxia Services.
          <br />
          The Professional acknowledges and accepts in this regard that he will be personally
          responsible for any claim or procedure brought against Picoxia, due to non-compliant use
          by it or its Veterinarians of the Services and / or the Website.
        </p>
        <p>
          The Parties are in no way liable for indirect damages suffered by the other Party
          resulting from the execution of this Contract, such as lost profits and any other damage
          qualified as indirect by case law.
        </p>
        <p>
          The responsibility of Picoxia cannot be directly or indirectly retained for damages
          related to:
        </p>
        <ol>
          <li>the professional practice of the Veterinarian(s);</li>
          <li>
            non-performance by the User of his obligations, such as non-compliance with the
            conditions of use of the Website;
          </li>
          <li>failure to configure the offered Services;</li>
          <li>
            any incident or interruption of the Professional's Internet connection or any incident
            inherent in the Professional's equipment;
          </li>
          <li>difficulty in accessing the Site due to maintenance operations;</li>
          <li>cases of illicit access to the Professional's account;</li>
          <li>the improper use of the Site by the Professional.</li>
        </ol>
        <p>
          Picoxia Solutions / Services are provided "as they are", without warranty of any kind, and
          Picoxia disclaims all express or implied warranties, including guarantees of quality /
          performance, suitability for a particular purpose, reliability and of non-infringement.
          Picoxia does not guarantee that the software accessible through its Website is flawless.
          <br />
          The Picoxia Website does not have an expertise vocation; The analyzes produced are the
          work of a machine and not of a human being. These analyzes cannot serve as a legal
          document and have no legal value. The User is responsible for the use he makes of these
          analyzes, as well as for the diagnosis he delivers.
        </p>
      </section>

      <section id="12-discontinuation-of-services">
        <h2>12.&nbsp;Discontinuation of Services</h2>
        <section id="12-1-final-discontinuation" className="sub-section">
          <h3>12.1.&nbsp;Final discontinuation</h3>
          <p>
            Subject to the provisions of&nbsp;
            <IntlCrawlableLink url="/terms-of-sales#9-4-effect-of-termination">
              section 9.4
            </IntlCrawlableLink>
            , Picoxia may interrupt all or part of the Services, or any related functionality for
            any reason whatsoever, at any time and without incurring any liability towards the
            Professional.
            <br />
            If applicable, Picoxia will announce its intention to discontinue or make changes that
            are not backward compatible with the Services at&nbsp;
            <a href="https://www.picoxia.com/en/">www.picoxia.com</a>.
          </p>
        </section>

        <section id="12-2-temporary-discontinuation-for-maintenance" className="sub-section">
          <h3>12.2.&nbsp;Temporary discontinuation for maintenance</h3>
          <p>
            Picoxia Services / solutions may occasionally be suspended due to maintenance
            interventions necessary for the proper functioning of the servers [or the platform]. In
            the event of an interruption of the Services for maintenance, Picoxia will inform the
            Professional as best as possible of the interruption, so that he can make arrangements
            sufficiently in advance to avoid any disruption of his activity. Picoxia cannot be held
            responsible for the possible impact of this unavailability on the activities of the
            Professional.
            <br />
            Any operating anomaly must be reported by email to Picoxia without delay. Picoxia will
            then diagnose the anomaly and implement its correction.
          </p>
          <p>Picoxia is not responsible for maintenance in the following cases:</p>
          <ol>
            <li>
              Refusal of the User to collaborate in the resolution of anomalies and in particular to
              answer questions and requests for information;
            </li>
            <li>
              Use of Application Services in a manner inconsistent with their destination or their
              documentation;
            </li>
            <li>
              Unauthorized modification of the Solutions by the Professional or by a third party;
            </li>
            <li>
              Implementation of all software packages, software or operating system not compatible
              with the Application Services;
            </li>
            <li>Failure of electronic communication networks;</li>
            <li>Voluntary act of degradation, malice, sabotage;</li>
            <li>Deterioration due to force majeure.</li>
          </ol>
        </section>
      </section>

      <section id="13-applicable-law">
        <h2>13.&nbsp;Applicable law</h2>
        <p>The laws of the French State will govern this Agreement.</p>
      </section>

      <section id="14-miscellaneous">
        <h2>14.&nbsp;Miscellaneous</h2>
        <section id="14-1-third-party-costs" className="sub-section">
          <h3>14.1.&nbsp;Third party costs</h3>
          <p>
            Users are responsible for all costs associated with the use of mobile devices and
            personal computers, such as airtime and Internet access costs.
          </p>
        </section>

        <section id="14-2-language" className="sub-section">
          <h3>14.2.&nbsp;Language</h3>
          <p>
            The original French version of this agreement may have been translated into other
            languages. In the event of any inconsistency or discrepancy between the French version
            and any other linguistic version of this Agreement, the French version shall prevail.
          </p>
        </section>

        <section id="14-3-convention-of-evidence" className="sub-section">
          <h3>14.3.&nbsp;Convention of evidence</h3>
          <p>
            Users agree to use electronic communication as a means of concluding agreements and
            placing orders, as well as as a means of issuing notices, policies and records of
            transactions undertaken with Picoxia.
            <br />
            The parties expressly acknowledge that they will have equivalent proof value to that of
            an original document and will therefore benefit from a presumption of validity: plain
            letters, faxes, electronic messages exchanged between the parties in the context of the
            execution of the Contract.
          </p>
          <p>
            Likewise, in the event of a dispute, Picoxia may validly provide proof of the
            Professional's actions, or of fraudulent access by a third party, by means of the
            connection and transmission logins recorded by its network administrators who alone
            shall be taken into account between the parts. The online acceptance of these GTC by
            electronic means has the same probative value between the Parties as the agreement on
            paper.
          </p>
          <p>
            The archiving of contractual documents and Picoxia invoices is carried out on a reliable
            and durable medium that can be produced as proof, in particular as proof of subscription
            requests and payments made between the Parties.
          </p>
        </section>

        <section id="14-4-change-of-control" className="sub-section">
          <h3>14.4.&nbsp;Change of control</h3>
          <p>
            Any change in the shareholding structure of either Party resulting in a change of
            control of the Company within the meaning of Article L. 233-3 of the Commercial Code
            will be reported immediately to the other Party, which will have then the right to
            unilaterally terminate the contract.
            <br />
            Each of the Parties will remain the guarantor, with regard to the other Party, of the
            respect by the assignee of all the rights and obligations resulting from the present,
            for the remaining period of the subscription.
          </p>
        </section>

        <section id="14-5-partial-invalidity" className="sub-section">
          <h3>14.5.&nbsp;Partial invalidity</h3>
          <p>
            In the event that a provision hereof would be null, illegal, unenforceable or
            unenforceable in any way whatsoever, the validity, legality or application of the other
            provisions of these general conditions would not be affected or altered in any way, the
            others stipulations hereof remaining in force and retaining their full and entire
            effect.
          </p>
        </section>
      </section>
    </>
  );
}

/**
 * TermsOfSalesContent
 * Display the content of the Terms of sales
 * @returns {JSX.Element}
 */
function TermsOfSalesContent() {
  const locale = useSelector(selectLocale);

  if (locale === 'fr') {
    return <RenderFrenchText />;
  }

  return <RenderEnglishText />;
}

export default TermsOfSalesContent;
