import { Action } from 'redux';
import { produce } from 'immer';
import { LOCATION_CHANGE, LocationChangeAction } from 'react-router-redux';
import { createSelector } from 'reselect';

export type RouteState = {
  locationBeforeTransitions: LocationChangeAction['payload'];
};

// Initial routing state
const routeInitialState: RouteState = {
  locationBeforeTransitions: null,
};

type StoreWithRoute = {
  route: RouteState;
};

export const selectRoute = (state: StoreWithRoute) => state.route;

/**
 * Route reducer using Immer
 */
export default function routeReducer(
  state = routeInitialState,
  action: Action & LocationChangeAction
) {
  return produce(state, (draft) => {
    switch (action.type) {
      /* istanbul ignore next */
      case LOCATION_CHANGE:
        draft.locationBeforeTransitions = action.payload;
        break;
      default:
        break;
    }
  });
}
