import { PatternsDescription } from 'app/utils/reports/types';

const COMMON_PATTERNS_DESCRIPTION: PatternsDescription = {
  differential_diagnosis: {
    isSentence: true,
  },
};

const EPANCHEMENT_ABDOMINAL_DD = ['epanchement_abdominal'];
const INTESTINAL_OBSTRUCTION_DD = ['obstruction_intestinale'];
const MALADIE_RENALE_CHRONIQUE_DD = ['maladie_renale_chronique'];

const ABDOMEN_PATTERNS: PatternsDescription = {
  diminution_de_contraste: {
    differentialDiagnosis: EPANCHEMENT_ABDOMINAL_DD,
    Causes: [
      'artefact',
      'physiologique',
      'epanchement_abdominal',
      'peritonite',
      'neoplasie_peritoneale',
      'encombrement_visceral',
    ],
    maxSeverityPresent: true,
    isSentence: true,
  },
  corps_etranger: {
    differentialDiagnosis: INTESTINAL_OBSTRUCTION_DD,
    maxSeverityPresent: true,
  },
  dilatation_gastrique: {
    differentialDiagnosis: INTESTINAL_OBSTRUCTION_DD,
    isSentence: true,
  },
  dilatation_intestinale: {
    differentialDiagnosis: INTESTINAL_OBSTRUCTION_DD,
    Causes: [
      'dilatation_moderee_focale',
      'dilatation_severe_focale',
      'dilatation_generalisee_moderee',
      'dilatation_generalisee_severe',
    ],
  },
  petits_reins: { differentialDiagnosis: MALADIE_RENALE_CHRONIQUE_DD, isSentence: true },
  mineralisation_renale: {
    differentialDiagnosis: MALADIE_RENALE_CHRONIQUE_DD,
    maxSeverityPresent: true,
  },
  urolithe: {
    Causes: ['calcul_ureteraux', 'cystolithes'],
    shouldFollow: 'petits_reins_mineralisation_renale',
    isSentence: true,
  },
  prostatomegalie: {
    Causes: ['hyperplasie', 'abces_kyste_neo', 'prostatite'],
  },
  masse_intra_abdominale: {
    maxSeverityPresent: true,
  },
  hepatomegalie: {
    Causes: ['physiologique_jeune', 'artefact', 'congestion', 'masse_hepatique'],
  },
  splenomegalie: {
    Causes: ['elargissement_generalise', 'elargissement_focal'],
    shouldFollow: 'hepatomegalie',
  },
  dilatation_gastrique_intestinale: {
    combinationOf: ['dilatation_gastrique', 'dilatation_intestinale'],
    differentialDiagnosis: INTESTINAL_OBSTRUCTION_DD,
    isSentence: true,
  },
  dilatation_gastrique_intestinale_corps_etranger: {
    associationOf: {
      pattern: [
        'dilatation_gastrique_intestinale',
        'dilatation_gastrique',
        'dilatation_intestinale',
      ],
      associatedPattern: 'corps_etranger',
    },
    differentialDiagnosis: INTESTINAL_OBSTRUCTION_DD,
    isSentence: true,
  },
  dilatation_gastrique_intestinale_obstruction_diminution_contraste_epanchement: {
    combinationOf: {
      dilatation: [
        'dilatation_gastrique',
        'dilatation_intestinale',
        'dilatation_gastrique_intestinale',
        'dilatation_gastrique_intestinale_corps_etranger',
      ],
      '0': 'obstruction_intestinale',
      '1': 'diminution_de_contraste',
      '2': 'epanchement_abdominal',
    },
    isSentence: true,
  },
  petits_reins_mineralisation_renale: {
    combinationOf: ['petits_reins', 'mineralisation_renale'],
    differentialDiagnosis: MALADIE_RENALE_CHRONIQUE_DD,
    isSentence: true,
  },
  urolithe_mineralisation_renale: {
    combinationOf: ['urolithe', 'mineralisation_renale'],
    differentialDiagnosis: MALADIE_RENALE_CHRONIQUE_DD,
    isSentence: true,
  },
};

const ABDOMEN_GROUPS = {
  peritoneum: {
    patterns: ['diminution_de_contraste'],
    differentialDiagnosis: EPANCHEMENT_ABDOMINAL_DD,
  },
  gastrointestinal_tract: {
    patterns: ['corps_etranger', 'dilatation_gastrique', 'dilatation_intestinale'],
    differentialDiagnosis: INTESTINAL_OBSTRUCTION_DD,
  },
  urogenital_system: {
    patterns: ['petits_reins', 'mineralisation_renale', 'urolithe', 'prostatomegalie'],
    differentialDiagnosis: MALADIE_RENALE_CHRONIQUE_DD,
  },
  others: { patterns: ['masse_intra_abdominale', 'hepatomegalie', 'splenomegalie'] },
};

const ABDOMEN_HIDDEN_PATTERNS = ['peritonite', 'nephromegalie', 'chat', 'face'];

const ABDOMEN_THRESHOLD_FOR_DOUBTFUL = 0.45;

const OPACITEES_DD = [
  'bronchopneumonie',
  'atelectasie',
  'oedeme_pulmonaire',
  'infection',
  'contusion',
];

const THORAX_PATTERNS: PatternsDescription = {
  cardiomegalie_globale: {},
  cardiomegalie: {},
  dilatation_atrium_gauche: {
    Causes: ['insuffisanceMitrale', 'myocardiopathieDilatee', 'myocardiopathieFeline'],
  },
  dilatation_ventricule_gauche: {
    Causes: ['surchargeVolume', 'surchargePression', 'myocardiopathieHypertrophique'],
  },
  dilatation_ventricule_droit: {
    Causes: [
      'insuffisanceCardiaqueGauche',
      'obstructionVentilatoire',
      'lesionParenchymePulmonaire',
      'obstruction',
    ],
  },
  opacite_bronchique: {
    differentialDiagnosis: OPACITEES_DD,
    Causes: ['physiologique', 'bronchiteAllergique', 'autres'],
  },
  congestion_vasculaire_pulmonaire: { maxSeverityPresent: true },
  opacite_alveolo_interstitielle_diffuse: {
    Causes: ['hematogene', 'parasitaire', 'neoplasique', 'pulmonaire', 'atelectasie'],
    differentialDiagnosis: OPACITEES_DD,
  },
  opacite_interstitielle_diffuse: {
    differentialDiagnosis: OPACITEES_DD,
    Causes: ['structuree', 'nonStructuree'],
  },
  opacite_non_specifique: {
    differentialDiagnosis: OPACITEES_DD,
    Causes: ['nonSpecifique', 'physiologique', 'nonPathologique'],
    maxSeverityPresent: true,
  },
  opacite_alveolaire_focale: {
    differentialDiagnosis: OPACITEES_DD,
    Causes: ['cranioventral', 'caudodorsal', 'nonSpecifique'],
  },
  collapsus_tracheal: {},
  flaccidite_tracheale: { isAlpha: true },
  deviation_tracheale: {},
  pneumothorax: { Causes: ['traumatique', 'iatrogene', 'extensionPneumomediastin', 'spontane'] },
  epanchement_pleural: {
    Causes: ['transsudat', 'exsudat', 'epanchementHemorragique', 'epanchementChyleux'],
  },
  scissure_interlobaire: {
    isAlpha: true,
    Causes: ['epanchementPleural', 'epaississementPleural'],
  },
  masse: {
    isAlpha: true,
    Causes: ['tumeurPrimitive', 'metastase', 'abces', 'granulome', 'hematome', 'kyste'],
    maxSeverityPresent: true,
  },
  dilatation_oesophagienne: {},
  'opacite bronchique a interstitielle': { differentialDiagnosis: OPACITEES_DD },
  'opacite interstitielle a bronchique': { differentialDiagnosis: OPACITEES_DD },
  'opacite interstitielle a bronchique probable': { differentialDiagnosis: OPACITEES_DD },
  'opacite interstitielle a bronchique suspicion': { differentialDiagnosis: OPACITEES_DD },
  'opacite interstitielle presente et bronchique probable': { differentialDiagnosis: OPACITEES_DD },
  'opacite interstitielle presente et bronchique suspicion': {
    differentialDiagnosis: OPACITEES_DD,
  },
  'opacite bronchique a interstitielle probable': { differentialDiagnosis: OPACITEES_DD },
  'opacite bronchique a interstitielle suspicion': { differentialDiagnosis: OPACITEES_DD },
  'opacite bronchique presente et interstitielle probable': { differentialDiagnosis: OPACITEES_DD },
  'opacite bronchique presente et interstitielle suspicion': {
    differentialDiagnosis: OPACITEES_DD,
  },
  'opacite broncho-interstitielle': { differentialDiagnosis: OPACITEES_DD },
  'opacite interstitielle a alveolaire': { differentialDiagnosis: OPACITEES_DD },
  'opacite interstitielle a alveolaire probable': { differentialDiagnosis: OPACITEES_DD },
  'opacite interstitielle a alveolaire suspicion': { differentialDiagnosis: OPACITEES_DD },
  'opacite interstitielle presente et alveolaire probable': { differentialDiagnosis: OPACITEES_DD },
  'opacite interstitielle presente et alveolaire suspicion': {
    differentialDiagnosis: OPACITEES_DD,
  },
  'opacite alveolaire a interstitielle': { differentialDiagnosis: OPACITEES_DD },
  'opacite alveolaire a interstitielle probable': { differentialDiagnosis: OPACITEES_DD },
  'opacite alveolaire a interstitielle suspicion': { differentialDiagnosis: OPACITEES_DD },
  'opacite alveolaire presente et interstitielle probable': { differentialDiagnosis: OPACITEES_DD },
  'opacite alveolaire presente et interstitielle suspicion': {
    differentialDiagnosis: OPACITEES_DD,
  },
  'opacite alveolo-interstitielle': { differentialDiagnosis: OPACITEES_DD },
  'opacite mixte': { differentialDiagnosis: OPACITEES_DD },
  'opacite alveolo-interstitielle marquee': { differentialDiagnosis: OPACITEES_DD },
  'opacite alveolo-interstitielle presente': { differentialDiagnosis: OPACITEES_DD },
  'opacite alveolo-interstitielle probable': { differentialDiagnosis: OPACITEES_DD },
  'opacite alveolo-interstitielle suspicion': { differentialDiagnosis: OPACITEES_DD },
};

const THORAX_GROUPS = {
  'Système Cardio-Vasculaire': {
    patterns: [
      'cardiomegalie_globale',
      'cardiomegalie',
      'dilatation_atrium_gauche',
      'dilatation_ventricule_gauche',
      'dilatation_ventricule_droit',
    ],
  },
  Poumons: {
    patterns: [
      'opacite_bronchique',
      'congestion_vasculaire_pulmonaire',
      'opacite_alveolo_interstitielle_diffuse',
      'opacite_interstitielle_diffuse',
      'opacite_non_specifique',
      'opacite_alveolaire_focale',
    ],
    differentialDiagnosis: OPACITEES_DD,
  },
  'Voies Aériennes': {
    patterns: ['collapsus_tracheal', 'flaccidite_tracheale', 'deviation_tracheale'],
  },
  Plèvre: { patterns: ['pneumothorax', 'epanchement_pleural', 'scissure_interlobaire'] },
  Global: { patterns: ['masse', 'dilatation_oesophagienne'] },
};

const THORAX_HIDDEN_PATTERNS = ['chien', 'chat', 'face', 'pas_thorax'];

const THORAX_THRESHOLD_FOR_DOUBTFUL = 0.45;
const GLOBAL_THRESHOLD_FOR_SECURE = 0.75;

export {
  COMMON_PATTERNS_DESCRIPTION,
  GLOBAL_THRESHOLD_FOR_SECURE,
  ABDOMEN_PATTERNS,
  ABDOMEN_GROUPS,
  ABDOMEN_HIDDEN_PATTERNS,
  ABDOMEN_THRESHOLD_FOR_DOUBTFUL,
  THORAX_PATTERNS,
  THORAX_GROUPS,
  THORAX_HIDDEN_PATTERNS,
  THORAX_THRESHOLD_FOR_DOUBTFUL,
};
