import { DataImage } from 'app/interfaces/DataImage';
import { DisplayableImageData } from 'app/interfaces/Image';

function getMinMaxValues(image: Uint8Array | Uint16Array): { min: number; max: number } {
  const result = { min: Infinity, max: -Infinity };
  const theoricalMax = 2 ** (8 * image.BYTES_PER_ELEMENT) - 1;
  const theoricalMin = 0;
  for (let i = 0; i < image.length; i++) {
    const value = image[i];
    if (value < result.min) result.min = value;
    if (value > result.max) result.max = value;
    if (result.min === theoricalMin && result.max === theoricalMax) {
      return result;
    }
  }
  return result;
}

export default function convertDisplayableImageDataToDataImage(
  displayableImage: DisplayableImageData
): DataImage {
  const rawData = displayableImage.getPixelData();
  let data;
  if (rawData instanceof Uint8Array || rawData instanceof Uint16Array) {
    data = rawData;
  } else {
    data = new Uint8Array(rawData);
  }
  const { minPixelValue, maxPixelValue } = displayableImage;
  let min;
  let max;
  if (minPixelValue === undefined || maxPixelValue === undefined) {
    ({ min, max } = getMinMaxValues(data));
  } else {
    min = minPixelValue;
    max = maxPixelValue;
  }
  let { windowCenter, windowWidth } = displayableImage;
  if (windowCenter === undefined || windowWidth === undefined) {
    windowCenter = Math.round((max + min) / 2);
    windowWidth = Math.round(max - min);
  }

  return {
    data,
    width: displayableImage.width,
    height: displayableImage.height,
    bytes_per_pixel: data.BYTES_PER_ELEMENT,
    minPixelValue: min,
    maxPixelValue: max,
    windowCenter: windowCenter,
    windowWidth: windowWidth,
  };
}
