import { restoreFromLocalStorage } from 'app/dataManagement/syncReduxStateWithLocalStorage';
import {
  PMS_SET_WATCHED_DIRECTORY,
  PMS_SET_IS_INVALID_WATCHED_DIRECTORY,
  PMS_SET_TYPE,
  PMS_SET_EXPORT_CONFIGURATION,
  PMS_SET_EXPORT_DIRECTORY,
  PMS_SET_SPECIFIC_CONFIGURATION,
  PMSAction,
} from 'app/redux/pms/actions';
import _ from 'lodash';
import produce from 'immer';
import { PmsTypes } from 'app/pms/parser/types';

export type PMSState = {
  watchedDirectory?: string;
  isInvalidWatchedDirectory?: boolean;
  type?: PmsTypes;
  exportConfiguration?: {
    exportDirectory?: string;
  };
};

export type StoreWithPMS = { pms: PMSState };

const initState = (): PMSState =>
  restoreFromLocalStorage('redux.pms') ?? {
    watchedDirectory: undefined,
    isInvalidWatchedDirectory: undefined,
  };

function pmsReducer(state: PMSState = initState(), { type, payload }: PMSAction) {
  return produce(state, (draft) => {
    switch (type) {
      case PMS_SET_WATCHED_DIRECTORY:
        draft.watchedDirectory = payload.watchedDirectory;
        break;
      case PMS_SET_IS_INVALID_WATCHED_DIRECTORY:
        draft.isInvalidWatchedDirectory = payload.isInvalidWatchedDirectory;
        break;
      case PMS_SET_TYPE:
        draft.type = payload.type;
        break;
      case PMS_SET_EXPORT_CONFIGURATION:
        draft.exportConfiguration = payload.exportConfiguration;
        break;
      case PMS_SET_EXPORT_DIRECTORY:
        if (!draft.exportConfiguration) {
          draft.exportConfiguration = {};
        }
        draft.exportConfiguration.exportDirectory = payload.exportDirectory;
        break;
      case PMS_SET_SPECIFIC_CONFIGURATION: {
        const { pmsType, path, value } = payload;
        _.set(draft, [pmsType, ..._.toPath(path)], value);
        break;
      }
    }
  });
}

export default pmsReducer;
