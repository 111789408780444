import { produce } from 'immer';
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import {
  XRAY_CONFIGURATION_ADD_OPERATOR,
  XRAY_CONFIGURATION_TOGGLE_TELEMETRY,
  XRAY_CONFIGURATION_DELETE_OPERATOR,
  XRAY_CONFIGURATION_SET_DEVICE_PATH,
  XRAY_CONFIGURATION_SET_LAST_OPERATORS,
  XrayGeneratorConfigurationAction,
} from 'app/redux/xrayGeneratorConfiguration/actions';
import observeStore from 'app/utils/redux/observeStore';
import { Store } from 'redux';

export const XRAY_GENERATOR_LOCAL_STORAGE_KEY = 'redux.xray_generator';

export type XRayGeneratorConfigurationState = {
  devicePath?: string;
  isTelemetry: boolean;
  operators?: string[];
  selected_operators?: string[];
};

const configurationInitialState = (): XRayGeneratorConfigurationState => ({
  isTelemetry: false,
  operators: [],
  selected_operators: [],
  ...restoreFromLocalStorage(XRAY_GENERATOR_LOCAL_STORAGE_KEY, {}),
});

const xrayConfigurationReducer = (
  xRayConfiguration: XRayGeneratorConfigurationState = configurationInitialState(),
  { type, payload }: XrayGeneratorConfigurationAction
) => {
  return produce(xRayConfiguration, (draft) => {
    switch (type) {
      case XRAY_CONFIGURATION_SET_DEVICE_PATH:
        draft.devicePath = payload.devicePath;
        break;
      case XRAY_CONFIGURATION_TOGGLE_TELEMETRY:
        draft.isTelemetry = payload.isTelemetry;
        break;
      case XRAY_CONFIGURATION_ADD_OPERATOR:
        draft.operators = Array.from(new Set([...(draft.operators || []), payload.operator]));
        break;
      case XRAY_CONFIGURATION_SET_LAST_OPERATORS:
        draft.selected_operators = payload.operators;
        break;
      case XRAY_CONFIGURATION_DELETE_OPERATOR:
        draft.operators = Array.from(
          new Set(
            [...((draft.operators || []) as string[])].filter(
              (operator) => operator !== payload.operator
            )
          )
        );
        break;
    }
  });
};

type StoreWithXRaygeneratorconfiguration = { xRayConfiguration: XRayGeneratorConfigurationState };

export const selectXRayConfiguration = (state: StoreWithXRaygeneratorconfiguration) =>
  state.xRayConfiguration;
export const selectXRayDevicePath = (state: StoreWithXRaygeneratorconfiguration) =>
  selectXRayConfiguration(state).devicePath;
export const selectTelemetry = (state: StoreWithXRaygeneratorconfiguration) =>
  selectXRayConfiguration(state).isTelemetry;
export const selectXRayOperators = (state: StoreWithXRaygeneratorconfiguration) =>
  selectXRayConfiguration(state).operators;
export const selectXRaySelectedOperators = (state: StoreWithXRaygeneratorconfiguration) =>
  selectXRayConfiguration(state).selected_operators;

export const attachXRayConfigurationWithLocalStorageSynchronizationObserver = <
  S extends StoreWithXRaygeneratorconfiguration
>(
  store: Store<S>
) => {
  observeStore(
    store,
    selectXRayConfiguration,
    makeSyncReduxWithLocalStorageObserver(XRAY_GENERATOR_LOCAL_STORAGE_KEY)
  );
};

export default xrayConfigurationReducer;
