// apiMiddleware.ts
import {
  SharedUserConfig,
  SharedUserConfigurationAPI,
} from 'app/interfaces/SharedUserConfiguration';
import { StoreWithViewerConfiguration } from 'app/redux/viewerConfiguration/selector';
import type { Action, Dispatch, Middleware, MiddlewareAPI } from 'redux';

interface UserConfigurationAction extends Action {
  syncWithUserConfigurationAPI?: boolean;
}

export type StoreWithSharedUserConfiguration = StoreWithViewerConfiguration;

function convertStoreToUserConfiguration<S extends StoreWithViewerConfiguration>(
  store: MiddlewareAPI<S>
): SharedUserConfig {
  const state = store.getState();
  return {
    noXRayDeletion: state.viewerConfiguration?.noXRayDeletion,
  };
}

export default function createUserConfigurationAPIMiddleware(
  api: SharedUserConfigurationAPI
): Middleware {
  return <S>(store: MiddlewareAPI<S>) =>
    (next: Dispatch<S>) =>
    <A extends Action>(action: A) => {
      // Let the action flow through first
      const result = next(action);

      // Check if this action should trigger API sync
      if ((action as UserConfigurationAction).syncWithUserConfigurationAPI) {
        // Send updated state to API
        const sharedUserConfig = convertStoreToUserConfiguration(
          store as MiddlewareAPI<StoreWithSharedUserConfiguration>
        );
        // Retry mechanism should be implemented here
        api?.update(sharedUserConfig).catch((error) => {
          console.warn('Failed to sync with API:', error);
        });
      }

      return result;
    };
}
