export default class LocalStorageCacheWithExpiry<Item> {
  constructor(private cacheKey: string, private ttl: number) {}

  setItem(key: string, value: Item) {
    const expiry = Date.now() + this.ttl;
    const item = [value, expiry];
    localStorage.setItem(`${this.cacheKey}_${key}`, JSON.stringify(item));
  }

  getItem(key: string): Item | null {
    const itemStr = localStorage.getItem(`${this.cacheKey}_${key}`);
    if (!itemStr) return null;

    const [value, expiry] = this.parseEntry(itemStr);
    if (Date.now() > expiry) {
      localStorage.removeItem(`${this.cacheKey}_${key}`);
      return null;
    }

    return value;
  }

  getAllItems() {
    const items: Item[] = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!key || !key.startsWith(this.cacheKey)) continue;

      const itemStr = localStorage.getItem(key);
      if (!itemStr) continue;

      const [value, expiry] = this.parseEntry(itemStr);
      if (Date.now() > expiry) {
        localStorage.removeItem(key);
        continue;
      }

      items.push(value as Item);
    }
    return items;
  }

  cleanUp() {
    const now = Date.now();
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!key || !key.startsWith(this.cacheKey)) continue;

      const itemStr = localStorage.getItem(key);
      if (!itemStr) continue;

      const [value, expiry] = this.parseEntry(itemStr);
      if (now > expiry) {
        localStorage.removeItem(key);
      }
    }
  }

  private parseEntry(entry: string): [Item, number] {
    try {
      const [value, expiry] = JSON.parse(entry);
      return [value, expiry];
    } catch {
      return [null, 0];
    }
  }
}
