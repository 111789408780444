import { createSelector } from 'reselect';
import { makeSyncReduxWithLocalStorageObserver } from 'app/dataManagement/syncReduxStateWithLocalStorage';
import observeStore from 'app/utils/redux/observeStore';
import { PMSState, StoreWithPMS } from 'app/redux/pms/reducer';

export const selectPMSReducer = (state: StoreWithPMS) => state.pms;

export const makeSelectIsInvalidWatchedDirectory = (subStateSelector = selectPMSReducer) =>
  createSelector(subStateSelector, (pms: PMSState) => pms.isInvalidWatchedDirectory);

export const makeSelectWatchedDirectory = (subStateSelector = selectPMSReducer) =>
  createSelector(subStateSelector, (pms: PMSState) => pms.watchedDirectory);

export const selectPMSWatchedDirectory = makeSelectWatchedDirectory();
export const selectPMSType = (state: StoreWithPMS) => selectPMSReducer(state).type;
export const selectPMSExportConfiguration = (state: StoreWithPMS) =>
  selectPMSReducer(state).exportConfiguration;

export const attachSyncPMSStateWithLocalStorageObserver = (store: StoreWithPMS) => {
  observeStore(store, selectPMSReducer, makeSyncReduxWithLocalStorageObserver('redux.pms'));
};
