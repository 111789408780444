/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import parse from 'html-react-parser';
import _ from 'lodash';
import * as pt from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Icon, Label, Modal, Progress } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import ElectronUpdater from 'app/adapters/ElectronUpdater';
import { DESKTOP_VERSIONS } from 'app/constants';
import ApiCalls from 'app/utils/apiCalls';
import { getAppName } from 'app/utils/envUtil';
import './style.scss';
import { selectVersionUpdate } from 'app/redux/versionUpdate/reducer';
import { setLastReleaseUrl, setPatchNotes } from 'app/redux/versionUpdate/actions';
import useDidUpdateEffect from 'app/hooks/useDidUpdateEffect';

const CHECK_INTERVAL = 30 * 60 * 1000;

function PatchNoteDisplay({ version, intlContent }) {
  const intl = useIntl();
  const content = intlContent[intl.locale] ?? intlContent.en;
  if (!content) return null;

  const htmlContent = useMemo(() => parse(content), [content]);
  return (
    <div>
      <h3>
        <FormattedMessage id="update.patch_notes.title" values={{ version }} />
      </h3>
      {htmlContent}
    </div>
  );
}

const compareIntTuple = (t1, t2) => {
  const length = Math.max(t1.length, t2.length);
  for (let i = 0; i < length; i += 1) {
    const v1 = t1[i] ?? 0;
    const v2 = t2[i] ?? 0;
    if (v1 < v2) return -1;
    if (v1 > v2) return 1;
  }
  return 0;
};

const compareVersions = ([version1], [version2]) =>
  -compareIntTuple(
    version1.split('.').map((v) => parseInt(v, 10)),
    version2.split('.').map((v) => parseInt(v, 10))
  );

const orderPatchNotesByVersion = (patchNotes) => {
  const patchesNotesEntries = _.entries(patchNotes);
  patchesNotesEntries.sort(compareVersions);
  return patchesNotesEntries;
};

/**
 *
 * @param {Object} props
 * @param {Updater} props.updater
 */
function UpdateButton({ updater }) {
  if (!DESKTOP_VERSIONS.includes(getAppName())) {
    return null;
  }
  const { releaseUrl, patchNotes } = useSelector(selectVersionUpdate) ?? {};
  const dispatch = useDispatch();

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(undefined);
  const [updateLaunched, setUpdateLaunched] = useState(false);
  const [isDownloadError, setIsDownloadError] = useState(false);
  const isDownloadInProgress = useRef(false);

  const downloadUpdate = () => {
    if (isDownloadInProgress.current) return;

    isDownloadInProgress.current = true;
    setDownloadProgress(0);
    setIsDownloadError(false);
    updater
      .download({
        url: releaseUrl,
        onProgress: (_event, progress) => setDownloadProgress(progress.percent),
      })
      .then((downloadFile) => {
        setUpdateLaunched(true);
        updater.launchUpdate(downloadFile);
      })
      .catch(() => setIsDownloadError(true))
      .finally(() => {
        setDownloadProgress(undefined);
        isDownloadInProgress.current = false;
      });
  };

  const checkUpdateInfo = () => {
    const checkInfo = () =>
      ApiCalls.checkUpdateInfo()
        .then((response) => {
          const { version, patch_notes, release_url } = response.data;
          if (version === 'latest') return;
          dispatch(setPatchNotes(patch_notes));
          dispatch(setLastReleaseUrl(release_url));
        })
        .catch(() => {});

    checkInfo();

    const interval = setInterval(checkInfo, CHECK_INTERVAL);

    return () => clearInterval(interval);
  };

  useEffect(checkUpdateInfo, []);

  useDidUpdateEffect(() => {
    if (releaseUrl === undefined) return;
    setIsUpdateModalOpen(true);
  }, [releaseUrl]);

  if (releaseUrl === undefined) return null;

  const orderedPatchNotes = orderPatchNotesByVersion(patchNotes);

  return (
    <Modal
      onClose={() => setIsUpdateModalOpen(false)}
      onOpen={() => setIsUpdateModalOpen(true)}
      open={isUpdateModalOpen}
      closeIcon={<Icon name="close" data-testid="update-close" />}
      trigger={
        <Button color="blue" size="mini">
          <FormattedMessage id="dropzone.helpMessages.newVersion.download" />
        </Button>
      }
    >
      <Modal.Header className="flex center">
        <FormattedMessage id="dropzone.helpMessages.newVersion.header" />
      </Modal.Header>
      <Modal.Content className="update-button__modal__content">
        {orderedPatchNotes.map(([version, patchNote]) => (
          <PatchNoteDisplay key={version} version={version} intlContent={patchNote} />
        ))}
      </Modal.Content>
      <Modal.Actions className="flex center column">
        {downloadProgress !== undefined && (
          <Progress
            percent={downloadProgress * 100}
            precision={0}
            progress
            indicating
            style={{ width: '100%' }}
          >
            <FormattedMessage id="download.in_progress" />
          </Progress>
        )}
        <div className="flex column center" style={{ gap: '2px' }}>
          {isDownloadError && (
            <Label basic color="red">
              <FormattedMessage id="update.download_failure" />
            </Label>
          )}
          <Button onClick={downloadUpdate} color="blue">
            {(downloadProgress !== undefined || updateLaunched) && <Icon name="sync" loading />}
            {updateLaunched ? (
              <FormattedMessage id="update.update_ongoing" />
            ) : (
              <FormattedMessage id="update.launch_update" />
            )}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
}

UpdateButton.propTypes = {
  updater: pt.shape({}),
};

UpdateButton.defaultProps = {
  updater: new ElectronUpdater(),
};

export default UpdateButton;
