/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import ApiCalls from 'app/utils/apiCalls';
import AppHelmet from 'app/components/AppHelmet';
import IntlCrawlableLink from 'app/components/IntlCrawlableLink';
import { Modal } from 'semantic-ui-react';
import SendRequestForm from 'app/components/Form/SendRequestForm';
import Hook from 'app/components/UIComponents/Hook';
import Button from 'app/components/UIComponents/Button';
import { useSelector } from 'react-redux';
import { selectLocale } from 'app/redux/language/selectors';

import './styles.scss';

import picoxiaLogo from './images/picoxia-dark-grey.svg?url';
import fasLogo from './images/logo-fas.png';
import catImage from './images/cat.png';
import demoImage from './images/demo.png';
import fastIcon from './images/fast-colors.svg?url';
import viewerIcon from './images/icon-viewer.svg?url';
import imagesIcon from './images/icon-images.svg?url';
import reportIcon from './images/icon-report.svg?url';
import vetIcon from './images/icon-vet.svg?url';
import radioIcon from './images/icon-radio.svg?url';
import radiosInDatabaseIcon from './images/icon-radiosindatabase.svg?url';
import cheatSheetImage from './images/cheatsheet.png';
import thoraxImage from './images/thorax.svg?url';
import bassinImage from './images/bassin.svg?url';
import abdomenImage from './images/abdomen.svg?url';
import radioIconWhite from './images/icon-radio-white.svg?url';
import downloadIconWhite from './images/icon-download-white.svg?url';
import webIconWhite from './images/icon-web-white.svg?url';
import arrowImage from './images/arrow.svg?url';
import languedociaPartnersLogo from './images/partners/logo-languedocia.png';
import gerPartnersLogo from './images/partners/logo-ger.png';
import examionPartnersLogo from './images/partners/logo-examion.png';
// import digivetPartnersLogo from './images/partners/logo-digivet.png';

const scientificArticles = [
  {
    title: 'home.scientificValidation.veterinaryRadiology2020Title',
    journal: 'home.scientificValidation.veterinaryRadiology2020Journal',
    date: '2020-09-29',
    picture: 'logo-veterinary-radiology.jpg',
    link: 'https://onlinelibrary.wiley.com/doi/abs/10.1111/vru.12912',
  },
  {
    title: 'home.scientificValidation.veterinaryFrontiers2021Title',
    journal: 'home.scientificValidation.veterinaryFrontiers2021Journal',
    date: '2021-12-09',
    picture: 'logo-frontiers-in-veterinary-science.jpg',
    link: 'https://pubmed.ncbi.nlm.nih.gov/34957280/',
  },
  {
    title: 'home.scientificValidation.veterinaryRadiology2022Title',
    journal: 'home.scientificValidation.veterinaryRadiology2022Journal',
    date: '2022-02-08',
    picture: 'logo-veterinary-radiology.jpg',
    link: 'https://onlinelibrary.wiley.com/doi/10.1111/vru.13069',
  },
];

const HomePage = () => {
  const intl = useIntl();
  const locale = useSelector(selectLocale);

  const [usersNumber, setUsersNumber] = useState(0);
  const [imagesNumber, setImagesNumber] = useState(0);
  const [modalRequestQuotationIsOpen, setModalRequestQuotationIsOpen] = useState(false);

  useEffect(() => {
    ApiCalls.getUsersNumber().then((response) => {
      setUsersNumber(response.data.number_of_users.toLocaleString());
    });
    ApiCalls.getImagesNumber().then((response) => {
      setImagesNumber(response.data.number_of_images.toLocaleString());
    });
  }, []);

  return (
    <div className="picoxia homepage">
      <AppHelmet
        titleIntlID="home.pageTitle"
        descriptionIntlID="home.pageDescription"
        additionnalMeta={[
          {
            name: 'google-site-verification',
            content: 'UL3bgCuS-t0M9SMZaKNP-2r-6RA7vDTYFK-SLDF6I6I',
          },
        ]}
      />

      {/* HEADER -- start */}
      <section className="pageHeader">
        <div className="pageHeader__left">
          <GetPhoneContact locale={locale} />
          <img src={catImage} alt="La santé animale d'abord !" className="pageHeader__image" />
        </div>
        <div className="pageHeader__right">
          <div className="pageHeader__content">
            <div className="pageHeader__picoxiaFAS">
              <h1 className="pageHeader__picoxiaFAS-picox">Picoxia</h1>
              <img src={picoxiaLogo} alt="" className="pageHeader__logo-picoxia-image" />
              {/* Special for Examion */}
              {locale !== 'de' && (
                <img
                  src={fasLogo}
                  alt="FAS Technology &amp; Diagnostics"
                  className="pageHeader__picoxiaFAS-FAS"
                />
              )}
            </div>
            <h2 className="pageHeader__moto">
              <FormattedMessage id="home.moto" description="Moto" />
            </h2>
            <Button url="/viewer" className="pageHeader__discover">
              <FormattedMessage id="home.tryOut" />
              <span className="ui">
                <i className="right arrow icon" />
              </span>
            </Button>
            <p className="pageHeader__demo">
              <IntlCrawlableLink url="/viewer?mode=demo">
                <FormattedMessage id="home.demo" />
              </IntlCrawlableLink>
            </p>
          </div>
        </div>
      </section>
      {/* HEADER -- end */}

      {/* You will not be alone anymore -- start */}
      <section className="notAlone">
        <div className="container">
          <h2 className="title">
            <FormattedMessage id="home.notAlone" />
          </h2>
          <p className="notAlone__subtitle">
            <FormattedMessage id="home.assistant" />
          </p>
          <div className="notAlone__iframe-container">
            <iframe
              title="{intl.formatMessage({ id: 'home.video.frameTitle' })}"
              src={`https://www.youtube.com/embed/${intl.formatMessage({
                id: 'home.videoId',
              })}?loop=1&amp;rel=0&amp;controls=0`}
              width="640"
              height="400"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              className="notAlone__iframe"
            />
          </div>
        </div>
      </section>
      {/* You will not be alone anymore -- end */}

      {/* Artificial Intelligence designed for Veterinarians -- start */}
      <section className="aiForVets">
        <div className="container">
          <h2 className="title">
            <FormattedMessage id="home.aiForVets" />
          </h2>
          <p className="aiForVets__easy">
            <FormattedMessage id="home.easyToUse" />
          </p>

          <div className="aiForVets__points">
            <div className="aiForVets__point">
              <img src={fastIcon} alt="" className="aiForVets__point-icon" />
              <Hook>
                <FormattedMessage id="home.instantResult" />
              </Hook>
            </div>
            <div className="aiForVets__point">
              <img src={viewerIcon} alt="" className="aiForVets__point-icon" />
              <Hook>
                <FormattedMessage id="home.completeViewer" />
              </Hook>
            </div>
            <div className="aiForVets__point">
              <img src={imagesIcon} alt="" className="aiForVets__point-icon" />
              <Hook>
                <FormattedMessage id="home.allFormat" />
              </Hook>
              <p className="aiForVets__point-formatsDetail">DICOM / JPG / PNG</p>
            </div>
            <div className="aiForVets__point">
              <img src={reportIcon} alt="" className="aiForVets__point-icon" />
              <Hook>
                <FormattedMessage id="home.automaticReport" />
              </Hook>
            </div>
          </div>

          <div className="aiForVets__imageBloc">
            <span className="aiForVets__ribbon aiForVets__ribbon--green ui green ribbon label">
              <FormattedMessage id="home.memos" />
            </span>
            <span className="aiForVets__ribbon aiForVets__ribbon--blue ui blue ribbon label">
              <FormattedMessage id="home.confidenceScore" />
            </span>
            <img
              className="aiForVets__image"
              src={demoImage}
              alt={intl.formatMessage({ id: 'home.demo.alt' })}
            />
          </div>

          <div className="aiForVets__buttons">
            <Button url="/request-demo" size="big">
              <FormattedMessage id="home.aiForVets.requestDemo" />
            </Button>
          </div>
        </div>
      </section>
      {/* Artificial Intelligence designed for Veterinarians -- end */}

      {/* Processed anatomic regions -- start */}
      <div className="separator" />
      <section className="anatomicRegions">
        <div className="container">
          <h2 className="title">
            <FormattedMessage id="home.regions.title" />
          </h2>

          <div className="anatomicRegions__list">
            <div className="anatomicRegions__region">
              <div className="anatomicRegions__iconBloc">
                <img
                  className="anatomicRegions__icon"
                  src={abdomenImage}
                  alt={intl.formatMessage({ id: 'home.regions.abdomen' })}
                />
              </div>
              <Hook as="h3" size="big">
                <FormattedMessage id="home.regions.abdomen" />
              </Hook>
              <ul className="anatomicRegions__details">
                <li>
                  <FormattedMessage id="home.regions.detectedPatterns" values={{ n: '13' }} />
                </li>
                <li>
                  <FormattedMessage id="home.regions.differentialDiagnosis" values={{ n: '3' }} />
                </li>
                <li>
                  <FormattedMessage id="home.regions.automaticKidney" values={{ n: '3' }} />
                </li>
                <li>
                  <FormattedMessage id="home.regions.automaticReport" />
                </li>
              </ul>
            </div>
            <div className="anatomicRegions__region">
              <div className="anatomicRegions__iconBloc">
                <img
                  className="anatomicRegions__icon"
                  src={thoraxImage}
                  alt={intl.formatMessage({ id: 'home.regions.thorax' })}
                />
              </div>
              <Hook as="h3" size="big">
                <FormattedMessage id="home.regions.thorax" />
              </Hook>
              <ul className="anatomicRegions__details">
                <li>
                  <FormattedMessage id="home.regions.detectedPatterns" values={{ n: '16' }} />
                </li>
                <li>
                  <FormattedMessage id="home.regions.differentialDiagnosis" values={{ n: '4' }} />
                </li>
                <li>
                  <FormattedMessage id="home.regions.automaticVHS" />
                </li>
                <li>
                  <FormattedMessage id="home.regions.automaticReport" />
                </li>
              </ul>
            </div>
            <div className="anatomicRegions__region">
              <div className="anatomicRegions__iconBloc">
                <img
                  className="anatomicRegions__icon"
                  src={bassinImage}
                  alt={intl.formatMessage({ id: 'home.regions.hipDysplasia' })}
                />
              </div>
              <Hook as="h3" size="big">
                <FormattedMessage id="home.regions.hipDysplasia" />
              </Hook>
              <ul className="anatomicRegions__details anatomicRegions__details--last">
                <li>
                  <FormattedMessage id="home.regions.detectedPatterns" values={{ n: '5' }} />
                </li>
                <li>
                  <FormattedMessage id="home.regions.automaticNorbergOlsson" />
                </li>
                <li>
                  <FormattedMessage id="home.regions.automaticReport" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="separator" />
      {/* Processed anatomic regions -- end */}

      {/* Picoxia in figures -- start */}
      <section className="picoxiaFigures">
        <div className="container">
          <h2 className="title">
            <FormattedMessage id="home.numbers.title" />
          </h2>
          <div className="picoxiaFigures__numbers">
            <div className="picoxiaFigures__numberBloc">
              <img src={radiosInDatabaseIcon} alt="" className="picoxiaFigures__icon" />
              <p>
                <span className="picoxiaFigures__number">250 000</span>
                <FormattedMessage id="home.numbers.images" />
              </p>
            </div>
            <div className="picoxiaFigures__numberBloc">
              <img src={vetIcon} alt="" className="picoxiaFigures__icon" />
              <p>
                <span className="picoxiaFigures__number">{usersNumber}</span>{' '}
                <FormattedMessage id="home.numbers.users" />
              </p>
            </div>
            <div className="picoxiaFigures__numberBloc">
              <img src={radioIcon} alt="" className="picoxiaFigures__icon" />
              <p>
                <span className="picoxiaFigures__number">{imagesNumber}</span>{' '}
                <FormattedMessage id="home.numbers.images.processed" />
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Picoxia in figures -- end */}

      {/* Discover our Cheat Sheet -- start */}
      <div className="separator" />
      <section className="cheatSheets">
        <div className="container">
          <h2 className="title">
            <FormattedMessage id="home.sheet.title" />
          </h2>
          <p className="cheatSheets__description">
            <FormattedMessage id="home.sheet.description" />
          </p>
          <div className="cheatSheets__image-container">
            <img
              src={cheatSheetImage}
              alt={intl.formatMessage({ id: 'home.sheet.image.alt' })}
              className="cheatSheets__image"
            />
          </div>
          <div className="cheatSheets__buttons">
            <Button url="/cheat_sheet" size="big">
              <FormattedMessage id="home.discover" />
            </Button>
          </div>
        </div>
      </section>
      <div className="separator" />
      {/* Discover our Cheat Sheet -- end */}

      {/* An app that integrates to your workflow -- start */}
      <section className="workflowIntegration">
        <div className="container">
          <h2 className="title">
            <FormattedMessage id="home.integrated" />
          </h2>
          <div className="workflowIntegration__content">
            <div className="workflowIntegration__arrow">
              <img src={arrowImage} alt="" className="workflowIntegration__arrow-img" />
            </div>
            <div className="workflowIntegration__list">
              <div className="workflowIntegration__card">
                <div className="workflowIntegration__illustration">
                  <img src={webIconWhite} alt="" />
                </div>
                <div className="workflowIntegration__card-content">
                  <div>
                    <Hook as="h3">
                      <FormattedMessage id="home.integrated.websiteAccess" />
                    </Hook>
                    <p>
                      <FormattedMessage id="home.integrated.websiteAccess.noInstallation" />
                      <br />
                      <FormattedMessage id="home.integrated.websiteAccess.history" />
                    </p>
                  </div>
                  <div className="workflowIntegration__card-buttons">
                    <Button url="/viewer">
                      <FormattedMessage id="home.integrated.useOnline" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="workflowIntegration__card">
                <div className="workflowIntegration__illustration">
                  <img src={downloadIconWhite} alt="" />
                </div>
                <div className="workflowIntegration__card-content">
                  <div>
                    <Hook as="h3">
                      <FormattedMessage id="home.integrated.picoxiaDesktop" />
                    </Hook>
                    <p>
                      <FormattedMessage id="home.integrated.picoxiaDesktop.moreFeatures" />
                    </p>
                  </div>
                  <div className="workflowIntegration__card-buttons">
                    <Button url="/download">
                      <FormattedMessage id="home.integrated.download" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="workflowIntegration__card">
                <div className="workflowIntegration__illustration">
                  <img src={radioIconWhite} alt="" />
                </div>
                <div
                  className="workflowIntegration__card-content
                    workflowIntegration__card-content--last"
                >
                  <div>
                    <Hook as="h3">
                      <FormattedMessage id="home.integrated.acquisitionSoftware" />
                    </Hook>
                    <p>
                      <FormattedMessage id="home.integrated.acquisitionSoftware.analysedImmediately" />
                    </p>
                  </div>
                  <div className="workflowIntegration__card-buttons">
                    <Modal
                      closeIcon
                      onClose={() => setModalRequestQuotationIsOpen(false)}
                      onOpen={() => setModalRequestQuotationIsOpen(true)}
                      open={modalRequestQuotationIsOpen}
                      trigger={
                        <Button action>
                          <FormattedMessage id="home.integrated.requestQuote" />
                        </Button>
                      }
                    >
                      <Modal.Header>
                        <FormattedMessage id="home.integrated.requestQuote" />
                      </Modal.Header>
                      <Modal.Content>
                        <SendRequestForm requestType="quotation" />
                      </Modal.Content>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* An app that integrates to your workflow -- end */}

      {/* Scientific Validation -- start */}
      <div className="separator" />
      <section className="scientificValidation" id="scientific-validation">
        <div className="container">
          <h2 className="article title">
            <FormattedMessage id="home.scientificValidation.title" />
          </h2>
          <p className="scientificValidation__description">
            <FormattedMessage id="home.scientificValidation.description" />
          </p>
          <div className="scientificValidation__articles-list">
            {scientificArticles.map((article) => {
              const { date } = article;
              const formattedDateValue = new Date(date);
              return (
                <div as="article" className="scientificValidation__article" key={article.title}>
                  <img
                    className="scientificValidation__image"
                    src={require(`./images/${article.picture}`)}
                    alt=""
                  />
                  <div className="scientificValidation__article-content">
                    <h3 className="scientificValidation__title">
                      <FormattedMessage id={article.title} />
                    </h3>
                    <div>
                      <p className="scientificValidation__journal">
                        <FormattedMessage id={article.journal} />
                      </p>
                      <p className="scientificValidation__date">
                        <time dateTime={date}>
                          <FormattedDate
                            value={formattedDateValue}
                            year="numeric"
                            month="long"
                            day="numeric"
                          />
                        </time>
                      </p>
                    </div>
                  </div>
                  <div className="scientificValidation__bottom-container">
                    <a
                      href={article.link}
                      className="scientificValidation__learn-more-link"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <FormattedMessage id="home.scientificValidation.learnMoreLink" />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div className="separator" />
      {/* Scientific Validation -- end */}

      {/* Our Partners -- start */}
      <section className="partners">
        <div className="container">
          <h2 className="title">
            <FormattedMessage id="home.partners.title" />
          </h2>
          <ul className="partners__list">
            <li className="partners__item">
              <a href="http://www.fas-imagerie.com/" target="_blank">
                <img
                  src={fasLogo}
                  alt="FAS Technology &nbsp; Diagnosis"
                  className="partners__image"
                />
              </a>
            </li>
            <li className="partners__item">
              <a href="https://www.ger-int.com/" target="_blank">
                <img src={gerPartnersLogo} alt="GER Made in France" className="partners__image" />
              </a>
            </li>
            <li className="partners__item">
              <a href="https://www.veterinaire-languedocia.com/" target="_blank">
                <img
                  src={languedociaPartnersLogo}
                  alt="Centre Hospitalier Vétérinaire Langedocia"
                  className="partners__image"
                />
              </a>
            </li>
            {locale === 'de' && (
              <li className="partners__item">
                <a href="https://www.examion.com/de" target="_blank">
                  <img src={examionPartnersLogo} alt="EXAMION" className="partners__image" />
                </a>
              </li>
            )}

            {/* <li className="partners__item">
              <a href="https://www.digivet.fr/" target="_blank">
                <img src={digivetPartnersLogo} alt="DIGIVet" className="partners__image" />
              </a>
            </li> */}
          </ul>
        </div>
      </section>
      {/* Our Partners -- end */}
    </div>
  );
};

function GetPhoneContact({ locale }) {
  const href = locale === 'de' ? '+4978519567093' : '+33160861717';
  const text = locale === 'de' ? '+49 (0) 7851 95 67 093' : '+33 (0)1 60 86 17 17';

  return (
    <p className="pageHeader__contactUs">
      <FormattedMessage id="home.contactus" description="Contact" />{' '}
      <a href={`tel:${href}`} style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
        {text}
      </a>
    </p>
  );
}

export default HomePage;
