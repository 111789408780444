/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import * as PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import 'app/styles/style.scss';
import { connect } from 'react-redux';
import { Modal, Button, Form, Checkbox, Icon } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactDatePicker from 'react-datepicker';
import ApiCalls from '../../utils/apiCalls';
import FormattedServerError from '../FormattedServerError';
import { selectRoute } from 'app/redux/route/reducer';

class SubscriptionModal extends React.Component {
  constructor(props) {
    super(props);

    this.user_mail = this.props.user.mail;
    this.state = {
      isModalOpen: false,
      isFormLoading: false,
      errorMessage: null,
      startDate: new Date(),
      endDate: new Date(),
      sendMail: true,
    };
  }

  setStartDate = (date) => this.setState({ startDate: date });

  setEndDate = (date) => this.setState({ endDate: date });

  renderNewSubscriptionFormActions = () => {
    const { intl, user } = this.props;
    // const { is_demo_used: isDemoUsed } = user;
    if (this.state.success) {
      return (
        <Modal.Actions>
          <Button positive>
            <FormattedMessage id="adminPage.modal.confirmButton.success" />
          </Button>
        </Modal.Actions>
      );
    }
    if (this.state.isFormLoading) {
      return (
        <Modal.Actions>
          <Button positive loading />
        </Modal.Actions>
      );
    }
    return (
      <Modal.Actions>
        <Button basic color="red" onClick={this.closeModal}>
          <Icon name="times" />
          <FormattedMessage id="general.cancel" />
        </Button>
        <Button onClick={this.handleSubmitNewSubscription} positive>
          <Icon name="plus" />
          <FormattedMessage id="account.protocols.add" />
        </Button>
      </Modal.Actions>
    );
  };

  sendSubscription = ({
    mail,
    subscriptionName,
    subscriptionPrice,
    start,
    end,
    sendMail,
    isDemo = false,
  } = {}) => {
    this.setState({ errorMessage: null, isFormLoading: true, success: false });

    return ApiCalls.addSubscription({
      mail,
      subscriptionName,
      price: subscriptionPrice ? subscriptionPrice * 100 : 0,
      start,
      end,
      sendMail,
      isDemo,
    })
      .then(() => {
        this.setState({ isFormLoading: false, success: true });
        setTimeout(() => {
          const { onSubscriptionAdded } = this.props;
          this.setState({ success: false, isModalOpen: false });
          onSubscriptionAdded();
        }, 1000);
      })
      .catch((response) => {
        this.setState({ errorMessage: response, isFormLoading: false });
      });
  };

  handleSubmitNewSubscription = () => {
    const mail = this.user_mail;
    const { subscriptionName, subscriptionPrice, startDate, endDate, sendMail } = this.state;

    this.sendSubscription({
      mail,
      subscriptionName,
      subscriptionPrice,
      start: startDate,
      end: endDate,
      sendMail,
    });
  };

  renderErrorMessage = () => {
    if (this.state.errorMessage) {
      return (
        <span style={{ color: '#cc0000' }}>
          <FormattedServerError error={this.state.errorMessage} />
        </span>
      );
    }
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, errorMessage: null });
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleChange = (event, fieldName) => {
    const stateUpdate = {};
    stateUpdate[fieldName] = event.target.value;
    this.setState(stateUpdate);
  };

  render() {
    const { intl } = this.props;
    const { startDate, endDate, isModalOpen } = this.state;

    return (
      <Modal
        onClose={this.closeModal}
        onOpen={this.openModal}
        open={isModalOpen}
        trigger={
          <Button size="mini" color="green">
            <Icon name="plus" />
            <FormattedMessage id="mgmt.newSubscription" />
          </Button>
        }
      >
        <Modal.Header>
          <FormattedMessage id="mgmt.newSubscription" />
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmitNewSubscription}>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>
                  <FormattedMessage id="mgmt.subscriptionName" />
                </label>
                <Form.Input
                  placeholder={intl.formatMessage({ id: 'mgmt.subscriptionName' })}
                  name="subscriptionName"
                  onChange={(e) => this.handleChange(e, 'subscriptionName')}
                />
              </Form.Field>

              <Form.Field>
                <label>
                  <FormattedMessage id="mgmt.monthlyPrice" />
                </label>
                <Form.Input
                  type="number"
                  placeholder={intl.formatMessage({ id: 'mgmt.monthlyPrice' })}
                  name="subscriptionPrice"
                  onChange={(e) => this.handleChange(e, 'subscriptionPrice')}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>
                  <FormattedMessage id="adminPage.addIALicense.input.start" />
                </label>
                <ReactDatePicker
                  dateFormat="P"
                  locale={intl.locale}
                  selected={startDate}
                  onSelect={this.setStartDate}
                  onChange={this.setStartDate}
                  showYearDropdown
                  scrollableYearDropdown
                />
              </Form.Field>
              <Form.Field required>
                <label>
                  <FormattedMessage id="mgmt.subscription.endDate" />
                </label>
                <ReactDatePicker
                  dateFormat="P"
                  locale={intl.locale}
                  selected={endDate}
                  onSelect={this.setEndDate}
                  onChange={this.setEndDate}
                  showYearDropdown
                  scrollableYearDropdown
                  minDate={startDate}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <Checkbox
                  toggle
                  label={intl.formatMessage({ id: 'mgmt.sendMail' })}
                  defaultChecked
                  onChange={() => this.setState(({ sendMail }) => ({ sendMail: !sendMail }))}
                />
              </Form.Field>
            </Form.Group>
          </Form>
          {this.renderErrorMessage()}
        </Modal.Content>
        {this.renderNewSubscriptionFormActions()}
      </Modal>
    );
  }
}

SubscriptionModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    route: selectRoute(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SubscriptionModal));
