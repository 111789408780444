import produce from 'immer';
import { SET_IMAGE_ID, SET_STUDY_ID, setImageId, setStudyId } from './actions';

interface CurrentStudyInfosState {
  studyId?: string;
  imageId?: string;
}

const currentStudyInfosState: CurrentStudyInfosState = {
  studyId: undefined,
  imageId: undefined,
};

type CurrentStudyInfosAction = ReturnType<typeof setStudyId> | ReturnType<typeof setImageId>;

const currentStudyInfosReducer = (
  state = currentStudyInfosState,
  { type, payload }: CurrentStudyInfosAction
) => {
  return produce(state, (draft) => {
    switch (type) {
      case SET_STUDY_ID:
        draft.studyId = payload.studyId;
        break;
      case SET_IMAGE_ID:
        draft.imageId = payload.imageId;
        break;
    }
  });
};

export const selectCurrentStudyInfos = (state: any) => state.currentStudyInfos;
export const selectCurrentStudyId = (state: any) => state.currentStudyInfos.studyId;
export const selectCurrentImageId = (state: any) => state.currentStudyInfos.imageId;

export default currentStudyInfosReducer;
