import React from 'react';
import { Icon, Label, Input } from 'semantic-ui-react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { selectLoggedIn } from 'app/redux/global/selectors';

const InputDate = React.forwardRef(
  ({ onChange, placeholder, value, isSecure, id, onClick }, ref) => (
    <div className="inputDate" ref={ref}>
      <Input
        inverted
        className="inputDate"
        icon="calendar"
        iconPosition="left"
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        id={id}
        onClick={onClick}
        transparent
      />
    </div>
  )
);

class DateSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = { date: null };
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  render() {
    const { intl } = this.props;
    return (
      <DatePicker
        selectsEnd={this.props.type == 'end' ? true : false}
        selectsStart={this.props.type == 'start' ? true : false}
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        calendarClassName="calendarClass"
        selected={this.state.date}
        dateFormat="P"
        locale={intl.locale}
        customInput={<InputDate />}
        onSelect={(date) => {
          this.props.onChangeDate(date);
          this.setState({ date: date });
        }}
        onChange={(date) => {
          if (date == null) {
            this.setState({ date: null });
          }
          this.props.onChangeDate(date);
        }}
        placeholderText={
          this.props.type === 'start'
            ? this.formatMessage({ id: 'date.from' })
            : this.formatMessage({ id: 'date.to' })
        }
        showYearDropdown
        scrollableYearDropdown
        isClearable
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: selectLoggedIn(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DateSearch));
