import 'app/styles/style.scss';
import './style.scss';

import React, { useContext, useState } from 'react';
import { Button, Grid, Icon, Label } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import {
  IsPACSAssociatedContext,
  PACSCommunicationContext,
} from 'app/components/PACSCommunicationProvider';
import PACSConfigurationModal from 'app/containers/AccountPage/PACSConfigurationModal';
import ErrorMessage from '../../components/PACSErrorMessage';
import TimeoutPromise from 'app/utils/TimeoutPromise';
import MinDurationPromise from 'app/utils/MinDurationPromise';
import useCancellablePromises from 'app/hooks/useCancelablePromises';
import { useSelector } from 'react-redux';
import { selectPACSConfiguration } from 'app/redux/PACSConfiguration/reducer';
import { useDispatch } from 'react-redux';
import { setPACSEnabled } from 'app/redux/PACSConfiguration/actions';
import { useCallback } from 'react';

const ASSOCIATION_STATUS_OPTION = {
  unknown: { testId: 'pacs.association_status.unknown', icon: 'question', color: 'orange' },
  ongoing: {
    testId: 'pacs.association_status.ongoing',
    icon: 'sync',
    color: 'orange',
    loading: true,
  },
  connected: { testId: 'pacs.association_status.connected', icon: 'check', color: 'green' },
  notConnected: {
    testId: 'pacs.association_status.not_connected',
    icon: 'times',
    color: 'red',
  },
};

const AssociationStatus = ({ isTestOngoing }) => {
  const isPACSAssociated = useContext(IsPACSAssociatedContext);

  let options;
  if (isTestOngoing) {
    options = ASSOCIATION_STATUS_OPTION.ongoing;
  } else if (isPACSAssociated === undefined) {
    options = ASSOCIATION_STATUS_OPTION.unknown;
  } else if (isPACSAssociated) {
    options = ASSOCIATION_STATUS_OPTION.connected;
  } else {
    options = ASSOCIATION_STATUS_OPTION.notConnected;
  }

  return (
    <div className="pacs-configuration-row__status-group__status">
      <div className={`pacs-configuration-row__status-group__status__title ${options.color}`}>
        <FormattedMessage id="pacs.association_status.status.title" />
      </div>
      <div className={`pacs-configuration-row__status-group__status__state ${options.color}`}>
        <Icon className="picoxia-fix" name={options.icon} loading={options.loading} />
        <FormattedMessage id={options.testId} />
      </div>
    </div>
  );
};

const PACSConfigurationRow = ({ testMinimumDuration = 1000 }) => {
  const pacsCommunication = useContext(PACSCommunicationContext);
  if (!pacsCommunication) return null;

  const [isTestOngoing, setIsTestOngoing] = useState(false);
  const [testError, setTestError] = useState(undefined);
  const cancellablePromises = useCancellablePromises();
  const pacsConfiguration = useSelector(selectPACSConfiguration);
  const dispatch = useDispatch();
  const pacsEnabled = pacsConfiguration.enabled;
  const toggleConfiguration = useCallback(() => {
    dispatch(setPACSEnabled(!pacsEnabled));
    if (pacsEnabled) pacsCommunication.release();
  }, [dispatch, pacsEnabled]);

  const testCommunication = () => {
    setIsTestOngoing(true);
    setTestError(undefined);
    cancellablePromises(MinDurationPromise(pacsCommunication.echo(), testMinimumDuration))
      .then(() => setIsTestOngoing(false))
      .catch((error) => {
        if (error.isCanceled) return;
        setTestError(error);
        setIsTestOngoing(false);
      });
  };

  return (
    <Grid.Row>
      <Grid.Column>
        <FormattedMessage id="account.configuration.pacs" />
      </Grid.Column>
      <Grid.Column>
        <div className="pacs-configuration-row__status-group">
          <AssociationStatus isTestOngoing={isTestOngoing} />
          <PACSConfigurationModal />
          <Button
            size="small"
            disabled={pacsEnabled === undefined}
            onClick={toggleConfiguration}
            className={`pacs-configuration-row__enable-button pacs-configuration-row__enable-button--${
              pacsEnabled === true ? 'disable' : 'enable'
            }`}
          >
            {pacsEnabled === true ? (
              <FormattedMessage id="account.configuration.pacs.disable" />
            ) : (
              <FormattedMessage id="account.configuration.pacs.enable" />
            )}
          </Button>
          <Button
            primary
            onClick={testCommunication}
            loading={isTestOngoing}
            disabled={isTestOngoing}
            size="tiny"
          >
            <FormattedMessage id="account.configuration.pacs.test" />
          </Button>
        </div>
        <ErrorMessage error={testError} titleID="pacs.message.test_error.title" />
      </Grid.Column>
    </Grid.Row>
  );
};

export default PACSConfigurationRow;
