import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

import MinimalButton from '../../components/MinimalButton';
import auth from '../auth';
import { REQUEST_ERROR, SET_AUTH } from 'app/redux/global/actions';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import FormattedServerError, { errorType } from '../../components/FormattedServerError';
import { selectLoginError } from 'app/redux/global/selectors';
const Error = styled.h4`
  color: #cc0000;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
`;

export class UnsubscribePage extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {
      unsubscribe: false,
    };
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  onSubmit = (event) => {
    event.preventDefault();
    const token = new URLSearchParams(this.props.location.search).get('token');
    auth.unsubscribeMail(token).then((response) => {
      if (response !== 'undefined' && response.error) {
        this.props.dispatch({ type: REQUEST_ERROR, error: response.error });
      } else {
        this.props.dispatch({ type: SET_AUTH, newAuthState: true });
        this.setState({ unsubscribe: true });
      }
    });
  };

  renderBody = () => {
    if (this.state.unsubscribe) {
      return this.renderSuccess();
    } else {
      return this.renderForm();
    }
  };

  renderSuccess = () => {
    return (
      <div style={{ marginTop: '10%', textAlign: 'center' }}>
        <h1>
          <FormattedMessage id="unsubscribeMail.success.title" />
        </h1>
        <span style={{ fontSize: '1.5em' }}>
          <br />
          <FormattedMessage id="unsubscribeMail.success" />
        </span>
      </div>
    );
  };

  renderForm = () => {
    return (
      <div>
        <Form className="ui form" onSubmit={this.onSubmit}>
          <h1 style={{ marginTop: '20vh' }}>
            <FormattedMessage id="unsubscribeMail.title" />
          </h1>
          <br />
          <h3>
            <FormattedMessage id="unsubscribeMail.pageDescription" />
          </h3>
        </Form>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          {this.props.loginError && (
            <Error>
              <FormattedServerError error={this.props.loginError} />
            </Error>
          )}
          <MinimalButton
            className="ui button "
            type="submit"
            value={this.formatMessage({ id: 'forms.send' })}
            onClick={this.onSubmit}
          />
        </div>
      </div>
    );
  };

  render() {
    return this.renderBody();
  }
}

UnsubscribePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loginError: PropTypes.shape(errorType),
};

UnsubscribePage.defaultProps = {
  loginError: null,
};

const mapStateToProps = createStructuredSelector({
  loginError: selectLoginError,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UnsubscribePage));
