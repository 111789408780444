import PizZip from 'pizzip';

// @ts-ignore
import docxtemplater from 'docxtemplater';
// @ts-ignore
import ImageModule from 'docxtemplater-image-module-free';
import * as _ from 'lodash';

import { getReport, splitReport } from 'app/utils/reports/reportsGenerator';
import downloadFile from 'app/utils/downloadFile';
import ApiCalls from 'app/utils/apiCalls';
import { ageToString } from 'app/components/AgeFromDate';
// import ImageModule from "open-docxtemplater-image-module"
// import ImageModule from "open-docxtemplater-image-module-free"
// var ImageModule = require('open-docxtemplater-image-module');
import ReportCanvas from 'static/canevas-compte-rendu-generic.docx';
import { IntlShape } from 'react-intl';
import { Patient } from 'app/interfaces/Patient';
import { ImageForReport } from 'app/utils/reports/types';
import { EncodableImage } from 'app/interfaces/IDisplayableImageEncoder';
import { DisplayableImageEncoder } from 'app/adapters/DisplayableImageEncoder';
import logger from 'app/utils/debug/logger';
import { isWorkListImage } from 'app/utils/isWorkListImage';

const DOCX_IMAGE_PPI = 96;
const HALF_PAGE_WIDTH_PIXELS = Math.floor(3.23 * DOCX_IMAGE_PPI);

type ReportImage = {
  width: number;
  height: number;
  forceHeight?: number;
  forceWidth?: number;
  imageData: ArrayBuffer;
};
type ReportImagesStore = {
  [tag: string]: ReportImage;
};

export type DisplayableImageForReport = ImageForReport & EncodableImage;

type ReportClinicLogo = {
  width: number;
  height: number;
  base64: string;
};

export type ReportStudy = {
  creationDate?: Date;
  comment?: string;
  images: DisplayableImageForReport[];
  animal?: Patient;
};

const separator = '@=@=@=@=@=@=@=@=@=@=@=@=@=';

const DOCX_TRANSLATION_KEYS = [
  'title',
  'clinic',
  'email',
  'patient',
  'owner',
  'animal_name',
  'species',
  'size',
  'age',
  'sex',
  'weight',
  'radiographic_findings',
  'conclusion',
  'recommendations',
  'x-ray_images',
];

function getOutputFileName(animalName: string, ownerName: string) {
  let outputFileName = 'PicoxIA_';
  const date = new Date();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const dateIdentifier = date.getFullYear() + month + day + date.getHours() + date.getMinutes();
  outputFileName += dateIdentifier;
  if (ownerName) {
    outputFileName += `_${ownerName}`;
  }
  if (animalName) {
    outputFileName += `_${animalName}`;
  }
  return `${outputFileName}.docx`;
}

function base64DataURLToArrayBuffer(dataURL: string) {
  //  console.log(dataURL)
  const base64Regex = /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
  if (!base64Regex.test(dataURL)) {
    return null;
  }
  const stringBase64 = dataURL.replace(base64Regex, '');
  let binaryString;
  if (typeof window !== 'undefined') {
    binaryString = window.atob(stringBase64);
  } else {
    binaryString = new Buffer(stringBase64, 'base64').toString('binary');
  }
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes.buffer;
}

const createImageModule = (imagesStore: ReportImagesStore) =>
  new ImageModule({
    getImage: (tag: any, tagName: string) => imagesStore[tagName].imageData,
    getSize: (imgBuffer: any, tag: any, tagName: string) => {
      const { height, width, forceWidth, forceHeight } = imagesStore[tagName];
      if (forceHeight) {
        const ratio = forceHeight / height;
        const newWidth = Math.round(width * ratio);
        return [newWidth, forceHeight];
      } else if (forceWidth) {
        const ratio = forceWidth / width;
        const newHeight = Math.round(height * ratio);
        return [forceWidth, newHeight];
      } else {
        return [width, height];
      }
    },
  });

const preloadImage = async (image: EncodableImage, intl: IntlShape) => {
  try {
    const imageEncoder = new DisplayableImageEncoder(intl);

    const imageBlob = await imageEncoder.toJPEG(image.image, {
      viewport: image.viewport,
      annotations: image.annotations,
      metadata: image.metadata,
      height: 720,
      width: 720,
      quality: 0.95,
    });

    const arrayBuffer = await imageBlob.arrayBuffer();
    return { width: imageBlob.width, height: imageBlob.height, arrayBuffer };
  } catch (e) {
    logger.error('Failed to convert image to jpeg', e);
  }
  return undefined;
};

const getSpecieString = (study: ReportStudy, intl: IntlShape) => {
  let specie = study?.animal?.specie;
  if (!specie) {
    const catConfidences = _(study.images)
      .map(({ predictions }) => ('chat' in predictions ? predictions?.chat : undefined))
      .reject(_.isUndefined)
      .value();
    const catConfidence = catConfidences[0];
    if (catConfidence !== undefined) {
      if (catConfidence > 0.9) specie = 'cat';
      if (catConfidence < 0.1) specie = 'dog';
    }
  }
  if (!specie) return '';
  return intl.formatMessage({ id: `patient_info.specie.${specie}`, defaultMessage: specie });
};

type ReportProps = {
  header?: string;
  compteRendu?: string;
  conclusion?: string;
  recommandations?: string;
  animalName?: string;
  ownerName?: string;
  specie?: string;
  clinicInfo?: string;
  clinicMail?: string;
  animalSex?: string;
  animalWeight?: string;
  animalSize?: string;
  animalAge?: string;
  [key: `docx.${string}`]: string;
  [key: `image${string}`]: string;
  logo?: string;
};

const generateDocxBlob = async (
  report: string,
  animalName: string,
  ownerName: string,
  animalSex: string,
  animalWeight: string,
  animalSize: string,
  animalAge: string,
  specie: string,
  clinicName: string,
  vetName: string,
  userMail: string,
  clinicInfo: string,
  images: DisplayableImageForReport[],
  clinicLogo: ReportClinicLogo,
  intl: IntlShape
): Promise<Blob> => {
  // images should have keys width, height and base64
  const reportData = splitReport(report, intl);
  const data: ReportProps = reportData;
  data.animalName = animalName || '';
  data.ownerName = ownerName || '';
  data.specie = specie || '';
  data.clinicInfo = clinicInfo || '';
  data.clinicMail = userMail || '';
  data.animalSex = animalSex;
  data.animalWeight = animalWeight;
  data.animalSize = animalSize;
  data.animalAge = animalAge;
  DOCX_TRANSLATION_KEYS.forEach((key) => {
    data[`docx.${key}`] = intl.formatMessage({ id: `docx.${key}` });
  });

  const imagesStore: ReportImagesStore = {};

  if (clinicLogo) {
    imagesStore.logo = {
      width: clinicLogo.width,
      height: clinicLogo.height,
      forceHeight: 150,
      imageData: base64DataURLToArrayBuffer(clinicLogo.base64),
    };
    data.logo = 'logo';
  }
  // Preload all images into promise to make them all available at once.
  const preloadPromises = images.map((img) => preloadImage(img, intl));
  const loadedImagesData = await Promise.all(preloadPromises);

  loadedImagesData.forEach((imageData, index) => {
    if (!imageData) return;
    const imageTag: `image${string}` = `image${index + 1}`;

    imagesStore[imageTag] = {
      width: imageData.width,
      height: imageData.height,
      forceWidth: HALF_PAGE_WIDTH_PIXELS,
      imageData: imageData.arrayBuffer,
    };
    data[imageTag] = imageTag;
  });

  return new Promise((resolve) => {
    const zip = new PizZip(new Uint8Array(ReportCanvas));
    // var zip = PizZip.getBinaryContent(content);
    // var zip = new JSZip(content);
    // var doc = new window.docxtemplater().loadZip(zip)
    const doc = new docxtemplater().loadZip(zip);
    doc.setOptions({ linebreaks: true });
    doc.attachModule(createImageModule(imagesStore)).compile();
    doc.setData(data);
    try {
      // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
      doc.render();
    } catch (err) {
      const e = {
        message: err.message,
        name: err.name,
        stack: err.stack,
        properties: err.properties,
      };
      console.log(JSON.stringify({ error: e }));
      // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
      throw err;
    }
    const out = doc.getZip().generate({
      type: 'blob',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    }); // Output the document using Data-URI
    resolve(out);
  });
};

const generateDocx = async (
  report: string,
  animalName: string,
  ownerName: string,
  animalSex: string,
  animalWeight: string,
  animalSize: string,
  animalAge: string,
  specie: string,
  clinicName: string,
  vetName: string,
  userMail: string,
  clinicInfo: string,
  images: DisplayableImageForReport[],
  clinicLogo: ReportClinicLogo,
  intl: IntlShape
) => {
  const docxBlob = await generateDocxBlob(
    report,
    animalName,
    ownerName,
    animalSex,
    animalWeight,
    animalSize,
    animalAge,
    specie,
    clinicName,
    vetName,
    userMail,
    clinicInfo,
    images,
    clinicLogo,
    intl
  );
  downloadFile(docxBlob, getOutputFileName(animalName, ownerName));
};

const generateDocxBlobFromStudy = async (study: ReportStudy, intl: IntlShape) => {
  const { reportInfo } = (await ApiCalls.getReportInfo(true))?.data ?? {};
  const { creationDate, images } = study;
  // eslint-disable-next-line camelcase
  const { name = '', owner_name = '', sex, birth_date } = study?.animal ?? {};
  const animalAgeString = ageToString(birth_date, intl);
  const specieString = getSpecieString(study, intl);

  const { comment = getReport(name, owner_name, images, intl, creationDate) } = study;
  const { clinicInfo, workplace_name: clinicName } = reportInfo;
  const vetName =
    reportInfo.first_name !== 'null' ? `Dr. ${reportInfo.first_name} ${reportInfo.last_name}` : '';
  const userMail = reportInfo.mail;
  const clinicLogo = reportInfo.clinic_logo === 'NO_LOGO' ? null : reportInfo.clinic_logo;
  return generateDocxBlob(
    comment,
    name,
    owner_name,
    sex ? intl.formatMessage({ id: `patient_info.sex.${sex}` }) : '',
    '',
    '',
    animalAgeString,
    specieString,
    clinicName,
    vetName,
    userMail,
    clinicInfo,
    _.reject(study.images, isWorkListImage),
    clinicLogo,
    intl
  );
};

const generateDocxFromStudy = async (study: ReportStudy, intl: IntlShape) => {
  const docxBlob = await generateDocxBlobFromStudy(study, intl);
  // eslint-disable-next-line camelcase
  const { name, owner_name } = study?.animal ?? {};
  downloadFile(docxBlob, getOutputFileName(name, owner_name));
};

export {
  generateDocx,
  generateDocxBlob,
  generateDocxBlobFromStudy,
  generateDocxFromStudy,
  getSpecieString,
};
