import { ExportableImage } from 'app/components/Dropzone/ExportStudyModal/exportStudy';
import { SelectableImage } from 'app/components/ImagesSelector';
import { DataImage } from 'app/interfaces/DataImage';
import { DicomData } from 'app/interfaces/Dicom';
import {
  AcquisitionConstants,
  DetectorInfo,
  DisplayableImageData,
  Feedbacks,
  ImageAnnotations,
  ImageDisplayableMetadata,
  ImageMetadata,
  Viewport,
} from 'app/interfaces/Image';
import { ToolsStates } from 'app/adapters/ImageRenderer/ConfigurableToolsOptions';
import { Patient } from 'app/interfaces/Patient';
import { AllPredictions } from 'app/interfaces/Predictions';
import { AcquisitionDetails } from 'app/interfaces/StudyStore';

const viewerModes = ['acquisition'] as const;

export type ViewerMode = typeof viewerModes[number];

export const isViewerMode = (s: any): s is ViewerMode => viewerModes.includes(s);

export type CompatDropzoneImage = ExportableImage &
  SelectableImage & {
    backendId: string;
    annotations: ImageAnnotations;
    predictions: AllPredictions;
    origin: string;
    computeDicomData?: () => Promise<DicomData>;
    dicomData?: DicomData;
  };

export type CompatDropzoneStudy = {
  ID: string;
  animal?: Patient;
  images: CompatDropzoneImage[];
  comment: string;
  isCommentDirty: boolean;
  creationDate: Date;
};

export type RawImageSaveData = {
  dataImage?: DataImage;
  acquisitionDetails?: AcquisitionDetails;
  dicomData?: DicomData;
};

export type ImageSaveState = {
  acquisitionInfo?: {
    anatomicRegion?: string;
    image_metadata?: ImageMetadata;
    acquisitionConstants?: AcquisitionConstants;
    detectorInfo?: DetectorInfo;
    acquisitionTime?: Date;
    feedback?: Feedbacks;
  };
  viewer?: {
    annotations?: ImageAnnotations;
    viewport?: Viewport;
  };
  rawImage?: RawImageSaveData;
};

export type ViewerImage = {
  origin: 'acquisition' | 'worklist' | 'upload';
  filename?: string;
  anatomicRegion: string;
  imageMetadata?: ImageMetadata;
  acquisitionConstants?: AcquisitionConstants;
  initialDataImage?: DataImage & { rowPixelSpacing: number; columnPixelSpacing: number };
  isSelected?: boolean;
  isImageLoading?: boolean;
  displayableImage?: DisplayableImageData;
  loadError?: boolean;
  dicomData?: DicomData;
  detectorInfo?: DetectorInfo;
  acquisitionTime?: Date;
  isProcessingOngoing?: boolean;
  processedImage?: DataImage;
  isFromLastProcessing?: boolean;
  isImageNewPredictionsNeeded?: boolean;
  isPredictionsLoading?: boolean;
  predictions?: AllPredictions;
  inferenceError?: object;
  feedback?: Feedbacks;
  activeRegionName?: string;
  toolsList?: ToolsStates;
  isAnnotationsSaved?: boolean;
  negatoView?: number;
  annotations?: ImageAnnotations;
  pendingAnnotations?: ImageAnnotations;
  isRealSizeMeasurementCalibration?: boolean;
  viewport?: Viewport;
  saveState: ImageSaveState;
  reloadedState?: ImageSaveState;
  isRawSavedOnce?: boolean;
  isNewImage?: boolean;
  needRawSave?: boolean;
  displayedMetadata?: ImageDisplayableMetadata;
  isInitialAnnotations?: boolean;
  shownAnnotations?: { [tool: string]: { state: string } };
  photometric_interpretation?: string;
  PACS?: { isSyncOngoing?: boolean; syncError?: boolean };
};

export type ViewerImages = {
  [imageId: string]: ViewerImage;
};
