export const XRAY_CONFIGURATION_SET_DEVICE_PATH = 'XRAY_CONFIGURATION.SET_DEVICE_PATH' as const;
export const XRAY_CONFIGURATION_TOGGLE_TELEMETRY = 'XRAY_CONFIGURATION.TOGGLE_TELEMETRY' as const;
export const XRAY_CONFIGURATION_ADD_OPERATOR = 'XRAY_CONFIGURATION.ADD_OPERATOR' as const;
export const XRAY_CONFIGURATION_SET_LAST_OPERATORS =
  'XRAY_CONFIGURATION.SET_SELECTED_OPERATORS' as const;
export const XRAY_CONFIGURATION_DELETE_OPERATOR = 'XRAY_CONFIGURATION.DELETE_OPERATOR' as const;

export const setDevicePath = (devicePath: string) => ({
  type: XRAY_CONFIGURATION_SET_DEVICE_PATH,
  payload: { devicePath },
});
export const toggleTelemetry = (isTelemetry: boolean) => ({
  type: XRAY_CONFIGURATION_TOGGLE_TELEMETRY,
  payload: { isTelemetry },
});
export const addOperator = (operator: string) => ({
  type: XRAY_CONFIGURATION_ADD_OPERATOR,
  payload: { operator },
});
export const setSelectedOperators = (operators: string[]) => ({
  type: XRAY_CONFIGURATION_SET_LAST_OPERATORS,
  payload: { operators },
});
export const deleteOperator = (operator: string) => ({
  type: XRAY_CONFIGURATION_DELETE_OPERATOR,
  payload: { operator },
});

export type XrayGeneratorConfigurationAction =
  | ReturnType<typeof setDevicePath>
  | ReturnType<typeof toggleTelemetry>
  | ReturnType<typeof addOperator>
  | ReturnType<typeof setSelectedOperators>
  | ReturnType<typeof deleteOperator>;
