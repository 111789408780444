// This action does not ensure input validity
export const INPUT_MAPPING_SET_DICOM_MAPPING = 'INPUT_MAPPING_SET_DICOM_MAPPING' as const;

type DicomMapping = {
  species: object;
};

export const setDicomMapping = (dicomMapping: DicomMapping) => ({
  type: INPUT_MAPPING_SET_DICOM_MAPPING,
  dicomMapping,
});

export type UserInputMappingAction = ReturnType<typeof setDicomMapping>;
