/* eslint-disable camelcase */
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import { produce } from 'immer';
import observeStore from 'app/utils/redux/observeStore';
import { TeleradiologyAction, UPDATE_TELERADIOLOGY } from './actions';

export const TELERADIOLOGY_LOCAL_STORAGE_KEY = 'redux.teleradiology';

type TeleradiologyState = {
  type?: string;
  affiliateId?: string;
};

/** @var {object} teleradiologyInitialState */
const teleradiologyInitialState = (): TeleradiologyState => ({
  type: undefined,
  affiliateId: undefined,
  ...restoreFromLocalStorage(TELERADIOLOGY_LOCAL_STORAGE_KEY),
});

function teleradiologyReducer(
  state: TeleradiologyState = teleradiologyInitialState(),
  action: TeleradiologyAction
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_TELERADIOLOGY: {
        draft.type = action.teleradiology?.type;
        draft.affiliateId = action.teleradiology?.affiliateId;
        break;
      }
    }
  });
}

export const selectTeleradiology = (state) => state.teleradiology;
export const selectTeleradiologyAffiliateId = (state) => state.teleradiology.affiliateId;
export const selectTeleradiologyType = (state) => state.teleradiology.type;

export const attachTeleradiologyWithLocalStorageSynchronizationObserver = (store) => {
  observeStore(
    store,
    selectTeleradiology,
    makeSyncReduxWithLocalStorageObserver(TELERADIOLOGY_LOCAL_STORAGE_KEY)
  );
};

export default teleradiologyReducer;
