import React, { useState } from 'react';
import { Button, Header, HeaderContent, HeaderSubheader, Icon, Modal } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { API } from 'app/utils/apiCalls';
import TeleradiologyIcon from 'app/containers/Teleradiology/TeleradiologyIcon';
import { useSelector } from 'react-redux';
import { selectLocale } from 'app/redux/language/selectors';

import cgvFr from './cgv-vedim.pdf';
import cgvEn from './terms-of-sale-vedim.pdf';

type TeleradiologyVetFlowModalAnalyzeRequestTermsOfSalesProps = {
  onAcceptClick: () => void;
};

function TeleradiologyVetFlowModalAnalyzeRequestTermsOfSales({
  onAcceptClick,
}: TeleradiologyVetFlowModalAnalyzeRequestTermsOfSalesProps) {
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const handleAccept = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpen(false);
    onAcceptClick?.();
    event.preventDefault();
    event.stopPropagation();
  };

  const locale = useSelector(selectLocale);
  const cgv = locale == 'fr' ? cgvFr : cgvEn;

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <button type="button" className="ui link" onClick={handleClick}>
          <FormattedMessage
            id="teleradiology.analyze-request.terms-of-sales.title"
            values={{ companyName: API.Teleradiology.Provider.VEDIM }}
          />
        </button>
      }
      className="terms-of-sales-modal"
    >
      <Modal.Header>
        <Header as="h4">
          <TeleradiologyIcon size="big" />
          <HeaderContent>
            <FormattedMessage
              id="teleradiology.analyze-request.terms-of-sales.title"
              values={{ companyName: API.Teleradiology.Provider.VEDIM }}
            />
            <HeaderSubheader>
              <FormattedMessage id="teleradiology.title" />
            </HeaderSubheader>
          </HeaderContent>
        </Header>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <embed src={cgv} type="application/pdf" style={{ width: '100%', minHeight: '70vh' }} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handleAccept}>
          <Icon name="check" /> <FormattedMessage id="billing.accept-terms-of-sales" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default TeleradiologyVetFlowModalAnalyzeRequestTermsOfSales;
