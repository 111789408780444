import 'app/components/Dropzone/ExportStudyModal/style.scss';

import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Modal, Popup } from 'semantic-ui-react';

import { updateSyncToPACSOnValidate } from 'app/redux/viewerConfiguration/actions';
import { selectViewerConfiguration } from 'app/redux/reducers';
import ImagesSelector, { ImageId, SelectableImage } from 'app/components/ImagesSelector';

export type PACSImagesSelectorProps<T extends SelectableImage> = {
  images: T[];
  initialSelectedImages: T[];
  onConfirm: (selectedImages: T[]) => void;
};

export default function PACSImagesSelector<T extends SelectableImage>({
  images,
  initialSelectedImages,
  onConfirm,
}: PACSImagesSelectorProps<T>) {
  const viewerConfiguration = useSelector(selectViewerConfiguration);
  const dispatch = useDispatch();
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState<ImageId[]>([]);
  const prevInitialSelectedImageIds = useRef<ImageId[]>();
  const { syncToPACSOnValidate } = viewerConfiguration;

  const onImagesSelect = (imageIds: ImageId[]) => {
    setSelectedImages(imageIds);
  };

  const toggleSyncToPACSOnValidateChange = () => {
    dispatch(updateSyncToPACSOnValidate(!syncToPACSOnValidate));
  };

  const triggerConfirm = () => {
    onConfirm(_.filter(images, ({ id }) => selectedImages.includes(id)));
    setIsSelectorOpen(false);
  };

  const triggerCancel = () => {
    setIsSelectorOpen(false);
  };

  const initialSelectedImageIds = initialSelectedImages?.map(({ id }) => id);
  const isSameInitialImages = _.isEqual(
    initialSelectedImageIds,
    prevInitialSelectedImageIds.current
  );

  if (!isSameInitialImages) prevInitialSelectedImageIds.current = initialSelectedImageIds;

  useEffect(() => {
    setSelectedImages(prevInitialSelectedImageIds.current);
  }, [prevInitialSelectedImageIds.current]);

  return (
    <>
      <Popup
        trigger={
          <button
            className="picoxia"
            type="button"
            data-testid="modal-trigger"
            onClick={() => setIsSelectorOpen(true)}
          >
            <Icon className="picoxia-fix" inverted name="cloud upload" size="big" />
          </button>
        }
        content={<FormattedMessage id="dropzone.pacsSend.description" />}
      />
      <Modal
        className="pacs-images-selector"
        onClose={() => setIsSelectorOpen(false)}
        onOpen={() => setIsSelectorOpen(true)}
        open={isSelectorOpen}
      >
        <Modal.Header className="pacs-images-selector__header">
          <FormattedMessage id="pacsImagesSelector.title" />
        </Modal.Header>
        <Modal.Content className="pacs-images-selector__content" data-testid="modal-content">
          <form className="exportForm">
            <ImagesSelector
              title={<FormattedMessage id="exportStudyModal.selectImages.label" />}
              onSelect={onImagesSelect}
              images={images}
              selectedImagesIds={selectedImages}
            />
            <div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="syncOnValidate-checkbox"
                  name="syncOnValidate-checkbox"
                  checked={syncToPACSOnValidate}
                  onChange={toggleSyncToPACSOnValidateChange}
                />
                <label htmlFor="syncOnValidate-checkbox">
                  <FormattedMessage id="dropzone.pacsSend.syncOnValidate.checkbox" />
                </label>
              </div>
            </div>
          </form>
        </Modal.Content>
        <Modal.Actions className="pacs-images-selector__footer">
          <button
            data-testid="button-export"
            type="button"
            className="footerModal__button footerModal__button--green"
            onClick={triggerConfirm}
          >
            <FormattedMessage id="general.confirm" />
          </button>

          <button
            type="button"
            className="footerModal__button"
            color="black"
            onClick={triggerCancel}
          >
            <FormattedMessage id="general.cancel" />
          </button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
