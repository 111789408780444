import React, { useEffect, useState } from 'react';
import { Button, Form, Header, HeaderContent, Icon, Modal } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import * as _ from 'lodash';
import ReactHookFormMessageError from 'app/containers/Teleradiology/MessageError';
import FormMandatoryMention from 'app/components/Form/MandatoryMention';
import {
  selectPermissionAcquisition,
  selectPermissionTeleradiology,
} from 'app/redux/permissions/reducer';
import './styles.scss';
import { useSelector } from 'react-redux';
import { selectLoggedIn, selectSubscribed } from 'app/redux/global/selectors';
import toastifyPromise from 'app/utils/toastifyPromise';
import ApiCalls from 'app/utils/apiCalls';
import { isElectron } from 'app/utils/envUtil';
import isAcquisitionSoftware from 'app/utils/isAcquisitionSoftware';
import { selectCurrentImageId, selectCurrentStudyId } from 'app/redux/currentStudyInfos/reducer';

type FormDataType = {
  type: string;
  category: string;
  subject: string;
  message: string;
  software: string;
  version: string;
  dateRelease: string;
  studyId: string;
  imageId: string;
};

/**
 * Modal to display Form Contact
 */
function FormContact() {
  const isLoggedIn = useSelector(selectLoggedIn);
  const hasAcquisitionPermission = useSelector(selectPermissionAcquisition);
  const hasAISubscription = useSelector(selectSubscribed);
  const hasTeleradiologyPermission = useSelector(selectPermissionTeleradiology);
  const hasProduct = hasAcquisitionPermission || hasAISubscription || hasTeleradiologyPermission;

  if (isLoggedIn && hasProduct) {
    return (
      <FormContactModal
        hasAcquisitionPermission={hasAcquisitionPermission}
        hasAISubscription={hasAISubscription}
        hasTeleradiologyPermission={hasTeleradiologyPermission}
      />
    );
  }
  return null;
}

type FormContactModalProps = {
  hasAcquisitionPermission?: boolean;
  hasAISubscription?: boolean;
  hasTeleradiologyPermission?: boolean;
};

function FormContactModal({
  hasAcquisitionPermission = false,
  hasAISubscription = false,
  hasTeleradiologyPermission = false,
}: FormContactModalProps) {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const [isRequestSending, setIsRequestSending] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  const types = ['upgrade', 'bug'];
  let categories = ['acq', 'ai', 'teleradiology'];

  if (!hasAcquisitionPermission) {
    categories = _.without(categories, 'acq');
  }

  if (!hasAISubscription) {
    categories = _.without(categories, 'ai');
  }

  if (!hasTeleradiologyPermission) {
    categories = _.without(categories, 'teleradiology');
  }

  const typesOptions = types.map((key) => ({
    key,
    value: key,
    text: intl.formatMessage({ id: `form.contact.type.option.${key}` }),
  }));

  const categoriesOptions = categories.map((key) => ({
    key,
    value: key,
    text: intl.formatMessage({ id: `form.contact.category.option.${key}` }),
  }));

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<FormDataType>({
    values: {
      type: '',
      category: categories.length == 1 ? categories[0] : '',
      subject: '',
      message: '',
      software: isElectron() ? (isAcquisitionSoftware() ? 'acq' : 'ai') : 'web',
      version: ApiCalls.version,
      dateRelease: ApiCalls.releaseDate,
      studyId: useSelector(selectCurrentStudyId),
      imageId: useSelector(selectCurrentImageId),
    },
  });

  useEffect(() => {
    if (!isSubmitSuccessful) return;
    reset();
    setIsSubmitSuccessful(false);
  }, [isSubmitSuccessful]);

  const handleChangeType = (event: any, { value }: { value: string }) => {
    setValue('type', value);
  };

  const handleChangeCategory = (event: any, { value }: { value: string }) => {
    setValue('category', value);
  };

  const onSubmit = (formData: FormDataType) => {
    setIsRequestSending(true);

    toastifyPromise('form.contact', ApiCalls.contactUs(formData))
      .then(() => {
        setIsRequestSending(false);
        setIsSubmitSuccessful(true);
        setOpen(false);
      })
      .catch((error: Error) => {
        setIsRequestSending(false);
        console.error('server-api-error', error);
      });
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <Button
          className="contact-form-button"
          onClick={() => setOpen(true)}
          title={intl.formatMessage({ id: 'form.contact.title' })}
        >
          <Icon name="chat" />
          <FormattedMessage id="form.contact.title" />
        </Button>
      }
      className="contact-modal dark-mode"
    >
      <Modal.Header>
        <Header as="h3">
          <Icon name="chat" />
          <HeaderContent>
            <FormattedMessage id="form.contact.title" />
          </HeaderContent>
        </Header>
      </Modal.Header>

      <Modal.Content scrolling>
        <Modal.Description>
          {!_.isEmpty(errors) && <ReactHookFormMessageError errors={errors} />}

          <FormMandatoryMention />

          <Form loading={isRequestSending} onSubmit={handleSubmit(onSubmit)}>
            <Form.Group widths="equal">
              <Controller
                name="type"
                control={control}
                rules={{
                  required: 'form.contact.type.error.required',
                }}
                render={({ field: { onBlur, value } }) => (
                  <Form.Field required>
                    <label>
                      <FormattedMessage id="form.contact.type.label" />
                    </label>
                    <Form.Select
                      fluid
                      placeholder={intl.formatMessage({
                        id: 'form.contact.type.placeholder',
                      })}
                      value={value}
                      options={typesOptions}
                      onChange={handleChangeType}
                      onBlur={onBlur}
                      error={!!errors.type}
                    />
                  </Form.Field>
                )}
              />
              <Controller
                name="category"
                control={control}
                rules={{
                  required: 'form.contact.category.error.required',
                }}
                render={({ field: { onBlur, value } }) => (
                  <Form.Field required>
                    <label>
                      <FormattedMessage id="form.contact.category.label" />
                    </label>
                    <Form.Select
                      fluid
                      placeholder={intl.formatMessage({
                        id: 'form.contact.category.placeholder',
                      })}
                      value={value}
                      options={categoriesOptions}
                      onChange={handleChangeCategory}
                      onBlur={onBlur}
                      error={!!errors.category}
                    />
                  </Form.Field>
                )}
              />
            </Form.Group>

            <Controller
              name="subject"
              control={control}
              rules={{
                required: 'form.contact.subject.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.Input
                  type="text"
                  fluid
                  required
                  label={intl.formatMessage({
                    id: 'form.contact.subject.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'form.contact.subject.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.subject}
                />
              )}
            />
            <Controller
              name="message"
              control={control}
              rules={{
                required: 'form.contact.message.error.required',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Form.TextArea
                  required
                  label={intl.formatMessage({
                    id: 'form.contact.message.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'form.contact.message.placeholder',
                  })}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  rows={6}
                  error={!!errors.message}
                />
              )}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>
          <Icon name="remove" /> <FormattedMessage id="general.cancel" />
        </Button>
        <Button
          color="green"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          loading={isRequestSending}
        >
          <Icon name="send" /> <FormattedMessage id="general.send" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default FormContact;
