import 'app/styles/style.scss';
import 'app/components/DysplasiaPatternStructuredList/style.scss';

import * as _ from 'lodash';
import * as pt from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Divider, Grid, List, Popup } from 'semantic-ui-react';

import DysplasiaPattern from 'app/components/DysplasiaPatternStructuredList/DysplasiaPattern';
import StadeHelpMessage from 'app/components/DysplasiaPatternStructuredList/StadeHelpMessage';
import {
  DYSPLASIA_STADE_THRESHOLDS,
  NORBERG_OLSSON_STADE_THRESHOLDS,
} from 'app/constants/dysplasia';
import { roundToStep } from 'app/utils/mathUtils';
import { filterDysplasiaPredictions } from 'app/utils/predictions/dysplasiaUtils';
import { ReactComponent as DistractionIndexIcon } from 'static/svg_icons/distraction_index_horizontal_icon.svg';
import { ReactComponent as NorbergOlssonIcon } from 'static/svg_icons/norberg_olsson_horizontal_icon.svg';

const SEVERITY_THRESHOLDS = [
  { severity: { name: 'negative', color: 'missing' }, threshold: 0.4 },
  { severity: { name: 'doubtful', color: 'minor' }, threshold: 0.5 },
  { severity: { name: 'positive', color: 'major' }, threshold: 0.75 },
  { severity: { name: 'secure', color: 'present' }, threshold: 1 },
];
Object.freeze(SEVERITY_THRESHOLDS);

const NORBERG_OLSSON_THRESHOLDS = [
  { severity: { color: 'bad' }, threshold: 90 },
  { severity: { color: 'medium' }, threshold: 100 },
  { severity: { color: 'good' }, threshold: 105 },
];
Object.freeze(NORBERG_OLSSON_THRESHOLDS);

const DISTRACTION_INDEX_THRESHOLDS = [
  { severity: { name: 'normal', color: 'good' }, threshold: 0.301 },
  { severity: { name: 'mild', color: 'poor' }, threshold: 0.71 },
  { severity: { name: 'severe', color: 'bad' }, threshold: 0.72 },
];
Object.freeze(DISTRACTION_INDEX_THRESHOLDS);

const getObjectUnderThreshold = (thresholds, value) => {
  const thresholdObject = _.find(thresholds, ({ threshold }) => value < threshold);
  return thresholdObject || thresholds[thresholds.length - 1];
};

const DistractionIndexValueCell = ({ severityName, indexValue }) => (
  <Popup
    content={<FormattedMessage id={`patterns.pelvis.distraction_index.${severityName}`} />}
    trigger={<pre>{indexValue.toFixed(2)}</pre>}
  />
);

const getAnnotationValue = (measurements) => {
  if (!measurements) return undefined;
  if (measurements instanceof Map) {
    return measurements.values().next().value;
  }
  return Object.values(measurements)[0];
};

class DysplasiaPatternStructuredList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showStadeHelp: false,
    };
    this.severityThresholds = SEVERITY_THRESHOLDS;
    this.stadeThresholds = DYSPLASIA_STADE_THRESHOLDS;
    this.norbergOlssonStadeThresholds = NORBERG_OLSSON_STADE_THRESHOLDS;
    this.norbergOlssonThresholds = NORBERG_OLSSON_THRESHOLDS;
    this.distractionIndexThresholds = DISTRACTION_INDEX_THRESHOLDS;
  }

  getStade = (value, norbergOlssonAngleKey) => {
    const { annotations } = this.props;
    const norbergOlssonAngle = getAnnotationValue(annotations.NorbergOlsson)?.[
      norbergOlssonAngleKey
    ];
    const norbergOlssonMaxStade = getObjectUnderThreshold(
      this.norbergOlssonStadeThresholds,
      Math.round(norbergOlssonAngle) // We round the value to account for annotation tool precision
    ).stade;

    const predictionsStade = getObjectUnderThreshold(this.stadeThresholds, value).stade;
    if (norbergOlssonMaxStade.name > predictionsStade.name) {
      return norbergOlssonMaxStade;
    }
    return predictionsStade;
  };

  getPatternSeverity = (value) => getObjectUnderThreshold(this.severityThresholds, value).severity;

  getNorbergOlssonSeverity = (value) =>
    getObjectUnderThreshold(this.norbergOlssonThresholds, Math.round(value)).severity;

  getDistractionIndexSeverity = (value) =>
    getObjectUnderThreshold(this.distractionIndexThresholds, value).severity;

  formatNorbergOlssonAngle = () => {
    const { annotations } = this.props;
    const norbergOlssonAnnotation = getAnnotationValue(annotations.NorbergOlsson);
    if (!norbergOlssonAnnotation) return null;
    const { leftAngle, rightAngle } = norbergOlssonAnnotation;

    const leftSeverity = this.getNorbergOlssonSeverity(leftAngle);
    const rightSeverity = this.getNorbergOlssonSeverity(rightAngle);

    return {
      left: { value: <pre>{`${Math.round(leftAngle)}\u00B0`}</pre>, color: leftSeverity.color },
      right: { value: <pre>{`${Math.round(rightAngle)}\u00B0`}</pre>, color: rightSeverity.color },
      pattern: 'norberg_olsson',
      content: <NorbergOlssonIcon />,
      isTool: true,
    };
  };

  formatDistractionIndex = () => {
    const { annotations } = this.props;
    const distractionIndex = getAnnotationValue(annotations.DistractionIndex);
    if (!distractionIndex) return null;
    const { leftIndex, rightIndex } = distractionIndex;

    if (leftIndex === undefined || rightIndex === undefined) return null;

    const leftSeverity = this.getDistractionIndexSeverity(leftIndex);
    const rightSeverity = this.getDistractionIndexSeverity(rightIndex);

    return {
      left: {
        value: (
          <DistractionIndexValueCell indexValue={leftIndex} severityName={leftSeverity.name} />
        ),
        color: leftSeverity.color,
      },
      right: {
        value: (
          <DistractionIndexValueCell indexValue={rightIndex} severityName={rightSeverity.name} />
        ),
        color: rightSeverity.color,
      },
      pattern: 'distraction_index',
      content: <DistractionIndexIcon />,
      isTool: true,
    };
  };

  formatDysplasiaValue = (value) => {
    const severity = this.getPatternSeverity(value);
    const displayedValue =
      severity.name === 'negative' ? (
        <pre>
          <FormattedMessage id="patterns.pelvis.ras" />
        </pre>
      ) : (
        <Popup
          content={<FormattedMessage id={`pattern.confidence.${severity.name}`} />}
          trigger={<pre>{`${roundToStep(value * 100, 5)} %`}</pre>}
        />
      );

    return { value: displayedValue, color: severity.color };
  };

  formatDysplasiaPredictions = ({ left: leftPredictions, right: rightPredictions }) => {
    const leftPatternsMap = _.mapValues(leftPredictions.patterns, (value) => ({ left: { value } }));
    const rightPatternsMap = _.mapValues(rightPredictions.patterns, (value) => ({
      right: { value },
    }));
    const mergedPatternsMap = _.merge({}, leftPatternsMap, rightPatternsMap);

    const patternsList = _.map(mergedPatternsMap, (v, pattern) => ({ pattern, ...v }));
    const positivePatternsList = _.filter(
      patternsList,
      ({ left, right }) =>
        this.getPatternSeverity(left.value).name !== 'negative' ||
        this.getPatternSeverity(right.value).name !== 'negative'
    );

    const sortedPatternsList = _.sortBy(
      positivePatternsList,
      ({ left, right }) => -Math.max(left.value, right.value)
    );

    const displayableList = sortedPatternsList.map(({ pattern, left, right }) => ({
      pattern,
      content: <FormattedMessage id={`patterns.pelvis.${pattern}`} />,
      left: this.formatDysplasiaValue(left.value),
      right: this.formatDysplasiaValue(right.value),
    }));

    let laxityIndex = _.findIndex(displayableList, { pattern: 'defaut_de_coaptation' });
    laxityIndex = laxityIndex < 0 ? displayableList.length : laxityIndex + 1;

    const distractionIndex = this.formatDistractionIndex();
    if (distractionIndex) displayableList.splice(laxityIndex, 0, distractionIndex);

    const norbergAngle = this.formatNorbergOlssonAngle();
    if (norbergAngle) displayableList.splice(laxityIndex, 0, norbergAngle);

    return displayableList;
  };

  switchStadeHelp = () =>
    this.setState((state) => ({
      showStadeHelp: !state.showStadeHelp,
    }));

  render() {
    const { predictions } = this.props;
    const { showStadeHelp } = this.state;
    if (predictions.norberg_olsson === undefined) return null;
    const leftStade = this.getStade(predictions.norberg_olsson.left.stade, 'leftAngle');
    const rightStade = this.getStade(predictions.norberg_olsson.right.stade, 'rightAngle');

    const patternsList = this.formatDysplasiaPredictions(
      filterDysplasiaPredictions(predictions).norberg_olsson
    );

    return (
      <div className="dysplasia patterns">
        <List>
          <List.Item>
            <Grid columns="equal">
              <Grid.Column>
                <div className="circular side-letter">
                  <FormattedMessage id="patterns.dysplasia.R" />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="circular side-letter">
                  <FormattedMessage id="patterns.dysplasia.L" />
                </div>
              </Grid.Column>
            </Grid>
          </List.Item>
          <Divider />
          <List.Item className="stade-row">
            <Grid columns="equal">
              <Grid.Column>
                <Button
                  size="small"
                  className="no-hover stade"
                  color={leftStade.color}
                  basic
                  content={leftStade.name}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  size="small"
                  className="no-hover stade"
                  basic
                  color={rightStade.color}
                  content={rightStade.name}
                />
              </Grid.Column>
            </Grid>
            <div className="help-button">
              <button className="dark-button circular" type="button" onClick={this.switchStadeHelp}>
                ?
              </button>
            </div>
          </List.Item>
          {showStadeHelp ? (
            <List.Item>
              <StadeHelpMessage />
            </List.Item>
          ) : null}
          {_.map(patternsList, ({ pattern, content, left, right, isTool }) => (
            <List.Item key={pattern}>
              <DysplasiaPattern content={content} left={left} right={right} isTool={isTool} />
            </List.Item>
          ))}
        </List>
      </div>
    );
  }
}

DysplasiaPatternStructuredList.propTypes = {
  predictions: pt.shape(),
  annotations: pt.shape(),
};

DysplasiaPatternStructuredList.defaultProps = {
  predictions: {},
  annotations: {},
};

export default injectIntl(DysplasiaPatternStructuredList);
