import {
  SET_LAST_RELEASE_URL,
  SET_PATCH_NOTES,
  VersionUpdateAction,
} from 'app/redux/versionUpdate/actions';
import produce from 'immer';
import { Action } from 'redux';

type VersionUpdateState = {
  releaseUrl?: string;
  patchNotes?: Record<string, string>;
};

type StoreWithVersionUpdate = { versionUpdate: VersionUpdateState };

const versionUpdateInitialState: VersionUpdateState = {
  releaseUrl: undefined,
  patchNotes: undefined,
};

const versionUpdateReducer = (
  state = versionUpdateInitialState,
  { type, payload }: Action | VersionUpdateAction
) => {
  return produce(state, (draft) => {
    switch (type) {
      case SET_LAST_RELEASE_URL:
        draft.releaseUrl = payload.releaseUrl;
        break;
      case SET_PATCH_NOTES:
        draft.patchNotes = payload.patchNotes;
        break;
    }
  });
};

export const selectVersionUpdate = (state: StoreWithVersionUpdate) => state.versionUpdate;

export default versionUpdateReducer;
