import { FlatPanelStateContext, getCurrentDetector } from 'app/components/FlatPanelStateProvider';
import { AcquisitionConstants } from 'app/interfaces/Image';
import fs from 'app/native/node/fs';
import path from 'app/native/node/path';
import XRayGeneratorHistoryContext, {
  XRayGeneratorHistory,
} from 'app/providers/XRayGeneratorHistoryProvider/context';
import { XRayGeneratorControllerContext } from 'app/providers/XRayGeneratorProvider';
import convertXRayFileToShotObjects from 'app/providers/convertXRayFileToShotObjects';
import { selectXRaySelectedOperators } from 'app/redux/xrayGeneratorConfiguration/reducer';
import { DETECTOR_EVENTS } from 'app/types/xray';
import { EVENT_GENERATOR_RAD_PARAMETERS_EXPOSURE } from 'app/xray/generator/constants';
import { format } from 'date-fns';
import { FileHandle } from 'fs/promises';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

type XRayGeneratorHistoryProviderProps = {
  xRayHistoryPath: string;
};

type ExposureEvent = {
  kV: number;
  mAs: number;
  mA: number;
  ms: number;
  focus: 0 | 1; // SMALL / BIG focus
  dose: number;
};

const getOperatorHistoryAsCsv = (fileContent: string, operator: string) => {
  const header = 'date,kV,mAs,mA,ms,operators\r\n';
  const matchingLines = !operator
    ? fileContent
    : fileContent
        .split(/\r?\n/)
        .filter((line) => line.match(new RegExp(`(,|")${operator}(,|")`)))
        .join('\r\n');
  return header + matchingLines;
};

const saveShotToHistory = async (
  xRayHistoryFile: FileHandle,
  { kV, mAs, ms, mA }: ExposureEvent,
  operators: string[] = []
) => {
  const date = format(new Date(), 'yyyy-MM-dd_kk:mm:ss');
  const operatorsString = operators.length > 0 ? `"${operators.join(',')}"` : '';
  const lineContent = [date, kV, mAs, mA, ms, operatorsString].join(',');
  await xRayHistoryFile?.appendFile(`${lineContent}\r\n`, {
    encoding: 'utf8',
  });
};

const saveDetectorShotToHistory = async (
  xRayHistoryFile: FileHandle,
  { kV, s, mA }: AcquisitionConstants,
  operators: string[] = []
) => {
  const date = format(new Date(), 'yyyy-MM-dd_kk:mm:ss');
  const ms = s * 1000; // Convert seconds to milliseconds
  const mAs = mA * s;
  const operatorsString = operators.length > 0 ? `"${operators.join(',')}"` : '';
  const lineContent = [
    date,
    kV.toFixed(0),
    +mAs.toFixed(2),
    mA.toFixed(0),
    ms.toFixed(0),
    operatorsString,
  ].join(',');
  await xRayHistoryFile?.appendFile(`${lineContent}\r\n`, {
    encoding: 'utf8',
  });
};

export default function XRayGeneratorHistoryProvider({
  children,
  xRayHistoryPath,
}: React.PropsWithChildren<XRayGeneratorHistoryProviderProps>) {
  const generator = useContext(XRayGeneratorControllerContext);
  const flatPanelContext = useContext(FlatPanelStateContext);
  const detector = getCurrentDetector(flatPanelContext);
  const [acquisitionConstants, setAcquisitionConstants] = useState<AcquisitionConstants>(undefined);
  const acquisitionConstantsRef = useRef<AcquisitionConstants>();
  acquisitionConstantsRef.current = acquisitionConstants;
  const selectedOperators = useSelector(selectXRaySelectedOperators);
  const selectedOperatorsRef = useRef();
  selectedOperatorsRef.current = selectedOperators;
  const xRayHistoryFile = useRef<FileHandle>();

  useEffect(() => {
    if (!xRayHistoryPath) return undefined;

    (async () => {
      await fs()?.promises.mkdir(path().dirname(xRayHistoryPath), { recursive: true });

      xRayHistoryFile.current = await fs()?.promises.open(xRayHistoryPath, 'a+');
    })();
    return () => {
      xRayHistoryFile.current?.close();
    };
  }, [xRayHistoryPath]);

  const xRayGeneratorHistory: XRayGeneratorHistory = useMemo(
    () => ({
      getHistoryAsCsv: (operator: string) =>
        fs()
          ?.promises.readFile(xRayHistoryPath, { encoding: 'utf8' })
          .then((fileContent: string) => getOperatorHistoryAsCsv(fileContent, operator)),
      getAllHistory: () =>
        fs()
          ?.promises.readFile(xRayHistoryPath, { encoding: 'utf8' })
          .then(convertXRayFileToShotObjects),
      setAcquisitionConstants,
    }),
    [setAcquisitionConstants]
  );

  useEffect(() => {
    const saveGeneratorShotToHistory = (exposureEvent: ExposureEvent) => {
      saveShotToHistory(
        xRayHistoryFile.current,
        exposureEvent,
        // @ts-ignore
        selectedOperatorsRef.current
      );
    };
    generator?.on(EVENT_GENERATOR_RAD_PARAMETERS_EXPOSURE, saveGeneratorShotToHistory);
    return () =>
      generator?.removeEventListener(
        EVENT_GENERATOR_RAD_PARAMETERS_EXPOSURE,
        // @ts-ignore
        saveGeneratorShotToHistory
      );
  }, [generator]);

  useEffect(() => {
    if (generator) return undefined;

    const onAcquisitionStart = () => {
      saveDetectorShotToHistory(
        xRayHistoryFile.current,
        acquisitionConstantsRef.current,
        // @ts-ignore
        selectedOperatorsRef.current
      );
    };
    detector?.on(DETECTOR_EVENTS.ACQUISITION_START, onAcquisitionStart);
    return () =>
      detector?.removeListener(
        DETECTOR_EVENTS.ACQUISITION_START,
        // @ts-ignore
        onAcquisitionStart
      );
  }, [detector, generator]);

  return (
    <XRayGeneratorHistoryContext.Provider value={xRayGeneratorHistory}>
      {children}
    </XRayGeneratorHistoryContext.Provider>
  );
}
