export const PMS_SET_WATCHED_DIRECTORY = 'pms/SET_WATCHED_DIRECTORY' as const;
export const PMS_SET_IS_INVALID_WATCHED_DIRECTORY = 'pms/SET_IS_INVALID_WATCHED_DIRECTORY' as const;
export const PMS_SET_TYPE = 'pms/set_type' as const;
export const PMS_SET_EXPORT_CONFIGURATION = 'pms/set_export_configuration' as const;
export const PMS_SET_EXPORT_DIRECTORY = 'pms/set_export_directory' as const;
export const PMS_SET_SPECIFIC_CONFIGURATION = 'pms/set_specific_configuration' as const;

export const setWatchedDirectory = (watchedDirectory: string) => ({
  type: PMS_SET_WATCHED_DIRECTORY,
  payload: { watchedDirectory },
});

export const setInvalidWatchedDirectory = (isInvalidWatchedDirectory: boolean) => ({
  type: PMS_SET_IS_INVALID_WATCHED_DIRECTORY,
  payload: { isInvalidWatchedDirectory },
});

export const setPMSType = (type: string) => ({
  type: PMS_SET_TYPE,
  payload: { type },
});

export const setPMSExportConfiguration = (exportConfiguration: any) => ({
  type: PMS_SET_EXPORT_CONFIGURATION,
  payload: { exportConfiguration },
});

export const setPMSExportDirectory = (exportDirectory: string) => ({
  type: PMS_SET_EXPORT_DIRECTORY,
  payload: { exportDirectory },
});

export const setPMSSpecificConfiguration = (pmsType: string, path: string, value: any) => ({
  type: PMS_SET_SPECIFIC_CONFIGURATION,
  payload: { pmsType, path, value },
});

export type PMSAction =
  | ReturnType<typeof setWatchedDirectory>
  | ReturnType<typeof setInvalidWatchedDirectory>
  | ReturnType<typeof setPMSType>
  | ReturnType<typeof setPMSExportConfiguration>
  | ReturnType<typeof setPMSExportDirectory>
  | ReturnType<typeof setPMSSpecificConfiguration>;
