import { LoadSharedUserConfigAction } from 'app/redux/sharedUserConfig/actions';

// Action type constants
export const FULL_SIZE_UPDATE = 'FULL_SIZE_UPDATE' as const;
export const NO_XRAY_DELETION_TOGGLE = 'NO_XRAY_DELETION_TOGGLE' as const;
export const UPDATE_SYNC_TO_PACS = 'updateSyncToPACSOnValidate' as const;

// Action creators
export const updateFullSize = (detectorId: string | number, scale: number) => ({
  type: FULL_SIZE_UPDATE,
  data: { detectorId, scale },
});

export const toggleNoXRayDeletion = (enabled: boolean) => ({
  type: NO_XRAY_DELETION_TOGGLE,
  data: { enabled },
  syncWithUserConfigurationAPI: true,
});

export const updateSyncToPACSOnValidate = (syncToPACSOnValidate: boolean) => ({
  type: UPDATE_SYNC_TO_PACS,
  data: { syncToPACSOnValidate },
});

type FullSizeUpdateAction = ReturnType<typeof updateFullSize>;

type NoXRayDeletionToggleAction = ReturnType<typeof toggleNoXRayDeletion>;

type SyncToPACSAction = ReturnType<typeof updateSyncToPACSOnValidate>;

// Export action types
export type ViewerConfigurationAction =
  | FullSizeUpdateAction
  | NoXRayDeletionToggleAction
  | SyncToPACSAction
  | LoadSharedUserConfigAction;
