import ApiCalls from 'app/utils/apiCalls';
import { changeLocale } from '../../redux/language/actions';
import { USER_SET_LANGUAGE } from 'app/redux/language/actions';
import { selectLoggedIn } from 'app/redux/global/selectors';

const languageMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case USER_SET_LANGUAGE: {
      store.dispatch(changeLocale(action.locale));
      localStorage.setItem('language', action.locale);

      const isLoggedIn = selectLoggedIn(store.getState());
      if (isLoggedIn) {
        ApiCalls.setLanguage(action.locale);
      }

      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default languageMiddleware;
