import { setAIPanelDisplayMode } from 'app/redux/global/actions';
import { setLocalAIPanelMode } from 'app/redux/AIPanelManager';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAIPanelMode } from 'app/redux/global/selectors';
import FoldablePanel from './FoldablePanel';

// eslint-disable-next-line react/prop-types
const FoldableAIPanel = ({ children }) => {
  const AIPanelMode = useSelector(selectAIPanelMode);
  const dispatch = useDispatch();

  const handleSwitchMode = (mode) => {
    dispatch(setAIPanelDisplayMode(mode));
    setLocalAIPanelMode(mode);
  };

  return (
    <FoldablePanel panelDisplayMode={AIPanelMode} handleSwitchMode={handleSwitchMode}>
      {children}
    </FoldablePanel>
  );
};

export default FoldableAIPanel;
