import { SharedUserConfig } from 'app/interfaces/SharedUserConfiguration';

export const SHARED_USER_CONFIG_LOADED = 'SHARED_USER_CONFIG_LOADED' as const;

export const loadSharedUserConfig = (sharedUserConfig: SharedUserConfig) => ({
  type: SHARED_USER_CONFIG_LOADED,
  data: sharedUserConfig,
});

export type LoadSharedUserConfigAction = ReturnType<typeof loadSharedUserConfig>;
