import observeStore from 'app/utils/redux/observeStore';
import { produce } from 'immer';
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import { SET_AI_ONLY_ENABLED, setAiOnlyEnabled } from './actions';
import { Store } from 'redux';

export const AI_ONLY_CONFIGURATION_LOCAL_STORAGE_KEY = 'redux.aiOnlyConfiguration';

export type AIOnlyConfigurationState = {
  enabled: boolean;
};

export type AIOnlyConfigurationAction = ReturnType<typeof setAiOnlyEnabled>;

const aiOnlyConfigurationInitialState: AIOnlyConfigurationState = restoreFromLocalStorage(
  AI_ONLY_CONFIGURATION_LOCAL_STORAGE_KEY,
  { enabled: false }
);

function aiOnlyConfigurationReducer(
  state: AIOnlyConfigurationState = aiOnlyConfigurationInitialState,
  action: AIOnlyConfigurationAction
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_AI_ONLY_ENABLED:
        draft.enabled = action.payload.enabled;
        break;
    }
  });
}

export const selectAiOnlyConfiguration = (state: {
  aiOnlyConfiguration: AIOnlyConfigurationState;
}) => state.aiOnlyConfiguration;
export const selectAiOnlyEnabledConfiguration = (state: {
  aiOnlyConfiguration: AIOnlyConfigurationState;
}) => selectAiOnlyConfiguration(state).enabled;

export const attachAiOnlyConfigurationWithLocalStorageSynchronizationObserver = (
  store: Store<any>
) => {
  observeStore(
    store,
    selectAiOnlyConfiguration,
    makeSyncReduxWithLocalStorageObserver(AI_ONLY_CONFIGURATION_LOCAL_STORAGE_KEY)
  );
};

export default aiOnlyConfigurationReducer;
