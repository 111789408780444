import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PACSCommunicationContext } from 'app/components/PACSCommunicationProvider';
import WorklistMonitorClass from 'app/dicom/WorklistMonitor';
import StudyStoreContext from 'app/providers/StudyStoreProvider/context';
import { selectPACSConfiguration } from 'app/redux/PACSConfiguration/reducer';
import WorklistMonitorContext from 'app/providers/WorklistMonitorProvider/context';

export default function WorklistMonitorProvider({ children }: React.PropsWithChildren<{}>) {
  const pacsCommunication = useContext(PACSCommunicationContext);
  const studyStore = useContext(StudyStoreContext);
  const worklistMonitorConfiguration = useSelector(selectPACSConfiguration)?.worklist;
  const [worklistMonitor] = useState(
    () => new WorklistMonitorClass(pacsCommunication, studyStore, worklistMonitorConfiguration)
  );

  useEffect(() => {
    worklistMonitor.update(pacsCommunication, studyStore, worklistMonitorConfiguration);
  }, [pacsCommunication, studyStore, worklistMonitorConfiguration]);

  // Terminate Monitor on component deletion
  useEffect(() => () => worklistMonitor.close(), []);

  return (
    <WorklistMonitorContext.Provider value={worklistMonitor}>
      {children}
    </WorklistMonitorContext.Provider>
  );
}
