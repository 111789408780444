/* eslint-disable no-underscore-dangle */

import { DicomData, DicomTransferSyntax } from 'app/interfaces/Dicom';
import { DicomRegistry } from 'app/interfaces/DicomRegistry';

export type AssociationParameters = {
  hostIP: string; //  IP of the host to connect to
  port: number; //  port of the host to connect to
  hostAETitle: string;
  selfAETitle: string;
  username?: string;
  password?: string;
  timeout?: number; //  Timeout in seconds
};

export type PACSError = {
  error_type: 'request_failure' | 'send_error' | 'associate_error';
  status: string;
  error_comment?: string;
  attribute_identifier_list?: string[];
  offending_elements?: string[];
};

export type RequestSuccess = {
  status: string;
  error_comment?: string;
  attribute_identifier_list?: string[];
  offending_elements?: string[];
};

export type DicomQuery = {
  QueryRetrieveLevel: 'STUDY' | 'SERIES' | 'IMAGE';
};

// The folowwinf field are always supported by PACS when peforming a C-FIND query.
export type PatientRootPatientLevelQueryRequired = {
  PatientName?: string;
  PatientID?: string; // Unique
};

export type PatientRootPatientLevelQueryOptional = {
  IssuerOfPatientID?: string;
  ReferencedPatientSequence?: string[];
  PatientBirthDate?: string; // YYYYMMDD
  PatientBirthTime?: string; // 'kkmmss.SSS00'
  PatientSex?: string;
  PatientSexNeutered?: string;
  ResponsiblePerson?: string;
  PhysiciansOfRecord?: string;
  PatientSpeciesDescription?: string;
  PatientBreedDescription?: string;
  OtherPatientIDsSequence?: any;
  OtherPatientNames?: string;
  EthnicGroup?: string;
  PatientComments?: string;
  NumberOfPatientRelatedStudies?: string; // integer string
  NumberOfPatientRelatedSeries?: string; // integer string
  NumberOfPatientRelatedInstances?: string; // integer string
};

export type PatientRootStudyLevelQueryRequired = {
  StudyDate?: string;
  StudyTime?: string;
  AccessionNumber?: string;
  StudyID?: string;
  StudyInstanceUID?: string;
};

export type PatientRootStudyLevelQueryOptional = {
  ModalitiesInStudy?: any;
  SOPClassesInStudy?: any;
  AnatomicRegionsInStudyCodeSequence?: any;
  ReferringPhysicianName?: any;
  StudyDescription?: any;
  ProcedureCodeSequence?: any;
  NameOfPhysiciansReadingStudy?: any;
  AdmittingDiagnosesDescription?: any;
  ReferencedStudySequence?: any;
  ReferencedSOPClassUID?: any;
  ReferencedSOPInstanceUID?: any;
  PatientAge?: any;
  PatientSize?: any;
  PatientWeight?: any;
  Occupation?: any;
  AdditionalPatientHistory?: any;
  OtherStudyNumbers?: any;
  NumberOfStudyRelatedSeries?: any;
  NumberOfStudyRelatedInstances?: any;
  StudyUpdateDateTime?: any;
};

export type PatientRootSeriesLevelQueryRequired = {
  Modality?: string;
  SeriesNumber?: number;
  SeriesInstanceUID?: string;
};

export type PatientRootSeriesLevelQueryOptional = {
  NumberOfSeriesRelatedInstances?: number;
};

// Image Level is also called Composite Object Instance Level
export type PatientRootImageLevelQueryRequired = {
  InstanceNumber?: number;
  SOPInstanceUID?: string;
};
export type PatientRootImageLevelQueryOptional = {
  SOPClassUID?: any;
  AvailableTransferSyntaxUID?: any;
  AlternateRepresentationSequence?: any;
  SeriesInstanceUID?: any;
  SOPInstanceUID?: any;
  PurposeOfReferenceCodeSequence?: any;
  RelatedGeneralSOPClassUID?: any;
  ConceptNameCodeSequence?: any;
  ContentTemplateSequence?: any;
  TemplateIdentifier?: any;
  MappingResource?: any;
  ContainerIdentifier?: any;
  SpecimenDescriptionSequence?: any;
  SpecimenIdentifier?: any;
  SpecimenUID?: any;
};

export type PatientQuery = {
  QueryRetrieveLevel: 'PATIENT' | 'STUDY' | 'IMAGE' | 'SERIES';
} & PatientRootPatientLevelQueryRequired &
  PatientRootPatientLevelQueryOptional &
  PatientRootStudyLevelQueryRequired &
  PatientRootStudyLevelQueryOptional &
  PatientRootSeriesLevelQueryRequired &
  PatientRootSeriesLevelQueryOptional &
  PatientRootImageLevelQueryRequired &
  PatientRootImageLevelQueryOptional;

export type StudyRootStudyLevelQueryRequired = {
  // For this type required fields may be empty string or DICOM equivalent for non string type but must be present, optional ones may be undefined.
  StudyDate?: string;
  StudyTime?: string;
  AccessionNumber?: string;
  PatientName?: string;
  PatientID?: string;
  StudyID?: string;
  StudyInstanceUID?: string;
};

export type StudyRootStudyLevelQueryOptional = PatientRootStudyLevelQueryOptional & {
  EthnicGroup?: string;
  IssuerOfPatientID?: string;
  PatientBirthDate?: string;
  PatientBirthTime?: string;
  PatientSex?: string;
  OtherPatientIDsSequence?: any;
  OtherPatientNames?: string;
};

export type StudyQuery = {
  QueryRetrieveLevel: 'STUDY' | 'IMAGE' | 'SERIES';
} & StudyRootStudyLevelQueryRequired &
  StudyRootStudyLevelQueryOptional &
  PatientRootSeriesLevelQueryRequired &
  PatientRootSeriesLevelQueryOptional &
  PatientRootImageLevelQueryRequired &
  PatientRootImageLevelQueryOptional;

export type WorklistQuery = {
  // See DICOM specification part04.html#table_K.6-1
  ScheduledProcedureStepSequence?: {
    // R
    ScheduledStationAETitle?: any; // R
    ScheduledProcedureStepStartDate?: any; // R
    ScheduledProcedureStepStartTime?: any; // R
    Modality?: any; // R
    ScheduledPerformingPhysicianName?: any; // R
    ScheduledProcedureStepDescription?: any;
    ScheduledStationName?: any;
    ScheduledProcedureStepLocation?: any;
    ReferencedDefinedProtocolSequence?: {
      ReferencedSOPClassUID?: any;
      ReferencedSOPInstanceUID?: any;
    };

    ReferencedPerformedProtocolSequence?: {
      ReferencedSOPClassUID?: any;
      ReferencedSOPInstanceUID?: any;
    };

    ScheduledProtocolCodeSequence?: {
      ProtocolContextSequence?: {
        ValueType?: any;
        ConceptNameCodeSequence?: {};
        DateTime?: any;
        PersonName?: any;
        TextValue?: any;
        ConceptCodeSequence?: {};
        NumericValue?: any;
        MeasurementUnitsCodeSequence?: {};
      };
    };

    PreMedication?: any;
    ScheduledProcedureStepID?: any;
    RequestedContrastAgent?: any;
    ScheduledProcedureStepStatus?: any;
  };

  ScheduledSpecimenSequence?: {
    ContainerIdentifier?: any;
    ContainerTypeCodeSequence?: {};
    SpecimenDescriptionSequence?: {
      SpecimenIdentifier?: any;
      SpecimenUID?: any;
    };
  };

  BarcodeValue?: any;
  RequestedProcedureID?: any;
  RequestedProcedureDescription?: any;
  RequestedProcedureCodeSequence?: {};
  RequestedLateralityCodeSequence?: {};
  StudyInstanceUID?: any;
  StudyDate?: any;
  StudyTime?: any;
  ReferencedStudySequence?: {
    ReferencedSOPClassUID?: any;
    ReferencedSOPInstanceUID?: any;
  };

  RequestedProcedurePriority?: any;
  PatientTransportArrangements?: any;
  AccessionNumber?: any;
  IssuerOfAccessionNumberSequence?: {};
  RequestingPhysician?: any;
  ReferringPhysicianName?: any;
  AdmissionID?: any;
  IssuerOfAdmissionIDSequence?: {};
  CurrentPatientLocation?: any;
  ReferencedPatientSequence?: {
    ReferencedSOPClassUID?: any;
    ReferencedSOPInstanceUID?: any;
  };

  PatientName?: any; // R
  PatientID?: any; // R
  IssuerOfPatientID?: any;
  IssuerOfPatientIDQualifiersSequence?: {};
  OtherPatientIDsSequence?: {};
  PatientBirthDate?: any;
  PatientSex?: any;
  PatientPrimaryLanguageCodeSequence?: {
    PatientPrimaryLanguageModifierCodeSequence?: {};
  };

  PatientWeight?: any;
  PatientSize?: any;
  ConfidentialityConstraintOnPatientData?: any;
  PatientState?: any;
  PregnancyStatus?: any;
  MedicalAlerts?: any;
  Allergies?: any;
  SpecialNeeds?: any;
  PertinentDocumentsSequence?: {
    ReferencedSOPClassUID?: any;
    ReferencedSOPInstanceUID?: any;
    PurposeOfReferenceCodeSequence?: {};
    DocumentTitle?: any;
  };

  SpecificCharacterSet?: any;
  TimezoneOffsetFromUTC?: any;
  HL7StructuredDocumentReferenceSequence?: {
    ReferencedSOPClassUID?: any;
    ReferencedSOPInstanceUID?: any;
    HL7InstanceIdentifier?: any;
    RetrieveURI?: any;
  };
};

export type QueryDataSet = StudyQuery | PatientQuery | WorklistQuery;

export type GetQueryDataSet =
  | { PatientID: string[] }
  | { StudyInstanceUID: string[] }
  | { SeriesInstanceUID: string[] }
  | { SOPInstanceUID: string[] };

export enum FindQueryModel {
  Patient = DicomRegistry.PatientRootQueryRetrieveInformationModelFind,
  Study = DicomRegistry.StudyRootQueryRetrieveInformationModelFind,
  PatientStudyOnly = DicomRegistry.PatientStudyOnlyQueryRetrieveInformationModelFind,
  Worklist = DicomRegistry.ModalityWorklistInformationModelFind,
}
export enum GetQueryModel {
  Patient = DicomRegistry.PatientRootQueryRetrieveInformationModelGet,
  Study = DicomRegistry.StudyRootQueryRetrieveInformationModelGet,
  PatientStudyOnly = DicomRegistry.PatientStudyOnlyQueryRetrieveInformationModelGet,
}

export interface PACSCommunication {
  associate: (associationParameters: AssociationParameters) => Promise<undefined | PACSError>;
  echo: () => Promise<RequestSuccess | PACSError>;
  store: (dataSet: DicomData) => Promise<RequestSuccess | PACSError>;
  storeMany: (dataSets: DicomData[]) => Promise<PromiseSettledResult<RequestSuccess | PACSError>>;
  /**
   * Results will only contains field that the query ask for. To request a field, set the field value as undefined
   */
  find: (
    queryDataSet: QueryDataSet,
    queryModel: FindQueryModel
  ) => Promise<DicomData[] | PACSError>;
  get: (
    queryDataSet: GetQueryDataSet,
    queryModel: GetQueryModel
  ) => Promise<DicomData[] | PACSError>;
  checkAssociation: () => boolean;
}
