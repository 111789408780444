import TimeoutPromise, { CancelError } from 'app/utils/TimeoutPromise';

type Options = {
  maxRetry?: number;
  delay: number;
  maxDelay: number;
};

export async function withRetry<T>(
  operation: () => Promise<T>,
  abortController: AbortController,
  operationErrorPrefix: string,
  options: Partial<Options> = {}
): Promise<T> {
  let retryCount = 0;
  const { maxRetry } = options;
  let delay = options.delay || 30000; // 30 seconds
  const maxDelay = options.maxDelay || 10 * 60000; // 10 minutes

  while (!maxRetry || retryCount < maxRetry) {
    try {
      return await operation();
    } catch (error) {
      if (abortController.signal.aborted) {
        throw new CancelError(`${operationErrorPrefix} canceled by newer request`);
      }

      retryCount++;
      console.warn(`Retry attempt ${retryCount} failed, retrying in ${delay}ms`);
      if (error instanceof Error) {
        console.warn(`An error occurred: ${error.message}`);
      }

      try {
        await TimeoutPromise(delay, { signal: abortController.signal });
      } catch (e) {
        throw new CancelError(`${operationErrorPrefix} canceled by newer request`);
      }
      delay = Math.min(delay * 2, maxDelay);
    }
  }
}
