/* eslint-disable max-len */
import React from 'react';
import Slider from 'react-slick';
import { FormattedMessage, useIntl } from 'react-intl';
import AppHelmet from 'app/components/AppHelmet';
import { useSelector } from 'react-redux';
import Button from 'app/components/UIComponents/Button';
import Hook from 'app/components/UIComponents/Hook';
import Gradient from 'app/components/UIComponents/Gradient';
import CardsList from 'app/components/UIComponents/CardsList';
import Card from 'app/components/UIComponents/CardsList/Card';
import { selectLocale } from '../../redux/language/selectors';

import AIScreenshotEn from './images/ai-screenshot-en.jpg';
import AIScreenshotFr from './images/ai-screenshot-fr.jpg';
import improveImage from './images/improve-colors.svg?url';
import fastImage from './images/fast-colors.svg?url';
import zenImage from './images/zen-colors.svg?url';
import forwardImage from './images/forward-colors.svg?url';
import vhsImage from './images/vhs.png';
import reportImage from './images/report.png';
import laptopPhoneImage from './images/laptop-phone.png';
import dogImage from './images/dog.png';
import performanceImage from './images/performance.svg?url';
import frontiersImage from './images/frontiers-in-vet-science.png';
import ultrasoundImage from './images/vet-radio-and-ultrasound.png';

import './style.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { selectLoggedIn } from 'app/redux/global/selectors';

const testimonials = [
  {
    author: 'picoxIAIntelligence.testimonials.montpellier.author',
    authorRole: 'picoxIAIntelligence.testimonials.montpellier.authorRole',
    address: 'picoxIAIntelligence.testimonials.montpellier.address',
    content: 'picoxIAIntelligence.testimonials.montpellier.content',
    logo: 'logo-languedocia.png',
  },
  {
    author: 'picoxIAIntelligence.testimonials.lamentin.author',
    address: 'picoxIAIntelligence.testimonials.lamentin.address',
    content: 'picoxIAIntelligence.testimonials.lamentin.content',
    logo: 'logo-drliabeuf.png',
  },
  {
    author: 'picoxIAIntelligence.testimonials.saintCyr.author',
    address: 'picoxIAIntelligence.testimonials.saintCyr.address',
    content: 'picoxIAIntelligence.testimonials.saintCyr.content',
    logo: 'logo-generic.png',
  },
];

const PicoxIAIntelligencePage = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const intl = useIntl();

  const isLoggedIn = useSelector(selectLoggedIn);

  const locale = useSelector(selectLocale);
  const screenshotAdaptedToLanguage = locale === 'fr' ? AIScreenshotFr : AIScreenshotEn;

  return (
    <div className="picoxia">
      <AppHelmet
        titleIntlID="picoxIAIntelligence.metaTitle"
        descriptionIntlID="picoxIAIntelligence.metaDescription"
      />
      <div className="picoxIAIntelligence">
        <section className="container pageHeader">
          <div className="pageHeader__left">
            <h1 className="title title--big">
              <FormattedMessage id="picoxIAIntelligence.header.title" />
            </h1>
            <Hook>
              <FormattedMessage id="picoxIAIntelligence.header.hook" />
            </Hook>
            <p className="pageHeader__desc">
              <FormattedMessage id="picoxIAIntelligence.header.text" />
            </p>
            <div className="pageHeader__buttons">
              <Button url="/viewer" color="blue">
                <FormattedMessage id="picoxIAIntelligence.header.button.tryOut" />
              </Button>
              <Button url="/request-demo">
                <FormattedMessage id="picoxIAIntelligence.header.button.requestDemo" />
              </Button>
            </div>
          </div>
          <div className="pageHeader__image-bloc">
            <img
              src={screenshotAdaptedToLanguage}
              alt={intl.formatMessage({ id: 'picoxIAIntelligence.header.screenshot.alt' })}
              className="pageHeader__image"
            />
          </div>
        </section>

        <Gradient />
        <section className="container advantages">
          <CardsList>
            <Card>
              <Card.Icon icon={improveImage} />
              <Card.Title>
                <FormattedMessage id="picoxIAIntelligence.advantages.readingProcess.title" />
              </Card.Title>
              <p>
                <FormattedMessage id="picoxIAIntelligence.advantages.readingProcess.text" />
              </p>
            </Card>
            <Card>
              <Card.Icon icon={fastImage} />
              <Card.Title>
                <FormattedMessage id="picoxIAIntelligence.advantages.processFaster.title" />
              </Card.Title>
              <p>
                <FormattedMessage id="picoxIAIntelligence.advantages.processFaster.text" />
              </p>
            </Card>
            <Card>
              <Card.Icon icon={zenImage} />
              <Card.Title>
                <FormattedMessage id="picoxIAIntelligence.advantages.gainConfidence.title" />
              </Card.Title>
              <p>
                <FormattedMessage id="picoxIAIntelligence.advantages.gainConfidence.text" />
              </p>
            </Card>
            <Card>
              <Card.Icon icon={forwardImage} />
              <Card.Title>
                <FormattedMessage id="picoxIAIntelligence.advantages.dontChangeHabits.title" />
              </Card.Title>
              <p>
                <FormattedMessage id="picoxIAIntelligence.advantages.dontChangeHabits.text" />
              </p>
            </Card>
          </CardsList>
        </section>

        <section className="container efficiency">
          <h2 className="title title--space-below">
            <FormattedMessage id="picoxIAIntelligence.efficiency.title" />
          </h2>
          <div className="efficiency__grid">
            <div className="efficiency__column">
              <Hook as="h3" size="big">
                <FormattedMessage id="picoxIAIntelligence.efficiency.gainTime.title" />
              </Hook>
              <p className="efficiency__text">
                <FormattedMessage id="picoxIAIntelligence.efficiency.gainTime.text" />
              </p>
              <div className="efficiency__image-bloc">
                <img
                  src={vhsImage}
                  alt=""
                  className="efficiency__illustration efficiency__illustration--vhs"
                />
              </div>
            </div>
            <div className="efficiency__column">
              <Hook as="h3" size="big">
                <FormattedMessage id="picoxIAIntelligence.efficiency.checkFromAnywhere.title" />
              </Hook>
              <p className="efficiency__text">
                <FormattedMessage id="picoxIAIntelligence.efficiency.checkFromAnywhere.text" />
              </p>
              <div className="efficiency__image-bloc">
                <img src={laptopPhoneImage} alt="" className="efficiency__illustration" />
              </div>
            </div>
            <div className="efficiency__column efficiency__column--wide">
              <Hook as="h3" size="big">
                <FormattedMessage id="picoxIAIntelligence.efficiency.report.title" />
              </Hook>
              <p className="efficiency__text">
                <FormattedMessage id="picoxIAIntelligence.efficiency.report.text" />
              </p>
              <div className="efficiency__image-bloc">
                <img
                  src={reportImage}
                  alt=""
                  className="efficiency__illustration efficiency__illustration--report"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="container scientificValidation">
          <h2 className="title">
            <FormattedMessage id="picoxIAIntelligence.scientificValidation.title" />
          </h2>
          <div className="scientificValidation__content">
            <div>
              <p className="scientificValidation__text">
                <FormattedMessage id="picoxIAIntelligence.scientificValidation.text" />
              </p>
              <div className="scientificValidation__bottom">
                <div className="scientificValidation__buttons">
                  <Button url="/#scientific-validation">
                    <FormattedMessage id="picoxIAIntelligence.scientificValidation.button.learnMore" />
                  </Button>
                </div>
                <div className="scientificValidation__revues">
                  <img
                    src={frontiersImage}
                    alt={intl.formatMessage({
                      id: 'picoxIAIntelligence.scientificValidation.image.frontiersLogo.alt',
                    })}
                    className="scientificValidation__revue-frontiers"
                  />
                  <img
                    src={ultrasoundImage}
                    alt={intl.formatMessage({
                      id: 'picoxIAIntelligence.scientificValidation.image.ultrasoundLogo.alt',
                    })}
                    className="scientificValidation__revue-ultrasound"
                  />
                </div>
              </div>
            </div>
            <div className="scientificValidation__illustration-bloc">
              <img src={performanceImage} alt="" className="scientificValidation__image" />
            </div>
          </div>
        </section>

        <Gradient style={{ marginTop: '0', marginBottom: '-5rem' }} />
        <section className="testimonials">
          <div className="testimonials__slider">
            <Slider {...sliderSettings}>
              {testimonials.map((testimonial) => {
                const authorRole = testimonial.authorRole ? (
                  <p className="testimonials__authorRole">
                    <FormattedMessage id={testimonial.authorRole} />
                  </p>
                ) : null;
                return (
                  <div className="testimonials__slide" key={testimonial.author}>
                    <img
                      src={require('./images/' + testimonial.logo)}
                      alt=""
                      className="testimonials__logo"
                    />
                    <h3 className="testimonials__author">
                      <FormattedMessage id={testimonial.author} />
                    </h3>
                    {authorRole}
                    <address className="testimonials__address">
                      <FormattedMessage id={testimonial.address} />
                    </address>
                    <p className="testimonials__content">
                      {' '}
                      &quot;
                      <FormattedMessage id={testimonial.content} />
                      &quot;
                    </p>
                  </div>
                );
              })}
            </Slider>
          </div>
        </section>

        <section className="container getStarted">
          <h2 className="title">
            <FormattedMessage id="picoxIAIntelligence.getStarted.title" />
          </h2>
          <div className="getStarted__content">
            <div className="getStarted__left">
              <Hook>
                <FormattedMessage id="picoxIAIntelligence.getStarted.hook" />
              </Hook>
              <p className="getStarted__text">
                <FormattedMessage id="picoxIAIntelligence.getStarted.text" />
              </p>
              <div className="getStarted__buttons">
                {!isLoggedIn && (
                  <Button url="/register">
                    <FormattedMessage id="picoxIAIntelligence.getStarted.button.start" />
                  </Button>
                )}
                <Button url="/request-demo">
                  <FormattedMessage id="picoxIAIntelligence.getStarted.button.contact" />
                </Button>
              </div>
            </div>
            <div className="getStarted__illustration-bloc">
              <img src={dogImage} alt="" className="getStarted__image-dog" />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PicoxIAIntelligencePage;
