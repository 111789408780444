/* eslint-disable react/prefer-stateless-function */
import 'semantic-ui-css/semantic.min.css';
import 'app/styles/style.scss';
import './style.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ButtonRegister from 'app/components/Button/Register';
import { selectLoggedIn, selectUsername } from 'app/redux/global/selectors';
import LoginButton from '../LoginButton';
import LogoutButton from '../LogoutButton';
import IntlCrawlableLink from '../IntlCrawlableLink';

class AccountDropdown extends React.Component {
  render() {
    const { isLoggedIn, username } = this.props;

    if (isLoggedIn) {
      return (
        <Dropdown icon="user md" className="flex center account-dropdown" title={username}>
          <Dropdown.Menu as="ul" className="dd-menu account-dropdown__content">
            <li className="account-dropdown__item">
              <IntlCrawlableLink url="/account" className="item account-dropdown__link">
                <i className="user md icon account-dropdown__icon" />
                <FormattedMessage id="header.account.title" />
              </IntlCrawlableLink>
            </li>
            <li className="account-dropdown__item">
              <LogoutButton />
            </li>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <ul className="flex center loggedout-menu">
        <li className="flex center loggedout-menu__item">
          <ButtonRegister />
        </li>
        <li className="flex center loggedout-menu__item">
          <LoginButton />
        </li>
      </ul>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: selectLoggedIn(state),
  username: selectUsername(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountDropdown));
