import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { browserHistory } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Button, Modal, Checkbox } from 'semantic-ui-react';
import ApiCalls from '../../utils/apiCalls';
import { setAuthState } from '../../redux/global/actions';
import auth from '../../containers/auth';
import { selectLoggedIn, selectUsername } from 'app/redux/global/selectors';

class ResetPasswordButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      resetPasswordMailSent: false,
      alsoLogout: false,
    };
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  sendResetPasswordMail = async () => {
    this.setState({ isLoading: true });

    let actionAfterCompletion = this.close;
    if (this.state.alsoLogout) {
      // Don't logout if the mail was not sent :O
      await ApiCalls.logoutFromAllDevices();
      auth.removeToken();
      this.props.dispatch(setAuthState(false));
      actionAfterCompletion = () => browserHistory.push('/');
    }
    ApiCalls.sendResetPasswordMail({ username: this.props.username })
      .then(() => {
        this.setState({ resetPasswordMailSent: true, isLoading: false });
        setTimeout(actionAfterCompletion, 2000);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false, hasError: true });
      });
  };

  renderResetPassword = () => {
    if (this.state.resetPasswordMailSent) {
      return (
        <b style={{ color: 'green', fontSize: '1.3em' }}>
          <FormattedMessage id="login.resetPasswordMailSent" />
        </b>
      );
    }
    if (!this.state.displayResetMailInstructions) {
      return (
        <div>
          <a onClick={this.onPasswordForgottenClick}>
            <FormattedMessage id="login.forgottenPassword" />
          </a>
          <br />
          <a onClick={() => browserHistory.push('/register')}>
            <FormattedMessage id="login.register" />
          </a>
        </div>
      );
    }
  };

  displayResetMailInstructions = () => {
    if (this.state.displayResetMailInstructions) {
      return (
        <b style={{ fontSize: '1em', textAlign: 'center', marginBottom: '10px' }}>
          <FormattedMessage id="login.forgottenPasswordProcedure" />
        </b>
      );
    }
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  open = () => {
    this.setState({ isOpen: true });
  };

  beforeSendContent = () => (
    <Modal.Content>
      <div>
        <FormattedMessage id="login.forgottenPasswordProcedure" />
      </div>
      <div style={{ marginTop: '10px' }}>
        <Checkbox
          toggle
          label={this.formatMessage({ id: 'resetPasswordButton.alsoLogout' })}
          onChange={() => this.setState({ alsoLogout: !this.state.alsoLogout })}
        />
      </div>
    </Modal.Content>
  );

  beforeSendActions = () => (
    <Modal.Actions>
      <Button color="black" onClick={this.close}>
        <FormattedMessage id="general.cancel" />
      </Button>
      <Button
        content={this.formatMessage({ id: 'general.confirm' })}
        onClick={this.sendResetPasswordMail}
        loading={this.state.isLoading}
        positive
      />
    </Modal.Actions>
  );

  afterSendContent = () => (
    <Modal.Content>
      <FormattedMessage id="login.resetPasswordMailSent" />
    </Modal.Content>
  );

  afterSendActions = () => (
    <Modal.Actions>
      <Button content={this.formatMessage({ id: 'general.agree' })} onClick={this.close} positive />
    </Modal.Actions>
  );

  render() {
    return (
      <Modal
        onClose={this.close}
        onOpen={this.open}
        open={this.state.isOpen}
        size="mini"
        trigger={
          <Button>
            <FormattedMessage id="resetPasswordButton.title" />
          </Button>
        }
      >
        <Modal.Header>
          <FormattedMessage id="resetPasswordButton.title" />
        </Modal.Header>

        {this.state.resetPasswordMailSent ? this.afterSendContent() : this.beforeSendContent()}
        {this.state.resetPasswordMailSent ? this.afterSendActions() : this.beforeSendActions()}
      </Modal>
    );
  }
}

ResetPasswordButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: selectLoggedIn(state),
    username: selectUsername(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

// export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordButton);
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ResetPasswordButton));
