/* eslint-disable camelcase */
import _ from 'lodash';

import { NON_ANNOTATIONS_TOOLS } from 'app/components/Viewer/constants';
import extractAcquisitionDataFromDicomData from 'app/utils/dicom/extractAcquisitionDataFromDicomData';
import convertDisplayableImageDataToDataImage from 'app/utils/image/convertDisplayableImageDataToDataImage';
import isWorkListImage from 'app/utils/isWorkListImage';
import {
  anatomicRegionToString,
  getAnatomicRegionFromString,
  getDefaultAnatomicRegion,
} from 'app/utils/xrayRegions';
import {
  AcquisitionConstants,
  AnyAnnotation,
  DetectorInfo,
  DisplayableImageData,
  Feedbacks,
  ImageAnnotations,
  ImageDisplayableMetadata,
  ImageMetadata,
  ToolAnnotations,
  Viewport,
} from 'app/interfaces/Image';
import { Patient } from 'app/interfaces/Patient';
import { DataImage } from 'app/interfaces/DataImage';
import { SpeciesType } from 'app/constants/species';
import { DicomData } from 'app/interfaces/Dicom';
import { IntlShape } from 'react-intl';
import { ImageSaveState, ViewerImage, ViewerImages } from 'app/components/Viewer/types';
import { ConfigurableToolsKey } from 'app/adapters/ImageRenderer/ConfigurableToolsKeys';
import {
  computeScaleFactorFromStoredViewport,
  getMatchingResolutionViewport,
  getViewportDimensions,
  injectImageDimensionsInViewport,
  rescaleAnnotationsPoints,
} from 'app/utils/cornerstone/imageUtils';
import { convertRectToCropHandles } from 'app/CornerstoneTools/CropTool';
import { NegatoMode, NEGATO_MODE_INFO } from 'app/components/ToolsBar/NegatoButton';
import { ViewerState } from 'app/components/Viewer';
import { convertPredictionsToVHSAnnotation } from 'app/utils/predictions/convertPredictionsToVHSAnnotation';
import { convertPredictionsToNorbergOlssonAnnotation } from 'app/utils/predictions/convertPredictionsToNorbergOlssonAnnotation';
import produce, { current, isDraft, original } from 'immer';
import { PRECOMPUTED_TOOLS } from '../ToolsBar/PRECOMPUTED_TOOLS';
import { VISIBLE_TOOLS } from 'app/CornerstoneTools/constants';
import formatXRayImageViewerState from 'app/components/Viewer/formatXRayImageViewerState';
import { getDicomDataValue } from 'app/utils/dicom/DicomData';
import { ToolsStates } from 'app/adapters/ImageRenderer/ConfigurableToolsOptions';
import { makeAcquisitionDetailsFromImage } from 'app/components/Viewer/makeAcquisitionDetailsFromImage';
import { LoadedImage } from 'app/interfaces/ImageLoader';
import mergeWithArrayBuffer from 'app/utils/mergeWithArrayBuffer';
import { computeRealSizePixelSpacingFromActualSpacing } from 'app/CornerstoneTools/RealSizeMeasurementCalibrationTool';
import { onRealSizeMeasurementConfigurationToggle } from 'app/components/Dropzone/RealSizeMeasurementCalibrationConfiguration';
import { AllPredictions } from 'app/interfaces/Predictions';
import { ProcessingKind } from 'app/interfaces/IImageProcessor';
import { createImageToolsList } from 'app/adapters/ImageRenderer/DefaultCornerstoneToolsInitialization';

const DEFAULT_ACQUISITION_CONSTANTS = {
  thickness: 0,
  kV: 0,
  mA: 0,
  s: 0,
};
Object.freeze(DEFAULT_ACQUISITION_CONSTANTS);

export const IMAGE_LOAD_START = 'images/load_start' as const;
export const IMAGE_LOAD_SUCCESS = 'images/load_success' as const;
export const IMAGE_LOAD_FAILURE = 'images/load_failure' as const;
export const IMAGE_SELECT = 'images/select' as const;
export const PREDICTIONS_LOAD_START = 'predictions/load_start' as const;
export const PREDICTIONS_LOAD_SUCCESS = 'predictions/load_success' as const;
export const PREDICTIONS_LOAD_FAILURE = 'predictions/load_failure' as const;
export const PREDICTIONS_UPDATE_FEEDBACK = 'predictions/update_feedback' as const;

export const acquisitionAddWorkListImage = (
  imageId: string,
  { anatomicRegion = getDefaultAnatomicRegion() } = {}
) => ({
  type: 'acquisition/addWorkListImage' as const,
  payload: {
    imageId,
    anatomicRegion,
  },
});
export const acquisitionRedoImage = (imageId: string, imageIdToCopy: string) => ({
  type: 'acquisition/RedoImage' as const,
  payload: { imageId, imageIdToCopy },
});
export const acquisitionLoadWorkListImage = (
  imageId: string,
  {
    anatomicRegion = getDefaultAnatomicRegion(),
    acquisitionConstants = { ...DEFAULT_ACQUISITION_CONSTANTS },
  }: {
    anatomicRegion?: string;
    acquisitionConstants?: AcquisitionConstants;
  } = {}
) => ({
  type: 'acquisitionLoadWorkListImage' as const,
  payload: {
    imageId,
    anatomicRegion,
    acquisitionConstants,
  },
});
type AcquisitionReceiveAcquiredImagePayload = {
  acquiredImage: DataImage;
  detectorInfo: DetectorInfo;
  patient: Patient;
};
export const acquisitionReceiveAcquiredImage = (
  imageId: string,
  { acquiredImage, detectorInfo, patient }: AcquisitionReceiveAcquiredImagePayload
) => ({
  type: 'acquisition/ReceiveAcquiredImage' as const,
  payload: {
    imageId,
    acquiredImage,
    detectorInfo,
    acquisitionTime: new Date(),
    patient,
  },
});
export const acquisitionUpdateAnatomicRegion = (imageId: string, anatomicRegion: string) => ({
  type: 'acquisition/updateAnatomicRegion' as const,
  payload: {
    imageId,
    anatomicRegion,
  },
});
export const acquisitionUpdateAcquisitionConstants = (
  imageId: string,
  acquisitionConstants: AcquisitionConstants
) => ({
  type: 'acquisition/updateAcquisitionConstants' as const,
  payload: {
    imageId,
    acquisitionConstants,
  },
});

export const imagesUpdatePatientSpecie = (specie: SpeciesType) => ({
  type: 'images/updatePatientSpecie' as const,
  payload: { imageId: undefined as string, specie },
});

type ImageLoadStartPayload = {
  annotations?: ImageAnnotations;
  viewport?: Viewport;
  imageMetadata?: ImageMetadata;
  origin?: string;
  anatomicRegion?: string;
  acquisitionTime?: Date;
  acquisitionConstants?: AcquisitionConstants;
  detectorInfo?: DetectorInfo;
  isSelected?: boolean;
  isNewImage?: boolean;
  feedback?: Feedbacks;
};
export const imageLoadStart = (imageId: string, payload: ImageLoadStartPayload = {}) => ({
  type: IMAGE_LOAD_START,
  payload: {
    imageId,
    ..._.pick(payload, [
      'annotations',
      'viewport',
      'imageMetadata',
      'origin',
      'anatomicRegion',
      'acquisitionTime',
      'acquisitionConstants',
      'detectorInfo',
      'isSelected',
      'feedback',
      'isNewImage',
    ]),
  },
});
export const imageCacheInitialContent = (imageId: string, imageData: DisplayableImageData) => ({
  type: 'image/CacheInitialContent' as const,
  payload: { imageId, imageData },
});
export const imageLoadSuccess = (imageId: string, loadedImage: LoadedImage) => ({
  type: IMAGE_LOAD_SUCCESS,
  payload: { imageId, ...loadedImage },
});
export const imageLoadFailure = (imageId: string, loadError: boolean) => ({
  type: IMAGE_LOAD_FAILURE,
  payload: { imageId, loadError },
});
export const imageSelect = (imageId: string) => ({
  type: IMAGE_SELECT,
  payload: { imageId },
});
export const imageSelectNextWorklist = (imageId: string) => ({
  type: 'image/SelectNextWorklist' as const,
  payload: { imageId },
});
export const imageDelete = (imageId: string) => ({
  type: 'image/delete' as const,
  payload: { imageId },
});
export const imageLoadDicomData = (imageId: string, dicomData: DicomData) => ({
  type: 'imageLoadDicomData' as const,
  payload: { imageId, dicomData },
});
export const imageSetIsRawDataSaved = (imageId: string) => ({
  type: 'imageSetIsRawDataSaved' as const,
  payload: { imageId },
});
type ImageProcessingStartPayload = {
  // Could be specialized with Enum
  processingType: ProcessingKind;
  isReload?: boolean;
};
export const imageProcessingStart = (
  imageId: string,
  { processingType, isReload }: ImageProcessingStartPayload
) => ({
  type: 'image/ProcessingStart' as const,
  payload: { imageId, processingType, isReload },
});

type ImageProcessingDonePayload = {
  processedImage?: DataImage;
  isFromLastProcessing?: boolean;
  isNewProcessing?: boolean;
};
export const imageProcessingDone = (
  imageId: string,
  {
    processedImage,
    isFromLastProcessing = false,
    isNewProcessing = false,
  }: ImageProcessingDonePayload = {}
) => ({
  type: 'image/ProcessingDone' as const,
  payload: { imageId, processedImage, isFromLastProcessing, isNewProcessing },
});

type ImageUpdateProcessingOptionsPayload = {
  photometric_interpretation?: string;
};
export const imageUpdateProcessingOptions = (
  imageId: string,
  { photometric_interpretation }: ImageUpdateProcessingOptionsPayload = {}
) => ({
  type: 'image/UpdateProcessingOptions' as const,
  payload: { imageId, photometric_interpretation },
});

export const predictionsLoadStart = (imageId: string) => ({
  type: PREDICTIONS_LOAD_START,
  payload: { imageId },
});
export const predictionsLoadSuccess = (imageId: string, predictions: AllPredictions) => ({
  type: PREDICTIONS_LOAD_SUCCESS,
  payload: { imageId, predictions },
});
export const predictionsLoadFailure = (imageId: string, inferenceError: any) => ({
  type: PREDICTIONS_LOAD_FAILURE,
  payload: { imageId, inferenceError },
});
export const feedbackUpdate = (imageId: string, patternName: string, isPositive: boolean) => ({
  type: PREDICTIONS_UPDATE_FEEDBACK,
  payload: { imageId, patternName, isPositive },
});
export const predictionsSetActiveRegion = (imageId: string, activeRegionName: string) => ({
  type: 'predictions/set_active_region' as const,
  payload: { imageId, activeRegionName },
});
export const toolsUpdateStates = (imageId: string, toolsState: object) => ({
  type: 'tools/updateStates' as const,
  payload: { imageId, toolsState },
});
export const toolsInitialAnnotationSkipped = (imageId: string) => ({
  type: 'tools/initialAnnotationSkipped' as const,
  payload: { imageId },
});
export const toolsUpdateAnnotation = (
  imageId: string,
  toolName: string,
  measurementData: AnyAnnotation
) => ({
  type: 'tools/updateAnnotation' as const,
  payload: { imageId, toolName, measurementData },
});
export const toolsUpdateViewport = (imageId: string, viewport: Viewport) => ({
  type: 'tools/updateViewport' as const,
  payload: { imageId, viewport },
});
export const toolsRemoveAnnotation = (imageId: string, toolName: string, uuid: string) => ({
  type: 'tools/removeAnnotation' as const,
  payload: { imageId, toolName, uuid },
});
export const toolsChangeAnnotationsVisibility = (imageId: string, showAnnotations: boolean) => ({
  type: 'toolsChangeAnnotationsVisibility' as const,
  payload: { imageId, showAnnotations },
});
export const toolsSwitchRealSizeCalibration = (imageId: string, isOn?: boolean) => ({
  type: 'toolsSwitchRealSizeCalibration' as const,
  payload: { imageId, isOn },
});
export const toolsConfirmRealSizeCalibration = (imageId: string) => ({
  type: 'toolsConfirmRealSizeCalibration' as const,
  payload: { imageId },
});
export const toolsCancelRealSizeCalibration = (imageId: string) => ({
  type: 'toolsCancelRealSizeCalibration' as const,
  payload: { imageId },
});
export const toolsResetRealSizeCalibration = (imageId: string) => ({
  type: 'toolsResetRealSizeCalibration' as const,
  payload: { imageId },
});

export const pacsSyncNeeded = (imageId: string) => ({
  type: 'pacsSyncNeeded' as const,
  payload: { imageId },
});
export const pacsStartSync = (imageId: string) => ({
  type: 'pacsStartSync' as const,
  payload: { imageId },
});
type PacsEndSyncPayload = {
  syncError?: boolean;
};
export const pacsEndSync = (imageId: string, { syncError }: PacsEndSyncPayload = {}) => ({
  type: 'pacsEndSync' as const,
  payload: { imageId, syncError },
});
export const displayableMetadataUpdate = (imageId: string, metadata: ImageDisplayableMetadata) => ({
  type: 'displayableMetadataUpdate' as const,
  payload: { imageId, metadata },
});
export const negatoChangeMode = (negatoMode: NegatoMode) => ({
  type: 'negatoChangeMode' as const,
  payload: { negatoMode },
});
export const negatoSelectView = (viewIndex: number) => ({
  type: 'negatSelectView' as const,
  payload: { viewIndex },
});

const selectImage = ({ images, selectedNegatoView }: ViewerState, imageId: string) => {
  let previousNegatoView;
  let previousDisplayedImage: ViewerImage;
  _.forEach(images, (draftImage, loopImageId) => {
    draftImage.isSelected = loopImageId === imageId;
    // Don't try to put worklist image into negatoview
    if (isWorkListImage(images[imageId])) return;

    // Here we trade places of negato view on selection
    if (draftImage.isSelected) {
      if (draftImage.negatoView !== selectedNegatoView) {
        previousNegatoView = draftImage.negatoView;
      }
      draftImage.negatoView = selectedNegatoView;
    } else if (draftImage.negatoView === selectedNegatoView) {
      draftImage.negatoView = undefined;
      previousDisplayedImage = draftImage;
    }
  });
  if (previousDisplayedImage && previousNegatoView !== undefined) {
    previousDisplayedImage.negatoView = previousNegatoView;
  }
};

const selectNegatoViewInImages = (draftImages: ViewerImages, selectedNegatoView: number = 0) => {
  _.forEach(draftImages, (draftImage) => {
    draftImage.isSelected = draftImage.negatoView === selectedNegatoView;
  });
};

const initWorkListImage = ({
  anatomicRegion,
  acquisitionConstants = DEFAULT_ACQUISITION_CONSTANTS,
  isSelected,
}: {
  anatomicRegion: string;
  acquisitionConstants?: AcquisitionConstants;
  isSelected?: boolean;
}) => ({
  isSelected,
  anatomicRegion,
  acquisitionConstants,
  isImageLoading: false,
  displayableImage: undefined as any,
  loadError: undefined as any,
  predictions: undefined as any,
  isPredictionsLoading: false,
  feedback: undefined as any,
  inferenceError: undefined as any,
  origin: 'worklist',
  saveState: {},
  reloadedState: {
    acquisitionInfo: formatXRayImageMainState({ anatomicRegion, acquisitionConstants }),
  },
});

const TYPES_WITHOUT_IMAGE_EXISTENCE_REQUIREMENT = [
  'images/updatePatientSpecie',
  'acquisition/addWorkListImage',
  'acquisition/RedoImage',
  'acquisitionLoadWorkListImage',
  'acquisition/ReceiveAcquiredImage',
  IMAGE_LOAD_START,
  'negatoChangeMode',
  'negatSelectView',
];
type State = {
  images: ViewerImages;
  imagesOrder: string[];
};
export type Action = (
  | ReturnType<typeof imageLoadStart>
  | ReturnType<typeof acquisitionAddWorkListImage>
  | ReturnType<typeof acquisitionRedoImage>
  | ReturnType<typeof acquisitionLoadWorkListImage>
  | ReturnType<typeof acquisitionReceiveAcquiredImage>
  | ReturnType<typeof acquisitionUpdateAnatomicRegion>
  | ReturnType<typeof acquisitionUpdateAcquisitionConstants>
  | ReturnType<typeof imagesUpdatePatientSpecie>
  | ReturnType<typeof imageCacheInitialContent>
  | ReturnType<typeof imageLoadSuccess>
  | ReturnType<typeof imageLoadFailure>
  | ReturnType<typeof imageSelect>
  | ReturnType<typeof imageSelectNextWorklist>
  | ReturnType<typeof imageDelete>
  | ReturnType<typeof imageLoadDicomData>
  | ReturnType<typeof imageSetIsRawDataSaved>
  | ReturnType<typeof imageProcessingStart>
  | ReturnType<typeof imageProcessingDone>
  | ReturnType<typeof imageUpdateProcessingOptions>
  | ReturnType<typeof predictionsLoadStart>
  | ReturnType<typeof predictionsLoadSuccess>
  | ReturnType<typeof predictionsLoadFailure>
  | ReturnType<typeof feedbackUpdate>
  | ReturnType<typeof predictionsSetActiveRegion>
  | ReturnType<typeof toolsUpdateStates>
  | ReturnType<typeof toolsInitialAnnotationSkipped>
  | ReturnType<typeof toolsUpdateAnnotation>
  | ReturnType<typeof toolsUpdateViewport>
  | ReturnType<typeof toolsRemoveAnnotation>
  | ReturnType<typeof toolsChangeAnnotationsVisibility>
  | ReturnType<typeof toolsSwitchRealSizeCalibration>
  | ReturnType<typeof toolsConfirmRealSizeCalibration>
  | ReturnType<typeof toolsCancelRealSizeCalibration>
  | ReturnType<typeof toolsResetRealSizeCalibration>
  | ReturnType<typeof pacsSyncNeeded>
  | ReturnType<typeof pacsStartSync>
  | ReturnType<typeof pacsEndSync>
  | ReturnType<typeof displayableMetadataUpdate>
  | ReturnType<typeof negatoChangeMode>
  | ReturnType<typeof negatoSelectView>
) & { payload: { imageId?: string } };
type Dependencies = {
  current: [IntlShape];
};

function adaptViewportAndAnnotationsToLoadedImage(draftImage: ViewerImage) {
  const { displayableImage, viewport, annotations } = draftImage;
  if (!viewport || !displayableImage) return;

  let viewportToInject = viewport;
  if (
    !_.isEqual(
      _.pick(displayableImage, ['width', 'height']),
      _.pick(getViewportDimensions(viewport), ['width', 'height'])
    )
  ) {
    const scaleFactor = computeScaleFactorFromStoredViewport(
      viewport,
      displayableImage
    ).scaleFactor;
    if (annotations) {
      draftImage.annotations = rescaleAnnotationsPoints(annotations, scaleFactor);
    }
    viewportToInject = injectImageDimensionsInViewport(viewport, displayableImage);
  }

  viewportToInject = getMatchingResolutionViewport(viewportToInject, displayableImage);

  draftImage.viewport = viewportToInject;
}

function injectPixelSpacingIntoDisplayableImage(
  draftImage: ViewerImage,
  image: DisplayableImageData
) {
  const { rowPixelSpacing, columnPixelSpacing } = draftImage?.initialDataImage ?? {};
  return { ...image, rowPixelSpacing, columnPixelSpacing };
}

function getNextEmptyNegatoViewPanel(
  images: ViewerImages,
  currentNegatoViewPanel: number,
  viewCount: number = 1
) {
  let nextNegatoViewPanel = currentNegatoViewPanel;
  while (++nextNegatoViewPanel < viewCount) {
    if (!_.find(images, { negatoView: nextNegatoViewPanel })) return nextNegatoViewPanel;
  }
  return undefined;
}

const hasMovedHandlesInTool = (imageAnnotation: ToolAnnotations) => {
  for (const k in imageAnnotation) {
    if (_.some(imageAnnotation[k].handles, 'hasMoved')) return true;
  }
};

const computePrecomputedAnnotations = (image: ViewerImage) => {
  const { displayableImage, predictions, annotations: imageAnnotations } = image;
  let annotations: ImageAnnotations = undefined;
  if (!displayableImage || !predictions) return undefined;
  if ('vhs' in predictions && !hasMovedHandlesInTool(imageAnnotations?.VHS)) {
    const VHSAnnotations = convertPredictionsToVHSAnnotation(predictions.vhs, displayableImage);
    if (VHSAnnotations) {
      VHSAnnotations.uuid = 'computed_uuid';
      annotations = _.merge(annotations, { VHS: { computed_uuid: VHSAnnotations } });
    }
  }
  if ('norberg_olsson' in predictions && !hasMovedHandlesInTool(imageAnnotations?.NorbergOlsson)) {
    const NorbergOlssonAnnotation = convertPredictionsToNorbergOlssonAnnotation(
      predictions.norberg_olsson,
      displayableImage
    );
    if (NorbergOlssonAnnotation) {
      NorbergOlssonAnnotation.uuid = 'computed_uuid';

      annotations = _.merge(annotations, {
        NorbergOlsson: { computed_uuid: NorbergOlssonAnnotation },
      });
    }
  }
  return annotations;
};

function checkForChange<T>(initialState: T, currentState: T, propertyPath: string) {
  if (!isDraft(currentState)) return true;
  return _.get(original(currentState), propertyPath) !== _.get(initialState, propertyPath);
}

function checkForImageChange<T>(
  initialState: T,
  currentState: T,
  imageId: string,
  propertyPath: string
) {
  return checkForChange(initialState, currentState, `images.${imageId}.${propertyPath}`);
}

function handleComputedToolsActivation(image: ViewerImage, newToolsState: ToolsStates) {
  PRECOMPUTED_TOOLS.some((toolName) => {
    if (!newToolsState[toolName]) return false;

    const isSwitchToVisibleState = newToolsState[toolName].state in VISIBLE_TOOLS;
    if (!isSwitchToVisibleState) return false;

    const isToolWithoutAnnotations = _.isEmpty(image.annotations?.[toolName]);

    if (isToolWithoutAnnotations) return false;

    return image.toolsList[toolName].state !== newToolsState[toolName].state;
  });

  // We are switching to visible state and have no precomputed tools, we try to inject one.
  const precomputedAnnotation = computePrecomputedAnnotations(image);
  if (!precomputedAnnotation) return;
  image.annotations = _.merge(image.annotations, precomputedAnnotation);
}

enum DisplayState {
  ALREADY_DISPLAYED,
  DISPLAYED,
  NOT_DISPLAYABLE,
  NO_FREE_VIEW,
}
function displayImageInFreeView(draftState: ViewerState, imageId: string) {
  const draftImages = draftState.images;
  if (!draftImages[imageId] || isWorkListImage(draftImages[imageId])) {
    return DisplayState.NOT_DISPLAYABLE;
  }
  if (draftImages[imageId].negatoView !== undefined) {
    return DisplayState.ALREADY_DISPLAYED;
  }

  const { viewCount = 1 } = NEGATO_MODE_INFO[draftState.negatoMode as NegatoMode];
  const nextEmptyNegatoViewPanel = getNextEmptyNegatoViewPanel(draftImages, 0, viewCount);

  if (nextEmptyNegatoViewPanel === undefined) {
    return DisplayState.NO_FREE_VIEW;
  }

  draftImages[imageId].negatoView = nextEmptyNegatoViewPanel;
  return DisplayState.DISPLAYED;
}

function applyPixelSpacingFromRealSizeMeasurement(image: ViewerImage) {
  const realSizeMeasurement = Object.values(
    image?.annotations?.RealSizeMeasurementCalibration ?? {}
  )[0];
  if (!realSizeMeasurement) return;
  const { rowPixelSpacing = 1, columnPixelSpacing = 1 } = image?.initialDataImage ?? {};

  const newPixelSpacing = computeRealSizePixelSpacingFromActualSpacing(
    { rowPixelSpacing, columnPixelSpacing },
    realSizeMeasurement
  );
  if (!newPixelSpacing) {
    image.annotations.RealSizeMeasurementCalibration = undefined;
    return;
  }
  image.displayableImage.columnPixelSpacing = newPixelSpacing.columnPixelSpacing;
  image.displayableImage.rowPixelSpacing = newPixelSpacing.rowPixelSpacing;
}

const ACQUISITION_STATE_KEYS_NO_RENAME = [
  'acquisitionConstants',
  'anatomicRegion',
  'detectorInfo',
  'acquisitionTime',
  'feedback',
];
const ALL_ACQUISITION_STATE_KEYS = [...ACQUISITION_STATE_KEYS_NO_RENAME, 'imageMetadata'];
export const formatXRayImageMainState = (
  image: Partial<ViewerImage>
): ImageSaveState['acquisitionInfo'] =>
  _.omitBy(
    {
      ..._.pick(image, ACQUISITION_STATE_KEYS_NO_RENAME),
      image_metadata: image.imageMetadata,
    },
    _.isUndefined
  );
export const formatRawImageSaveState = (
  image: ViewerImage,
  imageIndex: number,
  patient: Patient
): ImageSaveState['rawImage'] => ({
  dataImage: image.initialDataImage,
  acquisitionDetails: makeAcquisitionDetailsFromImage(patient, image, imageIndex),
  dicomData: image.dicomData,
});

const RAW_IMAGE_IMAGE_STATE_KEYS = [
  'initialDataImage',
  'dicomData',
  'acquisitionTime',
  'acquisitionConstants',
  'anatomicRegion',
  'detectorInfo',
];
const updateSavedState = (initialState: ViewerState, draftState: ViewerState) => {
  _.forEach(draftState.images, (draftImage, imageId) => {
    const hasMainStateChanged = _.some(ALL_ACQUISITION_STATE_KEYS, (key) =>
      checkForImageChange(initialState, draftState, imageId, key)
    );
    if (hasMainStateChanged) {
      draftImage.saveState.acquisitionInfo = formatXRayImageMainState(draftImage);
    }
    const hasViewerStateChanged = _.some(['annotations', 'viewport'], (key) =>
      checkForImageChange(initialState, draftState, imageId, key)
    );
    if (hasViewerStateChanged) {
      draftImage.saveState.viewer = formatXRayImageViewerState(draftImage);
    }
    const hasRawStateChanged =
      draftImage.needRawSave &&
      _.some(RAW_IMAGE_IMAGE_STATE_KEYS, (key) =>
        checkForImageChange(initialState, draftState, imageId, key)
      );
    if (hasRawStateChanged || initialState.patient !== draftState.patient) {
      const imageIndex = _.findIndex(draftState.imagesOrder, (id) => id === imageId);
      draftImage.saveState.rawImage = formatRawImageSaveState(
        draftImage,
        imageIndex,
        draftState.patient
      );
    }
  });
};

/**
 * Images reducer to be used with useImmerReducer
 */
const actionReducer = (
  draftState: ViewerState,
  { type, payload }: Action,
  { current: [intl] }: Dependencies
) => {
  const imageId = payload.imageId;
  const { images: draftImages, imagesOrder: draftImagesOrder } = draftState;

  if (!draftImages[imageId] && !TYPES_WITHOUT_IMAGE_EXISTENCE_REQUIREMENT.includes(type)) {
    return;
  }
  if (type === 'images/updatePatientSpecie') {
    const { specie } = payload;
    _.forEach(draftImages, (draftImage) => {
      if (!draftImage.anatomicRegion) return;
      draftImage.anatomicRegion = anatomicRegionToString({
        ...getAnatomicRegionFromString(draftImage.anatomicRegion),
        specie,
      });
    });
  } else if (type === 'acquisition/updateAnatomicRegion') {
    const { anatomicRegion } = payload;
    draftImages[imageId].anatomicRegion = anatomicRegion;

    if (draftImages[imageId].imageMetadata?.PACS) {
      _.merge(draftImages[imageId].imageMetadata, { PACS: { isSync: false } });
    }
  } else if (type === 'acquisition/addWorkListImage') {
    const { anatomicRegion }: { anatomicRegion: string } = payload;
    draftImages[imageId] = initWorkListImage({ anatomicRegion });
    draftImagesOrder.push(imageId);
    selectImage(draftState, imageId);
  } else if (type === 'acquisition/RedoImage') {
    const { imageIdToCopy } = payload;
    draftImages[imageId] = initWorkListImage({
      anatomicRegion: draftImages[imageIdToCopy].anatomicRegion,
      acquisitionConstants: draftImages[imageIdToCopy].acquisitionConstants,
    });
    draftImagesOrder.splice(
      draftImagesOrder.findIndex((element) => element === imageIdToCopy),
      0,
      imageId
    );
    selectImage(draftState, imageId);
  } else if (type === 'acquisitionLoadWorkListImage') {
    const { anatomicRegion, acquisitionConstants } = payload;
    const isFirstImage = _.isEmpty(draftImages);
    draftImages[imageId] = initWorkListImage({
      anatomicRegion,
      acquisitionConstants,
    });
    draftImagesOrder.push(imageId);
    if (isFirstImage) selectImage(draftState, imageId);
  } else if (type === 'acquisition/ReceiveAcquiredImage') {
    const { acquiredImage, detectorInfo, acquisitionTime, patient } =
      payload as AcquisitionReceiveAcquiredImagePayload & { acquisitionTime: Date };
    const imageAlreadyExist = draftImagesOrder.includes(imageId);

    const { acquisitionConstants, anatomicRegion = getDefaultAnatomicRegion(patient?.specie) } =
      draftImages[imageId] ?? {};
    draftImages[imageId] = _.merge(draftImages[imageId], {
      origin: 'acquisition',
      anatomicRegion,
      detectorInfo,
      acquisitionTime,
      toolsList: createImageToolsList(() => intl),
      photometric_interpretation: 'MONOCHROME',
      // We only care to save xRayConstants when acquisition is effectively done.
      acquisitionConstants,
      imageMetadata: { PACS: { isSync: false } },
      PACS: { isSyncOngoing: false },
      saveState: {},
      needRawSave: true,
    });
    // We extract pixelSpacing from detectorInfo to inject it into the base image
    const [rowPixelSpacing, columnPixelSpacing] = detectorInfo?.pixelSpacing ?? [];
    // lodash merge seems to copy the content of ArrayBuffer.
    // We do not wish that for caching and performance reason.
    draftImages[imageId].initialDataImage = {
      ...acquiredImage,
      rowPixelSpacing,
      columnPixelSpacing,
    };
    const isPMSWorklistSudy = !!draftState.pms_id;

    if (isPMSWorklistSudy) {
      draftImages[imageId].dicomData = _.merge(
        draftImages[imageId].dicomData ?? {},
        _.pickBy({
          AccessionNumber: draftState.pms_id,
        })
      );
    }
    const isPACSWorklistStudy = !!(
      draftState.requested_procedure_id ||
      draftState.study_instance_uid ||
      draftState.scheduled_procedure_step_id ||
      draftState.accession_number
    );
    if (isPACSWorklistStudy) {
      draftImages[imageId].dicomData = _.merge(
        draftImages[imageId].dicomData ?? {},
        _.pickBy({
          RequestedProcedureID: draftState.requested_procedure_id,
          StudyInstanceUID: draftState.study_instance_uid,
          ScheduledProcedureStepID: draftState.scheduled_procedure_step_id,
          AccessionNumber: draftState.accession_number,
        })
      );
    }
    if (!imageAlreadyExist) {
      draftImagesOrder.push(imageId);
    }
    displayImageInFreeView(draftState, imageId);
    selectImage(draftState, imageId);
  } else if (type === 'acquisition/updateAcquisitionConstants') {
    const { acquisitionConstants } = payload;
    draftImages[imageId].acquisitionConstants = acquisitionConstants;
  } else if (type === IMAGE_LOAD_START) {
    const { annotations, isSelected, isNewImage } = payload;
    const imageLoadPayload = _.omit(payload as ImageLoadStartPayload, ['imageId', 'isSelected']);
    const isExistingImage = imageId in draftImages;
    const isFirstImage = _.isEmpty(draftImages);

    draftImages[imageId] = {
      isImageLoading: true,
      displayableImage: undefined,
      loadError: undefined,
      predictions: undefined,
      isPredictionsLoading: false,
      isRealSizeMeasurementCalibration: false,
      inferenceError: undefined,
      toolsList: createImageToolsList(() => intl),
      ...imageLoadPayload,
      isInitialAnnotations: !!annotations,
      PACS: { isSyncOngoing: false },
      saveState: {},
      reloadedState: {
        acquisitionInfo: formatXRayImageMainState(imageLoadPayload),
        viewer: formatXRayImageViewerState(imageLoadPayload),
      },
      ...draftImages[imageId],
    };
    if (!isExistingImage) {
      draftImagesOrder.push(imageId);
      displayImageInFreeView(draftState, imageId);
    }
    if (isFirstImage) selectImage(draftState, imageId);
    if (isSelected) selectImage(draftState, imageId);
  } else if (type === IMAGE_LOAD_SUCCESS) {
    const { imageData, dicomData, filename } = payload;
    const { isNewImage } = draftImages[imageId];
    draftImages[imageId].isImageLoading = false;

    draftImages[imageId].initialDataImage ??= {
      ...convertDisplayableImageDataToDataImage(imageData),
      rowPixelSpacing: imageData.rowPixelSpacing,
      columnPixelSpacing: imageData.columnPixelSpacing,
    };

    draftImages[imageId].displayableImage = injectPixelSpacingIntoDisplayableImage(
      draftImages[imageId],
      imageData
    );

    draftImages[imageId].loadError = undefined;
    draftImages[imageId].filename = filename;
    adaptViewportAndAnnotationsToLoadedImage(draftImages[imageId]);
    applyPixelSpacingFromRealSizeMeasurement(draftImages[imageId]);

    if (dicomData) {
      const { detectorInfo, acquisitionTime, patient } =
        extractAcquisitionDataFromDicomData(dicomData);
      // Correct patientID if it comes from picoxia ID
      if (patient.file_id === draftState.patient._id) {
        delete patient.file_id;
      }
      draftImages[imageId].photometric_interpretation = getDicomDataValue(
        dicomData,
        'PhotometricInterpretation'
      );
      draftImages[imageId].dicomData = _.merge(draftImages[imageId].dicomData ?? {}, dicomData);
      draftImages[imageId].detectorInfo ??= detectorInfo;
      draftImages[imageId].acquisitionTime ??= acquisitionTime;

      if (isNewImage) {
        draftImages[imageId].needRawSave = true;
      }
    }
    draftImages[imageId].isNewImage = false;
  } else if (type === 'image/CacheInitialContent') {
    const { imageData } = payload;
    draftImages[imageId].initialDataImage = {
      ...convertDisplayableImageDataToDataImage(imageData),
      rowPixelSpacing: imageData.rowPixelSpacing,
      columnPixelSpacing: imageData.columnPixelSpacing,
    };
  } else if (type === IMAGE_LOAD_FAILURE) {
    const { loadError } = payload;
    draftImages[imageId].isImageLoading = false;
    draftImages[imageId].loadError = loadError;
  } else if (type === IMAGE_SELECT) {
    selectImage(draftState, imageId);
  } else if (type === 'image/SelectNextWorklist') {
    const selectedImagePosition = draftImagesOrder.findIndex(
      (loopImageId) => loopImageId === imageId
    );
    let nextImageId;
    for (let i = 1; i < draftImagesOrder.length; i += 1) {
      const currentPosition = (selectedImagePosition + i) % draftImagesOrder.length;
      if (isWorkListImage(draftImages[draftImagesOrder[currentPosition]])) {
        nextImageId = draftImagesOrder[currentPosition];
        break;
      }
    }
    if (nextImageId) selectImage(draftState, nextImageId);
  } else if (type === 'image/delete') {
    const deletedImageIndex = draftImagesOrder.indexOf(imageId);
    const { viewCount = 1 } = NEGATO_MODE_INFO[draftState.negatoMode as NegatoMode];
    if (draftImages[imageId].isSelected && viewCount === 1) {
      const nextImageId =
        draftImagesOrder[deletedImageIndex + 1] ?? draftImagesOrder[deletedImageIndex - 1];
      if (nextImageId) selectImage(draftState, nextImageId);
    }
    draftImagesOrder.splice(deletedImageIndex, 1);

    delete draftImages[imageId];
  } else if (type === 'imageSetIsRawDataSaved') {
    draftImages[imageId].isRawSavedOnce = true;
    draftImages[imageId].needRawSave = false;
  } else if (type === 'image/ProcessingStart') {
    const { processingType, isReload } = payload;
    draftImages[imageId].isProcessingOngoing = true;
    draftImages[imageId].imageMetadata = _.merge(draftImages[imageId].imageMetadata ?? {}, {
      processingType,
    });
    if (draftImages[imageId].imageMetadata.PACS && !isReload) {
      _.merge(draftImages[imageId].imageMetadata, { PACS: { isSync: false } });
    }
  } else if (type === 'image/ProcessingDone') {
    const { processedImage, isNewProcessing } = payload;
    draftImages[imageId].isProcessingOngoing = false;
    draftImages[imageId].processedImage = processedImage;
    draftImages[imageId].isFromLastProcessing = payload.isFromLastProcessing;
    draftImages[imageId].isImageNewPredictionsNeeded = isNewProcessing;
    if (isNewProcessing) {
      draftImages[imageId].viewport = _.merge(draftImages[imageId].viewport, {
        invert: false,
        voi: { windowCenter: processedImage.windowCenter, windowWidth: processedImage.windowWidth },
        uint16Voi: {
          windowCenter: processedImage.windowCenter,
          windowWidth: processedImage.windowWidth,
        },
      });
    }
    if (processedImage.collimation_rect) {
      const [cropUuid = 'crop_uuid'] = Object.keys(draftImages[imageId].annotations?.Crop ?? {});
      draftImages[imageId].annotations = _.merge(draftImages[imageId].annotations ?? {}, {
        Crop: {
          [cropUuid]: {
            ...convertRectToCropHandles(processedImage.collimation_rect),
            uuid: cropUuid,
          },
        },
      });
    }
  } else if (type === 'image/UpdateProcessingOptions') {
    _.merge(draftImages[imageId], {
      photometric_interpretation: payload.photometric_interpretation,
    });
  } else if (type === PREDICTIONS_LOAD_START) {
    draftImages[imageId].isPredictionsLoading = true;
    draftImages[imageId].inferenceError = undefined;
  } else if (type === PREDICTIONS_LOAD_SUCCESS) {
    const { predictions } = payload;
    draftImages[imageId].isPredictionsLoading = false;
    draftImages[imageId].predictions = predictions;
    draftImages[imageId].isImageNewPredictionsNeeded = false;
    computePrecomputedAnnotations(draftImages[imageId]);
    const precomputedAnnotation = computePrecomputedAnnotations(draftImages[imageId]);
    if (precomputedAnnotation) {
      draftImages[imageId].annotations = _.merge(
        draftImages[imageId].annotations,
        precomputedAnnotation
      );
    }
  } else if (type === PREDICTIONS_LOAD_FAILURE) {
    const { inferenceError } = payload;
    draftImages[imageId].isPredictionsLoading = false;
    draftImages[imageId].inferenceError = inferenceError;
  } else if (type === PREDICTIONS_UPDATE_FEEDBACK) {
    const { patternName, isPositive } = payload;
    draftImages[imageId].feedback = { ...draftImages[imageId].feedback, [patternName]: isPositive };
  } else if (type === 'predictions/set_active_region') {
    const { activeRegionName } = payload;
    draftImages[imageId].activeRegionName = activeRegionName;
  } else if (type === 'tools/updateStates') {
    const { toolsState } = payload;
    _.merge(draftImages[imageId].toolsList, toolsState);
    handleComputedToolsActivation(draftImages[imageId], toolsState);
  } else if (type === 'tools/updateAnnotation') {
    const { toolName, measurementData } = payload;
    draftImages[imageId].isAnnotationsSaved = false;
    draftImages[imageId].annotations = _.merge(draftImages[imageId].annotations, {
      [toolName]: { [measurementData.uuid]: measurementData },
    });
  } else if (type === 'tools/updateViewport') {
    const { viewport } = payload;
    draftImages[imageId].viewport = _.merge(draftImages[imageId].viewport, viewport);
  } else if (type === 'tools/removeAnnotation') {
    const { toolName, uuid } = payload;
    draftImages[imageId].isAnnotationsSaved = false;
    delete draftImages[imageId].annotations?.[toolName as ConfigurableToolsKey]?.[uuid];
  } else if (type === 'tools/initialAnnotationSkipped') {
    draftImages[imageId].isInitialAnnotations = false;
  } else if (type === 'toolsChangeAnnotationsVisibility') {
    const { showAnnotations } = payload;
    const image = draftImages[imageId];
    if (!image.toolsList) return;

    if (!showAnnotations) {
      if (draftImages[imageId].shownAnnotations) return;
      const annotationsToolsName = _.difference(
        Object.keys(image.toolsList),
        NON_ANNOTATIONS_TOOLS
      );

      const shownAnnotations = _.mapValues(
        _.pick(image.toolsList, annotationsToolsName),
        ({ state }) => ({ state })
      );
      draftImages[imageId].shownAnnotations = shownAnnotations;
      const disabledAnnotationsStates = _.fromPairs(
        annotationsToolsName.map((toolName) => [toolName, { state: 'disabled' }])
      );
      _.merge(draftImages[imageId].toolsList, disabledAnnotationsStates);
    } else {
      _.merge(draftImages[imageId].toolsList, draftImages[imageId].shownAnnotations);
      draftImages[imageId].shownAnnotations = undefined;
    }
  } else if (type === 'toolsSwitchRealSizeCalibration') {
    const image = draftImages[imageId];
    let { isOn } = payload;
    if (isOn === undefined) {
      isOn = !image.isRealSizeMeasurementCalibration;
    }
    image.isRealSizeMeasurementCalibration = isOn;

    onRealSizeMeasurementConfigurationToggle(
      image.isRealSizeMeasurementCalibration,
      {
        ...draftState.commonToolsList,
        ...image.toolsList,
      },
      (updateFn: (toolsList: ToolsStates) => void) => updateFn(image.toolsList),
      (updateFn: (toolsList: ToolsStates) => void) => updateFn(draftState.commonToolsList)
    );
    if (isOn) {
      image.pendingAnnotations = _.merge(image.pendingAnnotations, {
        RealSizeMeasurementCalibration: _.cloneDeep(
          image.annotations?.RealSizeMeasurementCalibration
        ),
      });
    }
  } else if (type === 'toolsConfirmRealSizeCalibration') {
    const image = draftImages[imageId];
    applyPixelSpacingFromRealSizeMeasurement(image);
  } else if (type === 'toolsCancelRealSizeCalibration') {
    const image = draftImages[imageId];
    image.annotations.RealSizeMeasurementCalibration =
      image.pendingAnnotations?.RealSizeMeasurementCalibration;
  } else if (type === 'toolsResetRealSizeCalibration') {
    const image = draftImages[imageId];
    image.annotations.RealSizeMeasurementCalibration = undefined;
    // We only restore image if we have associated pixel spacing from dicom data
    image.displayableImage = injectPixelSpacingIntoDisplayableImage(image, image.displayableImage);
  } else if (type === 'pacsStartSync') {
    draftImages[imageId].PACS.isSyncOngoing = true;
  } else if (type === 'pacsEndSync') {
    const { syncError } = payload;
    draftImages[imageId].PACS.syncError = syncError;
    _.merge(draftImages[imageId].imageMetadata, { PACS: { isSync: syncError === undefined } });
    draftImages[imageId].PACS.isSyncOngoing = false;
  } else if (type === 'pacsSyncNeeded') {
    if (draftImages[imageId].imageMetadata?.PACS) {
      _.merge(draftImages[imageId].imageMetadata, { PACS: { isSync: false } });
    }
  } else if (type === 'displayableMetadataUpdate') {
    const { metadata } = payload;
    draftImages[imageId].displayedMetadata = metadata;
  } else if (type === 'negatoChangeMode') {
    const { negatoMode } = payload;
    draftState.negatoMode = negatoMode;
    const { viewCount: newViewCount = 1 } = NEGATO_MODE_INFO[draftState.negatoMode as NegatoMode];
    if (draftState.selectedNegatoView >= newViewCount) {
      draftState.selectedNegatoView = 0;
      const baseViewImageId = _.findKey(draftImages, { negatoView: 0 });
      selectImage(draftState, baseViewImageId);
    }

    const selectedImageId = _.findKey(draftImages, 'isSelected');

    const imagePosition = draftImagesOrder.indexOf(selectedImageId);
    for (let posOffset = 1; posOffset < draftImagesOrder.length; posOffset++) {
      const imageId = draftImagesOrder[(imagePosition + posOffset) % draftImagesOrder.length];
      if (imageId === selectedImageId) break;

      if (displayImageInFreeView(draftState, imageId) === DisplayState.NO_FREE_VIEW) {
        break;
      }
    }
  } else if (type === 'negatSelectView') {
    const { viewIndex } = payload;

    selectNegatoViewInImages(draftImages, viewIndex);
    draftState.selectedNegatoView = viewIndex;
  }
};

const imagesReducer = (initialState: ViewerState, action: Action, deps: Dependencies) => {
  const producedState = produce(initialState, (draftState) =>
    actionReducer(draftState, action, deps)
  );

  // We check if state change happened to produce updated state to save
  const finalState = produce(producedState, (draftProducedState) =>
    updateSavedState(initialState, draftProducedState)
  );
  return finalState;
};

export default imagesReducer;
