import './style.scss';

import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';

import PACSConfiguration from 'app/components/PACSConfiguration';
import { setPACSConfiguration } from 'app/redux/PACSConfiguration/actions';
import { selectPACSConfiguration } from 'app/redux/PACSConfiguration/reducer';
import { FormattedMessage } from 'react-intl';

const PACSConfigurationModal = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContentKey, setModalContentKey] = useState(0);

  const dispatch = useDispatch();

  const pacsConfiguration = useSelector(selectPACSConfiguration);

  const openModal = useCallback(() => setModalOpen());

  const closeModal = useCallback(() => {
    setModalContentKey(modalContentKey + 1);
    setModalOpen(false);
  });

  const onAssociationSuccess = useCallback(
    (associationParameters) => {
      dispatch(setPACSConfiguration({ ...associationParameters, enabled: true }));
      closeModal();
    },
    [dispatch]
  );

  return (
    <Modal
      trigger={
        <Button size="small" className="pacs-configuration-row__modal--button">
          <FormattedMessage id="account.configuration.pacs.configure" />
        </Button>
      }
      open={modalOpen}
      closeOnEscape={false}
      onOpen={openModal}
      onClose={closeModal}
      size="large"
      closeIcon
    >
      <Modal.Header>
        <FormattedMessage id="account.configuration.pacs.modal.title" />
      </Modal.Header>
      <Modal.Content>
        <PACSConfiguration
          onAssociationSuccess={onAssociationSuccess}
          initialValue={pacsConfiguration}
          key={modalContentKey}
        />
      </Modal.Content>
    </Modal>
  );
};

export default PACSConfigurationModal;
