import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import CloudBasedStudyStore from 'app/adapters/CloudBasedStudyStore';
import CombinedStudyStore from 'app/adapters/CombinedStudyStore';
import CornerstoneImageLoader from 'app/adapters/CornerstoneImageLoader';
import LocalStudyStore from 'app/adapters/LocalStudyStore';
import IDisplayableImageEncoderContext from 'app/providers/IDisplayableImageEncoder/context';
import { LocalDicomStoreContext } from 'app/providers/LocalDicomStoreProvider';
import StudyStoreContext from 'app/providers/StudyStoreProvider/context';
import { isElectron } from 'app/utils/envUtil';

export default function CombinedStudyStoreProvider({ children }: React.PropsWithChildren<{}>) {
  const intl = useIntl();
  const localDicomStore = useContext(LocalDicomStoreContext);

  const [imageLoader] = useState(() => new CornerstoneImageLoader());
  const imageEncoder = useContext(IDisplayableImageEncoderContext);
  const [localStudyStore] = useState(() =>
    isElectron() ? new LocalStudyStore(imageLoader) : undefined
  );
  const [cloudBaseStudyStore] = useState(() => new CloudBasedStudyStore(imageLoader, imageEncoder));
  const [studyStore] = useState(() =>
    localStudyStore
      ? new CombinedStudyStore(localStudyStore, cloudBaseStudyStore)
      : cloudBaseStudyStore
  );

  useEffect(() => {
    localStudyStore?.syncIntl(intl);
  }, [intl]);

  useEffect(() => {
    localStudyStore?.syncLocalDicomStore(localDicomStore);
  }, [localDicomStore]);

  return <StudyStoreContext.Provider value={studyStore}>{children}</StudyStoreContext.Provider>;
}
