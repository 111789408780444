import { selectRoute } from 'app/redux/route/reducer';
import { redirectToURLWithNewLocale } from 'app/utils/languageUtil';
import observeStore from '../../utils/redux/observeStore';
import { selectLocale } from '../../redux/language/selectors';

const attachURLRedirectionManagerObserver = (store) => {
  const selectLocaleFromState = selectLocale;
  const selectRouteFromState = selectRoute;
  const onLocaleChange = ({ state: locale }) => {
    const route = selectRouteFromState(store.getState());
    let { location } = window;
    if (route.locationBeforeTransitions !== null) {
      location = route.locationBeforeTransitions;
    }
    redirectToURLWithNewLocale(location, locale);
  };

  observeStore(store, selectLocaleFromState, onLocaleChange);
};

export default attachURLRedirectionManagerObserver;
