import _ from 'lodash';
import { v4 } from 'uuid';

import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import observeStore from 'app/utils/redux/observeStore';
import { Store } from 'redux';

export const DEVICE_PARAMETERS_LOCAL_STORAGE_KEY = 'redux.deviceParameters';

type DeviceParametersState = {
  deviceIdentifier: string;
};

type StoreWithDeviceParameters = { deviceParameters: DeviceParametersState };

export const selectDeviceParameters = (state: StoreWithDeviceParameters) => state.deviceParameters;
export const selectDeviceIdentifier = (state: StoreWithDeviceParameters) =>
  selectDeviceParameters(state).deviceIdentifier;

export const attachDeviceParametersWithLocalStorageSynchronizationObserver = <
  S extends StoreWithDeviceParameters
>(
  store: Store<S>
) => {
  observeStore(
    store,
    selectDeviceParameters,
    makeSyncReduxWithLocalStorageObserver(DEVICE_PARAMETERS_LOCAL_STORAGE_KEY)
  );
};

const getInitialState = (): DeviceParametersState => {
  const initialState = restoreFromLocalStorage(
    DEVICE_PARAMETERS_LOCAL_STORAGE_KEY,
    {}
  ) as DeviceParametersState;
  initialState.deviceIdentifier ??= v4();
  return initialState;
};

const deviceParametersReducer = (state: DeviceParametersState = getInitialState(), action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default deviceParametersReducer;
