/* eslint-disable camelcase */
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import { produce } from 'immer';
import {
  ACQUISITION_PERMISSION,
  TELERADIOLOGY_PERMISSION,
  ADMIN_PERMISSION,
} from 'app/utils/apiCalls';
import observeStore from 'app/utils/redux/observeStore';
import {
  ManagerPermissions,
  PermissionsAction,
  SET_MANAGER_PERMISSIONS,
  UPDATE_PERMISSIONS,
} from './actions';
import { Store } from 'redux';

export const PERMISSIONS_LOCAL_STORAGE_KEY = 'redux.permissions' as const;

type PermissionsState = {
  admin: boolean;
  teams: Record<string, string>;
  acquisition: boolean;
  teleradiology: boolean;
  managerPermissions: ManagerPermissions;
};

type StoreWithPermissions = {
  permissions: PermissionsState;
};

const permissionsInitialState = (): PermissionsState => ({
  admin: false,
  teams: {},
  acquisition: false,
  teleradiology: false,
  managerPermissions: {},
  ...restoreFromLocalStorage(
    PERMISSIONS_LOCAL_STORAGE_KEY,
    restoreFromLocalStorage('global.subscriptions')
  ),
});

function permissionsReducer(
  state: PermissionsState = permissionsInitialState(),
  action: PermissionsAction
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_MANAGER_PERMISSIONS: {
        const {
          can_see_users_managed_by = [],
          manager_name,
          can_change_manager = false,
        } = action.managerPermissions;

        draft.managerPermissions = {
          can_see_users_managed_by,
          manager_name,
          can_change_manager,
        };
        break;
      }

      case UPDATE_PERMISSIONS: {
        const hasAcquisitionPermissions = action.permissions.includes(ACQUISITION_PERMISSION);
        const hasTeleradiologyPermissions = action.permissions.includes(TELERADIOLOGY_PERMISSION);
        const isAdmin = action.permissions.includes(ADMIN_PERMISSION);

        const teams: Record<string, string> = {};
        action.permissions
          .filter((permission) => permission.startsWith('team.'))
          .forEach((permission) => {
            const [teamName, role] = permission.split('.').slice(1);
            teams[teamName] = role;
          });

        draft.admin = isAdmin;
        draft.acquisition = hasAcquisitionPermissions;
        draft.teleradiology = hasTeleradiologyPermissions;
        draft.teams = teams;
        break;
      }
    }
  });
}

export const selectPermissions = (state: StoreWithPermissions) => state.permissions;
export const selectPermissionAdmin = (state: StoreWithPermissions) => state.permissions.admin;
export const selectPermissionAcquisition = (state: StoreWithPermissions) =>
  state.permissions.acquisition;
export const selectPermissionTeleradiology = (state: StoreWithPermissions) =>
  state.permissions.teleradiology;
export const selectPermissionTeams = (state: StoreWithPermissions) => state.permissions.teams;

export const attachPermissionsWithLocalStorageSynchronizationObserver = <
  S extends StoreWithPermissions
>(
  store: Store<S>
) => {
  observeStore(
    store,
    selectPermissions,
    makeSyncReduxWithLocalStorageObserver(PERMISSIONS_LOCAL_STORAGE_KEY)
  );
};

export default permissionsReducer;
