export const SET_STUDY_ID = 'SET_STUDY_ID' as const;
export const SET_IMAGE_ID = 'SET_IMAGE_ID' as const;

export const setStudyId = (studyId: string) => ({
  type: SET_STUDY_ID,
  payload: { studyId },
});
export const setImageId = (imageId: string) => ({
  type: SET_IMAGE_ID,
  payload: { imageId },
});
