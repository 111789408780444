import { ImageForReport } from 'app/utils/reports/types';
import * as _ from 'lodash';
import { IntlShape } from 'react-intl';
import {
  GLOBAL_THRESHOLD_FOR_SECURE,
  ABDOMEN_GROUPS,
  ABDOMEN_PATTERNS,
  ABDOMEN_THRESHOLD_FOR_DOUBTFUL,
  COMMON_PATTERNS_DESCRIPTION,
} from 'app/components/PatternStructuredList/patternsGroups';
import { newline, tab, bulletPointLevel1, bulletPointLevel2 } from './constants';
import GenericPatternsReportGenerator from './GenericPatternsReportGenerator';

class AbdomenThoraxReportGenerator extends GenericPatternsReportGenerator {
  generateReport = (imagesInCase: ImageForReport[]) => {
    const returned = this.getMaxPredictions(imagesInCase);
    const { maxPredictionsFeedback, maxPredictions } = returned;
    const numberOfPatternsDetected = this.getNumberOfPatternsToDisplay(
      maxPredictions,
      maxPredictionsFeedback,
      ABDOMEN_PATTERNS
    );
    let report = `${this.formatMessage('abdomen').toUpperCase()} :${newline}${newline}`;
    report += this.getShortCompteRendu(maxPredictions, maxPredictionsFeedback, {
      ...COMMON_PATTERNS_DESCRIPTION,
      ...ABDOMEN_PATTERNS,
    });

    if (numberOfPatternsDetected === 0) {
      report += this.formatMessage('report.abdomen.isOk');
    } else if (numberOfPatternsDetected <= 2) {
      report += this.formatMessage('report.abdomen.restIsOk');
    }

    report += newline + newline;
    return report;
  };

  /**
   * Generates Report as a List
   * @param imagesInCase
   * @returns string
   */
  generateList = (imagesInCase: ImageForReport[]) => {
    const returned = this.getMaxPredictions(imagesInCase);
    const { maxPredictionsFeedback, maxPredictions } = returned;
    const numberOfPatternsDetected = this.getNumberOfPatternsToDisplay(
      maxPredictions,
      maxPredictionsFeedback,
      ABDOMEN_PATTERNS
    );

    /* ABDOMEN TITLE */
    let list = this.formatMessage('abdomen').toUpperCase() + ' :' + newline;

    if (numberOfPatternsDetected === 0) {
      list += newline + tab + this.formatMessage('report.abdomen.isOk');
    }

    const groups = ABDOMEN_GROUPS;
    Object.keys(groups).forEach((group: keyof typeof groups) => {
      const patterns = groups[group].patterns;
      const differentialDiagnosis = _.get(groups[group], 'differentialDiagnosis', []);

      /* GROUP TITLE */
      let title = newline + tab + this.formatMessage(group);
      let countPartterns = 0;
      let partternsLines = '';
      let patternsMarques: string[] = [];
      let patternsPresents: string[] = [];

      patterns.forEach((pattern: string) => {
        if (maxPredictions[pattern] > ABDOMEN_THRESHOLD_FOR_DOUBTFUL) {
          countPartterns++;
          if (maxPredictions[pattern] >= GLOBAL_THRESHOLD_FOR_SECURE) {
            patternsMarques.push(pattern);
          } else {
            patternsPresents.push(pattern);
          }
        }
      });

      /* RIEN À SIGNALER */
      if (countPartterns === 0) {
        title += ' ' + this.formatMessage('patternStructuredList.noPatternInThisGroup');
      }

      /* Titre Zone */
      list += title;

      /* Présence Marquée >= 75% */
      if (patternsMarques.length) {
        patternsMarques.forEach((pattern) => {
          partternsLines +=
            newline +
            tab +
            tab +
            bulletPointLevel1 +
            this.formatMessage(`report.pathology.list.abdomen.${pattern}`) +
            ' : ' +
            this.formatMessage('report.pathology.list.marked-presence');
        });
      }

      /* Présence > 45% & <= 75% */
      if (patternsPresents.length) {
        patternsPresents.forEach((pattern) => {
          partternsLines +=
            newline +
            tab +
            tab +
            bulletPointLevel1 +
            this.formatMessage(`report.pathology.list.abdomen.${pattern}`);
        });
      }
      list += partternsLines + newline;

      /* Differential Diagnosis */
      if (differentialDiagnosis.length) {
        let patternsDD: string[] = [];

        differentialDiagnosis.forEach((pattern: string) => {
          if (maxPredictions[pattern] > ABDOMEN_THRESHOLD_FOR_DOUBTFUL) {
            patternsDD.push(pattern);
          }
        });
        if (patternsDD.length) {
          const titleDD =
            patternsPresents.length + patternsMarques.length > 1 ? 'plural' : 'singular';
          list +=
            newline +
            tab +
            tab +
            this.formatMessage(`report.pathology.list.differential_diagnosis.${titleDD}`);

          patternsDD.forEach((pattern) => {
            list += newline + tab + tab + tab + bulletPointLevel2 + this.formatMessage(pattern);
          });
          list += newline;
        }
      }
    });

    if (numberOfPatternsDetected <= 2) {
      list += newline + tab + this.formatMessage('report.abdomen.restIsOk') + newline;
    }

    list += newline;
    return list;
  };
}

const getAbdomenReport = (imagesInCase: ImageForReport[], intl: IntlShape) => {
  const abdomenReportGenerator = new AbdomenThoraxReportGenerator(intl);

  if (['fr', 'en'].includes(intl.locale) === false) {
    return abdomenReportGenerator.generateList(imagesInCase);
  }

  return abdomenReportGenerator.generateReport(imagesInCase);
};

export default getAbdomenReport;
