export const SET_MANAGER_PERMISSIONS = 'SET_MANAGER_PERMISSIONS' as const;
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS' as const;

export type ManagerPermissions = {
  can_see_users_managed_by: string[];
  manager_name: string;
  can_change_manager: boolean;
};
export function updateManagerPermissions(
  managerPermissions: ManagerPermissions = {} as ManagerPermissions
) {
  return {
    type: SET_MANAGER_PERMISSIONS,
    managerPermissions,
  };
}

/**
 * Update permissions
 */
export function updatePermissions(permissions: string[] = []) {
  return {
    type: UPDATE_PERMISSIONS,
    permissions,
  };
}

export type PermissionsAction =
  | ReturnType<typeof updateManagerPermissions>
  | ReturnType<typeof updatePermissions>;
