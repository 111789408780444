export const UPDATE_TELERADIOLOGY = 'UPDATE_TELERADIOLOGY';

type TeleradiologyConfig = {
  type: string | null;
  affiliateId: string | null;
};

function updateTeleradiology(
  teleradiology: TeleradiologyConfig = { type: null, affiliateId: null }
) {
  return {
    type: UPDATE_TELERADIOLOGY,
    teleradiology,
  };
}

export type TeleradiologyAction = ReturnType<typeof updateTeleradiology>;

export default updateTeleradiology;
