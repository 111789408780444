import { produce } from 'immer';
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import {
  INPUT_MAPPING_SET_DICOM_MAPPING,
  UserInputMappingAction,
} from 'app/redux/userInputMapping/actions';
import observeStore from 'app/utils/redux/observeStore';
import { createSelector } from 'reselect';
import { Store } from 'redux';

export const USER_INPUT_MAPPING_LOCAL_STORAGE_KEY = 'redux.userInputMapping';

export type UserInputMappingState = {
  dicomMapping: { species: object };
};

const userInputMappingInitialState: UserInputMappingState = restoreFromLocalStorage(
  USER_INPUT_MAPPING_LOCAL_STORAGE_KEY,
  { dicomMapping: undefined }
);

const userInputMappingReducer = (
  state = userInputMappingInitialState,
  action: UserInputMappingAction
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case INPUT_MAPPING_SET_DICOM_MAPPING:
        draft.dicomMapping = action.dicomMapping;
        break;
    }
  });
};

export const selectUserInputMapping = <S extends { userInputMapping: UserInputMappingState }>(
  state: S
) => state.userInputMapping;
export const selectDicomMapping = createSelector(
  selectUserInputMapping,
  (state: UserInputMappingState) => state.dicomMapping
);

export const attachUserInputMappingWithLocalStorageSynchronizationObserver = <
  S extends { userInputMapping: UserInputMappingState }
>(
  store: Store<S>
) => {
  observeStore(
    store,
    selectUserInputMapping,
    makeSyncReduxWithLocalStorageObserver(USER_INPUT_MAPPING_LOCAL_STORAGE_KEY)
  );
};

export default userInputMappingReducer;
