import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import { WMConfiguration } from 'app/dicom/WorklistMonitor';
import { AssociationParameters } from 'app/interfaces/PACSCommunication';
import {
  SET_PACS_CONFIGURATION,
  SET_PACS_ENABLED,
  setPACSConfiguration,
  setPACSEnabled,
} from 'app/redux/PACSConfiguration/actions';
import observeStore from 'app/utils/redux/observeStore';
import produce from 'immer';
import _ from 'lodash';
import { Store } from 'redux';

export const PACS_CONFIGURATION_LOCAL_STORAGE_KEY = 'redux.PACSConfiguration';

export type PACSConfigurationState = AssociationParameters & { enabled?: boolean } & {
  worklist?: WMConfiguration;
};

type StoreWithPACSConfigurationState = { PACSConfiguration: PACSConfigurationState };

const initPACSConfigurationState = () =>
  Object.freeze<PACSConfigurationState>(
    restoreFromLocalStorage(PACS_CONFIGURATION_LOCAL_STORAGE_KEY, {})
  );

type PACSConfigurationActions =
  | ReturnType<typeof setPACSConfiguration>
  | ReturnType<typeof setPACSEnabled>;

const PACSConfigurationReducer = (
  state = initPACSConfigurationState(),
  action: PACSConfigurationActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_PACS_CONFIGURATION:
        return { ...action.pacsConfiguration };
      case SET_PACS_ENABLED:
        draft.enabled = action.enabled;
        break;
    }
  });

export const selectPACSConfiguration = (
  state: StoreWithPACSConfigurationState
): PACSConfigurationState => state.PACSConfiguration;

export const isPACSConfigurationDisabled = (PACSConfiguration: PACSConfigurationState) => {
  return !PACSConfiguration?.enabled;
};

export const attachPACSConfigurationWithLocalStorageSynchronizationObserver = <
  S extends StoreWithPACSConfigurationState
>(
  store: Store<S>
) => {
  observeStore(
    store,
    selectPACSConfiguration,
    makeSyncReduxWithLocalStorageObserver(PACS_CONFIGURATION_LOCAL_STORAGE_KEY)
  );
};

export default PACSConfigurationReducer;
