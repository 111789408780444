import { createSelector } from 'reselect';
import { StoreWithGlobalState } from './reducer';

export const selectGlobal = (state: StoreWithGlobalState) => state.global;
export const selectUsername = createSelector(selectGlobal, (global) => global.username);
export const selectPassword = createSelector(selectGlobal, (global) => global.password);
export const selectLoggedIn = (state: StoreWithGlobalState) => state.global.loggedIn;
export const selectLoginError = createSelector(selectGlobal, (global) => global.loginError);
export const selectSubscribed = (state: StoreWithGlobalState) =>
  state.global.subscriptions.subscribed;
export const selectAIPanelMode = (state: StoreWithGlobalState) => state.global.AIPanelMode;
