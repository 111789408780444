/*
 *
 * LanguageProvider reducer
 *
 */

import produce from 'immer';
import { getInitLanguage } from '../../utils/languageUtil';

import { CHANGE_LOCALE, LanguageActions } from './actions';
import { Action } from 'redux';

type LanguageState = {
  locale: string;
};
export type StoreWithLanguage = {
  language: LanguageState;
};

const initialState: LanguageState = {
  locale: getInitLanguage(),
};

const languageProviderReducer = produce(
  (draft: LanguageState, action: Action | LanguageActions) => {
    switch (action.type) {
      case CHANGE_LOCALE: {
        draft.locale = (action as LanguageActions).locale;
        break;
      }
    }
  },
  initialState
);

export default languageProviderReducer;
