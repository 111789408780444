/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { Reducer, combineReducers } from 'redux';

import languageProviderReducer from 'app/redux/language/reducer';
import globalReducer from 'app/redux/global/reducer';
import pmsReducer from 'app/redux/pms/reducer';
import viewerConfigurationReducer, {
  VIEWER_CONFIGURATION_LOCAL_STORAGE_KEY,
} from 'app/redux/viewerConfiguration/reducer';
import { makeSyncReduxWithLocalStorageObserver } from 'app/dataManagement/syncReduxStateWithLocalStorage';
import observeStore from 'app/utils/redux/observeStore';
import PACSConfigurationReducer from 'app/redux/PACSConfiguration/reducer';
import xrayConfigurationReducer from 'app/redux/xrayGeneratorConfiguration/reducer';
import flatPanelConfigurationReducer from 'app/redux/flatPanelConfiguration/reducer';
import versionUpdateReducer from 'app/redux/versionUpdate/reducer';
import userInputMappingReducer from 'app/redux/userInputMapping/reducer';
import aiOnlyConfigurationReducer from 'app/redux/aiOnlyConfiguration/reducer';
import deviceParametersReducer from 'app/redux/deviceParameters/reducer';
import permissionsReducer from 'app/redux/permissions/reducer';
import teleradiologyReducer from 'app/redux/teleradiology/reducer';
import announcementsReducer from 'app/redux/announcements/reducer';
import currentStudyInfosReducer from 'app/redux/currentStudyInfos/reducer';
import routeReducer from './route/reducer';

export const selectViewerConfiguration = (state) => state.viewerConfiguration;

export const attachViewerConfigurationWithLocalStorageSynchronizationObserver = (store) => {
  observeStore(
    store,
    selectViewerConfiguration,
    makeSyncReduxWithLocalStorageObserver(VIEWER_CONFIGURATION_LOCAL_STORAGE_KEY)
  );
};

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer<S>(asyncReducers: Record<string, Reducer<S>> = {}) {
  return combineReducers({
    route: routeReducer,
    global: globalReducer,
    language: languageProviderReducer,
    pms: pmsReducer,
    userInputMapping: userInputMappingReducer,
    viewerConfiguration: viewerConfigurationReducer,
    xRayConfiguration: xrayConfigurationReducer,
    PACSConfiguration: PACSConfigurationReducer,
    flatPanelConfiguration: flatPanelConfigurationReducer,
    versionUpdate: versionUpdateReducer,
    aiOnlyConfiguration: aiOnlyConfigurationReducer,
    deviceParameters: deviceParametersReducer,
    permissions: permissionsReducer,
    teleradiology: teleradiologyReducer,
    announcements: announcementsReducer,
    currentStudyInfos: currentStudyInfosReducer,
    ...asyncReducers,
  });
}
