import { SharedUserConfigurationAPI } from 'app/interfaces/SharedUserConfiguration';
import { StoreWithSharedUserConfiguration } from 'app/redux/middleware/SharedUserConfigMiddleware';
import { loadSharedUserConfig as loadSharedUserConfigAction } from 'app/redux/sharedUserConfig/actions';
import logger from 'app/utils/debug/logger';
import { Store } from 'redux';

export default function loadSharedUserConfigFromAPI<S extends StoreWithSharedUserConfiguration>(
  store: Store<S>,
  api: SharedUserConfigurationAPI
) {
  return api
    ?.get()
    .then((sharedUserConfig) => {
      store.dispatch(loadSharedUserConfigAction(sharedUserConfig));
      logger.log('Shared user configuration loaded successfully.');
    })
    .catch((error) => {
      logger.warn('Error loading shared user configuration:', error);
    });
}
