import { PACSConfigurationState } from 'app/redux/PACSConfiguration/reducer';

export const SET_PACS_CONFIGURATION = 'SET_PACS_CONFIGURATION' as const;
export const SET_PACS_ENABLED = 'SET_PACS_ENABLED' as const;

export const setPACSConfiguration = (pacsConfiguration: Partial<PACSConfigurationState>) => ({
  type: SET_PACS_CONFIGURATION,
  pacsConfiguration,
});

export const setPACSEnabled = (enabled: boolean) => ({
  type: SET_PACS_ENABLED,
  enabled,
});
