import React from 'react';
import * as pt from 'prop-types';

import DirectorySelector from 'app/components/DirectorySelector';
import FilesystemUtility from 'app/interfaces/FileSystemUtility';
import { makeSelectWatchedDirectory } from 'app/redux/pms/selector';
/**
 * @param {{
 * watchedDirectorySelector,
 * isErroneousFieldSelector,
 * fileSystemUtility: FilesystemUtility
 * }} props
 */
const PMSWatchedDirectorySelector = ({
  label,
  fileSystemUtility,
  useDirectory,
  setSelectedDirectory,
}) => {
  const placeholderKey = 'account.configuration.no_directory_selected';
  const invalidDirectoryKey = 'account.configuration.is_invalid_directory';
  const setDirectoryDescriptionKey = 'account.configuration.pms_watched_directory.description';
  const clearDirectoryDescriptionKey =
    'account.configuration.pms_watched_directory.clear_directory.description';

  return (
    <DirectorySelector
      label={label}
      fileSystemUtility={fileSystemUtility}
      useDirectory={useDirectory}
      setSelectedDirectory={setSelectedDirectory}
      placeholderKey={placeholderKey}
      invalidDirectoryKey={invalidDirectoryKey}
      setDirectoryDescriptionKey={setDirectoryDescriptionKey}
      clearDirectoryDescriptionKey={clearDirectoryDescriptionKey}
    />
  );
};

PMSWatchedDirectorySelector.propTypes = {
  useDirectory: pt.func.isRequired,
  setSelectedDirectory: pt.func.isRequired,
  fileSystemUtility: pt.shape(FilesystemUtility).isRequired,
  label: pt.oneOfType([pt.string, pt.element]),
};

PMSWatchedDirectorySelector.defaultProps = {
  watchedDirectorySelector: makeSelectWatchedDirectory(),
  label: undefined,
};

export default PMSWatchedDirectorySelector;
