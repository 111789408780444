import produce from 'immer';
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import {
  SET_DETECTOR_IP_CONFIGURATION,
  SET_DETECTOR_KIND,
  DetectorKind,
  FlatPanelConfigurationAction,
} from 'app/redux/flatPanelConfiguration/actions';
import observeStore from 'app/utils/redux/observeStore';
import { Store } from 'redux';

export const FLAT_PANEL_CONFIGURATION_LOCAL_STORAGE_KEY = 'redux.flatPanelConfiguration';

export type FlatPanelConfigurationState = {
  detectorKind: DetectorKind;
  detectorIP?: string;
  hostIP?: string;
};
type StoreWithFlatPanelConfiguration = { flatPanelConfiguration: FlatPanelConfigurationState };

export const selectFlatPanelConfiguration = (
  state: StoreWithFlatPanelConfiguration
): FlatPanelConfigurationState => state.flatPanelConfiguration;

export const selectFlatPanelKind = (state: StoreWithFlatPanelConfiguration) =>
  selectFlatPanelConfiguration(state).detectorKind;

export const attachFlatPanelConfigurationWithLocalStorageSynchronizationObserver = <
  S extends StoreWithFlatPanelConfiguration
>(
  store: Store<S>
) => {
  observeStore(
    store,
    selectFlatPanelConfiguration,
    makeSyncReduxWithLocalStorageObserver(FLAT_PANEL_CONFIGURATION_LOCAL_STORAGE_KEY)
  );
};

const getInitialState = (): FlatPanelConfigurationState =>
  Object.freeze(restoreFromLocalStorage(FLAT_PANEL_CONFIGURATION_LOCAL_STORAGE_KEY, {}));

const flatPanelConfigurationReducer = (
  state: FlatPanelConfigurationState = getInitialState(),
  { type, payload }: FlatPanelConfigurationAction
) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_DETECTOR_KIND:
        draft.detectorKind = payload.detectorKind;
        break;
      case SET_DETECTOR_IP_CONFIGURATION:
        draft.detectorIP = payload.detectorIP;
        draft.hostIP = payload.hostIP;
        break;
      default:
    }
  });

export default flatPanelConfigurationReducer;
