import './style.scss';

import React, { useCallback, useEffect } from 'react';

import { DisplayableImageData, ImageAnnotations, Viewport } from 'app/interfaces/Image';
import { FormattedMessage } from 'react-intl';
import StaticImageRenderer from 'app/components/StaticImageRenderer';

export type ImageId = number | string;
export type SelectableImage = {
  id: ImageId;
  image: DisplayableImageData;
  viewport?: Viewport;
  annotations?: ImageAnnotations;
};

export type ImageSelectorProps = {
  images: SelectableImage[];
  selectedImagesIds?: ImageId[]; // Use this field to switch from controlled to uncontrolled component behavior.
  onSelect: (images: ImageId[]) => void;
  title?: string | JSX.Element;
  imageRender?: (props: any, index: number) => React.ReactNode;
  // Width and height of each image compatible with css.
  miniatureSize?: string;
};

export default function ({
  title,
  images,
  selectedImagesIds,
  onSelect,
  imageRender,
  miniatureSize = '7rem',
}: ImageSelectorProps) {
  const [internalSelectedImages, setInternalSelectedImages] = React.useState(
    images.map((image) => image.id)
  );

  const selectedImages = selectedImagesIds ?? internalSelectedImages;
  const setSelectedImages = useCallback(
    (newSelectedImages: ImageId[]) => {
      if (!selectedImagesIds) {
        setInternalSelectedImages(newSelectedImages);
      }
      onSelect(newSelectedImages);
    },
    [onSelect, setInternalSelectedImages]
  );

  const selectAllImages = () => {
    const allImagesIDs = images.map((image) => image.id);
    setSelectedImages(allImagesIDs);
  };

  const unselectAllImages = () => {
    setSelectedImages([]);
  };

  const selectImage = useCallback(
    (imageId: ImageId) => {
      {
        const imageIndex = selectedImages.indexOf(imageId);
        const isImageSelected = imageIndex !== -1;
        const newSelectedImages = [...selectedImages];
        if (isImageSelected) {
          newSelectedImages.splice(imageIndex, 1);
        } else {
          newSelectedImages.push(imageId);
        }
        setSelectedImages(newSelectedImages);
      }
    },
    [selectedImages, setSelectedImages]
  );
  useEffect(() => {
    onSelect?.(selectedImages);
  }, []);

  return (
    <div className="section selectImages">
      {title && <p className="label">{title}</p>}
      <div className="selectImages__controls">
        <button
          className="selectImages__button"
          type="button"
          onClick={selectAllImages}
          data-testid="selectAll-button"
        >
          <FormattedMessage id="exportStudyModal.selectImages.button.selectAll" />
        </button>
        <button
          className="selectImages__button"
          type="button"
          onClick={unselectAllImages}
          data-testid="unselectAll-button"
        >
          <FormattedMessage id="exportStudyModal.selectImages.button.unselectAll" />
        </button>
      </div>
      <div className="selectImages__imagesList">
        {images.map(({ id, image, viewport, annotations }, index) => {
          const isImageSelected = selectedImages.includes(id);

          const renderProps = { image, viewport, annotations };
          return (
            <div
              key={id}
              data-testid="radio-image"
              role="button"
              className={`selectImages__image ${isImageSelected ? 'selected' : ''}`}
              style={{ width: miniatureSize, height: miniatureSize }}
              onClick={() => selectImage(id)}
            >
              {imageRender?.(renderProps, index) ?? <StaticImageRenderer {...renderProps} />}
            </div>
          );
        })}
      </div>
    </div>
  );
}
