import { DicomData } from 'app/interfaces/Dicom';
import { DicomRegistry } from 'app/interfaces/DicomRegistry';
import { FindQueryModel, PACSCommunication } from 'app/interfaces/PACSCommunication';
import { Patient } from 'app/interfaces/Patient';
import MaxDurationPromise from 'app/utils/MaxDurationPromise';
import LocalStorageCacheWithExpiry from 'app/utils/cache/LocalStorageCacheWithExpiry';
import logger from 'app/utils/debug/logger';
import {
  dicomDateToDateTime,
  getDicomDataValue,
  getPatientInfoFromDicomData,
} from 'app/utils/dicom/DicomData';
import { format } from 'date-fns';
import _ from 'lodash';

const DEFAULT_INTERVAL = 5 * 60;
const MIN_INTERVAL = 1 * 60; // 1 minute minimum interval

export type WMPACSCommunication = Pick<PACSCommunication, 'find'>;

export type WMPatient = Pick<
  Patient,
  | 'name'
  | 'owner_name'
  | 'sex'
  | 'birth_date'
  | 'file_id'
  | 'specie'
  | 'race'
  | 'attending_veterinarian'
>;

export type WMExam = {
  RequestedProcedureID: string;
  StudyInstanceUID: string;
  AccessionNumber: string;
  ScheduledProcedureStepID: string;
  // Modality: string;
  planningDate: Date;
};

export type WMStudyStore = {
  createStudyFromWorklist: (patientInfo: WMPatient, exam: WMExam) => Promise<void>;
};

export type WMConfiguration = {
  aeTitles?: string[];
  isEnabled: boolean;
  interval: number; // Time in seconds
};

export default class WorklistMonitor extends EventTarget {
  private WORKLIST_CACHE_KEY = 'worklistCache';
  private isMonitorEnabled: boolean;
  private worklistInterval: NodeJS.Timer;
  private worklistCacheStorage: LocalStorageCacheWithExpiry<string> =
    new LocalStorageCacheWithExpiry(this.WORKLIST_CACHE_KEY, 2 * 24 * 60 * 60 * 1000);
  private retrieveWorklistPending: Promise<void> = undefined;

  constructor(
    private pacsCommunication: WMPACSCommunication,
    private studyStore: WMStudyStore,
    private configuration: WMConfiguration
  ) {
    super();
    this.update(pacsCommunication, studyStore, configuration);
    this.isMonitorEnabled = false;
    this.worklistCacheStorage.cleanUp();
  }

  // We guard ourselves against do frequennt intervals and convert the interval into milliseconds
  private getIntervalFrequency(): number {
    let intervalFrequency = DEFAULT_INTERVAL;
    if (this.configuration.interval) {
      intervalFrequency = this.configuration.interval;
    }
    return Math.max(intervalFrequency, MIN_INTERVAL) * 1000;
  }

  private addToWorklistCache(studyInstanceUID: string) {
    this.worklistCacheStorage.setItem(studyInstanceUID, studyInstanceUID);
  }

  update(
    pacsCommunication: WMPACSCommunication,
    studyStore: WMStudyStore,
    configuration: WMConfiguration
  ) {
    this.pacsCommunication = pacsCommunication;
    this.studyStore = studyStore;
    this.configuration = configuration;

    this.refreshMonitor();
  }

  private refreshMonitor(): void {
    this.close();
    if (!this.isMonitorEnabled || !this.configuration?.isEnabled) return;
    this.retrieveWorklist();
    this.worklistInterval = setInterval(this.retrieveWorklist, this.getIntervalFrequency());
  }

  isEnabled(): boolean {
    return this.pacsCommunication && this.configuration?.isEnabled;
  }

  enableMonitor(isMonitorEnabled: boolean) {
    this.isMonitorEnabled = isMonitorEnabled;
    this.refreshMonitor();
  }

  addEventListener(
    type: 'newWorlistItems',
    callback: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions
  ): void {
    super.addEventListener(type, callback, options);
  }

  retrieveWorklist = async () => {
    if (this.retrieveWorklistPending) {
      return this.retrieveWorklistPending;
    }
    this.retrieveWorklistPending = MaxDurationPromise(this.retrieveWorklistImpl(), 60 * 1000);
    // Handle possible hang-up by adding a maximum time limit
    return this.retrieveWorklistPending.finally(() => {
      this.retrieveWorklistPending = undefined;
    });
  };

  async retrieveWorklistImpl() {
    // We limit past date range to 2 days before now to reduce load on PACS
    const searchedDate = new Date();
    searchedDate.setDate(searchedDate.getDate() - 1);
    const worklistResult = await this.pacsCommunication.find(
      {
        ScheduledProcedureStepSequence: {
          Modality: ['DX', 'CR'],
          ScheduledStationAETitle: this.configuration?.aeTitles,
          ScheduledProcedureStepStartDate: `${format(searchedDate, 'yyyyMMdd')}-`,
          ScheduledProcedureStepStartTime: undefined,
          ScheduledPerformingPhysicianName: undefined,
          ScheduledProcedureStepDescription: undefined,
          ScheduledStationName: undefined,
          ScheduledProcedureStepLocation: undefined,
          ReferencedDefinedProtocolSequence: undefined,
          ReferencedPerformedProtocolSequence: undefined,
          ScheduledProtocolCodeSequence: undefined,
          PreMedication: undefined,
          ScheduledProcedureStepID: undefined,
          RequestedContrastAgent: undefined,
          ScheduledProcedureStepStatus: undefined,
        },
        PatientName: undefined,
        AccessionNumber: undefined,
        PatientID: undefined,
        IssuerOfPatientID: undefined,
        PatientBirthDate: undefined,
        PatientSex: undefined,
        PatientWeight: undefined,
        PatientSize: undefined,
        PatientState: undefined,

        RequestedProcedureID: undefined,
        StudyInstanceUID: undefined,
      },
      FindQueryModel.Worklist
    );

    let hasAnyNewItem = false;
    await Promise.allSettled(
      _.map(worklistResult, async (value: DicomData) => {
        const ScheduledProcedureStepSequence = getDicomDataValue(
          value,
          'ScheduledProcedureStepSequence'
        );
        logger.info('ScheduledProcedureStepSequence', ScheduledProcedureStepSequence);
        const exam: WMExam = {
          RequestedProcedureID: getDicomDataValue(value, 'RequestedProcedureID'),
          StudyInstanceUID: getDicomDataValue(value, 'StudyInstanceUID'),
          AccessionNumber: getDicomDataValue(value, 'AccessionNumber'),
          ScheduledProcedureStepID: getDicomDataValue(
            ScheduledProcedureStepSequence,
            'ScheduledProcedureStepID'
          ),
          // Modality: getDicomDataValue(value, 'Modality'),
          planningDate: dicomDateToDateTime(
            getDicomDataValue(ScheduledProcedureStepSequence, 'ScheduledProcedureStepStartDate'),
            getDicomDataValue(ScheduledProcedureStepSequence, 'ScheduledProcedureStepStartTime')
          ),
        };

        exam.StudyInstanceUID ??= exam.RequestedProcedureID;

        if (this.worklistCacheStorage.getItem(exam.StudyInstanceUID)) return;
        if (!exam.RequestedProcedureID && !exam.StudyInstanceUID) {
          return;
        }
        exam.planningDate ??= new Date();

        let patient: WMPatient = getPatientInfoFromDicomData(value);
        try {
          const patientDicomEntry = (
            (await this.pacsCommunication.find(
              {
                QueryRetrieveLevel: 'PATIENT',
                PatientName: getDicomDataValue(value, 'PatientName'),
                PatientID: getDicomDataValue(value, 'PatientID'),
                PatientBirthDate: undefined,
                PatientSex: undefined,
                PatientSexNeutered: undefined,
                ResponsiblePerson: undefined,
                PhysiciansOfRecord: undefined,
                PatientSpeciesDescription: undefined,
                PatientBreedDescription: undefined,
              },
              FindQueryModel.Patient
            )) as DicomData[]
          )?.[0];
          logger.info('patient info', patientDicomEntry);
          if (patientDicomEntry) {
            patient = getPatientInfoFromDicomData(patientDicomEntry);
          }
        } catch (error) {
          console.warn('Failed to retrieve patient info for ', value);
        }

        logger.info('Creating study with exam: ', exam);
        logger.info('Creating study with patient: ', patient);
        await this.studyStore.createStudyFromWorklist(patient, exam).then(() => {
          this.addToWorklistCache(exam.StudyInstanceUID);
        });
        hasAnyNewItem = true;
      })
    );
    if (hasAnyNewItem) {
      const worklistEvent = new Event('newWorlistItems');
      this.dispatchEvent(worklistEvent);
    }
  }

  close() {
    clearInterval(this.worklistInterval);
  }
}
