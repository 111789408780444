/* eslint-disable camelcase */
import produce from 'immer';
import { Store } from 'redux';
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import observeStore from 'app/utils/redux/observeStore';
import auth from 'app/containers/auth';
import { GLOBAL_SUBSCRIPTIONS_CONFIGURATION_LOCAL_STORAGE_KEY, GlobalAction } from './actions';
import {
  CHANGE_USERNAME,
  CHANGE_PASSWORD,
  SET_AUTH,
  SENDING_REQUEST,
  REQUEST_ERROR,
  CLEAR_ERROR,
  SET_SUBSCRIPTION_MAIL_SENT,
  SET_HAS_SUBSCRIBED,
  SET_AI_PANEL_DISPLAY_MODE,
} from './actions';
import { getAIPanelMode } from '../AIPanelManager';

type GlobalState = {
  username: string;
  password: string;
  loginError: any;
  currentlySending: boolean;
  loggedIn: boolean;
  subscription_mail_sent?: boolean;
  subscriptions: {
    subscribed: boolean;
    admin: boolean;
    teams: Record<string, boolean>;
    acquisition: boolean;
    teleradiology: boolean;
    managerPermissions: Record<string, boolean>;
  };
  AIPanelMode: string;
};
export type StoreWithGlobalState = { global: GlobalState };

const getAuthInitialState = (): GlobalState => ({
  username: '',
  password: '',
  loginError: null,
  currentlySending: false,
  loggedIn: auth.loggedIn(),
  subscriptions: {
    subscribed: false,
    /** @deprecated */
    admin: false,
    teams: {},
    acquisition: false,
    teleradiology: false,
    managerPermissions: {},
    ...restoreFromLocalStorage(GLOBAL_SUBSCRIPTIONS_CONFIGURATION_LOCAL_STORAGE_KEY),
  },
  AIPanelMode: getAIPanelMode(),
});

const globalReducer = (state = getAuthInitialState(), action: GlobalAction) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_SUBSCRIPTION_MAIL_SENT:
        // Kept but does not seem used
        // TODO Remove it
        draft.subscription_mail_sent = action.value;
        break;

      case CHANGE_USERNAME:
        draft.username = action.username;
        break;

      case CHANGE_PASSWORD:
        draft.password = action.password;
        break;

      case SET_AUTH:
        draft.loggedIn = action.newAuthState;
        break;

      case SENDING_REQUEST:
        draft.currentlySending = action.sending;
        break;

      case REQUEST_ERROR:
        draft.loginError = action.error;
        break;

      case CLEAR_ERROR:
        draft.loginError = null;
        break;

      case SET_HAS_SUBSCRIBED:
        draft.subscriptions.subscribed = action.hasSubscribed;
        break;

      case SET_AI_PANEL_DISPLAY_MODE:
        draft.AIPanelMode = action.mode;
        break;
    }
  });

export const attachGlobalSubscriptionsConfigurationWithLocalStorageSynchronizationObserver = <
  S extends StoreWithGlobalState
>(
  store: Store<S>
) => {
  observeStore(
    store,
    (state: StoreWithGlobalState) => state.global.subscriptions,
    makeSyncReduxWithLocalStorageObserver(GLOBAL_SUBSCRIPTIONS_CONFIGURATION_LOCAL_STORAGE_KEY)
  );
};

export default globalReducer;
