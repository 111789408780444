import CloudBasedStudyStore from 'app/adapters/CloudBasedStudyStore';
import LocalStudyStore from 'app/adapters/LocalStudyStore';

export default class CombinedStudyStore {
  /**
   *
   * @param {LocalStudyStore} localStudyStore
   * @param {CloudBasedStudyStore} cloudStudyStore
   */
  constructor(localStudyStore, cloudStudyStore) {
    this.localStudyStore = localStudyStore;
    this.cloudStudyStore = cloudStudyStore;

    this.updatePatient = this.cloudStudyStore.updatePatient;
    this.deleteImage = this.cloudStudyStore.deleteImage;
    this.getStudy = this.cloudStudyStore.getStudy;
    this.updateImage = this.cloudStudyStore.updateImage;
    this.updateReport = this.cloudStudyStore.updateReport;
    this.createStudyFromWorklist = async (...args) =>
      this.cloudStudyStore.createStudyFromWorklist(...args).then(() => {});
  }

  // The following methods try to save data on both local and cloud

  /** @type {StudyStore.updateThumbnailImage} */
  updateThumbnailImage = (...args) =>
    Promise.any([
      this.localStudyStore.updateThumbnailImage(...args),
      this.cloudStudyStore.updateThumbnailImage(...args),
    ]);

  /** @type {StudyStore.saveLastProcessedImage} */
  saveLastProcessedImage = (...args) =>
    Promise.any([
      this.localStudyStore.saveLastProcessedImage(...args),
      this.cloudStudyStore.saveLastProcessedImage(...args),
    ]);

  /** @type {StudyStore.saveRawImage} */
  saveRawImage = (...args) =>
    Promise.any([
      this.localStudyStore.saveRawImage(...args),
      this.cloudStudyStore.saveRawImage(...args),
    ]);
  // The following methods try to retrieve data from local then fall-back to cloud

  /** @type {StudyStore.loadImage} */
  loadImage = (...args) =>
    this.localStudyStore.loadImage(...args).catch(() => this.cloudStudyStore.loadImage(...args));

  /** @type {StudyStore.loadThumbnail} */
  loadThumbnail = (...args) =>
    this.localStudyStore
      .loadThumbnail(...args)
      .catch(() => this.cloudStudyStore.loadThumbnail(...args));
}
