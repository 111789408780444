import { restoreFromLocalStorage } from 'app/dataManagement/syncReduxStateWithLocalStorage';
import { SHARED_USER_CONFIG_LOADED } from 'app/redux/sharedUserConfig/actions';
import {
  FULL_SIZE_UPDATE,
  NO_XRAY_DELETION_TOGGLE,
  UPDATE_SYNC_TO_PACS,
  ViewerConfigurationAction,
} from 'app/redux/viewerConfiguration/actions';
import produce from 'immer';

export const VIEWER_CONFIGURATION_LOCAL_STORAGE_KEY = 'redux.viewerConfiguration';

export interface ViewerConfigurationState {
  syncToPACSOnValidate: boolean;
  noXRayDeletion: boolean;
  fullSize?: Record<string, number>;
}

const configurationInitialState: ViewerConfigurationState = {
  syncToPACSOnValidate: true,
  noXRayDeletion: false,
  ...restoreFromLocalStorage(VIEWER_CONFIGURATION_LOCAL_STORAGE_KEY),
};

const viewerConfigurationReducer = (
  state: ViewerConfigurationState = configurationInitialState,
  action: ViewerConfigurationAction
): ViewerConfigurationState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case FULL_SIZE_UPDATE:
        if (!action.data.detectorId) break;
        if (!draft.fullSize) draft.fullSize = {};
        draft.fullSize[action.data.detectorId] = action.data.scale;
        break;
      case NO_XRAY_DELETION_TOGGLE:
        draft.noXRayDeletion = action.data.enabled;
        break;
      case UPDATE_SYNC_TO_PACS:
        draft.syncToPACSOnValidate = action.data.syncToPACSOnValidate;
        break;
      case SHARED_USER_CONFIG_LOADED:
        draft.noXRayDeletion = action.data.noXRayDeletion;
        break;
    }
  });
};

export default viewerConfigurationReducer;
