import { produce } from 'immer';
import { Store } from 'redux';
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import {
  ANNOUNCEMENTS_SET_TELERADIOLOGY_SEEN,
  setAnnouncementsTeleradiologySeen,
} from 'app/redux/announcements/actions';
import observeStore from 'app/utils/redux/observeStore';

export const ANNOUNCEMENTS_LOCAL_STORAGE_KEY = 'redux.announcements';

interface AnnouncementsState {
  teleradiologySeen: boolean;
}

type AnnouncementsAction = ReturnType<typeof setAnnouncementsTeleradiologySeen>;

const getAnnouncementsInitialState = (): AnnouncementsState => ({
  teleradiologySeen: false,
  ...restoreFromLocalStorage(ANNOUNCEMENTS_LOCAL_STORAGE_KEY),
});

const announcementsReducer = (
  state = getAnnouncementsInitialState(),
  action: AnnouncementsAction
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ANNOUNCEMENTS_SET_TELERADIOLOGY_SEEN:
        draft.teleradiologySeen = action.isSeen;
        break;
    }
  });
};

export const selectAnnouncements = (state: any) => state.announcements;
export const selectAnnouncementsTeleradiologySeen = (state: any): boolean =>
  selectAnnouncements(state).teleradiologySeen;

export const attachAnnouncementsWithLocalStorageSynchronizationObserver = (store: Store<any>) => {
  observeStore(
    store,
    selectAnnouncements,
    makeSyncReduxWithLocalStorageObserver(ANNOUNCEMENTS_LOCAL_STORAGE_KEY)
  );
};

export default announcementsReducer;
